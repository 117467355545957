import { Modal, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { Download } from '../../../../componets/download';
import { searchCollection } from 'core/assets/icons';
import { Link, useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal as MOD } from "react-bootstrap";

import { phone } from 'core/assets/imgs';
import { useGetUserList, useEditUser, useGetProcessTypes, useGetRoleList, useEditRole, useGetUserRole } from '../../../auth/hooks/useUser';
import { FlapperSpinner } from 'react-spinners-kit';
import { phoneRegExp } from 'core/helpers';


const Schema = Yup.object().shape({
  // login: Yup.string()
  //   .required('Login Name is required')
  //   .matches(/^\S*$/, 'Username should not contain spaces').max(50, 'Text field must be at most 50 characters long'),

  firstName: Yup.string().required('First Name is required').max(50, 'Text field must be at most 50 characters long'),
  lastName: Yup.string().required('Last Name is required').max(50, 'Text field must be at most 50 characters long'),
  email: Yup.string().email('Wrong email format').required('Email is required').max(50, 'Text field must be at most 50 characters long'),
  // termsCondtion: Yup.string().required('Remember is required'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
});


  const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  };


const EditExistingUser = () => {
  const [showSvgPage, setShowSvgPage] = useState(false);
  const [editUserDetailsModal, setEditUserDetailsModal] = useState(false);
  const [editWorkflowAssignmentModal, setEditWorkflowAssignmentModal] =
    useState(false);
  const [editUserRolesResourcesModal, setEditUserRolesResourcesModal] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [dropdowns, setDropdowns] = useState({
    // "mda-top-official": false,
    // "mda-admin": false,
    // "mda-collection-officer": false,
    // "mda-payment-officer": false
  });
  const [getUser, setUser] = useState<any>([]);
  const [businessGroups, setSelectedUserArray] = useState<any[]>([]);



  


useEffect(() => {
  if (getUser && Object.keys(getUser).length > 0) {

    formik.setFieldValue("firstName", getUser.firstName)
    formik.setFieldValue("lastName", getUser.lastName)
    formik.setFieldValue("email", getUser.email)
    formik.setFieldValue("phoneNumber", getUser.phoneNumber)

  }
}, [getUser]);
  
  
  




  const navigate = useNavigate();

  const dataSource: any[] = [];

  const dataSourceForExcel: any[] = [[]];

  const { data, mutate, isLoading } = useGetUserList();

    const { data: role, mutate: mutateRole, isLoading: isLoadingRole } = useGetUserRole();

  const { data: dataRoleList, mutate: mutateRoleList, isLoading: isLoadingRoleList } = useGetRoleList();


  

  const { data: dateEditUser, mutate: mutateEditUser, isLoading: isLoadingEditUser } = useEditUser();

  const { data: dateEditRoleUser, mutate: mutateEditRoleUser, isLoading: isLoadingEditRoleUser } = useEditRole();


  

    const { data: ProcessTypes, mutate: mutateProcessTypes } =
    useGetProcessTypes();

  useEffect(() => {
    mutate(searchs);
    mutateProcessTypes()
    mutateRoleList()

    
  }, []);

  useEffect(() => {
        mutateRole(getUser.login)


  }, [getUser])
    
    const formatWorkFlowText = (inputText: String) => {
     

// Split the input text into parts using '#' as the separator
const parts = inputText.split("#");

// Extract the first part and capitalize each word
const formattedText = parts[0]
  .split("_")
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(" ");

return formattedText
    }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '30',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '180',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: '180',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '60',
    },
    {
      title: 'Official Email',
      dataIndex: 'email',
      key: 'email',
      width: '80',
    },
    // {
    //   title: 'Activation Status',
    //   dataIndex: 'activationStatus',
    //   key: 'activationStatus',
    //   render: (_, { paymentStatus }) => (
    //     <>
    //       <svg
    //         width="24"
    //         height="25"
    //         viewBox="0 0 24 25"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           d="M6.92263 17.5753V18.0753H7.42263H8.41847H8.62578L8.77227 17.9286L14.9074 11.7848L15.2602 11.4315L14.9074 11.0782L14.4094 10.5796L13.9115 10.0809L13.5577 9.72664L13.2039 10.0809L7.06882 16.2248L6.92263 16.3712V16.5781V17.5753ZM17.5923 9.69311L15.28 7.40475L15.921 6.76289C16.0968 6.58684 16.3032 6.5 16.5723 6.5C16.8413 6.5 17.0473 6.58682 17.2225 6.76258L17.2228 6.76289L18.2187 7.76015C18.4014 7.94312 18.4915 8.1518 18.4995 8.41091C18.5071 8.65938 18.4294 8.85511 18.2545 9.02995L18.2542 9.03026L17.5923 9.69311ZM13.5399 9.12953L15.8564 11.4493L8.81577 18.5H6.5V16.1795L13.5399 9.12953Z"
    //           stroke="black"
    //           stroke-opacity="0.8"
    //         />
    //       </svg>
    //     </>
    //   ),
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, { action }: any) => (
        <>
          <button
            className="svg-button"
            onClick={() => handleActionClick(action)}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.92263 17.5753V18.0753H7.42263H8.41847H8.62578L8.77227 17.9286L14.9074 11.7848L15.2602 11.4315L14.9074 11.0782L14.4094 10.5796L13.9115 10.0809L13.5577 9.72664L13.2039 10.0809L7.06882 16.2248L6.92263 16.3712V16.5781V17.5753ZM17.5923 9.69311L15.28 7.40475L15.921 6.76289C16.0968 6.58684 16.3032 6.5 16.5723 6.5C16.8413 6.5 17.0473 6.58682 17.2225 6.76258L17.2228 6.76289L18.2187 7.76015C18.4014 7.94312 18.4915 8.1518 18.4995 8.41091C18.5071 8.65938 18.4294 8.85511 18.2545 9.02995L18.2542 9.03026L17.5923 9.69311ZM13.5399 9.12953L15.8564 11.4493L8.81577 18.5H6.5V16.1795L13.5399 9.12953Z"
                stroke="black"
                stroke-opacity="0.8"
              />
            </svg>
          </button>
        </>
      ),
    },
  ];

  for (let i = 0; i < data?.length; i++) {
    const row = {
      id: i + 1,
      firstName: data[i]?.firstName,
      lastName: data[i]?.lastName,
      phoneNumber: data[i]?.phoneNumber,
      email: data[i]?.email,
      activationStatus: `button`,
      action: data[i],
    };
    dataSource.push(row);
  }

  const handleActionClick = (action) => {
    setShowSvgPage(true);
    // Handle click event for Action SVG// You can perform further actions here
    setUser(action);
  };

 const formik = useFormik({
    initialValues,
    validationSchema: Schema,
   onSubmit: (values) => {
     
     var paylod = {
       value: {
         firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
       },
       login: getUser.login
     }
     
        mutateEditUser(
       paylod,

        {
          onSuccess: (res: any) => {
         setShow(true)

          },
        }
      );
      
 
    },
 });
  



  const handleSubmit = () => {

   //console.log(selectedOption);

    var payload = {
      value: selectedOption,
      username: getUser.login.toLowerCase()
    }

        mutateEditRoleUser(
       payload,

        {
          onSuccess: (res: any) => {
            //  window.location.reload();
            setShow(true)
                       mutateRole(getUser.login)

            setEditUserRolesResourcesModal(false)
          },
        }
      );
    
   
  }


  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    // Show the dropdown corresponding to the selected radio button
    setDropdowns((prevDropdowns) => ({
      ...prevDropdowns,
      [value]: true,
    }));
  };

  const handleCheckboxChange = (event) => {
    // Handle checkbox changes
  };

  const processData = (code: string, value: string) => {
    setSelectedUserArray((prevSelectedUserArray) => {
      // Check if the item should be removed
      const shouldRemoveItem = businessGroups.some(
        (item) => item.code === `${role.code}#initiator`
      );
  
      // If shouldRemoveItem is true, filter out the item from prevSelectedUserArray
      if (shouldRemoveItem) {
        return prevSelectedUserArray.filter(item => item.code !== code);
      }
  
      // If shouldRemoveItem is false, add the new item to prevSelectedUserArray
      return [
        ...prevSelectedUserArray,
        {
          id: null,
          code,
          description: value,
        }
      ];
    });
  };
  

  const submit = () => {
   //console.log(businessGroups);

    // mutate(selectedUserArray, {
    //   onSuccess: (res: any) => {
    //     // Handle success
    //   },
    // });

    var payload = {
     value: {businessGroups},
     login: getUser.login.toLowerCase()
    }

    

       mutateEditUser(
       payload,

        {
          onSuccess: (res: any) => {
          //  window.location.reload();
            setShow(true)
            setEditWorkflowAssignmentModal(false)
          },
        }
      );
  };

  const [show, setShow] = useState(false);

  const refresh = () => {
    window.location.reload();
  };

  const SuccessModal = () => {
    return (
      <MOD
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MOD.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Successful!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  You have successfully updated the user.
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
           

              <button className="otp-button " onClick={() => refresh()}>
                <span className="otp-button-text">Done</span>
              </button>
            </div>
          </div>
        </MOD.Body>
      </MOD>
    );
  };

  const [searchs, setSearchs] = useState('')

  

  const handleSearchChange = (e) => {

    const { name, value } = e.target;
    setSearchs(value);
    mutate(value);
  }
  return (
    <>
      {isLoadingEditUser || isLoadingRoleList || isLoadingEditRoleUser ? (
        <div className="loading-container">
          <FlapperSpinner />
        </div>
      ) : (
        <div className={"editUser"}>
          {!showSvgPage ? (
            <div className={"editUserMainView"}>
              <div className="form-control form-control-solid pe-1 ">
                <input
                  type="text"
                  placeholder="Search by First Name or Last Name or Phone Number or Official Email"
                  className={"no-outline text-muted fs-8 w-90"}
                  name="searchs"
                  value={searchs}
                  onChange={handleSearchChange}
                />

                <img
                  src={searchCollection}
                  alt="search"
                  className="icon-style"
                />
              </div>

              <div className="d-flex justify-content-end  mb-9 me-4">
                <Download
                  dataSourceForExcel={dataSourceForExcel}
                  dataSource={dataSource}
                  excelFileTitle={"Collection Inflow for MDA"}
                  pdfFileTitle={"Collection Inflow for MDA"}
                  excelPageTitle={"Collection Inflow for MDA"}
                  grandTotalFor={{
                    amount: "Transaction Amount",
                  }}
                />
              </div>

              <div>LIST OF ALL USERS ({data?.length})</div>

              <Table
                dataSource={dataSource}
                bordered
                columns={columns}
                scroll={{ x: "1500px" }}
              />
            </div>
          ) : (
            <div className={"editUserEditView"}>
              <div>
                <div onClick={() => setShowSvgPage(false)}>
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="26"
                      height="26"
                      rx="4"
                      fill="#D2E7CD"
                    />
                    <path
                      d="M19.6875 13.5H7.3125M7.3125 13.5L12.375 8.4375M7.3125 13.5L12.375 18.5625"
                      stroke="#01903C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div>
                  {getUser?.firstName} {getUser?.lastName}
                </div>
              </div>

              <div>
                <div className={"userDetailsText"}>
                  <div>User Details</div>
                  <div>
                    <button onClick={() => setEditUserDetailsModal(true)}>
                      Edit
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4L12.5 6M13.5 8V13.5C13.5 13.6326 13.4473 13.7598 13.3536 13.8536C13.2598 13.9473 13.1326 14 13 14H3C2.86739 14 2.74021 13.9473 2.64645 13.8536C2.55268 13.7598 2.5 13.6326 2.5 13.5V3.5C2.5 3.36739 2.55268 3.24021 2.64645 3.14645C2.74021 3.05268 2.86739 3 3 3H8.5M8 10.5H6V8.5L12 2.5L14 4.5L8 10.5Z"
                          stroke="#F9F9F9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className={"userDetailsDiv"}>
                  <div>
                    <div className={"title"}>First Name</div>
                    <div className={"details"}>{getUser?.firstName}</div>
                  </div>
                  <div>
                    <div className={"title"}>Last Name</div>
                    <div className={"details"}>{getUser?.lastName}</div>
                  </div>
                  <div>
                    <div className={"title"}>Phone Number</div>
                    <div className={"details"}>{getUser?.phoneNumber}</div>
                  </div>
                  <div>
                    <div className={"title"}>Email</div>
                    <div className={"details"}> {getUser?.email} </div>
                  </div>
                </div>

                <div className={"userDetailsText"}>
                  <div>Workflow Assignment</div>
                  <div>
                    <button
                      onClick={() => {
                        setSelectedUserArray(getUser?.businessGroups);

                        setEditWorkflowAssignmentModal(true);
                      }}
                    >
                      Edit
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4L12.5 6M13.5 8V13.5C13.5 13.6326 13.4473 13.7598 13.3536 13.8536C13.2598 13.9473 13.1326 14 13 14H3C2.86739 14 2.74021 13.9473 2.64645 13.8536C2.55268 13.7598 2.5 13.6326 2.5 13.5V3.5C2.5 3.36739 2.55268 3.24021 2.64645 3.14645C2.74021 3.05268 2.86739 3 3 3H8.5M8 10.5H6V8.5L12 2.5L14 4.5L8 10.5Z"
                          stroke="#F9F9F9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className={"userDetailsDiv"}>
                  {getUser.businessGroups.map((d, i) => {
                    return (
                      <div>
                        <div className={"title"}>
                          {" "}
                          {formatWorkFlowText(d.code)}{" "}
                        </div>
                        <div className={"details"}> {d.description} </div>
                      </div>
                    );
                  })}
                </div>

                <div className={"userDetailsText"}>
                  <div>User Roles</div>
                  <div>
                    <button
                      onClick={() => setEditUserRolesResourcesModal(true)}
                    >
                      Edit
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4L12.5 6M13.5 8V13.5C13.5 13.6326 13.4473 13.7598 13.3536 13.8536C13.2598 13.9473 13.1326 14 13 14H3C2.86739 14 2.74021 13.9473 2.64645 13.8536C2.55268 13.7598 2.5 13.6326 2.5 13.5V3.5C2.5 3.36739 2.55268 3.24021 2.64645 3.14645C2.74021 3.05268 2.86739 3 3 3H8.5M8 10.5H6V8.5L12 2.5L14 4.5L8 10.5Z"
                          stroke="#F9F9F9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                {role?.map((res, i) => {
                  return (
                    <div className={"userRoleResource"} key={i}>
                      <div className={"userRole"}> {res?.name} </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <Modal
            centered
            style={{ width: "500px", maxWidth: "100%" }}
            open={editUserDetailsModal}
            // onOk={() => setSuccessModal(false)}
            onCancel={() => setEditUserDetailsModal(false)}
            closable={false}
            footer={null}
          >
            <div className={"editUserDetailsModal"}>
              <div className={"editUserDetailsModalTitle"}>
                Edit User Details
              </div>

              <div className={"editUserDetailsModalDesc"}>
                This section requires you to specify the details of users that
                wil operate on this profile
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <div className="mt-1">
                    <div className="col-lg-4 col-form-label py-0 input-height">
                      {formik.values.firstName.length !== 0 && (
                        <label className="required fw-bold fs-9">
                          First Name
                        </label>
                      )}
                    </div>
                    <div className={`form-control form-control-solid`}>
                      <input
                        type="text"
                        placeholder="First Name*"
                        className={"no-outline text-dark fs-8"}
                        {...formik.getFieldProps("firstName")}
                        value={formik?.values?.firstName}
                      />
                    </div>
                    <div className="fv-plugins-message-container input-height">
                      <div className="fv-help-block fs-9 text-danger">
                        {formik.touched.firstName &&
                          formik.errors.firstName &&
                          formik.errors.firstName}
                      </div>
                    </div>
                  </div>

                  <div className="mt-1">
                    <div className="col-lg-4 col-form-label py-0 input-height">
                      {formik.values.lastName.length !== 0 && (
                        <label className="required fw-bold fs-9">
                          Last Name
                        </label>
                      )}
                    </div>
                    <div className={`form-control form-control-solid`}>
                      <input
                        type="text"
                        placeholder="Last Name*"
                        className={"no-outline text-dark fs-8"}
                        {...formik.getFieldProps("lastName")}
                      />
                    </div>
                    <div className="fv-plugins-message-container input-height">
                      <div className="fv-help-block fs-9 text-danger">
                        {formik.touched.lastName &&
                          formik.errors.lastName &&
                          formik.errors.lastName}
                      </div>
                    </div>
                  </div>

                  <div className="mt-1">
                    <div className="col-lg-4 col-form-label py-0 input-height">
                      {formik.values.phoneNumber.length !== 0 && (
                        <label className="required fw-bold fs-9">
                          Phone number
                        </label>
                      )}
                    </div>
                    <div className={`form-control form-control-solid`}>
                      <input
                        type="tel"
                        placeholder="Phone Number*"
                        className={"no-outline text-dark fs-8"}
                        {...formik.getFieldProps("phoneNumber")}
                      />
                    </div>
                    <div className="fv-plugins-message-container input-height">
                      <div className="fv-help-block fs-9 text-danger">
                        {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber &&
                          formik.errors.phoneNumber}
                      </div>
                    </div>
                  </div>

                  <div className="mt-1">
                    <div className="col-lg-4 col-form-label py-0 input-height">
                      {formik.values.email.length !== 0 && (
                        <label className="required fw-bold fs-9">
                          Official Email
                        </label>
                      )}
                    </div>
                    <div className={`form-control form-control-solid`}>
                      <input
                        type="text"
                        placeholder="Official Email*"
                        className={"no-outline text-dark fs-8"}
                        {...formik.getFieldProps("email")}
                      />
                    </div>
                    <div className="fv-plugins-message-container input-height">
                      <div className="fv-help-block fs-9 text-danger">
                        {formik.touched.email &&
                          formik.errors.email &&
                          formik.errors.email}
                      </div>
                    </div>
                  </div>

                  <div className={"editUserDetailsModalActionButton"}>
                    <button type="submit" className="">
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>

          <Modal
            centered
            style={{ width: "500px", maxWidth: "100%" }}
            open={editWorkflowAssignmentModal}
            // onOk={() => setSuccessModal(false)}
            onCancel={() => setEditWorkflowAssignmentModal(false)}
            closable={false}
            footer={null}
          >
            <div className={"editUserDetailsModal"}>
              <div className={"editUserDetailsModalTitle"}>
                Edit Workflow Assignment
              </div>

              <div className={"editUserDetailsModalDesc"}>
                This section is to assign workflows to users based on process
                types
              </div>

              <div className={"editUserDetailsCheckbox"}>
                {ProcessTypes?.map((role, i) => {
                  return (
                    <div key={role.code}>
                      <label style={{ paddingBottom: "13px" }}>
                        <input
                          style={{ marginRight: "15px" }}
                          type="checkbox"
                          value={role.code}
                          checked={selectedOption === role.code}
                          onChange={handleOptionChange}
                        />
                        {role.description}
                      </label>
                      {true && (
                        <div className="checkbox-dropdown">
                          <label>
                            <input
                              type="checkbox"
                              value={`${role.code}#initiator`}
                              onChange={() =>
                                processData(
                                  `${role.code}#initiator`,
                                  "initiator"
                                )
                              }
                              checked={businessGroups.some(
                                (item) => item.code === `${role.code}#initiator`
                              )}
                            />
                            Initiator
                          </label>

                          <label>
                            <input
                              type="checkbox"
                              value={`${role.code}#reviewer`}
                              onChange={() =>
                                processData(`${role.code}#reviewer`, "reviewer")
                              }
                              checked={businessGroups.some(
                                (item) => item.code === `${role.code}#reviewer`
                              )}
                            />
                            Reviewer
                          </label>

                          <label>
                            <input
                              type="checkbox"
                              value={`${role.code}#approver`}
                              onChange={() =>
                                processData(`${role.code}#approver`, "approver")
                              }
                              checked={businessGroups.some(
                                (item) => item.code === `${role.code}#approver`
                              )}
                            />
                            Approver
                          </label>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className={"editUserDetailsModalActionButton"}>
                <button className="" onClick={submit}>
                  Save Changes
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            centered
            style={{ width: "500px", maxWidth: "100%" }}
            open={editUserRolesResourcesModal}
            // onOk={() => setSuccessModal(false)}
            onCancel={() => setEditUserRolesResourcesModal(false)}
            closable={false}
            footer={null}
          >
            <div className={"editUserDetailsModal"}>
              <div className={"editUserDetailsModalTitle"}>
                Edit User Roles & Resources
              </div>

              <div className={"editUserDetailsModalDesc"}>
                This section is to assign roles to users
              </div>

              <div className={"editUserRolesRadioButton"}>
                {dataRoleList?.map((role, i) => {
                  return (
                    <label key={i}>
                      <input
                        style={{ marginRight: "15px" }}
                        type="radio"
                        value={role?.name}
                        checked={selectedOption === role?.name}
                        onChange={handleOptionChange}
                      />
                      {role?.name}
                    </label>
                  );
                })}

                {/* <label>
                  <input
                    style={{ marginRight: '15px' }}
                    type="radio"
                    value="mda-payment-officer"
                    checked={selectedOption === 'mda-payment-officer'}
                    onChange={handleOptionChange}
                  />
                  MDA Payment Officer
                </label>
                {dropdowns['mda-payment-officer'] && (
                  <div className="checkbox-dropdown">
                    <label>
                      <input type="checkbox" value="rvp" />
                      Retail/Vendor Payment
                    </label>
                    <label>
                      <input type="checkbox" value="billPayment" />
                      Bill Payment
                    </label>
                    <label>
                      <input type="checkbox" value="payrollPayment" />
                      Payroll Payment
                    </label>
                    <label>
                      <input type="checkbox" value="payrollPayment" />
                      Payroll Payment
                    </label>
                    <label>
                      <input type="checkbox" value="taxPayment" />
                      Tax Payment
                    </label>
                  </div>
                )} */}
              </div>

              <div className={"editUserDetailsModalActionButton"}>
                <button
                  className=""
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
      {SuccessModal()}
    </>
  );
};

export default EditExistingUser;
