import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FlapperSpinner } from "react-spinners-kit";
import "../../../App.css";
import { infoBlue } from "../../../core/assets/icons";
import { ErrorToast, SuccessToast } from "../../componets/toast";
import {
  activeService,
  useServiceDetailsWithOrgId,
} from "../auth/hooks/useUser";

const ServiceDetails = () => {
  const navigate = useNavigate();
  const [activationLoading, setActivationLoading] = useState<boolean>(false);
  const [ismoreInfo, setMoreInfo] = useState<any>();
  const [isIntegrated, setIntegrated] = useState<any>();
  const [customFieldsInputs, setCustomFieldsInputs] = useState<any>([]);
  const [multiplePayment, setMultiplePayment] = useState<any>([]);
  const [showConfirm, setConfrim] = useState<boolean>(false);
  const [revenueCode, setRevenueCode] = useState<string>("");
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [wModal, setWModal] = useState<number>(1);

  const [fields, setFields] = useState<any>({
    integration: "",
    label: "",
    paymentEmail: "",
    revenueCode: "",
    amount: "",
    vat: false,
    processingFee: false,
    installmental: false,
    collectionAccount: "",
    type: "single",
    // authorizeDebit: false,
    // beneficiaryVatPay: false,
    beneficiaryFeePay: false,
    websiteUrl: "",
    notificationUrl: "",
    multipleSelect: false,
    multipleFixedAmount: true,
    orgName: "",
    id: "",
    status: false,
  });

  const {
    data: detailsData,
    mutate: detailsMutate,
    isLoading,
  } = useServiceDetailsWithOrgId();

  const fetchData = () => {
    const segments = window.location.pathname.split("/").slice(-2);

    if (segments[0] && segments[1]) {
      var data = {
        orgId: segments[0],
        serviceId: segments[1],
      };

      detailsMutate(data, {
        onSuccess: (response: any) => {
          setCurrentRecord(response);

          if (response.length === 0) {
            // console.log("go wow bro", response, "\n", response.length);
            navigate("/admin/oagf-overview/service-activation");
          } else {
            if (
              response.notificationUrl.length > 0 ||
              response.websiteUrl.length > 0
            ) {
              setIntegrated(true);
            }
            if (response?.paymentComponents?.length > 1) {
              let multiplePayment: any = [];

              setFields({
                ...fields,
                label: response?.description,
                paymentEmail: response?.notificationEmail,
                revenueCode: response?.revCode,
                amount: "",
                vat: response.vatRequired,
                processingFee: response?.payerToPay,
                installmental: response.installPayment,
                collectionAccount: false,
                beneficiaryFeePay: response.payerToPay,
                websiteUrl: response.websiteUrl,
                notificationUrl: response.notificationUrl,
                multipleSelect: response.multiSelect,
                multipleFixedAmount: response?.fixedAmount,
                type: "multiple",
                status: response?.active,
                id: response?.id,
                orgName: response?.orgName,
              });

              response?.paymentComponents?.map(
                (item: {
                  id: any;
                  name: any;
                  amount: any;
                  destAccount: { accountNumber: any };
                }) => {
                  multiplePayment.push({
                    id: item?.id,
                    itemName: item?.name,
                    amount: item?.amount ? item.amount : null,
                    collectionAccount: item?.destAccount.accountNumber,
                    code: "",
                  });
                }
              );

              setMultiplePayment(multiplePayment);
            } else {
              setFields({
                ...fields,
                label: response?.description,
                paymentEmail: response?.notificationEmail,
                revenueCode: response?.revCode,
                amount: response?.amount,
                vat: response.vatRequired,
                processingFee: response?.payerToPay,
                installmental: response.installPayment,
                beneficiaryFeePay: response.payerToPay,
                websiteUrl: response.websiteUrl,
                notificationUrl: response.notificationUrl,
                id: response?.id,
                orgName: response?.orgName,
                type: "single",
                status: response?.active,
                collectionAccount: `${response.paymentComponents[0]?.destAccount?.accountNumber} - ${response.paymentComponents[0]?.destAccount?.accountName}`,
              });
            }
            if (response.customFields.length > 0) {
              setMoreInfo(true);
              let customFieldsList: any = [];
              response?.customFields?.map(
                (item: {
                  id: any;
                  label: any;
                  customFieldType: { type: any };
                  limit: any;
                  code: any;
                  optional: any;
                }) => {
                  customFieldsList.push({
                    id: item?.id,
                    fieldName: item?.label,
                    dataType: item?.customFieldType?.type,
                    charaterCount: item?.limit,
                    code: item.code,
                    optional: item?.optional,
                  });
                }
              );
              setCustomFieldsInputs(customFieldsList);
            }
          }
        },
        onError: (err: any) => {
          ErrorToast(err);
          // console.log("go yeye bro");
          //navigate("/admin/oagf-overview/service-activation");
        },
      });
    } else {
      // console.log("go out bro");
      // navigate("/admin/oagf-overview/service-activation");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const goBacktoTable = () => {
    navigate("/admin/oagf-overview/service-activation");
  };

  const onChange = () => {
    if (currentRecord?.revenueCode) {
      setRevenueCode(currentRecord?.revenueCode);
    } else {
      setRevenueCode("");
    }
    if (currentRecord?.active) {
      setWModal(2);
    } else {
      setWModal(1);
    }
    setConfrim(true);
  };

  const cancelModal = () => {
    setConfrim(false);
    if (wModal === 2) {
      setWModal(1);
    } else {
      setRevenueCode("");
    }
  };

  const Submit = () => {
    const revCode = {
      revCode: revenueCode,
    };
    setActivationLoading(true);
    activeService(currentRecord?.id, currentRecord?.orgId, revCode)
      .then(() => {
        fetchData();
        SuccessToast(
          `Service has been ${
            currentRecord?.active ? "deactivated" : "activated"
          } `
        );
        setConfrim(false);
      })
      .catch((err: any) => {
        ErrorToast("");
      })
      .finally(() => {
        setActivationLoading(false);
      });
  };
  const ConfrimModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm} //showConfirm
        onHide={() => setConfrim(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-6 mx-11">
            {wModal === 1 ? (
              <>
                <div className="mt-1">
                  <div className="col-form-label input-height d-flex justify-content-center">
                    <span className=" fw-bolder fs-8e text-dark">
                      Revenue Code
                    </span>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Please enter revenue code"
                      className={"no-outline ps-0 text-dark fs-8"}
                      value={revenueCode}
                      onChange={(e) => setRevenueCode(e.target.value)}
                      maxLength={50}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-around my-5">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className=" btn btn-white px-7 py-2 h-44 w-110 rounded-1 border-success "
                    onClick={() => cancelModal()}
                  >
                    <span className="indicator-label fs-base text-success ">
                      Back
                    </span>
                  </button>

                  <button
                    type="button"
                    className={` btn ${
                      revenueCode.length < 16 ? "btn-secondary" : "btn-success"
                    } h-44 w-110 rounded-1  center-all  `}
                    onClick={() => setWModal(2)}
                    disabled={revenueCode.length < 16}
                  >
                    <span className="indicator-label fs-base text-white ">
                      Proceed
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className=" d-flex flex-column align-items-center ">
                  <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                  <span className="text-center fs-base text-dark">
                    Are you sure you want to{" "}
                    {currentRecord?.active ? "deactivate" : "activate"} this
                    service
                  </span>
                </div>
                <div className="d-flex justify-content-around mt-10">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                    onClick={() => cancelModal()}
                  >
                    <span className="indicator-label fs-base ">Cancel</span>
                  </button>
                  <button
                    className=" btn btn-success h-44 w-110 center-all"
                    type="button"
                    onClick={() => Submit()}
                  >
                    <span className="indicator-label fs-base ">Submit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <>
        {isLoading || activationLoading ? (
          <div className="loading-container">
            <FlapperSpinner />
          </div>
        ) : (
          <>
            <div className={"adminBody"}>
              <div className={"breadcrumb"}>
                <div className="setup-administration">
                  <span className="text-muted fs-8 fw-bold"> Overview</span>
                </div>
                <div>
                  <svg
                    width="10"
                    height="13"
                    viewBox="0 0 10 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                      fill="#6366F1"
                    />
                  </svg>
                </div>
                <div
                  className="corporate-profile cursor-pointer"
                  onClick={() => goBacktoTable()}
                >
                  <span className="text-dark fs-8 fw-bolder">
                    Service Activation
                  </span>
                </div>
              </div>

              <div className="card  collection-card bg-white border-0 m-auto my-10 mx-10">
                <div>
                  <div className="d-flex justify-content-center ">
                    <span className="text-black fs-5 fw-bolder lh-lg">
                      Service Details
                    </span>
                  </div>

                  <div>
                    <div className="my-2 ">
                      <span className="fs-8 fw-bolder text-dark">
                        Service Profile
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-9 fw-bold text-start min-w-25">
                        Entity Name
                      </span>

                      <span className="text-dark fs-8 text-end">
                        {fields?.orgName}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-9 fw-bold text-start min-w-25">
                        Service ID
                      </span>

                      <span className="text-dark fs-8 text-end">
                        {fields?.id}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-9 fw-bold text-start min-w-25">
                        Service Name / Label
                      </span>
                      <span className="text-dark fs-8  text-end">
                        {fields?.label}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-9 fw-bold text-start min-w-25">
                        Service List Type
                      </span>
                      <span className="text-dark fs-8  text-end">
                        {fields?.type}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-9 fw-bold text-start min-w-25">
                        Revenue Code
                      </span>
                      <span className="text-dark fs-8 text-end">
                        {fields?.revenueCode ? fields?.revenueCode : "N/A"}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-9 fw-bold text-start min-w-25">
                        Payment Notification Email Address
                      </span>
                      <span className="text-dark fs-8 text-end">
                        {fields?.paymentEmail}
                      </span>
                    </div>

                    <hr className="my-3  text-muted" />
                  </div>

                  <div className="my-0">
                    <div className="d-flex flex-column">
                      <span className="fs-8 fw-bolder mt-5">
                        Prices & Charges
                      </span>

                      {fields?.type === "multiple" ? (
                        <>
                          <div className="table-responsive mb-4">
                            <table className="table align-middle  gx-0 gy-1 mt-1">
                              <thead className="bg-white">
                                <tr>
                                  <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold ps-0">
                                    Item Name
                                  </th>
                                  <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold">
                                    Amount
                                  </th>
                                  <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold">
                                    Collection Account
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {multiplePayment?.map(
                                  (array: any, index: any) => {
                                    return (
                                      <tr key={index}>
                                        <td className="text-dark fs-8  text-start border-0 capitaliseText ps-0">
                                          {array.itemName}
                                        </td>
                                        <td className="text-dark fs-8  text-start capitaliseText border-0">
                                          ₦
                                          {Number(array.amount).toLocaleString(
                                            "en",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </td>
                                        <td className="text-dark fs-8  text-start border-0">
                                          {array.collectionAccount}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : (
                        <div className="d-flex justify-content-between py-3">
                          <span className="text-muted fs-9 fw-bold text-start min-w-25">
                            Service Amount
                          </span>
                          <span className="text-dark fs-8  text-end">
                            {Number(fields.amount) === 0
                              ? "Not Specified"
                              : `₦ ${Number(fields.amount).toLocaleString(
                                  "en",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}`}
                          </span>
                        </div>
                      )}

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9  text-start min-w-25">
                          Value Added Tax (VAT)
                        </span>

                        <span className="text-dark fs-8 text-end">
                          {fields.vat ? "Yes" : "No"}
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9  text-start min-w-25">
                          Processing fee
                        </span>

                        <span className="text-dark fs-8 text-end">
                          {fields.processingFee ? "Yes" : "No"}
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9  text-start min-w-25">
                          Beneficiary to pay
                        </span>
                        <span className="text-dark fs-8  text-end">
                          {fields.beneficiaryFeePay ? "Yes" : "No"}
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 text-start min-w-25">
                          Instalmental payment
                        </span>

                        <span className="text-dark fs-8 text-end">
                          {fields.installmental ? "Yes" : "No"}
                        </span>
                      </div>

                      <hr className="my-1 text-muted" />
                    </div>
                  </div>

                  {fields?.type === "single" && (
                    <div className="my-10">
                      <div className="d-flex justify-content-between py-1">
                        <span className="fs-8 fw-bolder mt-5 text-dark">
                          Collection Account
                        </span>
                      </div>
                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9  text-start min-w-25">
                          Collection Account
                        </span>
                        <span className="text-dark fs-8  text-end">
                          {fields?.collectionAccount}
                        </span>
                      </div>
                      <hr className="my-3 text-muted" />
                    </div>
                  )}

                  <div className="my-0">
                    <div className="d-flex flex-column">
                      <span className="fs-8 fw-bolder mt-5 text-dark ">
                        More Information
                      </span>
                    </div>
                    {ismoreInfo ? (
                      <>
                        <div className="table-responsive  ps-0">
                          <table className="table align-middle  gx-0 gy-1 mt-1 ">
                            <thead className="bg-white">
                              <tr>
                                <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold ps-0">
                                  Field Name
                                </th>
                                <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold">
                                  Data Type
                                </th>
                                <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold">
                                  Character Count
                                </th>
                                <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold">
                                  Optional
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {customFieldsInputs?.map(
                                (array: any, index: any) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text-dark fs-8  text-start border-0 capitaliseText ps-0">
                                        {array.fieldName}
                                      </td>
                                      <td className="text-dark fs-8  text-start capitaliseText border-0">
                                        {array.dataType}
                                      </td>
                                      <td className="text-dark fs-8  text-start border-0">
                                        {array.charaterCount
                                          ? array.charaterCount
                                          : "-"}
                                      </td>
                                      <td className="text-dark fs-8  text-start border-0 capitaliseText">
                                        {array.optional ? "True" : "False"}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9  text-start min-w-25">
                          Additional Information
                        </span>
                        <span className="text-dark fs-8  text-end">None</span>
                      </div>
                    )}
                    {isIntegrated ? (
                      <>
                        <div className="mb-10">
                          <div className="d-flex justify-content-between py-3">
                            <span className="text-dark fs-9 fw-bolder text-start min-w-25">
                              Integration
                            </span>
                            <span className="text-dark fs-8  text-end"></span>
                          </div>

                          <div className="d-flex justify-content-between py-3">
                            <span className="text-muted fs-9  text-start min-w-25">
                              Website URL
                            </span>
                            <span className="text-dark fs-8  text-end">
                              {fields.websiteUrl}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between py-3">
                            <span className="text-muted fs-9  text-start min-w-25">
                              Notification URL
                            </span>
                            <span className="text-dark fs-8  text-end">
                              {fields.notificationUrl}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9  text-start min-w-25">
                          Integration
                        </span>
                        <span className="text-dark fs-8  text-end">None</span>
                      </div>
                    )}
                  </div>

                  <div className="d-flex mt-5 justify-content-center">
                    <button
                      className="btn btn-white border-success rounded-1  w-148 h-51 center-all mx-5"
                      type="button"
                      onClick={() => goBacktoTable()}
                    >
                      <span className="fs-5 text-success ">Back</span>
                    </button>

                    <button
                      className={`btn ${
                        !fields?.status ? " btn-success " : " btn-danger "
                      } rounded-1 w-220 h-51 center-all mx-5  border-0`}
                      type="button"
                      onClick={() => onChange()}
                    >
                      <span className="fs-5 text-white">
                        {!fields?.status
                          ? "Activate Service "
                          : "Deactivate Service"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {ConfrimModal()}
          </>
        )}
      </>
    </>
  );
};

export default ServiceDetails;
