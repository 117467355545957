import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MDAdirectory,
  arrowRight,
  arrowUpCircle,
  einvoice,
  homepageBubles,
  playCircle,
  receiptgeneration,
  revenuepayment,
  userHeroImg,
  welcomeImg,
  heroImg
} from "../../../core/assets/imgs";

import { Modal } from "react-bootstrap";
import Typewriter from "typewriter-effect";

const UserHero: React.FC = () => {
  const [show, setShow] = useState<any>(false);
  const navigate = useNavigate();

  const done = () => {
    navigate("/payment");
  };

    const redirecSignup = () => {
    navigate('/auth/register');
  };
  const PageModal = () => {
    return (
      <Modal
        dialogClassName="home-modal"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
      >
        <div className=" d-flex flex-cloumn justify-content-center align-items-center w-536e h-326e bg-black rounded-1">
          <article className="w-100 h-100">
            <iframe
              className="rounded-1"
              width="100%"
              height="326"
              src="https://www.youtube-nocookie.com/embed/zH1HtWW_3-Q?rel=0&showinfo=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
              allowFullScreen
            ></iframe>
          </article>
        </div>
      </Modal>
    );
  };
  return (
    <div>
      <header className="hero mt-3b bg-img">
        <div className="section-center hero-center">


          <div className="hero-div">
            <span className="hero-header">Simplify Your Payments with VoxePay </span>
            <span className="hero-text">Discover how VoxePay simplifies payment processing for businesses, merchants and government, making transactions effortless and efficient.</span>
            <button onClick={redirecSignup}>
              <span>Get Started</span>
              <span><svg xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15" fill="none">
  <path d="M1.25 7.5H17.75M17.75 7.5L11 0.75M17.75 7.5L11 14.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span>
            </button>


      
          </div>


                      <div className="img-hero">
           <img src={heroImg} alt=""  className="img-hero"/>
          </div>

    
   

    

     

        
        </div>
      </header>

      {PageModal()}
    </div>
  );
};

export { UserHero };
