import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { getStoredUser } from "../auth/user-storage";

export const ProfileSetup = () => {
    const navigate = useNavigate();

    const [trustedUrl, setTrustedUrl] = useState('');
    
      const authed = getStoredUser();


  useEffect(() => {
      setTrustedUrl(`https://compliance.voxepay.com/kycManagement/${authed?.mdaAdminCode}?code=${authed?.mdaCode}&registeredName=${authed?.registeredName}`);
      
      
  }, []);

    return (
        <>
   
              <iframe
      src={trustedUrl}
      width="100%"
      height="100%"
      frameBorder="0"
      title="KYC Management"
    ></iframe>
            {/* <div className={'onboarding'}>

                <div className={'onboardingForm'}>
                    <div className={'onboardingFormRow1'}>
                        Select an Organization Type
                    </div>

                    <div className={'onboardingFormRow2'} style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div onClick={() => navigate('/admin/profile-setup/starter-business')} className={'businessTypeCard'}>
                            <div></div>
                            <div>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.9764 33.2483C3.70012 33.7265 3.86382 34.3381 4.34203 34.6144C4.82025 34.8907 5.43189 34.727 5.70817 34.2488L3.9764 33.2483ZM34.2925 34.2491C34.5688 34.7273 35.1804 34.891 35.6586 34.6147C36.1368 34.3385 36.3006 33.7268 36.0243 33.2486L34.2925 34.2491ZM5.70817 34.2488C7.15719 31.7407 9.24073 29.6581 11.7495 28.2101L10.7497 26.4779C7.937 28.1013 5.60101 30.4363 3.9764 33.2483L5.70817 34.2488ZM11.7495 28.2101C14.2582 26.7622 17.1038 26 20.0004 26L20.0004 24C16.7529 24 13.5625 24.8546 10.7497 26.4779L11.7495 28.2101ZM20.0004 26C22.897 26 25.7426 26.7623 28.2513 28.2103L29.2511 26.4781C26.4384 24.8547 23.248 24 20.0004 24L20.0004 26ZM28.2513 28.2103C30.76 29.6583 32.8435 31.7409 34.2925 34.2491L36.0243 33.2486C34.3997 30.4366 32.0638 28.1015 29.2511 26.4781L28.2513 28.2103ZM29.0003 15C29.0003 19.9706 24.971 24 20.0004 24V26C26.0756 26 31.0003 21.0751 31.0003 15H29.0003ZM20.0004 24C15.0298 24 11.0003 19.9705 11.0003 15H9.00025C9.00025 21.0752 13.9253 26 20.0004 26V24ZM11.0003 15C11.0003 10.0294 15.0297 6 20.0003 6V4C13.9251 4 9.00025 8.92487 9.00025 15H11.0003ZM20.0003 6C24.9708 6 29.0003 10.0294 29.0003 15H31.0003C31.0003 8.92487 26.0754 4 20.0003 4V6Z" fill="#5E718D"/>
                                </svg>
                            </div>
                            <div>Starter business</div>
                        </div>

                        <div onClick={() => navigate('/admin/profile-setup/registered-business')} className={'businessTypeCard'}>
                            <div></div>
                            <div>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.9764 33.2483C3.70012 33.7265 3.86382 34.3381 4.34203 34.6144C4.82025 34.8907 5.43189 34.727 5.70817 34.2488L3.9764 33.2483ZM34.2925 34.2491C34.5688 34.7273 35.1804 34.891 35.6586 34.6147C36.1368 34.3385 36.3006 33.7268 36.0243 33.2486L34.2925 34.2491ZM5.70817 34.2488C7.15719 31.7407 9.24073 29.6581 11.7495 28.2101L10.7497 26.4779C7.937 28.1013 5.60101 30.4363 3.9764 33.2483L5.70817 34.2488ZM11.7495 28.2101C14.2582 26.7622 17.1038 26 20.0004 26L20.0004 24C16.7529 24 13.5625 24.8546 10.7497 26.4779L11.7495 28.2101ZM20.0004 26C22.897 26 25.7426 26.7623 28.2513 28.2103L29.2511 26.4781C26.4384 24.8547 23.248 24 20.0004 24L20.0004 26ZM28.2513 28.2103C30.76 29.6583 32.8435 31.7409 34.2925 34.2491L36.0243 33.2486C34.3997 30.4366 32.0638 28.1015 29.2511 26.4781L28.2513 28.2103ZM29.0003 15C29.0003 19.9706 24.971 24 20.0004 24V26C26.0756 26 31.0003 21.0751 31.0003 15H29.0003ZM20.0004 24C15.0298 24 11.0003 19.9705 11.0003 15H9.00025C9.00025 21.0752 13.9253 26 20.0004 26V24ZM11.0003 15C11.0003 10.0294 15.0297 6 20.0003 6V4C13.9251 4 9.00025 8.92487 9.00025 15H11.0003ZM20.0003 6C24.9708 6 29.0003 10.0294 29.0003 15H31.0003C31.0003 8.92487 26.0754 4 20.0003 4V6Z" fill="#5E718D"/>
                                </svg>
                            </div>
                            <div>Registered business</div>
                        </div>

                        <div onClick={() => navigate('/admin/profile-setup/government-business')} className={'businessTypeCard'}>
                            <div></div>
                            <div>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.9764 33.2483C3.70012 33.7265 3.86382 34.3381 4.34203 34.6144C4.82025 34.8907 5.43189 34.727 5.70817 34.2488L3.9764 33.2483ZM34.2925 34.2491C34.5688 34.7273 35.1804 34.891 35.6586 34.6147C36.1368 34.3385 36.3006 33.7268 36.0243 33.2486L34.2925 34.2491ZM5.70817 34.2488C7.15719 31.7407 9.24073 29.6581 11.7495 28.2101L10.7497 26.4779C7.937 28.1013 5.60101 30.4363 3.9764 33.2483L5.70817 34.2488ZM11.7495 28.2101C14.2582 26.7622 17.1038 26 20.0004 26L20.0004 24C16.7529 24 13.5625 24.8546 10.7497 26.4779L11.7495 28.2101ZM20.0004 26C22.897 26 25.7426 26.7623 28.2513 28.2103L29.2511 26.4781C26.4384 24.8547 23.248 24 20.0004 24L20.0004 26ZM28.2513 28.2103C30.76 29.6583 32.8435 31.7409 34.2925 34.2491L36.0243 33.2486C34.3997 30.4366 32.0638 28.1015 29.2511 26.4781L28.2513 28.2103ZM29.0003 15C29.0003 19.9706 24.971 24 20.0004 24V26C26.0756 26 31.0003 21.0751 31.0003 15H29.0003ZM20.0004 24C15.0298 24 11.0003 19.9705 11.0003 15H9.00025C9.00025 21.0752 13.9253 26 20.0004 26V24ZM11.0003 15C11.0003 10.0294 15.0297 6 20.0003 6V4C13.9251 4 9.00025 8.92487 9.00025 15H11.0003ZM20.0003 6C24.9708 6 29.0003 10.0294 29.0003 15H31.0003C31.0003 8.92487 26.0754 4 20.0003 4V6Z" fill="#5E718D"/>
                                </svg>
                            </div>
                            <div>Government</div>
                        </div>


                    </div>


                </div>
            </div>); */}
        </>
    );
};
