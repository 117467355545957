import React from "react";
import { useNavigate } from 'react-router-dom';
import {Form, Input} from "antd";




const CooperativeSociety: React.FC<any> = ({ onNext }) => {
    const onFinish = (values: any) => {
        console.log('Success:', values);

        // setFormData(values)
        const payload: any = {
            "registeredName": values.businessName,
            "tradingName": values.tradingName,
            "mdaAdminCode": "RC123459",
            "description": values.businessDescription,
        }

        // mutate(payload, {
        //     onSuccess: (res: any) => {
        //         // setEmailVerification(true)
        //         setCurrentStage(currentStage + 1)
        //     },
        // })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <div className={'businessSetup'}>
                <Form
                    name="basicInformation"
                    initialValues={{remember: true}}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className={'profileInformation'}>
                        Cooperative Society
                    </div>


                    <div className={'profileInformation'}>
                        Documents
                    </div>

                    <div className={ 'businessDetails'}>
                        <div>
                            <label className="required fw-bold fs-9">
                                Cooperative certificate
                            </label>
                            <div className={`form-control form-control-solid fileUpload`}>
                                <label>
                                    <div>Click here to upload document</div>
                                    <input
                                        hidden
                                        id="input"
                                        type="file"
                                        // accept="text/csv"
                                        // onChange={extractData}
                                    />
                                </label>
                            </div>

                        </div>

                        <div className="mt-1">
                            <label className="required fw-bold fs-9">
                                Certified application form
                            </label>
                            <div className={`form-control form-control-solid fileUpload`}>
                                <label>
                                    <div>Click here to upload document</div>
                                    <input
                                        hidden
                                        id="input"
                                        type="file"
                                        // accept="text/csv"
                                        // onChange={extractData}
                                    />
                                </label>
                            </div>

                        </div>
                    </div>


                    <div className={'businessRegNextButton'}>
                        <button onClick={onNext}>Next</button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export { CooperativeSociety };
