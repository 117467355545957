import {FlapperSpinner} from "react-spinners-kit";
import {Layout} from "../../layout";
import {useMdaActivate, useMdaDetails} from "../../../auth/hooks/useUser";
import {DatePicker, Select, Table} from "antd";
import dayjs from "dayjs";
import React from "react";
import {Download} from "../../../../componets/download";
import {filter} from "../../../../../core/assets/icons";


const dateFormat = "YYYY/MM/DD";
const TopMdaSector = () => {

    const {
        isLoading,
    } = useMdaDetails();

    const {
        data: activateData,
        mutate: activateMutate,
        isLoading: activateLoading,
    } = useMdaActivate();


    const dataSource: any[] = [];
    const dataSourceForExcel: any[] = [
        [

        ],
    ];


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "30",
        },
        {
            title: "MDA Sector",
            dataIndex: "mdaSector",
            key: "mdaSector",
            width: "180",
        },
        {
            title: "Number of MDA",
            dataIndex: "numOfMda",
            key: "numOfMda",
            width: "180",
        },
        {
            title: "Revenue",
            dataIndex: "revenue",
            key: "revenue",
            width: "80",
        },
    ];

    for (let i = 1; i <= 100; i++) {
        const row = {
            id: i,
            mdaSector: `Administration`,
            numOfMda: `200`,
            revenue: `₦1,000,000`,
        };
        dataSource.push(row);
    }


    return (
       <>
                {isLoading || activateLoading ? (
                    <div className="loading-container">
                        <FlapperSpinner/> {/* Replace with your loading indicator */}
                    </div>
                ) : (
                    <div className={"topMda"}>
                        <div className={"topMdaBreadcrumb"}>
                                <div>Analytics</div>

                                <svg width="10" height="13" viewBox="0 0 10 13" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                        fill="#6366F1"/>
                                </svg>

                                <div>Collection View</div>

                                <svg width="10" height="13" viewBox="0 0 10 13" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                        fill="#6366F1"/>
                                </svg>

                                <div>Top 5 Performing MDA Sector</div>
                            </div>

                        <div className={"topMdaRow2"}>
                            <div>TOP 5 PERFORMING MDA SECTOR</div>

                            <div className="d-flex justify-content-end  mb-9 me-4">
                                <Download
                                    dataSourceForExcel={dataSourceForExcel}
                                    dataSource={dataSource}
                                    excelFileTitle={"Collection Inflow for MDA"}
                                    pdfFileTitle={"Collection Inflow for MDA"}
                                    excelPageTitle={"Collection Inflow for MDA"}
                                    grandTotalFor={{
                                        transactionAmount: "Transaction Amount",
                                        processingFee: "Processing Fee",
                                        vatProcessingFee: "VAT Processing Fee",
                                        totalProcessingFee: "Total Processing Fee",
                                        netAmount: "Net Amount",
                                        cumulativeNetAmount: "Cumulative Net Amount",
                                    }}
                                />
                            </div>

                            <div>
                                <div>TOTAL:</div>
                                <div>N6,000,000,000</div>
                            </div>

                            <Table
                                dataSource={dataSource}
                                bordered
                                columns={columns}
                                scroll={{ x: "800px" }}
                            />
                        </div>
                    </div>
                )}
            </>
    );
};

export default TopMdaSector;
