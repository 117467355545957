export const InfoSvg = () => {
  return (
    <span className="me-2 ">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.38 9.38H10V13.76H10.62M10 17.5C14.14 17.5 17.5 14.14 17.5 10C17.5 5.86 14.14 2.5 10 2.5C5.86 2.5 2.5 5.86 2.5 10C2.5 14.14 5.86 17.5 10 17.5ZM10 7.01C10.25 7.01 10.45 6.81 10.45 6.56C10.45 6.31 10.25 6.11 10 6.11C9.75 6.11 9.55 6.31 9.55 6.56C9.55 6.81 9.75 7.01 10 7.01Z"
        stroke="#3DADFE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      </svg>
    </span>
  );
};
