import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  MDAdirectory,
  arrowRight,
  arrowUpCircle,
  einvoice,
  homepageBubles,
  playCircle,
  receiptgeneration,
  revenuepayment,
  userHeroImg,
  welcomeImg,
  heroImg,
  developerHerorRHS,
  developerHerorLHS,
  voxepayImg,
} from '../../../core/assets/imgs';

import { Modal } from 'react-bootstrap';
import Typewriter from 'typewriter-effect';

const DeveloperHero: React.FC = () => {
  const [show, setShow] = useState<any>(false);
  const navigate = useNavigate();

  const done = () => {
    navigate('/payment');
  };
  const PageModal = () => {
    return (
      <Modal
        dialogClassName="home-modal"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
      >
        <div className=" d-flex flex-cloumn justify-content-center align-items-center w-536e h-326e bg-black rounded-1">
          <article className="w-100 h-100">
            <iframe
              className="rounded-1"
              width="100%"
              height="326"
              src="https://www.youtube-nocookie.com/embed/zH1HtWW_3-Q?rel=0&showinfo=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
              allowFullScreen
            ></iframe>
          </article>
        </div>
      </Modal>
    );
  };
  return (
    <div>
      <header className="hero mt-3b bg-img-2">
        <div className="section-center hero-center">
          <div className="hero-div">
            <span className="hero-header-dev">
              Build faster and integrate your payment solutions with ease
            </span>
            <button>
              <span>Get Started</span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="15"
                  viewBox="0 0 19 15"
                  fill="none"
                >
                  <path
                    d="M1.25 7.5H17.75M17.75 7.5L11 0.75M17.75 7.5L11 14.25"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </button>
          </div>
          

          
          <div className="img-hero-dev">
            <div >
              <img
                src={developerHerorRHS}
                alt=""
                className="img-hero-img-dev"
              />
            </div>
            <div>
              <img
                src={developerHerorLHS}
                alt=""
                className="img-hero-img-dev"
              />
            </div>
          </div>


               <div className="w-100  " id="help">
            <div
              className="help-center-section-developer"
            >
              <div className="developer-section-div">
                <div className="developer-section-div-section">
                  <div className="developer-section-div-item">
                    <div>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="48" height="48" rx="4" fill="#E6EFFF" />
                        <path
                          d="M30 32L38 25M18 32L10 25M30 16L38 23M18 16L10 23M11 16H37C37.5523 16 38 16.4477 38 17V31C38 31.5523 37.5523 32 37 32H11C10.4477 32 10 31.5523 10 31V17C10 16.4477 10.4477 16 11 16ZM28 24C28 26.2091 26.2091 28 24 28C21.7909 28 20 26.2091 20 24C20 21.7909 21.7909 20 24 20C26.2091 20 28 21.7909 28 24Z"
                          stroke="#0866FF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <div className="rhs-div-con">
                        <span className='header'>Collections and Payouts</span>
                          <span className='body'>
                            Seamlessly integrate with our API for collections
                            and payouts, enabling direct debit and credit
                            functionality on any account.
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="developer-section-div-section">
                  <div className="developer-section-div-item">
                    <div>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="48" height="48" rx="4" fill="#E6EFFF" />
                        <path
                          d="M30 32L38 25M18 32L10 25M30 16L38 23M18 16L10 23M11 16H37C37.5523 16 38 16.4477 38 17V31C38 31.5523 37.5523 32 37 32H11C10.4477 32 10 31.5523 10 31V17C10 16.4477 10.4477 16 11 16ZM28 24C28 26.2091 26.2091 28 24 28C21.7909 28 20 26.2091 20 24C20 21.7909 21.7909 20 24 20C26.2091 20 28 21.7909 28 24Z"
                          stroke="#0866FF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <div className="rhs-div-con">
                        <span className='header'>Secure Payment Process</span>
                          <span className='body'>
                           Secure Payment processing for business entity integrating payment or a developer building a custom payment solution.
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="developer-section-div-section">
                  <div className="developer-section-div-item">
                    <div>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="48" height="48" rx="4" fill="#E6EFFF" />
                        <path
                          d="M30 32L38 25M18 32L10 25M30 16L38 23M18 16L10 23M11 16H37C37.5523 16 38 16.4477 38 17V31C38 31.5523 37.5523 32 37 32H11C10.4477 32 10 31.5523 10 31V17C10 16.4477 10.4477 16 11 16ZM28 24C28 26.2091 26.2091 28 24 28C21.7909 28 20 26.2091 20 24C20 21.7909 21.7909 20 24 20C26.2091 20 28 21.7909 28 24Z"
                          stroke="#0866FF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <div className="rhs-div-con">
                        <span className='header'>Reference Data Services</span>
                          <span className='body'>
                           Validate your customer’s identity and associated information in an automated manner with simple calls to VoxePay’s API.
                          </span>
                      </div>
                    </div>
                  </div>
                </div>{' '}
              </div>
            </div>
          </div>

        

      
        </div>

        
      </header>

      {PageModal()}
    </div>
  );
};

export { DeveloperHero };
