import { DatePicker, Select, Space, Table, Tag } from "antd";
import { default as Moment, default as moment } from "moment";
import { useEffect, useState } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import { filter } from "../../../../../core/assets/icons";
import {
  FormatDate,
  convertToFirstLetterCap,
} from "../../../../../core/helpers";
import { Download } from "../../../../componets/download";
import { getAmountFilterOptions } from "../../../../services/utilityService";
import { useRequestgetServices } from "../../../auth/hooks/useUser";
import {
  useGetInFlow,
  useGetOrganisationChild,
  useGetUserBankDetails,
} from "../../Payments/hooks/usePayment";
const { RangePicker } = DatePicker;

const CollectionInflow = () => {
  const {
    data: InFlowData,
    mutate: mutateInFlowData,
    isLoading: isLoadingInFlowData,
  } = useGetInFlow();

  const {
    data: bankData,
    mutate: mutateBankData,
    isLoading: isLoadingBankData,
  } = useGetUserBankDetails();

  const {
    data: bankChild,
    mutate: mutateBankChild,
    isLoading: isLoadingBankChild,
  } = useGetOrganisationChild();

  const {
    data: orgSrvData,
    mutate: mutateOrgSrv,
    isLoading: isLoadingOrgSrv,
  } = useRequestgetServices();

  const lazydayUser = JSON.parse(
    localStorage.getItem("lazyday_user") as string
  );

  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [
    [
      "Date & Time",
      "Payment Reference",
      "Service",
      "Revenue Code",
      "Transaction Amount",
      "Processing Fee",
      "VAT Processing Fee",
      "Total Processing Fee",
      "Net Amount",
      "Cumulative Net Amount",
      "Currency",
      "Purpose",
      "Bank Name",
      "Account Name",
      "Account Classification",
      "Account Number",
      "Payment Channel",
      "Payment Gateway Name",
      "Payer Name",
      "Email",
      "Phone no",
      // "Payment Reference",
      "Payment Status",
    ],
  ];

  let cumulativeNetAmount = 0;

  function getAmountFilterOption() {
    const amounts = InFlowData?.map((obj) => obj.amount);
    if (amounts?.length > 0 && amountFilterOptions.length === 0) {
      setAmountFilterOptions(getAmountFilterOptions(amounts, 5));
    }
  }

  for (let i = 0; i < InFlowData?.length; i++) {
    const currentData = InFlowData[i];
    if (currentData) {
      const vat = Number((currentData.amount * 7.5) / 1000);
      const totalVat = 0 + vat;
      const netAmount = totalVat + currentData.amount;
      cumulativeNetAmount += currentData.amount + totalVat;

      const datad = {
        key: i.toString(),
        sn: i + 1,
        dateAndTime: currentData.dateTime
          ? Moment(currentData.dateTime).format("MMMM Do YYYY, h:mm a")
          : "",
        "Payment Reference": currentData.invoiceNumber,
        service: currentData.service,
        revenueCode: currentData.revCode || "N/A",
        transactionAmount: currentData.amount
          ? currentData.amount.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        processingFee: "0.00",
        vatProcessingFee: vat.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        totalProcessingFee: totalVat.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        netAmount: currentData.amount
          ? netAmount.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        cumulativeNetAmount: cumulativeNetAmount
          ? cumulativeNetAmount.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        currency: "NGN",
        purpose: currentData.purpose || "N/A",
        bankName: currentData.receivingBank,
        accountName: currentData.receivingAccountName,
        accountClassification: currentData?.accountType?.description,
        accountNumber: currentData.fundingAccount,
        paymentChannel: currentData.paymentChannel || "N/A",
        paymentGatewayName: currentData.paymentGateway || "N/A",
        payerName: currentData.fullName.trim(),
        email: currentData.email,
        phoneNo: currentData.phone,
        // paymentReference: currentData.paymentReference,
        paymentStatus: currentData.status,
        // orgName: currentData?.sector?.description,
      };
      dataSource.push(datad);
      dataSourceForExcel.push(Object.values(datad).slice(2));
    }
  }

  const columns = [
    {
      title: "",
      children: [
        {
          title: "S/N",
          dataIndex: "sn",
          key: "sn",
          width: "40",
          sorter: (a, b) => a.sn - b.sn,
        },
        {
          title: "Date & Time",
          dataIndex: "dateAndTime",
          key: "dateAndTime",
          width: "77",
        },
        {
          title: "Payment Reference",
          dataIndex: "Payment Reference",
          key: "Payment Reference",
          width: "120",
        },
        {
          title: "Service",
          dataIndex: "service",
          key: "service",
          width: "120",
        },
        {
          title: "Revenue Code",
          dataIndex: "revenueCode",
          key: "revenueCode",
          width: "90",
        },
        {
          title: "Transaction Amount",
          dataIndex: "transactionAmount",
          key: "transactionAmount",
          width: "130",
          sorter: (a, b) =>
            a.transactionAmount.replace(",", "") -
            b.transactionAmount.replace(",", ""),
        },
      ],
    },
    {
      title: "Charges",
      children: [
        {
          title: "Processing Fee",
          dataIndex: "processingFee",
          key: "processingFee",
          width: "100",
          sorter: (a, b) =>
            a.processingFee.replace(",", "") - b.processingFee.replace(",", ""),
        },
        {
          title: "VAT Processing Fee",
          dataIndex: "vatProcessingFee",
          key: "vatProcessingFee",
          width: "130",
          sorter: (a, b) =>
            a.vatProcessingFee.replace(",", "") -
            b.vatProcessingFee.replace(",", ""),
        },
        {
          title: "Total Processing Fee",
          dataIndex: "totalProcessingFee",
          key: "totalProcessingFee",
          width: "130",
          sorter: (a, b) =>
            a.totalProcessingFee.replace(",", "") -
            b.totalProcessingFee.replace(",", ""),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: "Net Amount",
          dataIndex: "netAmount",
          key: "netAmount",
          width: "120",
          sorter: (a, b) =>
            a.netAmount.replace(",", "") - b.netAmount.replace(",", ""),
        },
        {
          title: "Cumulative Net Amount",
          dataIndex: "cumulativeNetAmount",
          key: "cumulativeNetAmount",
          width: "150",
          sorter: (a, b) =>
            a.cumulativeNetAmount.replace(",", "") -
            b.cumulativeNetAmount.replace(",", ""),
        },
        {
          title: "Currency",
          dataIndex: "currency",
          key: "currency",
          width: "80",
        },
        {
          title: "Purpose",
          dataIndex: "purpose",
          key: "purpose",
          width: "150",
        },
      ],
    },
    {
      title: "Beneficiary Details",
      children: [
        {
          title: "Bank Name",
          dataIndex: "bankName",
          key: "bankName",
          width: "110",
        },
        {
          title: "Account Name",
          dataIndex: "accountName",
          key: "accountName",
          width: "125",
        },
        {
          title: "Account Classification",
          dataIndex: "accountClassification",
          key: "accountClassification",
          width: "150",
        },
        {
          title: "Account Number",
          dataIndex: "accountNumber",
          key: "accountNumber",
          width: "140",
        },
      ],
    },
    {
      title: "Payment Gateway Details",
      children: [
        {
          title: "Payment Gateway Name",
          dataIndex: "paymentGatewayName",
          key: "paymentGatewayName",
          width: "160",
        },
        {
          title: "Payment Channel",
          dataIndex: "paymentChannel",
          key: "paymentChannel",
          width: "110",
        },
      ],
    },
    {
      title: "Payment Details",
      children: [
        {
          title: "Payer Name",
          dataIndex: "payerName",
          key: "payerName",
          width: 200,
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          width: 200,
        },
        {
          title: "Phone no",
          dataIndex: "phoneNo",
          key: "phoneNo",
          width: 150,
        },
      ],
    },
    {
      title: "",
      children: [
        // {
        //   title: "Payment Reference",
        //   dataIndex: "paymentReference",
        //   key: "paymentReference",
        //   width: "120",
        // },
        {
          title: "Payment Status",
          dataIndex: "paymentStatus",
          key: "paymentStatus",
          render: (_, { paymentStatus }) => (
            <>
              <Tag color="green" key={paymentStatus}>
                {paymentStatus}
              </Tag>
            </>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    mutateInFlowData(`size=10000`);
    mutateBankData();
    mutateOrgSrv();
    mutateBankChild();
  }, []);

  useEffect(() => {
    getAmountFilterOption();
  }, [InFlowData]);

  const [account, setAccount] = useState<string>("");
  const [srv, setSrv] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [accountType, setAccountType] = useState<string>("");
  const [organisationChild, seTranisationChild] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [paymentChannelF, setPaymentChannelF] = useState<string>("");
  const [paymentGatewayF, setPaymentGatewayF] = useState<string>("");
  const [revcode, setRevcode] = useState<string>("");
  const [payerName, setPayerName] = useState<string>("");
  const [reference, setReference] = useState<string>("");
  const [grr, setGrr] = useState<string>("");
  const [amountFilter, setAmountFilter] = useState<string>("");
  const [amountFilterOptions, setAmountFilterOptions] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [refreshFilter, setRefreshFilter] = useState<boolean>(false);
  const [hasbnFiltered, setHasbnFiltered] = useState<boolean>(false);

  const searchFilter = `src-acct-num=${account}&service=${srv}&invoice-number=${grr}&start-date=${startDate}&end-date=${endDate}&size=10000&account-type=${accountType}&org-id=${organisationChild}&gateway=${paymentGatewayF}&rev-code=${revcode}&payer=${payerName}&payment-ref=${reference}&start-amount=${
    amountFilter.split("-")[0] || ""
  }&end-amount=${amountFilter.split("-")[1] || ""}&status=${status}`;
  const fetchFilter = () => {
    mutateInFlowData(searchFilter, {
      onSuccess: (response: any) => {
        setHasbnFiltered(true);
        setRefreshFilter(false);
      },
    });
  };

  const updateFilter = (value: string, fn: any) => {
    fn(value);
    if (value.length === 0 && !refreshFilter && hasbnFiltered) {
      setRefreshFilter(true);
      setHasbnFiltered(false);
    } else {
      setRefreshFilter(false);
      setHasbnFiltered(false);
    }
  };

  useEffect(() => {
    if (refreshFilter) {
      fetchFilter();
    }
  }, [refreshFilter]);

  const resetFilter = () => {
    let filter = `size=10000`;
    mutateInFlowData(filter, {
      onSuccess: (response: any) => {
        setAccount("");
        setSrv("");
        setGrr("");
        setStatus("");
        setAccountType("");
        seTranisationChild("");
        setCurrency("");
        setPaymentChannelF("");
        setPaymentGatewayF("");
        setReference("");
        setPayerName("");
        setRevcode("");
        setAmountFilterOptions([]);
        setAmountFilter("");
        setStartDate("");
        setEndDate("");
        setDateRange([]);
      },
    });
  };

  //   const transformedData = bankData?.map((item) => ({
  //     value: item.accountNumber,
  //     label: `${item.accountNumber}-${item.accountName}`,
  //   }));

  // const transformedData = Array.from(
  //   new Set(bankData?.map((item: any) => item))
  // ).map((item: any) => ({
  //   value: item.accountNumber,
  //   label: `${item.accountNumber}-${item.accountName}`,
  // }));

  const paymentStatusList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          return obj?.paymentStatus;
        })
        .filter((paymentStatus: any) => paymentStatus !== null)
    )
  ).map((paymentStatus) => ({
    value: paymentStatus,
    label: convertToFirstLetterCap(paymentStatus),
  }));

  const transformedAccountData = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.accountName === null ||
            obj?.accountName === undefined ||
            obj?.accountName === "N/A"
          ) {
            return null;
          }

          return obj;
        })
        .filter((obj: any) => obj !== null)
    )
  ).map((obj) => ({
    value: obj.accountNumber,
    label: `${obj.accountNumber}-${obj.accountName}`,
  }));
  const transformedData: any = Array.from(
    new Set(transformedAccountData?.map((obj: any) => obj.value))
  ).map((value) =>
    transformedAccountData?.find((obj: any) => obj.value === value)
  );

  const childDataTransaform = Array.from(
    new Set(bankChild?.map((item: any) => item))
  ).map((item: any) => ({
    value: item.id,
    label: item.registeredName,
  }));

  //   const transformedServiceData = orgSrvData?.map((item) => ({
  //     value: item?.description,
  //     label: item?.description,
  //   }));

  const transformedServiceData = Array.from(
    new Set(orgSrvData?.map((item: any) => item))
  ).map((item: any) => ({
    value: item?.description,
    label: item?.description,
  }));

  // const transformedServiceData = Array.from(
  //   new Set(
  //     dataSource
  //       .map((obj: any) => {
  //         return obj?.service;
  //       })
  //       .filter((obj: any) => obj?.service !== null)
  //   )
  // ).map((service: any) => ({
  //   value: service,
  //   label: service,
  // }));

  const transformedpaymentGateway = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.paymentGatewayName === null ||
            obj?.paymentGatewayName === undefined ||
            obj?.paymentGatewayName === "N/A"
          ) {
            return null;
          }
          return obj?.paymentGatewayName;
        })
        .filter((paymentGatewayName: any) => paymentGatewayName !== null)
    )
  ).map((paymentGatewayName: any) => ({
    value: paymentGatewayName,
    label: paymentGatewayName,
  }));

  const transformedAccountTypeData = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          return obj?.accountClassification;
        })
        .filter((accountClassification: any) => accountClassification !== null)
    )
  ).map((accountClassification) => ({
    value: accountClassification,
    label: accountClassification,
  }));

  const grrList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj["Payment Reference"] === null ||
            obj["Payment Reference"] === undefined
          ) {
            return null;
          }
          return obj["Payment Reference"];
        })
        .filter((item: null) => item !== null)
    )
  ).map((item) => ({
    value: item,
    label: item,
  }));

  // const paymentReferenceList = Array.from(
  //   new Set(
  //     dataSource
  //       .map((obj: { paymentReference: null | undefined }) => {
  //         if (
  //           obj?.paymentReference === null ||
  //           obj?.paymentReference === undefined
  //         ) {
  //           return null;
  //         }
  //         return obj.paymentReference;
  //       })
  //       .filter((paymentReference: null) => paymentReference !== null)
  //   )
  // ).map((paymentReference) => ({
  //   value: paymentReference,
  //   label: paymentReference,
  // }));

  const payerNameList = Array.from(
    new Set(
      dataSource
        .map((obj: { payerName: null | undefined }) => {
          if (obj?.payerName === null || obj?.payerName === undefined) {
            return null;
          }
          return obj.payerName;
        })
        .filter((payerName: null) => payerName !== null)
    )
  ).map((payerName) => ({
    value: payerName,
    label: payerName,
  }));

  const [dateRange, setDateRange] = useState<any>([]);
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      setStartDate(date1);
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");

      setEndDate(date2);
    } else {
      setDateRange([]);
    }
  };
  return (
    <>
      {isLoadingBankData ||
      isLoadingInFlowData ||
      isLoadingOrgSrv ||
      isLoadingBankChild ? (
        <div className="loading-container">
          <FlapperSpinner />
        </div>
      ) : (
        <div className={"reviewerDashboard"}>
          <div className={"ciBreadcrumb"}>
            <div>Reports</div>
            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                fill="#6366F1"
              />
            </svg>
            <div>Inflow</div>
          </div>

          <div className={"ciRow1"}>
            <div className={"ministryOfEduText"}>
              {" "}
              {lazydayUser?.registeredName}
            </div>

            <div>
              <div>
                <div className={"ciTitle"}>Report Date & Time</div>
                <div className={"ciValue"}> {FormatDate()} </div>
              </div>

              <div>
                <div className={"ciTitle"}>Administrative Code</div>
                <div className={"ciValue"}>{lazydayUser?.mdaAdminCode} </div>
              </div>

              <div>
                <div className={"ciTitle"}>Total Receipts</div>
                <div className={"ciValue"}> {InFlowData?.length} </div>
              </div>

              {/*<div>*/}
              {/*  <div className={"ciTitle"}>MDA Email</div>*/}
              {/*  <div className={"ciValue"}> {lazydayUser?.contact?.email} </div>*/}
              {/*</div>*/}

              {/*<div>*/}
              {/*  <div className={"ciTitle"}>MDA Phone number</div>*/}
              {/*  <div className={"ciValue"}>*/}
              {/*    {lazydayUser?.contact?.phoneNumber}{" "}*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>

          <div className={"reviewerTableContainer"}>
            <div className="d-flex justify-content-end  mb-9 me-4">
              <Download
                dataSourceForExcel={dataSourceForExcel}
                dataSource={dataSource}
                excelFileTitle={
                  lazydayUser?.registeredName + " - Inflow Report"
                }
                pdfFileTitle={
                  lazydayUser?.registeredName + " - Inflow Report"
                }
                excelPageTitle={
                  lazydayUser?.registeredName + " - Inflow Report"
                }
                grandTotalFor={{
                  transactionAmount: "Transaction Amount",
                  processingFee: "Processing Fee",
                  vatProcessingFee: "VAT Processing Fee",
                  totalProcessingFee: "Total Processing Fee",
                  netAmount: "Net Amount",
                  cumulativeNetAmount: "Cumulative Net Amount",
                }}
              />
            </div>

            <div
              className={"ciSelectOptions"}
              style={{
                display: "flex",
                gap: "15px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 190 }}
                options={[
                  { value: "", label: "Organisation Child" },
                  ...childDataTransaform,
                ]}
                onChange={(e) => updateFilter(e, seTranisationChild)}
                value={organisationChild}
                placeholder="Organisation Child"
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 200 }}
                options={[
                  { value: "", label: "Collection Account" },
                  ...transformedData,
                ]}
                onChange={(e) => updateFilter(e, setAccount)}
                placeholder="Collection Account"
                value={account}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 162 }}
                className="border border-gray-800 my-0 py-0 rounded-2"
                options={[
                  { value: "", label: "Payment Reference" },
                  ...grrList,
                ]}
                showSearch
                onChange={(e) => updateFilter(e, setGrr)}
                placeholder="Payment Reference"
                value={grr}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "Service" },
                  ...transformedServiceData,
                ]}
                onChange={(e) => updateFilter(e, setSrv)}
                placeholder="Service"
                value={srv}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
              />

              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Amount Range"
                placeholder={"Filter by Amount"}
                style={{ minWidth: 200 }}
                onChange={(e) => updateFilter(e, setAmountFilter)}
                options={[
                  { value: "", label: "Amount" },
                  ...amountFilterOptions,
                ]}
                value={amountFilter}
              />

              <Space direction="vertical" size={12}>
                <RangePicker
                  className="py-1 fs-8 fw-bold"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  allowClear
                  size={"small"}
                />
              </Space>

              {/* <Select
              dropdownMatchSelectWidth={false}
                            defaultValue="Expenditure Head/Subhead"
                            style={{width: 210}}
                            options={[
                                {value: 'jack', label: 'Jack'},
                                {value: 'lucy', label: 'Lucy'},
                                {value: 'Yiminghe', label: 'yiminghe'},
                                {value: 'disabled', label: 'Disabled', disabled: true},
                            ]}
                        /> */}

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 170 }}
                options={[
                  { value: "", label: "Account Classification" },
                  ...transformedAccountTypeData,
                ]}
                onChange={(e) => setAccountType(e)}
                placeholder="Account Classification"
                value={accountType}
              />

              {/* <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 162 }}
                className="border border-gray-800 my-0 py-0 rounded-2"
                options={[
                  { value: "", label: "Payment Reference" },
                  ...paymentReferenceList,
                ]}
                showSearch
                onChange={(e) => updateFilter(e, setReference)}
                placeholder="Payment Account"
                value={reference}
              /> */}
              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: 100 }}
                options={[
                  { value: "", label: "Currency" },
                  { value: "NGN", label: "NGN" },
                ]}
                onChange={(e) => setCurrency(e)}
                placeholder="Currency"
                value={currency}
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: 155 }}
                options={[
                  { value: "", label: "Payment Gateway" },
                  ...transformedpaymentGateway,
                ]}
                onChange={(e) => setPaymentGatewayF(e)}
                placeholder="Payment Gateway"
                value={paymentGatewayF}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: 152 }}
                options={[{ value: "", label: "Payment Channel" }]}
                onChange={(e) => setPaymentChannelF(e)}
                placeholder="Payment Channel"
                value={paymentChannelF}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 160 }}
                options={[{ value: "", label: "Payer Name" }, ...payerNameList]}
                onChange={(e) => setPayerName(e)}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                placeholder="Payer Name"
                value={payerName}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: 150 }}
                onChange={(e) => setStatus(e)}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                options={[
                  {
                    value: "",
                    label: "Transaction Status",
                  },
                  ...paymentStatusList,
                ]}
                value={status}
              />

              <button onClick={fetchFilter}>
                <span>Apply Filter</span>

                <img src={filter} alt="" />
              </button>

              <div onClick={resetFilter} className="cursor-pointer">
                <span className="fs-9 text-primary fw-bold">Reset Filter</span>
              </div>
            </div>

            <div style={{ overflowX: "auto" }} className="mt-9">
              <Table
                dataSource={dataSource}
                bordered
                columns={columns}
                scroll={{ x: "5000px" }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CollectionInflow;
