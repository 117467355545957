import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";

import {
  useDoAccountNameVerification,
  useGetBankInstitutions,
  useGetUserBankDetails,
  useRequestOnboardingStepBankDetails,
} from "../hooks/useUser";

const Schema = Yup.object().shape({
  bankAccountNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Invalid account number")
    .max(10, "Account number must be at most 10 characters long")
    .min(10, "Account number must be at most 10 characters long")
    .required("Account number is required"),

  // .typeError('Invalid number')
  // .nullable()

  // .max(9999999999, 'Number exceeds maximum length of 10 digits')
  //  .min(999999999, 'Number below minimum length of 10 digits')
  // .required('Number is required'),

  daliyTransactionLimit: Yup.number()
    .typeError("Invalid number")
    .max(99999999999, "Number exceeds maximum length of 11 digits")
    .required("Number is required"),
  // accountName: Yup.string().required("Account Name is required"),
});

interface Step1Props {
  handleNextStep?: () => void;
  handlePreviousStep?: () => void;
  setFormData?: React.Dispatch<React.SetStateAction<any>>;
  setTable?: React.Dispatch<React.SetStateAction<any>>;
  setStepTable?: React.Dispatch<React.SetStateAction<any>>;
  done?: any;
  stepTable?: any;
  close?: (e: boolean) => void;
}

const StepFour: React.FC<Step1Props> = ({ done, close }) => {
  const [editIndex, setEditIndex] = useState<any>(-1);
  const [proceed, setProceed] = useState<Boolean>(false);

  const formRef = useRef<any>(null);

  const { data, mutate, isLoading } = useRequestOnboardingStepBankDetails();

  const {
    data: bankDetails,
    mutate: mutateBankDetails,
    isLoading: mutateBankDetailsLoading,
  } = useGetUserBankDetails();

  const {
    data: bankInstitutions,
    mutate: mutateBankInstitutions,
    isLoading: mutateBankInstitutionsLoading,
  } = useGetBankInstitutions();

  const {
    data: getAccountNameVerification,
    mutate: mutateAccountNameVerification,
    isLoading: mutateAccountNameVerificationLoading,
  } = useDoAccountNameVerification();

  useEffect(() => {
    if (data) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
      formik.resetForm();
      setProceed(true);
    }
  }, [mutate, data, mutateBankDetails, bankDetails]);

  useEffect(() => {
    mutateBankInstitutions();
  }, []);

  const onNext = () => {};

  const [show, setShow] = useState<boolean>(false);

  const doNameVerification = () => {
    // const payload = {
    //   accountNumber: "0229942106",
    //   destinationInstitutionCode: "999058"
    // }
    const payload = {
      accountNumber: formik.values.bankAccountNumber,
      destinationInstitutionCode: formik.values.bankName.split("-")[1].trim(),
    };
    mutateAccountNameVerification(payload);
  };

  const initialValues = {
    daliyTransactionLimit: "",
    bankAccountNumber: "",
    bankName: "",
    accountPurpose: "",
    bankVerificationNumber: "",
    accountName: "",
  };

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#0866FF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Successful!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  You have successfully added the bank account.
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button
                className="otp-button-addmore"
                onClick={() => {
                  setShow(false);
                  close?.(false);
                }}
              >
                <span className="">Close</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      mutate(
        {
          bankName: values.bankName.split("-")[0].trim(),
          accountNumber: values.bankAccountNumber,
          dailyLimit: values.daliyTransactionLimit,
          bankVerificationNumber: values.bankVerificationNumber,
          institutionCode: values.bankName.split("-")[1].trim(),
          accountName:
            getAccountNameVerification?.accountName || values.accountName,
          accountPurpose: values.accountPurpose,
        },
        {
          onSuccess: (res: any) => {
            setShow(true);
            console.log("LLLLL");
            done();

            setProceed(true);
            formik.resetForm();
          },
        }
      );

      //hello

      // handleNextStep();
    },
  });

  return (
    <>
      {!show && (
        <div className="w-100 px-5 pb-5">
          <div>
            <div className="user-creation-header mb-3 mt-5">
              Add New Account
            </div>
            <div className="user-creation-text mb-9">
              This section requires you to specify the details of account that
              wil operate.
            </div>
          </div>
          <form ref={formRef} onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="py-0 input-height">
                {formik.values.bankName.length !== 0 && (
                  <label className="row required fw-bold fs-9">Bank Name</label>
                )}
              </div>

              {/*<div className="form-control form-control-solid  ">*/}
              {/*  <input*/}
              {/*    type="text"*/}
              {/*    placeholder="Central Bank Of Nigeria (CBN)  "*/}
              {/*    className={"no-outline ps-1 text-dark fs-8"}*/}
              {/*    {...formik.getFieldProps("bankName")}*/}
              {/*    disabled*/}
              {/*  />*/}
              {/*</div>*/}

              <div className="form-control form-control-solid  ">
                <select
                  {...formik.getFieldProps("bankName")}
                  className={`no-outline ps-1 fs-8 w-100 ${
                    formik.values.bankName !== "" ? "text-dark" : "text-muted"
                  } `}
                  placeholder="Select Bank "
                >
                  <option value="">Select Bank *</option>
                  {bankInstitutions?.map((institution, i) => {
                    return (
                      <option
                        value={
                          institution.institutionName +
                          " - " +
                          institution.institutionCode +
                          " - " +
                          institution.bankCode
                        }
                      >
                        {institution.institutionName}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.bankName &&
                    formik.errors.bankName &&
                    formik.errors.bankName}
                </div>
              </div>
            </div>

            <div className="">
              <div className="col-lg-12 col-form-label py-0 input-height">
                {formik.values.bankAccountNumber.length !== 0 && (
                  <label className=" row required fw-bold fs-9">
                    Bank Account Number
                  </label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="Bank Account Number *   "
                  className={"no-outline ps-1 text-dark fs-8"}
                  {...formik.getFieldProps("bankAccountNumber")}
                  onBlur={() => doNameVerification()}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.bankAccountNumber &&
                    formik.errors.bankAccountNumber &&
                    formik.errors.bankAccountNumber}
                </div>
              </div>
            </div>

            <div className="">
              <div className="col-lg-12 col-form-label py-0 input-height">
                {formik.values.accountName.length !== 0 && (
                  <label className=" row required fw-bold fs-9">
                    Account Name
                  </label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="Account Name * "
                  className={"no-outline ps-1 text-dark fs-8"}
                  {...formik.getFieldProps("accountName")}
                  value={getAccountNameVerification?.accountName || ""}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.accountName &&
                    formik.errors.accountName &&
                    formik.errors.accountName}
                </div>
              </div>
            </div>

            <div className="">
              <div className="col-lg-12 col-form-label py-0 input-height">
                {formik.values.daliyTransactionLimit.length !== 0 && (
                  <label className=" row required fw-bold fs-9">
                    Daliy Transaction Limit
                  </label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="Daliy Transaction Limit *   "
                  className={"no-outline ps-1 text-dark fs-8"}
                  {...formik.getFieldProps("daliyTransactionLimit")}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.daliyTransactionLimit &&
                    formik.errors.daliyTransactionLimit &&
                    formik.errors.daliyTransactionLimit}
                </div>
              </div>
            </div>

            <div className="">
              <div className="col-lg-12 col-form-label py-0 input-height">
                {formik.values.bankVerificationNumber.length !== 0 && (
                  <label className=" row required fw-bold fs-9">
                    Bank Verification Number
                  </label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="Bank Verification Number *   "
                  className={"no-outline ps-1 text-dark fs-8"}
                  {...formik.getFieldProps("bankVerificationNumber")}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.bankVerificationNumber &&
                    formik.errors.bankVerificationNumber &&
                    formik.errors.bankVerificationNumber}
                </div>
              </div>
            </div>

            <div>
              <label className="required fs-9">
                Note: Direct debit mandate is required if the bank account is
                for payments. Our customer support will work with you to ensure
                a seamless process.
              </label>
              <div className="col-lg-12 col-form-label py-0 input-height">
                {formik.values.accountPurpose.length !== 0 && (
                  <label className="required fw-bold fs-9">
                    Purpose of Bank Account *
                  </label>
                )}
              </div>

              <div className="form-control form-control-solid">
                <select
                  {...formik.getFieldProps("accountPurpose")}
                  className={`no-outline ps-1 fs-8 w-100 ${
                    formik.values.accountPurpose !== ""
                      ? "text-dark"
                      : "text-muted"
                  } `}
                  placeholder="Purpose of Bank Account "
                >
                  <option value="">Purpose of Bank Account</option>
                  <option value="PAYMENT">Payment Only</option>

                  <option value="COLLECTION">Collections Only</option>
                  <option value="COLLECTION_PAYMENT">
                    Payment and Collections
                  </option>
                </select>
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.accountPurpose &&
                    formik.errors.accountPurpose &&
                    formik.errors.accountPurpose}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                // className="row mt-9 add-more-account-root user-btn"
                disabled={!(formik.isValid && formik.dirty)}
                className={`row mt-9  otp-button user-btn ${
                  !(formik.isValid && formik.dirty)
                    ? "add-more-account-root-cancle"
                    : "add-more-account-root "
                } text-white px-7 py-2`}
              >
                Save
                {/* <span>
              
              <span className="ms-6 ">
                <span className="add-more-account">
                  {editIndex === -1 ? '  Save' : 'Update'}
                </span>
              </span>
            </span> */}
              </button>
            </div>
          </form>
        </div>
      )}

      {SuccessModal()}
    </>
  );
};

export { StepFour };
