import React, { useState } from 'react';
import '../../../../../App.css';
import { Modal } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import {
  useCreateSinglePaymentRequest,
  useGetBatchId,
  useGetUserBankDetails,
} from '../hooks/usePayment';

const Schema = Yup.object().shape({
  fundingAccount: Yup.string().required('Funding Account is required'),
});

const initialValues = {
  fundingAccount: '',
  beneficiaryFirstName: '',
  beneficiaryLastName: '',
  staffCustomerId: '',
  amount: '',
  employeeContribution: '',
  employerContribution: '',
  voluntaryContribution: '',
  pfaCode: '',
  pensionPin: '',
  beneficiaryEmail: '',
  beneficiaryPhoneNumber: '',
};

const SingleKeyIn = () => {
  const [successModal, setSuccessModal] = useState(false);

  const navigate = useNavigate();

  const { data, error, mutate, isLoading } = useCreateSinglePaymentRequest();

  const {
    data: batchId,
    mutate: mutateBatchId,
    isLoading: isLoadingBatchId,
  } = useGetBatchId();
    
    
     const {
    data: bankData,
    mutate: mutateBankData,
    isLoading: isLoadingBankData,
  } = useGetUserBankDetails();

  React.useEffect(() => {
      mutateBatchId();
    mutateBankData();

  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
        console.log(values);
        
        const accountData = values.fundingAccount.split("-");

        console.log(accountData);
        

      mutate(
        {
          data: {
            batchId: batchId,
    accountType: {
              code: accountData[2],
              value: accountData[3],
                },
              narration: "Pension",
    expenseType: "Pension",
            paymentType: {
              code: 'pensionPayments',
              description: 'Pension Payments',
            },
            paymentInstructions: [
              {
                sourceAccount: accountData[0],
                beneficiaryFirstName: values.beneficiaryFirstName,
                beneficiaryLastName: values.beneficiaryLastName,
                staffCustomerId: values.staffCustomerId,
                amount: values.amount,
                employeeContribution: values.employeeContribution,
                employerContribution: values.employerContribution,
                voluntaryContribution: values.voluntaryContribution,
                pfaCode: values.pfaCode,
                pensionPin: values.pensionPin,
                beneficiaryEmail: values.beneficiaryEmail,
                beneficiaryPhoneNumber: values.beneficiaryPhoneNumber,
                sourceBankAccountCode: accountData[2],
                sourceName: accountData[1],
                     paymentType: {
              code: 'pensionPayments',
              description: 'Pension Payments',
            },
                destAccountName: `${values.beneficiaryFirstName} + ' ' + ${values.beneficiaryLastName } `,
              },
            ],
          },
        },
        {
          onSuccess: (res: any) => {
            setSuccessModal(true);
          },
        }
      );
    },
  });

  let fundingAccount = [
    {
      id: '',
      description: 'Account number',
    },
    {
      id: '1',
      description: 'First Bank of Nigeria PLC (099***5545)',
    },
  ];

    const formatCurrency = (value, currency = 'USD') => 
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(value);

  return (
    <>
      <div className={'pensionPayments'}>
        <div className={'pensionPaymentsBreadcrumb'}>
          <div>Payments</div>
          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
              fill="#6366F1"
            />
          </svg>
          <div>Pension Payments</div>

          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
              fill="#6366F1"
            />
          </svg>
          <div>Key-In</div>
        </div>

        <div className={'singleKeyIn'}>
          <div className={'singleKeyInRow1'}>
            <div onClick={() => navigate('/admin/payment/pension-payments')}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7037_76293)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                    fill="#6366F1"
                  />
                </g>
                <rect
                  x="0.75"
                  y="1.25"
                  width="22.5"
                  height="22.5"
                  rx="11.25"
                  stroke="#6366F1"
                  stroke-width="1.5"
                />
                <defs>
                  <clipPath id="clip0_7037_76293">
                    <rect y="0.5" width="24" height="24" rx="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div>Pension payments</div>
          </div>

          <div className={'singleKeyInForm'}>
            <div className={'selectFundingDiv'}>
              <div>Select funding account</div>

              <div>
                <div className={`mt-1`}>
                  <div className={`form-control form-control-solid`}>
                    <select
                      className={`no-outline fs-8 ${
                        formik.values.fundingAccount !== ''
                          ? 'text-dark'
                          : 'text-muted'
                      } `}
                      {...formik.getFieldProps('fundingAccount')}
                      placeholder="Account number"
                    >
                      {/* {fundingAccount &&
                        fundingAccount?.map((array: any, i: any) => {
                          return (
                            <option value={array?.description} key={i}>
                              {array?.description}
                            </option>
                          );
                        })} */}
                                          
                                             <option value="">
                        Select account to make payment from
                      </option>
                                          
                                              {bankData?.map((array: any, i: any) => {
                        return (
                          <option
                            value={`${array.accountNumber}-${array.bankName}-${array.accountType.code}-${array.accountType.description}`}
                            key={i}
                          >
                            {array?.bankName}- {array?.accountNumber} - {formatCurrency(array?.balance || 0, 'NGN') }
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div></div>
              </div>
            </div>

            <div className={'beneficiaryDetails'}>
              <div>Beneficiary Details</div>

              <div className={'detailsRow'}>
                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Beneficiary first name
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Beneficiary first name"
                      className={'no-outline text-dark fs-8'}
                      {...formik.getFieldProps('beneficiaryFirstName')}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.beneficiaryFirstName &&
                        formik.errors.beneficiaryFirstName &&
                        formik.errors.beneficiaryFirstName}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Beneficiary last name
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Beneficiary last name"
                      className={'no-outline text-dark fs-8'}
                      {...formik.getFieldProps('beneficiaryLastName')}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.beneficiaryLastName &&
                        formik.errors.beneficiaryLastName &&
                        formik.errors.beneficiaryLastName}
                    </div>
                  </div>
                </div>
              </div>

              <div className={'detailsRow'}>
                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Staff/Customer ID
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Staff/Customer ID"
                      className={'no-outline text-dark fs-8'}
                      {...formik.getFieldProps('staffCustomerId')}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.staffCustomerId &&
                        formik.errors.staffCustomerId &&
                        formik.errors.staffCustomerId}
                    </div>
                  </div>
                </div>

                <div></div>
              </div>

              <div className={'detailsRow'}>
                <div className={`mt-1`}>
                  <div className="col-lg-4 col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">Amount</label>
                  </div>
                  <div className={'form-control form-control-solid'}>
                    <NumericFormat
                      type="text"
                      prefix="₦"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      className="no-outline text-dark fs-8"
                      placeholder="Amount"
                      allowNegative={false}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(event) =>
                        formik.setFieldValue('amount', event.floatValue)
                      }
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.amount &&
                        formik.errors.amount &&
                        formik.errors.amount}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Employee contribution
                      </label>
                  </div>
                  <div className={'form-control form-control-solid'}>
                    <NumericFormat
                      type="text"
                      prefix="₦"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      className="no-outline text-dark fs-8"
                      placeholder="Employee contribution"
                      allowNegative={false}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(event) =>
                        formik.setFieldValue(
                          'employeeContribution',
                          event.floatValue
                        )
                      }
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.employeeContribution &&
                        formik.errors.employeeContribution &&
                        formik.errors.employeeContribution}
                    </div>
                  </div>
                </div>
              </div>

              <div className={'detailsRow'}>
                <div className="mt-1">
                  <div className=" col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Employer contribution
                      </label>
                  </div>
                  <div className={'form-control form-control-solid'}>
                    <NumericFormat
                      type="text"
                      prefix="₦"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      className="no-outline text-dark fs-8"
                      placeholder="Employer contribution"
                      allowNegative={false}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(event) =>
                        formik.setFieldValue(
                          'employerContribution',
                          event.floatValue
                        )
                      }
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.employerContribution &&
                        formik.errors.employerContribution &&
                        formik.errors.employerContribution}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className=" col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Voluntary contribution
                      </label>
                  </div>
                  <div className={'form-control form-control-solid'}>
                    <NumericFormat
                      type="text"
                      prefix="₦"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      className="no-outline text-dark fs-8"
                      placeholder="Voluntary contribution"
                      allowNegative={false}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(event) =>
                        formik.setFieldValue(
                          'voluntaryContribution',
                          event.floatValue
                        )
                      }
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.voluntaryContribution &&
                        formik.errors.voluntaryContribution &&
                        formik.errors.voluntaryContribution}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'beneficiaryDetails'}>
              <div>Additional information</div>

              <div className={'detailsRow'}>
                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">PFA code</label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="PFA code"
                      className={'no-outline text-dark fs-8'}
                      {...formik.getFieldProps('pfaCode')}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.pfaCode &&
                        formik.errors.pfaCode &&
                        formik.errors.pfaCode}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Pension PIN
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Pension PIN"
                      className={'no-outline text-dark fs-8'}
                      {...formik.getFieldProps('pensionPin')}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.pensionPin &&
                        formik.errors.pensionPin &&
                        formik.errors.pensionPin}
                    </div>
                  </div>
                </div>
              </div>

              <div className={'detailsRow'}>
                <div className="mt-1">
                  <div className="col-lg-4 col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Beneficiary email
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="email"
                      placeholder="Beneficiary email"
                      className={'no-outline text-dark fs-8'}
                      {...formik.getFieldProps('beneficiaryEmail')}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.beneficiaryEmail &&
                        formik.errors.beneficiaryEmail &&
                        formik.errors.beneficiaryEmail}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Beneficiary phone number
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="tel"
                      placeholder="Beneficiary phone number"
                      className={'no-outline text-dark fs-8'}
                      {...formik.getFieldProps('beneficiaryPhoneNumber')}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.beneficiaryPhoneNumber &&
                        formik.errors.beneficiaryPhoneNumber &&
                        formik.errors.beneficiaryPhoneNumber}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {formik.isValid && formik.dirty ? (
            <div className={'singleKeyInSubmitButton'}>
              <button
                onClick={() => formik.handleSubmit()}
                style={{ background: '#6366F1' }}
              >
                Submit
              </button>
            </div>
          ) : (
            <div className={'singleKeyInSubmitButton'}>
              <button
                disabled
                onClick={() => setSuccessModal(false)}
                style={{ background: '#666666' }}
              >
                Loading...
              </button>
            </div>
          )} */}

               <div className={"userDetailsNextButton"}>
                                {/* <button
                                     className="text-success fw-bolder  fs-base text-center cursor-pointer"
                             
                                         type="submit"
                                >
                                    Next
                                </button> */}
            

            <button
                id="kt_password_cancel "
                onClick={() => formik.handleSubmit()}
                className={`btn nav-btn-Proceed ${
                  !(formik.isValid && formik.dirty )
                    ? 'btn-secondary'
                    : 'btn-success'
                } text-white px-7 py-2`}
                disabled={!(formik.isValid && formik.dirty )}
                // onClick={(e) => handleNextStep(e)}
              >
                {!isLoading && <span className="indicator-label">Proceed</span>}
                {isLoading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
                                </div>
        </div>

        <Modal
          centered
          style={{ width: '500px', maxWidth: '100%' }}
          open={successModal}
          onOk={() => {
              navigate('/admin/payment/pension-payments');
                  setSuccessModal(false);
          }}
          onCancel={() => {
              navigate('/admin/payment/pension-payments');
                  setSuccessModal(false);
          }}
          footer={null}
        >
          <div className={'ppSuccessModal'}>
            <svg
              style={{ justifySelf: 'center' }}
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.5937 20.3125L22.1354 31.25L16.4062 25.7812M43.75 25C43.75 35.3553 35.3553 43.75 25 43.75C14.6447 43.75 6.25 35.3553 6.25 25C6.25 14.6447 14.6447 6.25 25 6.25C35.3553 6.25 43.75 14.6447 43.75 25Z"
                stroke="#3BAEFE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div className={'ppSuccessModalTitle'}>Successful!</div>

            <div className={'ppSuccessModalDesc'}>
              Your transaction with Payment Reference {batchId} has been sent to the reviewer group for action.
            </div>

            <div className={'ppSuccessModalActionButton'}>
              <button
                onClick={() => {
                  navigate('/admin/payment/pension-payments');
                  setSuccessModal(false);
                }}
              >
                Done
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SingleKeyIn;
