export const hasRoleOAGFAdmin = (roles) => {
  return roles?.some((role) => role?.id === "ROLE_OAGF_ADMIN");
};

export const isOagfAdmin = (): boolean => {
  const x: any = localStorage.getItem("lazyday_user");
  const authorities = x !== null ? JSON.parse(x) : null;
  return hasRoleOAGFAdmin(authorities?.organizationUser?.authorities);
};

export const isOagfOrg = (): boolean => {
  var x: any = localStorage.getItem("lazyday_user");
  var authorities = x !== null ? JSON.parse(x) : null;
  return authorities.oagf;
};
