import React from "react";
import { useNavigate } from 'react-router-dom';




const FreeZoneEntity: React.FC = () => {


  const navigate = useNavigate();

  return (
        <div className={''}>
          <div className={'documentTitle'}>Free Zone Entity</div>

          <div>
            <ul className="bullet-list">
              <li className={'listStyle'}>Operating license</li>
              <li className={'listStyle'}>CTC of Notice of Situation or Change of Registered Address</li>
              <li className={'listStyle'}>Particulars of Directors or any claim therein</li>
              <li className={'listStyle'}>Memorandum and articles of association</li>
              <li className={'listStyle'}>NEPZA number</li>
              <li className={'listStyle'}>Certificate of Incorporation/Registration</li>
              <li className={'listStyle'}>Statement of Share Capital and Return of Allotment</li>
              <li className={'listStyle'}>Proof of business address</li>
              <li className={'listStyle'}>Proof of identity and address for directors of business</li>
              <li className={'listStyle'}>Proof of identity and address for shareholders that own up to 51% of business</li>
            </ul>
          </div>

          <div className={'requiredDocumentButton'}>
          <button
              onClick={() => navigate('/admin/profile-setup/registered-business/business-setup')}
            >
              Proceed
            </button>
          </div>
        </div>
          
  );
};

export { FreeZoneEntity };
