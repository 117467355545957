import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import {Button, Form, Input} from "antd";
import {useStarterBusiness} from "../../../auth/hooks/NewSimplyPay/onboarding";
// import { Button, Form, Input } from "antd";

interface BusinessDetailsProps {
  onNext: () => void;
}

const BusinessDetails: React.FC<BusinessDetailsProps> = ({ onNext }) => {
    const { data, mutate, isLoading } = useStarterBusiness();

    const onFinish = (values: any) => {
        console.log('Success:', values);

        // setFormData(values)
        const payload: any = {
            "registeredName": values.businessName,
            "tradingName": values.tradingName,
            "mdaAdminCode": "RC123459",
            "description": values.businessDescription,
            "contact": {
                "physicalAddress": values.currentOfficeAddress,
                "phoneNumber": values.businessPhoneNumber,
                "websiteUrl": values.website,
                "email": values.streetAddress,
                "businessEmail": values.businessEmail,
                "supportEmail": values.supportEmail,
                "disputeEmail": values.disputeEmail,
                "twitterHandle": values.twitter,
                "facebookHandle": values.facebook,
                "instagramHandle": values.instagram,
                "country": values.businessCountry,
                "state": values.stateRegion,
                "city": values.city,
                "buildingName": values.buildingName,
                "floor": values.floorUnitNumber
            },
            "bankDetails": [
                {
                    "bankName": values.bankName,
                    "accountNumber": values.accountNumber,
                    "accountName": values.bankName,
                    "dailyLimit": 0.0,
                    "accountType": {
                        "code": "",
                        "description": ""
                    },
                    "orgId": "",
                    "balance": 0.0,
                    "institutionCode": "123456"
                }
            ],
            "registrationType": "PRIVATE_INCORPORATED",
            "organizationType": {
                "code": "STARTER",
                "description": "",
                "orgType": "CORPORATE",
                "address": "",
                "documents": []
            },
            "kycDocuments": {},
            "sector": {
                "code": "RC654323",
                "description": "Affairs"
            },
            "staffSize": "",
            "industryType": "COMMERCE",
            "category": "REAL_ESTATE"
        }

        mutate(payload, {
            onSuccess: (res: any) => {
                // setEmailVerification(true)
                onNext()
                // setCurrentStage(currentStage + 1)
            },
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    return (
    <div className={'businessSetup'}>
        <Form
            name="basicInformation"
            initialValues={{remember: true}}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
      <div className={'profileInformation'}>
        Profile Information
      </div>

        <div className={ 'businessDetails'}>
            <Form.Item
                label="Business country"
                name="businessCountry"
                rules={[{required: true, message: 'Business country is required'}]}
            >
                <Input size="large"/>
            </Form.Item>

            <Form.Item
                label="Business name"
                name="businessName"
                rules={[{required: true, message: 'Business name is required'}]}
            >
                <Input size="large"/>
            </Form.Item>
        </div>

        <div className={ 'businessDetails'}>
            <Form.Item
                label="Trading name"
                name="tradingName"
                rules={[{required: true, message: 'Trading name is required'}]}
            >
                <Input size="large"/>
            </Form.Item>

            <Form.Item
                label="Business description"
                name="businessDescription"
                rules={[{required: true, message: 'Business description is required'}]}
            >
                <Input size="large"/>
            </Form.Item>
        </div>

        <div className={ 'businessDetails'}>
            <Form.Item
                label="Website"
                name="website"
                rules={[{required: true, message: 'Website is required'}]}
            >
                <Input size="large"/>
            </Form.Item>
        </div>

    <div className={'profileInformation'}>
      Contact Information
    </div>

        <div className={ 'businessDetails'}>
            <Form.Item
                label="Business email"
                name="businessEmail"
                rules={[{required: true, message: 'Business email is required'}]}
            >
                <Input size="large"/>
            </Form.Item>

            <Form.Item
                label="Business phone number"
                name="businessPhoneNumber"
                rules={[{required: true, message: 'Business phone number is required'}]}
            >
                <Input size="large"/>
            </Form.Item>
        </div>

        <div className={ 'businessDetails'}>
            <Form.Item
                label="Facebook"
                name="facebook"
            >
                <Input size="large"/>
            </Form.Item>

            <Form.Item
                label="X (Twitter)"
                name="twitter"
            >
                <Input size="large"/>
            </Form.Item>

        </div>

        <div className={ 'businessDetails'}>
            <Form.Item
                label="Instagram"
                name="instagram"
            >
                <Input size="large"/>
            </Form.Item>
        </div>

        <div className={'profileInformation'}>
      Address Information (as it appears on the business registration document)
    </div>
            <div className={ 'businessDetails'}>
                <Form.Item
                    label="Country"
                    name="country"
                    rules={[{required: true, message: 'Country is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>

                <Form.Item
                    label="State/Region"
                    name="stateRegion"
                    rules={[{required: true, message: 'State/Region is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>
            </div>

            <div className={ 'businessDetails'}>
                <Form.Item
                    label="City"
                    name="city"
                    rules={[{required: true, message: 'City is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>
            </div>

            <Form.Item
                label="Address line 1"
                name="addressLine1"
                rules={[{required: true, message: 'Address line 1 is required'}]}
            >
                <Input size="large"/>
            </Form.Item>

            <Form.Item
                label="Address line 2"
                name="addressLine2"
            >
                <Input size="large"/>
            </Form.Item>

            <Form.Item
                label="Current office address (if different from registered business address)"
                name="currentOfficeAddress"
                rules={[{required: true, message: 'Current office address is required'}]}
            >
                <Input size="large"/>
            </Form.Item>


            <div className={'businessDetails'}>
                <div className="mt-1">
                  <label className="required fw-bold fs-9">
                      Proof of address document
                  </label>
                  <div className={`form-control form-control-solid fileUpload`}>
                    <label>
                      <div>Click here to upload document</div>
                      <input
                        hidden
                        id="input"
                        type="file"
                        // accept="text/csv"
                        // onChange={extractData}
                      />
                    </label>
                  </div>

                </div>
      </div>


      <div className={'proofOfAddress'}>
        <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.38 7.38H8V11.76H8.62M8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5ZM8 5.01C8.25 5.01 8.45 4.81 8.45 4.56C8.45 4.31 8.25 4.11 8 4.11C7.75 4.11 7.55 4.31 7.55 4.56C7.55 4.81 7.75 5.01 8 5.01Z" stroke="#3DADFE" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div>
            Proof of address document can be bank statements, utility bills (electricity, water, DSTV, etc), tax assessment, or a letter from a government authority. Document provided should not be older than 3 months.
        </div>
      </div>

      <div className={'businessDetailsButton'}>
        <div className={'businessDetailsSkipButton'}>
          <button>Skip</button>
        </div>

        <div className={'businessDetailsNextButton'}>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Next
                </Button>
            </Form.Item>
        </div>
        </div>
        </Form>
      </div>
  );
};

export { BusinessDetails };
