import React, {useEffect, useState} from 'react';
import '../../../../../../App.css';
import {Modal} from "antd";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {NumericFormat} from "react-number-format";
import {
    useCreateSinglePaymentRequest, useGetBatchId, useGetUserBankDetails, useNameEnquiry
} from '../../hooks/usePayment';
import {MetroSpinner} from 'react-spinners-kit';


const Schema = Yup.object().shape({
    fundingAccount: Yup.string().required('Funding Account is required'),
    beneficiaryTin: Yup.string().required('Beneficiary Tin is required').matches(/^\d{8}-0001$/, {message: ['Beneficiary Tin should be an eight digit number, then - and then 0001']}),
    phoneNumber: Yup.string().required('Phone Number is required').matches(/^(?:\+?234|0)?[789]\d{9}$/, {message: ['Enter a valid phone number']}),
    emailAddress: Yup.string().required('Email Address is required').matches(/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/, {message: ['Enter a valid email address']}),
    grossAmount: Yup.string().required('Contract Amount is required'),
    taxState: Yup.string().required('Tax State is required'), // from: Yup.string().required('Date is required'),
    chargeableIncomeField: Yup.string().required('Chargeable Income Field is required'), // from: Yup.string().required('Date is required'),
});

const initialValues = {
    fundingAccount: '',
    beneficiaryTin: '',
    phoneNumber: '',
    emailAddress: '',
    grossAmount: '',
    taxPayable: '',
    taxState: '',
    chargeableIncomeField: '',
};

const PayeSingleKeyIn = () => {
    const [successModal, setSuccessModal] = useState(false);


    const navigate = useNavigate();


    const {data, error, mutate, isLoading} = useCreateSinglePaymentRequest();


    const {
        data: bankData, mutate: mutateBankData, isLoading: isLoadingBankData,
    } = useGetUserBankDetails();

    const {
        data: batchId, mutate: mutateBatchId, isLoading: isLoadingBatchId,
    } = useGetBatchId();



    useEffect(() => {
        mutateBankData();
        mutateBatchId();
    }, []);

    const nigeriaStates = [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara",
        "Federal Capital Territory"
    ];

    const formik = useFormik({
        initialValues, validationSchema: Schema, onSubmit: async (values) => {

            console.log(values);

            const accountData = values.fundingAccount.split('-');

            mutate({
                data: {
                    batchId: batchId, accountType: {
                        code: accountData[2], value: accountData[3],
                    }, narration: "Paye ", expenseType: 'Paye ', paymentType: {
                        code: 'paye_payment', description: 'Paye ',
                    }, paymentInstructions: [{
                        sourceAccount: accountData[0],
                        beneficiaryTin: values.beneficiaryTin,
                        phoneNumber: values.phoneNumber,
                        emailAddress: values.emailAddress,
                        grossAmount: values.grossAmount,
                        taxPayable: values.taxPayable,
                        taxState: values.taxState,
                        chargeableIncomeField: values.chargeableIncomeField,
                        paymentType: {
                            code: 'paye_payment', description: 'Paye ',
                        },
                    },],
                },
            }, {
                onSuccess: (res: any) => {
                    setSuccessModal(true);
                },
            });


        },
    });

    return (<>
            {isLoading || isLoadingBatchId || isLoadingBankData || isLoadingBatchId ?
                <div className="d-flex justify-content-center align-items-center my-10">
                    <MetroSpinner color="#1f8505"/>
                </div> : <div className={'vatPayment'}>
                    <div className={'vatPaymentBreadcrumb'}>
                        <div>Payments</div>
                        <svg
                            width="10"
                            height="13"
                            viewBox="0 0 10 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                fill="#1F8505"
                            />
                        </svg>
                        <div>Tax Payments</div>

                        <svg
                            width="10"
                            height="13"
                            viewBox="0 0 10 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                fill="#1F8505"
                            />
                        </svg>
                        <div>Paye</div>

                        <svg
                            width="10"
                            height="13"
                            viewBox="0 0 10 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                fill="#1F8505"
                            />
                        </svg>

                        <div>Key-In</div>
                    </div>

                    <div className={'vatSingleKeyIn'}>
                        <div className={'vatSingleKeyInRow1'}>
                            <div  onClick={() => navigate(-1)}>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7037_76293)">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                                              fill="#1F8505"/>
                                    </g>
                                    <rect x="0.75" y="1.25" width="22.5" height="22.5" rx="11.25" stroke="#1F8505"
                                          stroke-width="1.5"/>
                                    <defs>
                                        <clipPath id="clip0_7037_76293">
                                            <rect y="0.5" width="24" height="24" rx="12" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div>Paye</div>
                        </div>

                        <div className={'vatSingleKeyInForm'}>
                            <div className={'selectFundingDiv'}>
                                <div>Select funding account</div>

                                <div>
                                    <div className={`mt-1`}>
                                        <div className={`form-control form-control-solid`}>
                                            <select
                                                className={`no-outline fs-8 ${formik.values.fundingAccount !== '' ? 'text-dark' : 'text-muted'} `}
                                                {...formik.getFieldProps('fundingAccount')}
                                            >
                                                <option value="">
                                                    Select account to make payment from
                                                </option>

                                                {bankData?.map((array: any, i: any) => {
                                                    return (<option
                                                        value={`${array.accountNumber}-${array.accountName}-${array.accountType.code}-${array.accountType.description}`}
                                                        key={i}
                                                    >
                                                        {array?.accountName}- {array?.accountNumber} -₦{array?.balance.toLocaleString('en-US', {
                                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                                    })}
                                                    </option>);
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div></div>
                                </div>
                            </div>

                            <div className={'beneficiaryDetails'}>
                                <div>Beneficiary Details</div>
                                <div className={'detailsRow'}>

                                    <div className="mt-1">
                                        <div className="col-form-label py-0 input-height">
                                            <label className="required fw-bold fs-9">
                                                TIN
                                            </label>
                                        </div>
                                        <div className={`form-control form-control-solid`}>
                                            <input
                                                type="text"
                                                placeholder="TIN"
                                                className={'no-outline text-dark fs-8'}
                                                {...formik.getFieldProps('beneficiaryTin')}
                                            />
                                        </div>
                                        <div className="fv-plugins-message-container input-height">
                                            <div className="fv-help-block fs-9 text-danger">
                                                {formik.touched.beneficiaryTin && formik.errors.beneficiaryTin && formik.errors.beneficiaryTin}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-1">
                                        <div className="col-form-label py-0 input-height">
                                            <label className="required fw-bold fs-9">
                                                Tax state
                                            </label>
                                        </div>
                                        <div className={`form-control form-control-solid`}>

                                            <select
                                                className={`no-outline fs-8 ${formik.values.taxState !== "" ? "text-dark" : "text-muted"}`}
                                                {...formik.getFieldProps("taxState")}
                                            >
                                                <option value="">Select a state</option>
                                                {nigeriaStates.map((state, index) => (
                                                    <option key={index} value={state}>
                                                        {state}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="fv-plugins-message-container input-height">
                                            <div className="fv-help-block fs-9 text-danger">
                                                {formik.touched.taxState && formik.errors.taxState && formik.errors.taxState}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'detailsRow'}>
                                    <div className="mt-1">
                                        <div className="col-form-label py-0 input-height">
                                            <label className="required fw-bold fs-9">
                                                Phone number
                                            </label>
                                        </div>
                                        <div className={`form-control form-control-solid`}>
                                            <input
                                                type="text"
                                                placeholder="Phone number"
                                                className={'no-outline text-dark fs-8'}
                                                {...formik.getFieldProps('phoneNumber')}
                                            />
                                        </div>
                                        <div className="fv-plugins-message-container input-height">
                                            <div className="fv-help-block fs-9 text-danger">
                                                {formik.touched.phoneNumber && formik.errors.phoneNumber && formik.errors.phoneNumber}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-1">
                                        <div className="col-form-label py-0 input-height">
                                            <label className="required fw-bold fs-9">
                                                Email address
                                            </label>
                                        </div>
                                        <div className={`form-control form-control-solid`}>
                                            <input
                                                type="text"
                                                placeholder="Email address"
                                                className={'no-outline text-dark fs-8'}
                                                {...formik.getFieldProps('emailAddress')}
                                            />
                                        </div>
                                        <div className="fv-plugins-message-container input-height">
                                            <div className="fv-help-block fs-9 text-danger">
                                                {formik.touched.emailAddress && formik.errors.emailAddress && formik.errors.emailAddress}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'detailsRow'}>

                                    <div className="mt-1">
                                        <div className="col-form-label py-0 input-height">
                                            <label className=" fw-bold fs-9">
                                                Gross amount
                                            </label>
                                        </div>
                                        <div className={"form-control form-control-solid"}>
                                            <NumericFormat
                                                type="text"
                                                prefix="₦"
                                                thousandsGroupStyle="thousand"
                                                thousandSeparator=","
                                                className="no-outline text-dark fs-8"
                                                placeholder="Contract Amount"
                                                allowNegative={false}
                                                allowLeadingZeros={false}
                                                decimalScale={2}
                                                onValueChange={(event) => formik.setFieldValue("grossAmount", event.floatValue)}
                                            />
                                        </div>
                                        <div className="fv-plugins-message-container input-height">
                                            <div className="fv-help-block fs-9 text-danger">
                                                {formik.touched.grossAmount && formik.errors.grossAmount && formik.errors.grossAmount}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-1">
                                        <div className="col-form-label py-0 input-height">
                                            <label className=" fw-bold fs-9">
                                                Tax payable
                                            </label>
                                        </div>
                                        <div className={"form-control form-control-solid"}>

                                            <input
                                                type="text"
                                                placeholder="Paye  amount"
                                                className={"no-outline text-dark fs-8"}
                                                {...formik.getFieldProps("taxPayable")}
                                            />
                                        </div>
                                        <div className="fv-plugins-message-container input-height">
                                            <div className="fv-help-block fs-9 text-danger">
                                                {formik.touched.taxPayable && formik.errors.taxPayable && formik.errors.taxPayable}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            <div className={'detailsRow'}>
                                <div className="mt-1">
                                    <div className="col-form-label py-0 input-height">
                                        <label className=" fw-bold fs-9">
                                            Chargeable income field
                                        </label>
                                    </div>
                                    <div className={"form-control form-control-solid"}>

                                        <input
                                            type="text"
                                            placeholder="Chargeable income field"
                                            className={"no-outline text-dark fs-8"}
                                            {...formik.getFieldProps("chargeableIncomeField")}
                                        />
                                    </div>
                                    <div className="fv-plugins-message-container input-height">
                                        <div className="fv-help-block fs-9 text-danger">
                                            {formik.touched.chargeableIncomeField && formik.errors.chargeableIncomeField && formik.errors.chargeableIncomeField}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {formik.isValid && formik.dirty ? (<div className={"vatSingleKeyInSubmitButton"}>
                        <button
                            onClick={() => formik.handleSubmit()}
                            style={{background: "#1F8505"}}
                        >
                            Submit
                        </button>
                    </div>) : (<div className={"vatSingleKeyInSubmitButton"}>
                        <button
                            disabled
                            onClick={() => setSuccessModal(false)}
                            style={{background: "#CCCCCC"}}
                        >
                            Submit
                        </button>
                    </div>)}
                </div>


                <Modal
                centered
                style={{width: "500px", maxWidth: "100%"}}
            open={successModal}
            onOk={() => {
                navigate("/admin/payment/tax-payments");
                setSuccessModal(false);
            }}
            onCancel={() => {
                navigate("/admin/payment/tax-payments");
                setSuccessModal(false);
            }}
                        footer={null}
                    >
                        <div className={"ppSuccessModal"}>
                            <svg style={{justifySelf: "center"}} width="50" height="50" viewBox="0 0 50 50" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M33.5937 20.3125L22.1354 31.25L16.4062 25.7812M43.75 25C43.75 35.3553 35.3553 43.75 25 43.75C14.6447 43.75 6.25 35.3553 6.25 25C6.25 14.6447 14.6447 6.25 25 6.25C35.3553 6.25 43.75 14.6447 43.75 25Z"
                                    stroke="#FBCB38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <div className={"ppSuccessModalTitle"}>Successful!</div>

                            <div className={'ppSuccessModalDesc'}>
                                Your transaction with GPR no {batchId} has been sent to the reviewer group for action.
                            </div>

                            <div className={"ppSuccessModalActionButton"}>
                                <button
                                    onClick={() => {
                                        navigate("/admin/payment/tax-payments");
                                        setSuccessModal(false);
                                    }}
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>}

        </>);
};

export default PayeSingleKeyIn;
