import SimpliPayLogo from "../../../../core/assets/img/SimpliPayLogo.png";
import React, {useState} from "react";
import {Button, Form, Input, Select, Steps} from "antd";
import {useStarterBusiness} from "../../auth/hooks/NewSimplyPay/onboarding";

export const BusinessProfile: React.FC<any> = ({isFormSubmitted}) => {
    const { data, mutate, isLoading } = useStarterBusiness();
    const [currentStage, setCurrentStage] = useState(1);


    const countries = [
        {
            label: 'Asia',
            options: [
                { label: 'Afghanistan', value: 'afghanistan' },
                { label: 'China', value: 'china' },
            ],
        },
        {
            label: 'Africa',
            options: [
                { label: 'Algeria', value: 'algeria' },
                { label: 'Nigeria', value: 'nigeria' },
            ],
        },
        {
            label: 'Europe',
            options: [
                { label: 'France', value: 'france' },
                { label: 'Germany', value: 'germany' },
            ],
        },
        {
            label: 'North America',
            options: [
                { label: 'Canada', value: 'canada' },
                { label: 'United States', value: 'us' },
            ],
        },
        {
            label: 'South America',
            options: [
                { label: 'Argentina', value: 'argentina' },
                { label: 'Brazil', value: 'brazil' },
            ],
        },
        {
            label: 'Oceania',
            options: [
                { label: 'Australia', value: 'australia' },
                { label: 'New Zealand', value: 'new-zealand' },
            ],
        },
    ];

    const industries = [
        { label: "Information Technology (IT)", value: "it" },
        { label: "Healthcare", value: "healthcare" },
        { label: "Finance and Banking", value: "finance" },
        { label: "Manufacturing", value: "manufacturing" },
        { label: "Retail", value: "retail" },
        { label: "Education", value: "education" },
        { label: "Construction", value: "construction" },
        { label: "Hospitality and Tourism", value: "hospitality" },
        { label: "Telecommunications", value: "telecom" },
        { label: "Transportation and Logistics", value: "logistics" },
        { label: "Energy and Utilities", value: "energy" },
        { label: "Agriculture", value: "agriculture" },
        { label: "Media and Entertainment", value: "media" },
        { label: "Real Estate", value: "real-estate" },
        { label: "Automotive", value: "automotive" },
        { label: "Pharmaceuticals", value: "pharmaceuticals" },
        { label: "Aerospace and Defense", value: "aerospace-defense" },
        { label: "Government and Public Administration", value: "government" },
        { label: "Non-profit and Social Services", value: "non-profit" },
        { label: "Legal Services", value: "legal" },
        { label: "Consulting", value: "consulting" },
        { label: "Marketing and Advertising", value: "marketing" },
        { label: "Environmental Services", value: "environmental" },
        { label: "Research and Development", value: "research" },
        { label: "Insurance", value: "insurance" },
        { label: "Human Resources", value: "hr" },
        { label: "Food and Beverage", value: "food-beverage" },
        { label: "Fitness and Wellness", value: "fitness-wellness" },
        { label: "Fashion and Apparel", value: "fashion-apparel" },
        { label: "Art and Design", value: "art-design" }
    ];

    const onFinish = (values: any) => {
        console.log('Success:', values);

        // setFormData(values)
        const payload: any = {
            "registeredName": values.businessName,
            "tradingName": values.tradingName,
            "mdaAdminCode": "RC123459",
            "description": values.businessDescription,
            "contact": {
                "physicalAddress": values.streetAddress,
                "phoneNumber": values.businessPhoneNumber,
                "websiteUrl": values.website,
                "email": values.streetAddress,
                "businessEmail": values.businessEmail,
                "supportEmail": values.supportEmail,
                "disputeEmail": values.disputeEmail,
                "twitterHandle": values.twitter,
                "facebookHandle": values.facebook,
                "instagramHandle": values.instagram,
                "country": values.country,
                "state": values.stateRegion,
                "city": values.city,
                "buildingName": values.buildingName,
                "floor": values.floorUnitNumber
            },
            "bankDetails": [
                {
                    "bankName": values.bankName,
                    "accountNumber": values.accountNumber,
                    "accountName": values.bankName,
                    "dailyLimit": 0.0,
                    "accountType": {
                        "code": "",
                        "description": ""
                    },
                    "orgId": "",
                    "balance": 0.0,
                    "institutionCode": "123456"
                }
            ],
            "registrationType": "PRIVATE_INCORPORATED",
            "organizationType": {
                "code": "STARTER",
                "description": "",
                "orgType": "CORPORATE",
                "address": "",
                "documents": []
            },
            "kycDocuments": {},
            "sector": {
                "code": "RC654323",
                "description": "Affairs"
            },
            "staffSize": "",
            "industryType": "COMMERCE",
            "category": "REAL_ESTATE"
        }

        mutate(payload, {
            onSuccess: (res: any) => {
                isFormSubmitted()
            },
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <>
            <div className={'onboardingForm'}>
                <div className={'onboardingFormRow1'}>
                    Business Profile
                </div>

                <div className={'onboardingFormRow2'}>
                            <Form
                                name="basicInformation"
                                initialValues={{remember: true}}
                                layout="vertical"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="Business country"
                                        name="businessCountry"
                                        rules={[{required: true, message: 'Business country is required'}]}
                                    >
                                        <Select
                                            size="large"
                                            placeholder="Select a country"
                                            options={countries}
                                        >
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Business name"
                                        name="businessName"
                                        rules={[{required: true, message: 'Business name is required'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>

                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="Trading name"
                                        name="tradingName"
                                        rules={[{required: true, message: 'Trading name is required'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Business description"
                                        name="businessDescription"
                                        rules={[{required: true, message: 'Business description is required'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>

                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="Staff size"
                                        name="staffSize"
                                        rules={[{required: true, message: 'Staff size is required'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Industry"
                                        name="industry"
                                        rules={[{required: true, message: 'Industry is required'}]}
                                    >
                                        <Select
                                            size="large"
                                            placeholder="Select a Industry"
                                            options={industries}
                                        />
                                    </Form.Item>
                                </div>

                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="Category"
                                        name="category"
                                        rules={[{required: true, message: 'Category is required'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>

                                <div style={{fontWeight: 500, fontSize: '16px', marginBlock: '10px'}}>Business contacts</div>

                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="Business email"
                                        name="businessEmail"
                                        rules={[{required: true, message: 'Business email is invalid', type:'email'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Support email"
                                        name="supportEmail"
                                        rules={[{required: true, message: 'Support email is invalid', type:'email'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>

                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="Dispute email"
                                        name="disputeEmail"
                                        rules={[{required: true, message: 'Dispute email is invalid', type:'email'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Business phone number"
                                        name="businessPhoneNumber"
                                        rules={[{required: true, message: 'Business phone number is invalid', pattern: /^\+(?:[0-9] ?|[0-9]\([0-9]+\)[\s-]?){6,14}[0-9]$/}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>

                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="Website"
                                        name="website"
                                        rules={[{required: true, message: 'Website is invalid', pattern: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Facebook"
                                        name="facebook"
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>

                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="X (Twitter)"
                                        name="twitter"
                                    >
                                        <Input size="large"/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Instagram"
                                        name="instagram"
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>

                                <div style={{fontWeight: 500, fontSize: '16px', marginBlock: '10px'}}>Business Bank Account Details</div>

                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="Bank name"
                                        name="bankName"
                                        rules={[{required: true, message: 'Bank name is required'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Account number"
                                        name="accountNumber"
                                        rules={[{required: true, message: 'Account number is required'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>

                                <div>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.38 9.38H10V13.76H10.62M10 17.5C14.14 17.5 17.5 14.14 17.5 10C17.5 5.86 14.14 2.5 10 2.5C5.86 2.5 2.5 5.86 2.5 10C2.5 14.14 5.86 17.5 10 17.5ZM10 7.01C10.25 7.01 10.45 6.81 10.45 6.56C10.45 6.31 10.25 6.11 10 6.11C9.75 6.11 9.55 6.31 9.55 6.56C9.55 6.81 9.75 7.01 10 7.01Z" stroke="#3DADFE" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span>Name on account must be the same as business owner’s name.</span>
                                </div>

                                <div style={{fontWeight: 500, fontSize: '16px', marginBlock: '10px'}}>Business address</div>

                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="Country"
                                        name="country"
                                        rules={[{required: true, message: 'Country is required'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>

                                    <Form.Item
                                        label="State/Region"
                                        name="stateRegion"
                                        rules={[{required: true, message: 'State/Region is required'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>

                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="City"
                                        name="city"
                                        rules={[{required: true, message: 'City is required'}]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    label="Street address"
                                    name="streetAddress"
                                    rules={[{required: true, message: 'Street address is required'}]}
                                >
                                    <Input size="large"/>
                                </Form.Item>


                                <div className={'onboardingDetails'}>
                                    <Form.Item
                                        label="Building name"
                                        name="buildingName"
                                    >
                                        <Input size="large"/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Floor/Unit number"
                                        name="floorUnitNumber"
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </div>


                                <div className={'onboardingNextButton'}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Next
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
            </div>
        </>
    );
};
