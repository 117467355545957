import React, {useState} from "react";
import {Button, Modal} from "antd";
import {AddBusinessRepresentative} from "./AddBusinessRepresentative";


interface BusinessRepresentativeDetailsProps {
  onNext: () => void;
}

const BusinessRepresentativeDetails: React.FC<BusinessRepresentativeDetailsProps> = ({ onNext }) => {
    const [openAddRepresentative, setOpenAddRepresentative] = useState(false)
    const [representatives, setRepresentatives] = useState<any[]>([]);

    const addRepresentative = (payload: any) => {
        console.log('Received payload:', payload);
        setOpenAddRepresentative(false)
        setRepresentatives([...representatives, payload.businessRepresentatives[0]])
    };
    return (
    <>
        <div style={{display: "flex", justifyContent: "right", marginBlock: "20px"}}>
            <Button onClick={() => setOpenAddRepresentative(true)} style={{backgroundColor: "#0866FF", color: "white"}}>Add a representative</Button>
        </div>

        <div style={{display: "grid", gap: "20px"}}>
            {/*{*/}
            {/*    "firstName": "Bethany",*/}
            {/*    "lastName": "Ford",*/}
            {/*    "dateOfBirth": "07-Aug-2022",*/}
            {/*    "country": "Voluptatum ratione v",*/}
            {/*    "businessRole": "OWNER",*/}
            {/*    "percentageShare": 0,*/}
            {/*    "idDocument": "Possimus nulla cons",*/}
            {/*    "idDocumentNumber": "758",*/}
            {/*    "idDocumentPath": "",*/}
            {/*    "addressLine1": "95 New Parkway",*/}
            {/*    "addressLine2": "Quia provident dolo",*/}
            {/*    "city": "Hic architecto est a",*/}
            {/*    "state": "Et libero eum fugiat",*/}
            {/*    "documentPath": "",*/}
            {/*    "documents": [*/}
            {/*    ""*/}
            {/*    ]*/}
            {/*}*/}
            {representatives.map((representative, index) => (
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px", backgroundColor: "#FBFBFF", borderRadius: "10px"}}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: "20px"}}>
                    <span>{representative.firstName} {representative.lastName}</span>
                    <span>{representative.businessRole}</span>
                </div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: "20px"}}>
                <span style={{color: "#2075FF"}}>
                    <span>Edit</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.125 4.375L15.625 6.875M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#2075FF" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                    <span style={{color: "#B91C1C"}}>
                    <span>Delete</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.8753 8.125V13.125M8.12531 8.125V13.125M16.875 4.375L3.125 4.37501M15.6253 4.375V16.25C15.6253 16.4158 15.5595 16.5747 15.4422 16.6919C15.325 16.8092 15.1661 16.875 15.0003 16.875H5.00031C4.83455 16.875 4.67557 16.8092 4.55836 16.6919C4.44115 16.5747 4.37531 16.4158 4.37531 16.25V4.375M13.1253 4.375V3.125C13.1253 2.79348 12.9936 2.47554 12.7592 2.24112C12.5248 2.0067 12.2068 1.875 11.8753 1.875H8.12531C7.79378 1.875 7.47584 2.0067 7.24142 2.24112C7.007 2.47554 6.87531 2.79348 6.87531 3.125V4.375" stroke="#B91C1C" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                </div>
            </div>
            ))}

            <div className={'businessProfileNextButton'}>
                <Button onClick={onNext} type="primary" htmlType="submit">
                    Next
                </Button>
            </div>

        </div>

        <Modal centered open={openAddRepresentative} closable={false} footer={null} width={'50%'}>
            <AddBusinessRepresentative onNext={addRepresentative} />
        </Modal>
    </>
  );

};

export { BusinessRepresentativeDetails };
