import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  arrowDown,
  arrowRight,
  arrowUp,
  envelope,
  voxepayGroup,
  voxepayImg,
  voxepayFrame
} from '../../../core/assets/imgs';
import { Hero } from '../../componets/Hero.tsx';
import { AboutPage } from '../../componets/about';
import { Footer } from '../../componets/footer';
import NavBar from '../../componets/nav/NavBar';
import { Service } from '../../componets/service';
import { useRequestSubscribtion } from '../payments/hooks/usePayment';
import { UserHero } from '../../componets/Hero.tsx/userHero';
import { UserAboutPage } from '../../componets/about/UserAbout';
import { chat } from '../../../core/assets/icons';

const profileDetailsSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
});

const initialValues = {
  email: '',
};

const SimpliPayHomePage: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(initialValues);
  const [show, setShow] = useState<any>(false);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

    const redirecSignup = () => {
    navigate('/auth/register');
  };

  const [arrow, setArrow] = useState(true);
  const [FaqNo, setFaqNo] = useState<Number>(0);
  const handleFaq = (index: Number) => {
    if (index == FaqNo && arrow) {
      setArrow(false);
    } else {
      setArrow(true);
      setFaqNo(index);
    }
  };

  const done = () => {
    navigate('/payment');
  };

  const { data: subData, mutate, isLoading } = useRequestSubscribtion();

  // useEffect(() => {
  //   if (subData) {
  //     setShow(true);
  //   }
  // }, [mutate, subData]);

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      console.log(values);

      mutate(values);

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
  });

  const FloatingButton = () => {
    return (
      <div className="floating-button-container ">
        <button className="floating-button">
          <img src={chat} alt="" className="w-25e h-25e" />
        </button>
      </div>
    );
  };

  const PageModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-9 modal-card"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        backdrop={'static'}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="d-flex flex-column align-items-center m-10">
            <article className="about-img d-flex justify-content-center align-items-center">
              <iframe
                className="hero-photo-video"
                width="90%"
                height="304px"
                src="https://www.youtube.com/embed/zH1HtWW_3-Q"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
              ></iframe>
            </article>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const faq = [
    {
      title: 'Why use TMS?',
      desc: 'The Treasury Portal is an application that provides ease of payments for government services through multiple payment channels for your convenience.',
    },
    {
      title: 'How to pay for school fees?',
      desc: 'To make fees payment or any other payment, please go to Revenue Payment, search for the MDA and select service.',
    },
    {
      title: 'Do I have to register to use TMS?',
      desc: 'No. You can go ahead to make payment for a services through the provided options without registering. Once you have made your first payment, you can carry out subsequent payment using same details.',
    },
  ];
  return (
    <div className="bg-img">
      <NavBar />
      <UserHero />

      <div
        className="py-20 w-100 px-5 "
        id="help"
        style={{ marginTop: '9rem' }}
      >
        <div className="help-center-section" style={{ width: '96vw' }}>
          <div className="d-flex flex-column align-items-center">
            <div className="help-center-rw">
              <span className="help-text-rw-1">
                VoxePay facilitates Hassle-free online payment solutions.
              </span>
              <span className="help-text-rw-2">
                Let your customers pay you easily, quickly, and securely, and
                take your business to the next level with our cutting-edge
                payment gateway solution. Quick payments, more peace of mind.
                Simplify your payment process, skyrocket your income.
              </span>
              <button onClick={redirecSignup}>
                <span>Get Started</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="15"
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M1.25 7.5H17.75M17.75 7.5L11 0.75M17.75 7.5L11 14.25"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div>
            <div>
              <img src={voxepayImg} alt="" style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      <div className=" w-100 " id="help">
        <div className="help-center-section-2" style={{ width: '98vw' }}>
          <div>
            <div className="img-div-rw">
              <img
                src={voxepayGroup}
                alt=""
                style={{ width: '100%', height: '80%' }}
              />
            </div>
          </div>

          <div className="d-flex flex-column align-items-center">
            <div className="help-center-rw">
              <span className="help-text-rw-1">
                Frictionless Payments, Focused on Your Business.
              </span>
              <span className="help-text-rw-2">
                Effortlessly create services and receive payments with VoxePay's
                secure and streamlined payment checkout process.
              </span>
              <button onClick={redirecSignup}>
                <span>Get Started</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="15"
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M1.25 7.5H17.75M17.75 7.5L11 0.75M17.75 7.5L11 14.25"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>


           <div
        className=" w-100 px-5 "
        id="help"
      >
        <div className="help-center-section" style={{ width: '96vw' }}>
          <div className="d-flex flex-column align-items-center">
            <div className="help-center-rw">
              <span className="help-text-rw-1">
             Streamline payments,
Grow your business faster.
              </span>
              <span className="help-text-rw-2">
                <ul>
                  <li>Accept payments from anywhere in the world</li>
                  <li>Offer flexibility and convenience to your customers</li>
                  <li>Reduce payment processing time and complexity</li>
                  <li>Increase your revenue and business growth</li>
                  <li>Provide a seamless and secure payment experience</li>
              </ul>
              </span>
              <button onClick={redirecSignup}>
                <span>Get Started</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="15"
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M1.25 7.5H17.75M17.75 7.5L11 0.75M17.75 7.5L11 14.25"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div>
            <div>
              <img src={voxepayFrame} alt="" style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {PageModal()}
      {FloatingButton()}
    </div>
  );
};

export { SimpliPayHomePage };
