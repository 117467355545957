import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBarSignup from '../../componets/nav/NavSignup';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import Multiselect from 'multiselect-react-dropdown';

import { edit } from '../../../core/assets/icons';
import { StepFive } from './step/StepFive';
import { StepFour } from './step/StepFour';
import { StepOne } from './step/StepOne';
import { StepSix } from './step/StepSix';
import { StepThree } from './step/StepThree';
import { StepTwo } from './step/StepTwo';
import { Preview } from './step/Preview';
import {
  useGetUserBankDetails,
  useGetUsersDetails,
  useGetOrgUserFLow,
} from './hooks/useUser';
import { log } from 'console';

const Signup: React.FC = () => {
  const navigate = useNavigate();


   const location = useLocation();
  const { stage } = location.state || {};

  useEffect(() => {
    if (stage === 1) {
      setStep(stage + 1)
    }

    if (stage === 2) {
      setStep(stage + 0.5)
    }
   
  }, [stage])

  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState([]);

  const [formData2, setFormData2] = useState([]);

  const [loading, setLoading] = useState(false);



  const [tableSet, setTable] = useState<Boolean>(false);
  const [stepTable, setStepTable] = useState<Number>(1);

  const [tableSet2, setTable2] = useState<Boolean>(false);
  const [stepTable2, setStepTable2] = useState<Number>(1);

  const [tableUserWork, setTableUserWork] = useState<Boolean>(false);
  const [stepTableUserWorkFlow, setStepTableUserWorkFlow] = useState<Number>(1);

  const finalStep = 2.5;

  const handleNextStep = () => {
    if (step === finalStep) {
      console.log(step);
    } else {
      setStep(step + 0.5);
      console.log(step);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 0.5);
  };

  const {
    data: userDetails,
    mutate: mutateUserDetails,
    isLoading: mutateUserDetailsLoading,
  } = useGetUsersDetails();

  if (userDetails) {
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  }

  const {
    data: bankDetails,
    mutate: mutateBankDetails,
    isLoading: mutateBankDetailsLoading,
  } = useGetUserBankDetails();

  const {
    data: userWorkFlow,
    mutate: mutateUserWorkFlow,
    isLoading: mutateUserWorkFlowLoading,
  } = useGetOrgUserFLow();

  //console.log(stepTableUserWorkFlow);

  useEffect(() => {
    if (tableUserWork) {
      mutateUserWorkFlow();
    }
  }, [stepTableUserWorkFlow]);

  useEffect(() => {
    if (tableSet) {
      mutateBankDetails();
    }
  }, [stepTable]);

  useEffect(() => {
    if (tableSet2) {
      mutateUserDetails();
    }
  }, [stepTable2]);

  interface Props {
    data: Array<{ name: string; account: number; limit: number }>;
  }

  const Table: React.FC<any> = ({ data }) => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Bank Name</th>
            <th> Account Number</th>
            {/* <th> Type</th> */}
            <th> Name</th>
            <th> Limit</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {bankDetails?.map((row: any, index: any) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{row.bankName}</td>
              <td>{row.accountNumber}</td>
              {/* <td> {row.accountType.description} </td> */}
              {/* <td>{row.accountType}</td> */}
              <td>{row.accountName}</td>
              <td>{row.dailyLimit}</td>
           
              {/* <td>
                <svg
                  width="6"
                  height="20"
                  viewBox="0 0 6 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="2.65686" r="2.5" fill="#08140C" />
                  <circle cx="3" cy="9.65686" r="2.5" fill="#08140C" />
                  <circle cx="3" cy="16.6569" r="2.5" fill="#08140C" />
                </svg>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const TableUser: React.FC<any> = ({ data }) => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name </th>
            <th>Last Name </th>
            <th>Phone Number </th>
            <th>Official Email </th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {userDetails?.map((row: any, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{row.firstName}</td>
              <td>{row.lastName}</td>
              <td>{row.phoneNumber}</td>
              <td>{row.email}</td>
              {/* <td>
                <svg
                  width="6"
                  height="20"
                  viewBox="0 0 6 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="2.65686" r="2.5" fill="#08140C" />
                  <circle cx="3" cy="9.65686" r="2.5" fill="#08140C" />
                  <circle cx="3" cy="16.6569" r="2.5" fill="#08140C" />
                </svg>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const TableUserWork: React.FC<any> = ({ data }) => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Process Type</th>
            <th>Initiator</th>
            <th>Reviewer</th>
            <th>Approver </th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {userWorkFlow?.map((userFlow, i) => {
            return (
              <tr key={i}>
                <td> {i + 1} </td>
                <td>{userFlow.cat}</td>
                {userFlow.roles.map((user, i) => {
                  return (
                    <td>
                      {user.users.map((u, i) => {
                        return (
                          <div>
                            <div>{u}</div>
                          </div>
                        );
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const dataAccount = [
    {
      name: 'string',
      account: 2,
      limit: 5000,
    },
    {
      name: 'string',
      account: 2,
      limit: 5000,
    },
    {
      name: 'string',
      account: 3,
      limit: 5000,
    },
  ];

  return (
    <div className={`alt-bg h-1000px`} style={{ background: '#F5F5F5' }}>
      <NavBarSignup />
      <div className="">
        {step !== 2.5 ? (
          <div className="login-main mt-9">
            <div className="py-15 pt-3">
              <div className="m-auto my-11 responsive-card overflow-none d-flex flex-column">
                <div className="d-flex align-items-center">
                  <span
                    className={`fw-bold fs-9 ${
                      step >= 1 ? 'touched-step' : 'unTouched-step'
                    }`}
                  >
                    1
                  </span>

                  <span
                    className={`mx-1 ${
                      step >= 1 ? 'touched-line' : 'unTouched-line'
                    }`}
                  />

                  <span
                    className={`fw-bold fs-9 ${
                      step >= 2 ? 'touched-step' : 'unTouched-step'
                    }`}
                  >
                    2
                  </span>

                  <span
                    className={`mx-1  ${
                      step === 3 ? 'touched-line' : 'unTouched-line'
                    }`}
                  />

                  <span
                    className={`fw-bold fs-9 ${
                      step === 3 ? 'touched-step' : 'unTouched-step'
                    }`}
                  >
                    3
                  </span>
                </div>

                <div className="d-flex justify-content-between my-4">
                  <span className="fs-9 ">Step 1 of 3</span>

                  <span className="fs-9 ">Step 2 of 3</span>

                  <span className="fs-9 ">Step 3 of 3</span>
                </div>

                <div className="signup-container">
                  <div className="side-1">
                    <span
                      className={`side-1-text ${
                        step === 1 || step === 2 || step === 3
                          ? 'side-1-text-active'
                          : ''
                      }`}
                    >
                      {(step === 1 && ' Personal Details') ||
                        (step === 1.5 && ' Personal Details')}
                      {(step === 2 && ' Entity Details') ||
                        (step === 2.5 && ' Entity Details')}

                      {(step === 3 && ' User Creation') ||
                        (step === 3.5 && 'User Creation')}
                    </span>
                  </div>
                  <div className="side-2">
                    <span
                      className={`side-1-text ${
                        step === 1.5 || step === 2.5 || step === 3.5
                          ? 'side-1-text-active'
                          : ''
                      }`}
                    >
                      {(step === 1 && 'Security') ||
                        (step === 1.5 && 'Security')}
                      {/* {(step === 2 && 'Bank Account Details') ||
                        (step === 2.5 && 'Bank Account Details')} */}

                      {(step === 3 && 'Approval Workflow Definition') ||
                        (step === 3.5 && 'Approval Workflow Definition')}
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={`card m-auto p-11 responsive-card  ${
                  step === 2 ? 'responsive-card-large' : 'responsive-card'
                }`}
                style={{ marginTop: '4rem' }}
              >
                <div className="form align-middle  gx-0 gy-5">
                  {step === 1 && <StepOne handleNextStep={handleNextStep} />}
                  {step === 1.5 && (
                    <StepTwo
                      handleNextStep={handleNextStep}
                      handlePreviousStep={handlePreviousStep}
                    />
                  )}

                  {step === 2 && (
                    <StepThree
                      handleNextStep={handleNextStep}
                      handlePreviousStep={handlePreviousStep}
                      stage={stage}
                    />
                  )}

                  {/* {step === 2.5 && (
                    <StepFour
                      handleNextStep={handleNextStep}
                      handlePreviousStep={handlePreviousStep}
                      setFormData={setFormData}
                      setTable={setTable}
                      setStepTable={setStepTable}
                      stepTable={stepTable}
                    />
                  )} */}

                  {/* {step === 3 && (
                    <StepFive
                      handleNextStep={handleNextStep}
                      handlePreviousStep={handlePreviousStep}
                      setFormData2={setFormData2}
                      setTable2={setTable2}
                      setStepTable2={setStepTable2}
                      stepTable2={stepTable2}
                    />
                  )} */}

                  {/* {step === 3.5 && (
                    <StepSix
                      handleNextStep={handleNextStep}
                      handlePreviousStep={handlePreviousStep}
                      setFormData={setFormData}
                      setTableUserWork={setTableUserWork}
                      setStepTableUserWorkFlow={setStepTableUserWorkFlow}
                      stepTableUserWorkFlow={stepTableUserWorkFlow}
                    />
                  )} */}
                </div>
              </div>
{/* 
              {step === 2.5 && tableSet && (
                <div className="pt-10 mt-9">
                  <div className=" m-auto p-11 py-7 responsive-card-large mt-19">
                    <div className="d-flex justify-content-between ">
                      <Table data={dataAccount} />
                    </div>
                    <div></div>
                  </div>
                </div>
              )} */}

              {/* {step === 3 && tableSet2 && (
                <div className="pt-10 mt-9">
                  <div className=" m-auto p-11 py-7 responsive-card-large mt-19">

                    <div className="d-flex justify-content-between ">
                      <TableUser data={dataAccount} />
                    </div>
                    <div></div>
                  </div>
                </div>
              )} */}

              {/* {step === 3.5 && tableUserWork && (
                <div className="pt-10 mt-9">
                  <div className=" m-auto p-11 py-7 responsive-card-large mt-19">

                    <div className="d-flex justify-content-between ">
                      <TableUserWork data={dataAccount} />
                    </div>
                    <div></div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        ) : (
          <Preview
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            step={step}
          />
        )}
      </div>
    </div>
  );
};

export { Signup };
