import { useMdaDetails } from "app/pages/auth/hooks/useUser";
import { StepFour } from "app/pages/auth/step/StepFourHome";
import { Modal } from "react-bootstrap";

export const AddBankModal = ({ show, setShow }) => {
  const orgId: string | null = localStorage.getItem("orgId");

  const { mutate: detailsMutate } = useMdaDetails();

  return (
    <div className="w-100">
    <Modal
      dialogClassName="m-auto px-7 modal-card-success"
      aria-hidden="true"
      show={show}
      onHide={() => setShow(false)}
      animation
      centered
      style={{
        padding: "2rem",
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body className="rounded">
        <StepFour done={() => detailsMutate(orgId)} stepTable={undefined} />
      </Modal.Body>
    </Modal>
    </div>
  );
};
