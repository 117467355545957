import React from "react";
import { useNavigate } from 'react-router-dom';




const PublicPrivateIncorporated: React.FC = () => {


  const navigate = useNavigate();

  return (
        <div className={''}>
          <div className={'documentTitle'}>Public Incorporated Company and Private Incorporated Company</div>

          <div>
            <ul className="bullet-list">
              <li className={'listStyle'}>Form CAC 1.1</li>
              <li className={'listStyle'}>Memorandum and articles of n</li>
              <li className={'listStyle'}>Certificate of Incorporation</li>
              <li className={'listStyle'}>Registration Number</li>
              <li className={'listStyle'}>Tax Identification Number</li>
              <li className={'listStyle'}>Proof of business address</li>
              <li className={'listStyle'}>Proof of identity and address for directors of business</li>
              <li className={'listStyle'}>Proof of identity and address for shareholders that own up to 51% of business</li>
            </ul>
          </div>

          <div className={'requiredDocumentButton'}>
          <button
              onClick={() => navigate('/admin/profile-setup/registered-business/business-setup')}
            >
              Proceed
            </button>
          </div>
        </div>
          
  );
};

export { PublicPrivateIncorporated };
