
// export const baseUrl = 'https://api-web.voxepay.app/';
// export const baseUrlStepTwo = 'https://api-web.voxepay.app/';
// export const baseUrlStepThree = 'https://api-web.voxepay.app/';



const host = window.location.host;

export const kycBaseURL =
  host === 'voxepay.com'
    ? 'https://bucket.voxepay.com'
    : 'https://bucket.voxepay.app';




export const baseUrl = 'https://api-prd.voxepay.app/';
export const baseUrlStepTwo = 'https://api-prd.voxepay.app/';
export const baseUrlStepThree = 'https://api-prd.voxepay.app/';

export const baseUrlNameEnquiry = 'https://acc.voxepay.app/';



export const baseImageUrl = `${baseUrl}/images`;
