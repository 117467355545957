import React, {useState} from "react";
import {BusinessProfile} from "./BusinessProfile";
import {BusinessOwnerInformation} from "./BusinessOwnerInformation";
import {Steps} from "antd";
import {useNavigate} from "react-router-dom";
import { LeftCircleOutlined } from '@ant-design/icons';

export const StarterBusinessProfile = () => {
    const [currentStage, setCurrentStage] = useState(0);

    const navigate = useNavigate()
    const onChange = (value: number) => {
        console.log('onChange:', value);
        setCurrentStage(value);
    };

    return (
        <>
            <div className={'onboarding'}>
                <div className={'onboardingForm'}>
                    <div onClick={() => navigate('/admin/profile-setup')} style={{display: "flex", alignItems: "center", gap: '5px', fontSize: '20px', color: '#0866FF', cursor: "pointer"}}>
                        <LeftCircleOutlined style={{fontSize: '25px'}} />
                        <span>Back</span>
                    </div>
                    <Steps
                        current={currentStage}
                        onChange={onChange}
                        items={[
                            {
                                title: 'Business Profile',
                            },
                            {
                                title: 'Business Owner Information',
                            }
                        ]}
                    />

                    {
                        currentStage === 0 ? <>
                            <BusinessProfile isFormSubmitted={() => setCurrentStage(currentStage + 1)} />
                        </> : currentStage === 1 ? <>
                            <BusinessOwnerInformation isFormSubmitted={() => navigate('/admin')} />
                        </> : null
                    }
                </div>
            </div>);
        </>
    );
};
