import React, { useState, useEffect, useCallback } from 'react';
import '../../../App.css';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { WhicHome, checkIsHome, checkIsMdaHome } from '../../../core/helpers';
import { home } from '../../../core/assets/icons';
import { voxepay as logo } from '../../../core/assets/imgs';
import { isUserLoggedIn } from '../../routing/PrivateRoutes';

const NavBar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [navText, setNav] = useState('');
  const navigate = useNavigate();
  // const [active, setActive] = useState('Home');
  // const [activeSet, setActiveSet] = useState('');

  const { pathname } = useLocation();

  const isHome = checkIsHome(pathname, window.location.hash);
  const isMdaHome = checkIsMdaHome(pathname, window.location.hash);

  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;

      if (window.pageYOffset > 80) {
        setNav('navbar-fixed');
      } else {
        setNav('');
      }
      setY(window.scrollY);
    },
    [y]
  );

  // const onActiveMenu = (menu: string) => {
  //   setActive(menu);
  //   console.log(active);
  // };

  // useEffect(() => {
  //   onActiveMenu(activeSet)
  // },[activeSet, setActiveSet] )

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  const redirecLogin = () => {
    navigate('/auth/login');
  };
  const backToDashboard = () => {
    navigate('/admin');
  };
  const redirecSignup = () => {
    navigate('/auth/register');
  };

  const redirectHome = () => {
    setSidebar(false);
    navigate('/');
  };
  const redirectMda = () => {
    setSidebar(false);
    navigate('/mda-home');
  };

  const WhereToNavigate = () => {
    if (WhicHome() === 'mdaHome') {
      return '/mda-home';
    } else {
      return '/';
    }
  };

  const sideBarDash = () => {
    setSidebar(false);
    backToDashboard();
  };

  return (
    <div>
      <nav className={`nav ${navText}`} id="nav">
        <div className="nav-center">
          {/* Nav header */}
          <div className="nav-header">
            <img src={logo} className="nav-logo" alt="nav logo" width={140} />
            <button
              className="nav-btn"
              id="nav-btn"
              onClick={() => setSidebar(!sidebar)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>

          <div className="nav-link-container">
            <ul className="nav-links mb-0">
              <li>
                <Link to={'/personal'}>Personal</Link>
              </li>

              <li>
                <HashLink to={'/developer'}>Developers</HashLink>
              </li>

              <li>
                <HashLink to={'/'}>Business</HashLink>
              </li>
            </ul>
          </div>

          <span className="nav-button-container align-items-center">
            <>
              {/* {isUserLoggedIn() ? (
                  <button className="nav-btn-link" onClick={backToDashboard}>
                    Dashboard
                  </button>
                ) : ( */}
              <>
                <button className="nav-btn-link-2" onClick={redirecLogin}>
                  <span>Sign in</span>

                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                    >
                      <path
                        d="M7.81353 12.9375L11.75 9L7.81353 5.0625M1.25 9H11.7472M11.75 0.75H17C17.1989 0.75 17.3897 0.829018 17.5303 0.96967C17.671 1.11032 17.75 1.30109 17.75 1.5V16.5C17.75 16.6989 17.671 16.8897 17.5303 17.0303C17.3897 17.171 17.1989 17.25 17 17.25H11.75"
                        stroke="#0866FF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>

                <button className="nav-btn-link" onClick={redirecSignup}>
                  Get Started
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                    >
                      <path
                        d="M0.75 8H17.25M17.25 8L10.5 1.25M17.25 8L10.5 14.75"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </>
              {/* )} */}
            </>
          </span>
        </div>
      </nav>

      {/* siderbar */}
      <aside
        className={sidebar ? 'sidebar show-sidebar' : 'sidebar'}
        id="sidebar"
      >
        <div>
          <button
            className="close-btn"
            id="close-btn"
            onClick={() => setSidebar(!sidebar)}
          >
            <i className="fas fa-times"></i>
          </button>

          <ul className="sidebar-links">
            <li
              onClick={WhicHome() === 'userHome' ? redirectHome : redirectMda}
            >
              <Link to={'/personal'}>Personal</Link>
            </li>
            <li onClick={() => setSidebar(!sidebar)}>
              <HashLink to={'/developer'}>developers</HashLink>
            </li>

              <li onClick={() => setSidebar(!sidebar)}>
              <HashLink to={'/'}>Business</HashLink>
            </li>
            {/* <li onClick={() => setSidebar(!sidebar)}>
              <Link to={"#"}>Live Chat</Link>
            </li>{" "} */}

              <li onClick={() => setSidebar(!sidebar)}>
                <Link to={'/auth/login'}> Sign in</Link>
            </li>
            
                  <li onClick={() => setSidebar(!sidebar)}>
                <Link to={'/auth/register'}>  Get Started</Link>
              </li>

  
          </ul>
          {/*  social icons*/}
          {/* <ul className="social-icons">
            <a href="#" className="social-icon">
              <i className="fab fa-facebook"></i>
            </a>{" "}
            <a href="#" className="social-icon">
              <i className="fab fa-linkedin"></i>
            </a>{" "}
            <a href="#" className="social-icon">
              <i className="fab fa-youtube"></i>
            </a>{" "}
            <a href="#" className="social-icon">
              <i className="fab fa-instagram"></i>
            </a>
          </ul> */}
        </div>
      </aside>
    </div>
  );
};

export default NavBar;
