import React, {useState} from "react";
import {Button, Checkbox, Form, Input, Select, Steps} from "antd";
import {useNavigate} from "react-router-dom";
import {useStarterBusiness} from "../../auth/hooks/NewSimplyPay/onboarding";

export const UserCreationAccessControl = () => {
    const { data, mutate, isLoading } = useStarterBusiness();
    const [selectedOption, setSelectedOption] = useState("");

    const [currentStage, setCurrentStage] = useState(1);
    const [dropdowns, setDropdowns] = useState({});

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);

        // Show the dropdown corresponding to the selected radio button
        setDropdowns(prevDropdowns => ({
            ...prevDropdowns,
            [value]: true
        }));
    };

    const onFinish = (values: any) => {
        console.log('Success:', values);

        // setFormData(values)
        const payload: any = {
            "registeredName": values.businessName,
            "tradingName": values.tradingName,
            "mdaAdminCode": "RC123459",
            "description": values.businessDescription,
            "contact": {
                "physicalAddress": values.streetAddress,
                "phoneNumber": values.businessPhoneNumber,
                "websiteUrl": values.website,
                "email": values.streetAddress,
                "businessEmail": values.businessEmail,
                "supportEmail": values.supportEmail,
                "disputeEmail": values.disputeEmail,
                "twitterHandle": values.twitter,
                "facebookHandle": values.facebook,
                "instagramHandle": values.instagram,
                "country": values.country,
                "state": values.stateRegion,
                "city": values.city,
                "buildingName": values.buildingName,
                "floor": values.floorUnitNumber
            },
            "bankDetails": [
                {
                    "bankName": values.bankName,
                    "accountNumber": values.accountNumber,
                    "accountName": values.bankName,
                    "dailyLimit": 0.0,
                    "accountType": {
                        "code": "",
                        "description": ""
                    },
                    "orgId": "",
                    "balance": 0.0,
                    "institutionCode": "123456"
                }
            ],
            "registrationType": "PRIVATE_INCORPORATED",
            "organizationType": {
                "code": "STARTER",
                "description": "",
                "orgType": "CORPORATE",
                "address": "",
                "documents": []
            },
            "kycDocuments": {},
            "sector": {
                "code": "RC654323",
                "description": "Affairs"
            },
            "staffSize": "",
            "industryType": "COMMERCE",
            "category": "REAL_ESTATE"
        }

        mutate(payload, {
            onSuccess: (res: any) => {
            },
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <>
            <div className={'onboarding'}>
            <div className={'onboardingForm'}>
                <div className={'onboardingFormRow1'}>
                    User Setup
                </div>

                <div className={'onboardingFormRow2'}>
                    <Form
                        name="basicInformation"
                        initialValues={{remember: true}}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="First name"
                                name="firstName"
                                rules={[{required: true, message: 'First name is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="Last name"
                                name="lastName"
                                rules={[{required: true, message: 'Last name is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="Official phone number"
                                name="officialPhoneNumber"
                                rules={[{required: true, message: 'Official phone number is invalid', pattern: /^\+(?:[0-9] ?|[0-9]\([0-9]+\)[\s-]?){6,14}[0-9]$/}]}
                            >
                                <Input size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="Official email address"
                                name="officialEmailAddress"
                                rules={[{required: true, message: 'Official email is invalid', type:'email'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>


                        <div className={"userRolesRadioButton"}>
                            <label>
                                <input
                                    style={{marginRight: '15px'}}
                                    type="radio"
                                    value="mda-top-official"
                                    checked={selectedOption === "mda-top-official"}
                                    onChange={handleOptionChange}
                                />
                                MDA Top Official
                            </label>
                            <label>
                                <input
                                    style={{marginRight: '15px'}}
                                    type="radio"
                                    value="mda-admin"
                                    checked={selectedOption === "mda-admin"}
                                    onChange={handleOptionChange}
                                />
                                MDA Admin
                            </label>
                            <label>
                                <input
                                    style={{marginRight: '15px'}}
                                    type="radio"
                                    value="mda-collection-officer"
                                    checked={selectedOption === "mda-collection-officer"}
                                    onChange={handleOptionChange}
                                />
                                MDA Collection Officer
                            </label>
                            <label>
                                <input
                                    style={{marginRight: '15px'}}
                                    type="radio"
                                    value="mda-payment-officer"
                                    checked={selectedOption === "mda-payment-officer"}
                                    onChange={handleOptionChange}
                                />
                                MDA Payment Officer
                            </label>
                            {dropdowns["mda-payment-officer"] && (
                                <div className="checkbox-dropdown">
                                    <label>
                                        <input type="checkbox" value="rvp"/>
                                        Retail/Vendor Payment
                                    </label>
                                    <label>
                                        <input type="checkbox" value="billPayment"/>
                                        Bill Payment
                                    </label>
                                    <label>
                                        <input type="checkbox" value="payrollPayment"/>
                                        Payroll Payment
                                    </label>
                                    <label>
                                        <input type="checkbox" value="payrollPayment"/>
                                        Payroll Payment
                                    </label>
                                    <label>
                                        <input type="checkbox" value="taxPayment"/>
                                        Tax Payment
                                    </label>
                                </div>
                            )}
                        </div>



                        <div className={'onboardingNextButton'}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Next
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
            </div>
        </>
    );
};
