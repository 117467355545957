export const CloudSvg = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.62453 13.75C-0.000465572 14.375 0.624534 7.50004 5.62453 8.12504C3.74953 1.25004 14.3745 1.25004 13.7495 6.25004C19.9995 4.37504 19.9995 14.375 14.3745 13.75M6.87453 11.25L9.99953 8.75004M9.99953 8.75004L13.1245 11.25M9.99953 8.75004V18.125"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
