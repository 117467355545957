import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
// import { Button, Form, Input } from "antd";

interface BusinessProfileProps {
  onNext: () => void;
}

const Schema = Yup.object().shape({
  tradingName: Yup.string().required('Trading Name is required'),
  businessDescription: Yup.string().required('Business Description is required'),
  staffSize: Yup.string().required('Staff Size is required'),
  industry: Yup.string().required('Industry is required'),
  category: Yup.string().required('Category is required'),
  businessType: Yup.string().required('Business Type is required'),
 });

const initialValues = {
  tradingName: '',
  businessDescription: '',
  staffSize: '',
  industry: '',
  category: '',
  businessType: '',
};

const BusinessProfile: React.FC<BusinessProfileProps> = ({ onNext }) => {

  // const [isButtonClickable, setIsButtonClickable] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      console.log(values);
    
    },
  });


  return (
    <div className={'businessSetup'}>

      <div className={'businessDetails'}>
      <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Trading name
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Trading name"
                      className={'no-outline text-dark fs-8'}                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.tradingName &&
                        formik.errors.tradingName &&
                        formik.errors.tradingName}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Business description
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder=" Business description"
                      className={'no-outline text-dark fs-8'}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.businessDescription &&
                        formik.errors.businessDescription &&
                        formik.errors.businessDescription}
                    </div>
                  </div>
                </div>
      </div>

      <div className={'businessDetails'}>
                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Staff size
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <select
                      className={`no-outline text-dark fs-8`}
                    >
                      <option value="" disabled selected>- Select staff size -</option>
                      <option value="registeredBusiness">10 - 50</option>
                      <option value="starterBusiness">51 - 100</option>
                    </select>
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.staffSize &&
                        formik.errors.staffSize &&
                        formik.errors.staffSize}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Industry
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <select
                      className={`no-outline text-dark fs-8`}
                    >
                      <option value="" disabled selected>- Select industry -</option>
                      <option value="registeredBusiness">Commerce</option>
                      <option value="starterBusiness">Education</option>
                    </select>
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.industry &&
                        formik.errors.industry &&
                        formik.errors.industry}
                    </div>
                  </div>
                </div>
      </div>

    <div className={'businessDetails'}>
                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Category
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <select
                      className={`no-outline text-dark fs-8`}
                    >
                      <option value="" disabled selected>- Select category-</option>
                      <option value="registeredBusiness">Real Estate</option>
                      <option value="starterBusiness">Real Estate</option>
                    </select>
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.category &&
                        formik.errors.category &&
                        formik.errors.category}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Business type
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <select
                      className={`no-outline text-dark fs-8`}
                    >
                      <option value="" disabled selected>- Select a business type -</option>
                      <option value="registeredBusiness">Private Incorporated Company</option>
                      <option value="starterBusiness">Public Incorporated Company</option>
                    </select>
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.businessType &&
                        formik.errors.businessType &&
                        formik.errors.businessType}
                    </div>
                  </div>
                </div>
      </div>

        <div className={'businessProfileNextButton'}>
          <button 
          onClick={onNext}
          // disabled={!isButtonClickable}
          // style={{
          //   background: isButtonClickable ? '#0866FF' : '#E4E4E8',
          //   color: isButtonClickable ? 'white' : 'black', // You can adjust text color as needed
          // }}
          >
            Next</button>
        </div>
      </div>
  );
};

export { BusinessProfile };
