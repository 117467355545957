import React, { useState } from "react";
import SimpliPayLogo from '../../../../../core/assets/img/SimpliPayLogo.png'
import { useNavigate } from "react-router-dom";
import { Steps } from "antd";
import { BusinessProfile } from "./BusinessProfile";
import { BusinessContacts } from "./BusinessContacts";
import { BusinessOwnerInformation } from "./BusinessOwnerInformation";
import { BusninessBankDetails } from "./BusninessBankDetails";

const { Step } = Steps;

const StarterBusiness: React.FC = () => {

  const navigate = useNavigate();

  const [current, setCurrent] = useState(0);

  const steps = [
    'Business Profile',
    'Business Contacts',
    'Business Owner Information',
    'Business Bank Account Details',
  ];

  return (
    <div className={'onboarding'}>
    <div className={'SimpliPayLogo'}>
      <img src={SimpliPayLogo} alt={'SimpliPayLogo'} width={'140'}/>
    </div>

    <div className={'businessSetupSteps'}>
      <div className={'businessSetupStepsRow1'}>
        <div></div>

          <div>Business Setup</div>
      </div>

      <div className="businessSetupStepsRow2">
        <Steps current={current} size="small" className="stepTitle">
          {steps.map((title, index) => (
            <Step key={title} title={current === index ? title : null} />
          ))}
        </Steps>

          {current === 0 && <BusinessProfile onNext={() => setCurrent(current + 1)} />}
          {current === 1 && <BusinessContacts onNext={() => setCurrent(current + 1)} />}
          {current === 2 && <BusinessOwnerInformation onNext={() => setCurrent(current + 1)} />}
          {current === 3 && <BusninessBankDetails />}

      </div>

  </div>

  </div>
  );
};

export { StarterBusiness };
