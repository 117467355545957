import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {Button, Form, Input, Select} from "antd";
import {useSendBankDetails} from "../../auth/hooks/NewSimplyPay/onboarding";
import {useDoAccountNameVerification, useGetBankInstitutions} from "../../auth/hooks/useUser";


const GovernmentBankAccountDetails: React.FC = () => {
    const {
        data: bankInstitutions,
        mutate: mutateBankInstitutions,
        isLoading: mutateBankInstitutionsLoading,
    } = useGetBankInstitutions();

    const {
        data: bankDetails,
        mutate: mutateBankDetails,
        isLoading: mutateBankDetailsLoading,
    } = useSendBankDetails();


    const {
        data: getAccountNameVerification,
        mutate: mutateAccountNameVerification,
        isLoading: mutateAccountNameVerificationLoading,
    } = useDoAccountNameVerification();

  const navigate = useNavigate();


    useEffect(() => {
        mutateBankInstitutions()
    }, []);

    const onFinish = (values: any) => {
        console.log('Success:', values);

        const orgId = localStorage.getItem('orgId')

        values.bankName = JSON.parse(values.bankName)
        const payload: any = {
            "bankName": values.bankName.institutionName,
            "accountNumber": values.accountNumber,
            "accountName": values.accountName,
            "dailyLimit": Number(values.dailyLimit),
            "accountType": {
                "code": values.bankName.institutionCode,
                "description": ""
            },
            "balance": 0.0,
            "institutionCode": values.bankName.institutionCode
        }

        mutateBankDetails(payload, {
            onSuccess: {
                onSuccess: (res: any) => {
                    navigate('/admin')
                },
            }
        })
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    const handleNameVerification = async () => {
        let formData = form.getFieldsValue()
        formData = JSON.parse(formData.bankName)
        console.log(formData)
        await mutateAccountNameVerification({
            accountNumber: formData.accountNumber,
            destinationInstitutionCode: formData.institutionCode
        });
    };


    const [form] = Form.useForm();

    return (
        <div className={'onboarding'}>
        <div className={'onboardingForm'}>
            <div className={'onboardingFormRow'}>
                <div onClick={() => navigate('/admin/profile-setup/government-business')}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.9016 15.7123C11.1945 16.0052 11.6694 16.0052 11.9623 15.7123C12.2552 15.4194 12.2552 14.9445 11.9623 14.6516L10.9016 15.7123ZM8.25 12L7.71967 11.4697C7.42678 11.7626 7.42678 12.2374 7.71967 12.5303L8.25 12ZM11.9623 9.34836C12.2552 9.05547 12.2552 8.58059 11.9623 8.2877C11.6694 7.99481 11.1945 7.99481 10.9016 8.2877L11.9623 9.34836ZM15.75 12.75C16.1642 12.75 16.5 12.4142 16.5 12C16.5 11.5858 16.1642 11.25 15.75 11.25V12.75ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75ZM11.9623 14.6516L8.78033 11.4697L7.71967 12.5303L10.9016 15.7123L11.9623 14.6516ZM8.78033 12.5303L11.9623 9.34836L10.9016 8.2877L7.71967 11.4697L8.78033 12.5303ZM8.25 12.75H15.75V11.25H8.25V12.75Z" fill="#2A7CB4"/>
                    </svg>
                </div>

                <div>Bank Account Details</div>
            </div>

            <div className={'onboardingFormRow2'}>
                <div style={{fontWeight: 500, fontSize: '16px', marginBlock: '10px'}}>Bank Account Details</div>

                <div className={'businessSetup'}>
        <Form
            form={form}
            name="basicInformation"
            initialValues={{remember: true}}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div className={'profileInformation'}>
                Account Information
            </div>

            <div className={ 'businessDetails'}>
                <Form.Item
                    label="Bank name"
                    name="bankName"
                    rules={[{ required: true, message: "Bank name is required" }]}
                >
                    <Select
                        showSearch
                        placeholder="Select bank"
                        size="large"
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {bankInstitutions &&
                            bankInstitutions.map((institution) => (
                                <Select.Option key={institution.id} value={JSON.stringify(institution)}>
                                    {institution.institutionName}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Account Number"
                    name="accountNumber"
                    rules={[
                        {required: true, message: 'Account Number is required'},
                        { pattern: /^\d{10}$/, message: 'Account number must be a 10-digit number' },
                    ]}
                >
                    <Input onBlur={() => handleNameVerification()} size="large" minLength={10} maxLength={10}/>
                </Form.Item>
            </div>

            <div className={ 'businessDetails'}>

                <Form.Item
                    label="Account Name"
                    name="accountName"
                    rules={[
                        {required: true, message: 'Account Number is required'},
                    ]}
                >
                    <Input size="large"/>
                </Form.Item>

                <Form.Item
                    label="Daily Limit"
                    name="dailyLimit"
                    rules={[
                        {required: true, message: 'Daily limit is required'},
                        { pattern: /^\d+$/, message: 'Daily limit must be a  number' },
                    ]}
                >
                    <Input size="large"/>
                </Form.Item>
            </div>

            <div className={'proofOfAddress'}>
                <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.38 7.38H8V11.76H8.62M8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5ZM8 5.01C8.25 5.01 8.45 4.81 8.45 4.56C8.45 4.31 8.25 4.11 8 4.11C7.75 4.11 7.55 4.31 7.55 4.56C7.55 4.81 7.75 5.01 8 5.01Z" stroke="#DC2626" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>

                <div>
                    Name on account must be the same as business owner’s name.
                </div>
            </div>

            <div className={'businessRegNextButton'}>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </div>
        </Form>

  </div>
            </div>
        </div>
        </div>
  );
};

export { GovernmentBankAccountDetails };
