import {FlapperSpinner} from "react-spinners-kit";
import {Layout} from "../../layout";
import {useMdaActivate, useMdaDetails} from "../../../auth/hooks/useUser";
import {DatePicker, Select, Table} from "antd";
import dayjs from "dayjs";
import React from "react";
import {Download} from "../../../../componets/download";
import {filter} from "../../../../../core/assets/icons";


const dateFormat = "YYYY/MM/DD";
const OagfPaymentApproved = () => {

    const {
        isLoading,
    } = useMdaDetails();

    const {
        data: activateData,
        mutate: activateMutate,
        isLoading: activateLoading,
    } = useMdaActivate();


    const dataSource: any[] = [];
    const dataSourceForExcel: any[] = [
        [

        ],
    ];


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "30",
        },
        {
            title: "Entity Name",
            dataIndex: "mdaName",
            key: "mdaName",
            width: "30",
        },
        {
            title: "Payment Type",
            dataIndex: "paymentType",
            key: "paymentType",
            width: "180",
        },
        {
            title: "Payment Option",
            dataIndex: "paymentOption",
            key: "paymentOption",
            width: "180",
        },
        {
            title: "VoxePay Payment Reference ",
            dataIndex: "gpr",
            key: "gpr",
            width: "60",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            width: "80",
        },
        {
            title: "Initiation Date & Time Stamp",
            dataIndex: "initiationDateTime",
            key: "initiationDateTime",
            width: "80",
        },
        {
            title: "Initiator",
            dataIndex: "initiator",
            key: "initiator",
            width: "80",
        },
        {
            title: "Reviewer",
            dataIndex: "reviewer",
            key: "reviewer",
            width: "80",
        },
        {
            title: "Approver",
            dataIndex: "approver",
            key: "approver",
            width: "80",
        },
    ];

    for (let i = 1; i <= 100; i++) {
        const row = {
            id: i,
            mdaName: `Ministry of Transportation`,
            paymentType: `Pension`,
            paymentOption: `Bulk`,
            gpr: `0000-0000-0000-0002`,
            amount: `₦1,000,000`,
            initiationDateTime: `23 Mar 2023 8:00AM`,
            initiator: `Ogundipe Damilola`,
            reviewer: `Obed Favour`,
            approver: `Joke Silver`,
        };
        dataSource.push(row);
    }


    return (
        <>
            {isLoading || activateLoading ? (
                <div className="loading-container">
                    <FlapperSpinner/> {/* Replace with your loading indicator */}
                </div>
            ) : (
                <div className={"outstandingPayment"}>
                    <div className={"outstandingPaymentRow1"}>
                        <div className={"outstandingPaymentBreadcrumb"}>
                            <div>Analytics</div>

                            <svg width="10" height="13" viewBox="0 0 10 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                    fill="#6366F1"/>
                            </svg>

                            <div>Payment View</div>

                            <svg width="10" height="13" viewBox="0 0 10 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                    fill="#6366F1"/>
                            </svg>

                            <div>Payment Approved</div>
                        </div>

                        <div>
                            <span className="data-range-text-overview">Date Range:</span>
                            <span className="data-from-text-overview">From</span>
                            <DatePicker
                                defaultValue={dayjs("2015/01/01", dateFormat)}
                                format={dateFormat}
                                className="datepicker"
                            />
                            <span className="data-from-text-overview">To</span>
                            <DatePicker
                                defaultValue={dayjs("2015/01/01", dateFormat)}
                                format={dateFormat}
                                className="datepicker"
                            />{" "}
                        </div>
                    </div>

                    <div className={"outstandingPaymentRow2"}>
                        <div>PAYMENT APPROVED</div>

                        <div className="d-flex justify-content-end  mb-9 me-4">
                            <Download
                                dataSourceForExcel={dataSourceForExcel}
                                dataSource={dataSource}
                                excelFileTitle={"Collection Inflow for MDA"}
                                pdfFileTitle={"Collection Inflow for MDA"}
                                excelPageTitle={"Collection Inflow for MDA"}
                                grandTotalFor={{
                                    transactionAmount: "Transaction Amount",
                                    processingFee: "Processing Fee",
                                    vatProcessingFee: "VAT Processing Fee",
                                    totalProcessingFee: "Total Processing Fee",
                                    netAmount: "Net Amount",
                                    cumulativeNetAmount: "Cumulative Net Amount",
                                }}
                            />
                        </div>

                        <div>
                            <div>TOTAL:</div>
                            <div>N6,000,000,000</div>
                        </div>

                        <div>
                            <Select
                                dropdownMatchSelectWidth={false}
                                defaultValue="Payment Type"
                                style={{ width: 184 }}
                                options={[
                                    { value: "all", label: "All" },
                                    { value: "rvp", label: "Retail/Vendor Payment" },
                                    { value: "billPayment", label: "Bill Payment" },
                                    { value: "salaryPayment", label: "Salary Payment" },
                                    { value: "pensionPayment", label: "Pension Payment" },
                                    { value: "taxPayment", label: "Tax Payment" },
                                ]}
                            />

                            <Select
                                dropdownMatchSelectWidth={false}
                                defaultValue="Payment Option"
                                style={{ width: 184 }}
                                options={[
                                    { value: "all", label: "All" },
                                    { value: "single", label: "Single" },
                                    { value: "bulk", label: "Bulk" },
                                ]}
                            />

                            <div className={"filterButton"}>
                                <button>
                                    <img src={filter} alt="" />
                                    <span>Apply Filter</span>
                                </button>
                            </div>

                            <div>
                                <button>Reset All</button>
                            </div>
                        </div>

                        <Table
                            dataSource={dataSource}
                            bordered
                            columns={columns}
                            scroll={{ x: "1900px" }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default OagfPaymentApproved;
