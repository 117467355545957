import React, { useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { useOrganizationKYC } from "../../auth/hooks/NewSimplyPay/onboarding";

interface BusinessOwnerInformationProps {
  isFormSubmitted: () => void;
}
export const BusinessOwnerInformation: React.FC<any> = ({
  isFormSubmitted,
}) => {
  const { data, mutate, isLoading } = useOrganizationKYC();
  const [currentStage, setCurrentStage] = useState(0);

  const onFinish = (values: any) => {
    console.log("Success:", values);

    const orgId = localStorage.getItem("orgId");

    const payload: any = {
      serialVersionUID: 0,
      id: "",
      code: "",
      description: "",
      fileName: "",
      organizationId: orgId,
      businessRepresentatives: [
        {
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: values.dateOfBirth,
          country: values.nationality,
          businessRole: "OWNER",
          percentageShare: 0,
          idDocument: values.identificationDocument,
          idDocumentNumber: values.documentNumber,
          idDocumentPath: "",
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values.city,
          state: values.stateRegion,
          documentType: values.documentType,
          documentPath: "",
          documents: [""],
        },
      ],
      documents: [""],
    };

    mutate(payload, {
      onSuccess: (res: any) => {
        isFormSubmitted();
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className={"onboardingFormRow2"}>
        <Form
          name="organizationInformation"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div
            style={{ fontWeight: 500, fontSize: "16px", marginBlock: "10px" }}
          >
            Organization Registration Documents
            <p className="fs-8 text-gray-500 my-2">
              <span className="me-2">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.38 9.38H10V13.76H10.62M10 17.5C14.14 17.5 17.5 14.14 17.5 10C17.5 5.86 14.14 2.5 10 2.5C5.86 2.5 2.5 5.86 2.5 10C2.5 14.14 5.86 17.5 10 17.5ZM10 7.01C10.25 7.01 10.45 6.81 10.45 6.56C10.45 6.31 10.25 6.11 10 6.11C9.75 6.11 9.55 6.31 9.55 6.56C9.55 6.81 9.75 7.01 10 7.01Z"
                    stroke="#3DADFE"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              Accepted document formats are jpg, png, or pdf
            </p>
          </div>

          <div className={"onboardingDetails"}>
            <div>
              <label className="required fw-bold fs-9 required my-1">Certificate of Registration/Incorporation</label>
              <div className={`form-control fileUpload`}>
                <label>
                  <div>Click here to upload document</div>
                  <input
                    hidden
                    id="input"
                    type="file"
                    // accept="text/csv"
                    // onChange={extractData}
                  />
                </label>
              </div>
            </div>

            <div>
              <label className="required fw-bold fs-9 required my-1" >Form CAC/IT 1 *</label>
              <div className={`form-control fileUpload`}>
                <label>
                  <div>Click here to upload document</div>
                  <input
                    hidden
                    id="input"
                    type="file"
                    // accept="text/csv"
                    // onChange={extractData}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className={"onboardingDetails"}>
            <div>
              <label className="required fw-bold fs-9 required my-1">Certificate of Registration/Incorporation</label>
              <div className={`form-control fileUpload`}>
                <label>
                  <div>Click here to upload document</div>
                  <input
                    hidden
                    id="input"
                    type="file"
                    // accept="text/csv"
                    // onChange={extractData}
                  />
                </label>
              </div>
            </div>

            <div>
              <label className="required fw-bold fs-9 required my-1" >Form CAC/IT 1 *</label>
              <div className={`form-control fileUpload`}>
                <label>
                  <div>Click here to upload document</div>
                  <input
                    hidden
                    id="input"
                    type="file"
                    // accept="text/csv"
                    // onChange={extractData}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className={"onboardingDetails"}>
            <Form.Item
              label="First name"
              name="firstName"
              rules={[{ required: true, message: "First name is required" }]}
            >
              <Input size="large" />
            </Form.Item>
            

            <Form.Item
              label="Last name"
              name="lastName"
              rules={[{ required: true, message: "Last name is required" }]}
            >
              <Input size="large" />
            </Form.Item>
          </div>

          <div className={"onboardingDetails"}>
            <Form.Item
              label="Date of birth"
              name="dateOfBirth"
              rules={[{ required: true, message: "Date of birth is required" }]}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              label="Nationality"
              name="nationality"
              rules={[{ required: true, message: "Nationality is required" }]}
            >
              <Input size="large" />
            </Form.Item>
          </div>

          <div
            style={{ fontWeight: 500, fontSize: "16px", marginBlock: "10px" }}
          >
            Identity Verification
          </div>

          <div className={"onboardingDetails"}>
            <Form.Item
              label="Identification document"
              name="identificationDocument"
              rules={[
                {
                  required: true,
                  message: "Identification document is required",
                },
              ]}
            >
              <Select
                placeholder="Select a Industry"
                size="large"
                options={[
                  { label: "NIN", value: "NIN" },
                  { label: "Drivers Licence", value: "Drivers Licence" },
                  { label: "Voters card", value: "Voters Card" },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="Document number"
              name="documentNumber"
              rules={[
                { required: true, message: "Document number is required" },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </div>

          <div className={"onboardingDetails"}>
            <div>
              <label className="required fw-bold fs-9">Upload document</label>
              <div className={`form-control form-control-solid fileUpload`}>
                <label>
                  <div>Click here to upload document</div>
                  <input
                    hidden
                    id="input"
                    type="file"
                    // accept="text/csv"
                    // onChange={extractData}
                  />
                </label>
              </div>
            </div>
          </div>

          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.38 9.38H10V13.76H10.62M10 17.5C14.14 17.5 17.5 14.14 17.5 10C17.5 5.86 14.14 2.5 10 2.5C5.86 2.5 2.5 5.86 2.5 10C2.5 14.14 5.86 17.5 10 17.5ZM10 7.01C10.25 7.01 10.45 6.81 10.45 6.56C10.45 6.31 10.25 6.11 10 6.11C9.75 6.11 9.55 6.31 9.55 6.56C9.55 6.81 9.75 7.01 10 7.01Z"
                stroke="#3DADFE"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>Accepted document formats are .jpg, .png or .pdf.</span>
          </div>

          <div
            style={{ fontWeight: 500, fontSize: "16px", marginBlock: "10px" }}
          >
            Proof of address
          </div>

          <Form.Item
            label="Address line 1"
            name="addressLine1"
            rules={[{ required: true, message: "Address line 1 is required" }]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Address line 2"
            name="addressLine2"
            rules={[{ required: true, message: "Address line 2 is required" }]}
          >
            <Input size="large" />
          </Form.Item>

          <div className={"onboardingDetails"}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "City is required" }]}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              label="State/Region"
              name="stateRegion"
              rules={[{ required: true, message: "State/Region is required" }]}
            >
              <Input size="large" />
            </Form.Item>
          </div>

          <div className={"onboardingDetails"}>
            <Form.Item
              label="Document type"
              name="documentType"
              rules={[{ required: true, message: "Document type is required" }]}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              label="Upload document"
              name="uploadDocument"
              rules={[
                { required: true, message: "Upload document is required" },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </div>

          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.38 9.38H10V13.76H10.62M10 17.5C14.14 17.5 17.5 14.14 17.5 10C17.5 5.86 14.14 2.5 10 2.5C5.86 2.5 2.5 5.86 2.5 10C2.5 14.14 5.86 17.5 10 17.5ZM10 7.01C10.25 7.01 10.45 6.81 10.45 6.56C10.45 6.31 10.25 6.11 10 6.11C9.75 6.11 9.55 6.31 9.55 6.56C9.55 6.81 9.75 7.01 10 7.01Z"
                stroke="#3DADFE"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>
              Proof of address document can be bank statements, utility bills
              (electricity, water, DSTV, etc), tax assessment, or a letter from
              a government authority. Document provided should not be older than
              3 months.
            </span>
          </div>

          <div className={"onboardingNextButton"}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setCurrentStage(currentStage + 1)}
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};
