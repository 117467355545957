import React, {useEffect, useState} from "react";
import {BusinessNameRegistration} from "./DocumentUpload/BusinessNameRegistration";
import {PublicPrivateIncorporated} from "./DocumentUpload/PublicPrivateIncorporated";
import {FreeZoneEntity} from "./DocumentUpload/FreeZoneEntity";
import {IncorporatedTrustees} from "./DocumentUpload/IncorporatedTrustees";
import {GovernmentEntity} from "./DocumentUpload/GovernmentEntity";
import {PrivateEntity} from "./DocumentUpload/PrivateEntity";
import {CooperativeSociety} from "./DocumentUpload/CooperativeSociety";

interface BusinessRegistrationInformationProps {
  onNext: () => void;
}

const BusinessRegistrationInformation: React.FC<BusinessRegistrationInformationProps> = ({ onNext }) => {
    const [requiredDocument, setRequiredDocument] = useState<string>("");

    useEffect(() => {
        setRequiredDocument(sessionStorage.getItem('requiredDocuments') || '')
    }, []);

    return (
    <>
        {
            requiredDocument === 'privateIncorporatedCompany' ?
                <PublicPrivateIncorporated onNext={onNext} /> :
                requiredDocument === 'publicIncorporatedCompany' ?
                    <PublicPrivateIncorporated onNext={onNext} /> :
                    requiredDocument === 'businessNameRegistration' ?
                        <BusinessNameRegistration onNext={onNext} /> :
                        requiredDocument === 'freeZoneEntity' ?
                            <FreeZoneEntity onNext={onNext} />  :
                            requiredDocument === 'incorporatedTrustees' ?
                                <IncorporatedTrustees onNext={onNext} /> :
                                requiredDocument === 'governmentEntity' ?
                                    <GovernmentEntity onNext={onNext} />  :
                                    requiredDocument === 'privateEntity' ?
                                        <PrivateEntity onNext={onNext} />  :
                                        requiredDocument === 'cooperativeSociety' ?
                                            <CooperativeSociety onNext={onNext} />  :
                                            null
        }
    </>
  );
};

export { BusinessRegistrationInformation };
