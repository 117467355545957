import { Button, Table } from "antd";
//import { useOrganizationKYC } from "app/pages/auth/hooks/NewSimplyPay/onboarding";
import React from "react";
import { BackSvg } from "../component/BackSvg";
import { ForwardSvg } from "../component/ForwardSvg";
import { PlusSvg } from "../component/PlusSvg";

interface LLCKycStepTwoProps {
  isFormSubmitted: () => void;
  setCurrentStage: (e: number) => void;
  currentStage: number;
  setSelectedRecord: (e: any) => void;
  setShowDirectorModal: (e: boolean) => void;
  setShowDirectorDeleteModal: (e: boolean) => void;
  directorTable: any[];
}
export const LLCKycStepTwo: React.FC<LLCKycStepTwoProps> = ({
  isFormSubmitted,
  setCurrentStage,
  currentStage,
  setSelectedRecord,
  setShowDirectorModal,
  setShowDirectorDeleteModal,
  directorTable,
}) => {
  //const { mutate } = useOrganizationKYC();

  const editRecord = (e: any) => {
    setSelectedRecord(e);
    setShowDirectorModal(true);
  };

  const removeRecord = (e: any) => {
    setSelectedRecord(e);
    setShowDirectorDeleteModal(true);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Official Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Bvn",
      dataIndex: "bvn",
      key: "bvn",
    },

    {
      title: "Actions ",
      dataIndex: "actions",

      render: (_: any, record: any) => (
        <div className={`d-flex gap-2`}>
          <div
            className="text-primary fs-8 d-flex align-items-center gap-1 cursor-pointer"
            onClick={() => editRecord(record)}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.125 4.875L15.625 7.375M16.875 9.875V16.75C16.875 16.9158 16.8092 17.0747 16.6919 17.1919C16.5747 17.3092 16.4158 17.375 16.25 17.375H3.75C3.58424 17.375 3.42527 17.3092 3.30806 17.1919C3.19085 17.0747 3.125 16.9158 3.125 16.75V4.25C3.125 4.08424 3.19085 3.92527 3.30806 3.80806C3.42527 3.69085 3.58424 3.625 3.75 3.625H10.625M10 13H7.5V10.5L15 3L17.5 5.5L10 13Z"
                stroke="#0866FF"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Edit</span>
          </div>

          <div
            className="text-danger fs-8 d-flex align-items-center gap-1 cursor-pointer"
            onClick={() => removeRecord(record)}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8753 8.625V13.625M8.12531 8.625V13.625M16.875 4.875L3.125 4.87501M15.6253 4.875V16.75C15.6253 16.9158 15.5595 17.0747 15.4422 17.1919C15.325 17.3092 15.1661 17.375 15.0003 17.375H5.00031C4.83455 17.375 4.67557 17.3092 4.55836 17.1919C4.44115 17.0747 4.37531 16.9158 4.37531 16.75V4.875M13.1253 4.875V3.625C13.1253 3.29348 12.9936 2.97554 12.7592 2.74112C12.5248 2.5067 12.2068 2.375 11.8753 2.375H8.12531C7.79378 2.375 7.47584 2.5067 7.24142 2.74112C7.007 2.97554 6.87531 3.29348 6.87531 3.625V4.875"
                stroke="#EC2106"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Delete</span>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={"kycFormRow2"}>
        <hr />

        <div className="my-10" style={{ overflowX: "auto" }}>
          <Table
            dataSource={directorTable}
            bordered
            columns={columns}
            scroll={{ x: "1000px" }}
          />
        </div>

        <div className="mx-15">
          <div className="d-flex justify-content-center my-5">
            <Button
              type="link"
              htmlType="submit"
              className="d-flex flex-column justify-content-center align-items-center"
              onClick={() => setShowDirectorModal(true)}
            >
              <div className="d-flex text-primary fw-bold fs-5">
                <PlusSvg /> Add More Director
              </div>

              <hr className="text-primary w-100 m-1 opacity-100" />
            </Button>
          </div>

          <div className="step-btn-group">
            <Button
              type="default"
              htmlType="button"
              onClick={() => setCurrentStage(currentStage - 1)}
              className="border-primary"
            >
              <div className="d-flex gap-2 justify-content-center align-items-center">
                <BackSvg />
                <span className="fs-8 text-primary">Previous</span>
              </div>
            </Button>

            <Button
              type="primary"
              htmlType="button"
              onClick={() => setCurrentStage(currentStage + 1)}
              disabled={directorTable.length === 0}
            >
              <div className="d-flex gap-2 justify-content-center align-items-center fs-8">
                Next
                <ForwardSvg />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
