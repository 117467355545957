import React from "react";


const TestD = () => {

  const jsonData = [
 
 
    {
        "c2": "AWKA SOUTH",
        "data": [
            {
                "c1": "Purchase Of Motor Vehicles",
                "totalAmount": 893000000
            },
            {
                "c1": "Purchase Of Office Furniture And Fittings",
                "totalAmount": 10000000
            },
            {
                "c1": "Purchase Of Computers",
                "totalAmount": 9700000
            },
            {
                "c1": "Purchase Of Power Generating Set",
                "totalAmount": 4000000
            },
            {
                "c1": "Purchase Of Library Books & Equipment",
                "totalAmount": 0
            },
            {
                "c1": "Purchase Of Security Equipment",
                "totalAmount": 0
            },
            {
                "c1": "Purchase Of Industrial Equipment",
                "totalAmount": 100000000
            },
            {
                "c1": "Purch. & Installation Of Secuirty Equp.(CCTV And Intercom)",
                "totalAmount": 89205500
            },
            {
                "c1": "Construction / Provision Of Office Buildings",
                "totalAmount": 0
            },
            {
                "c1": "Construction / Provision Of Libraries",
                "totalAmount": 0
            },
            {
                "c1": "Construction/Provision of Landscapping",
                "totalAmount": 5000000
            },
            {
                "c1": "Construction/Provisions of Media Equipments",
                "totalAmount": 82000000
            },
            {
                "c1": "Construction/Provisions of Media Equipments",
                "totalAmount": 70000000
            },
            {
                "c1": "Rehabilitation / Repairs Of Residential Building",
                "totalAmount": 20000000
            },
            {
                "c1": "Rehabilitation / Repairs - Housing",
                "totalAmount": 10000000
            },
            {
                "c1": "Rehabilitation / Repairs - Recreational Facilities",
                "totalAmount": 0
            },
            {
                "c1": "Rehabilitation / Repairs Of Office Buildings",
                "totalAmount": 181000000
            },
            {
                "c1": "Research And Development",
                "totalAmount": 283000000
            },
            {
                "c1": "Monitoring And Evaluation",
                "totalAmount": 0
            },
            {
                "c1": "Anniversaries/Celebrations",
                "totalAmount": 100000000
            },
            {
                "c1": "Margin For Increases In Costs",
                "totalAmount": 100000000
            },
            {
                "c1": "Margin For Increases In Costs",
                "totalAmount": 80000000
            },
            {
                "c1": "Capacity Building",
                "totalAmount": 23000000
            },
            {
                "c1": "E-Learning",
                "totalAmount": 5000000
            },
            {
                "c1": "Value Orientation/Sensistization",
                "totalAmount": 200000000
            },
            {
                "c1": "Publications/Publicity",
                "totalAmount": 75000000
            }
        ]
    },
    {
        "c2": "State Wide",
        "data": [
            {
                "c1": "Rehabilitation / Repairs Of Residential Building",
                "totalAmount": 0
            }
        ]
    }




  ];
  
  const allC1Values: string[] = [];

  jsonData.forEach((category) => {
    category.data.forEach((dataItem) => {
      if (!allC1Values.includes(dataItem.c1)) {
        allC1Values.push(dataItem.c1);
      }
    });
  });

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Category</th>
            {allC1Values.map((c1, index) => (
              <th key={index}>{c1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {jsonData.map((category, categoryIndex) => (
            <tr key={categoryIndex}>
              <th>{category.c2}</th>
              {allC1Values.map((c1, c1Index) => {
                const dataItem = category.data.find((item) => item.c1 === c1);
                return (
                  <td key={c1Index}>{dataItem ? dataItem.totalAmount : '-'}</td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}


export default TestD;
