import React from "react";
import SimpliPayLogo from '../../../../../core/assets/img/SimpliPayLogo.png'
import { useNavigate } from 'react-router-dom';




const BusinessNameRegistration: React.FC = () => {


  const navigate = useNavigate();

  return (
    // <div className={'onboarding'}>
        <div className={''}>
          <div className={'documentTitle'}>Business Name Registration</div>

          <div>
            <ul className="bullet-list">
              <li className={'listStyle'}>Certificate of business name</li>
              <li className={'listStyle'}>Business Number (BN)</li>
              <li className={'listStyle'}>Form CAC BN 1</li>
            </ul>

            <div className={'cac'}>
            (The application form for registration of a business name. If business name was registered pre-December 2020,
            provide a form CAC BN 1 but if registered post-December 2020, provide a status report from the CAC instead)
            </div>

            
            <ul className="bullet-list">
              <li className={'listStyle'}>Proof of business address</li>
              <li className={'listStyle'}>Proof of identity and address for directors of business</li>
              <li className={'listStyle'}>Proof of identity and address for shareholders that own up to 51% of business</li>
            </ul>
          </div>

          <div className={'requiredDocumentButton'}>
            <button
              onClick={() => navigate('/admin/profile-setup/registered-business/business-setup')}
            >
              Proceed
            </button>
          </div>
        </div>
          
    // </div>
  );
};

export { BusinessNameRegistration };
