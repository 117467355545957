import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
// import { Button, Form, Input } from "antd";



const Schema = Yup.object().shape({
  bankName: Yup.string().required('Bank Name is required'),
  accountNumber: Yup.string().required('Account Number is required'),
});

const initialValues = {
  bankName: '',
  accountNumber: '',
};

const Summary: React.FC = () => {

  // const [isButtonClickable, setIsButtonClickable] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      console.log(values);
    
    },
  });

  // const onFinish = (values) => {
  //   console.log('Submitted values:', values);
  // };

  return (
    <div className={'businessSetup'}>
     <div>Summary</div>


      <div className={'businessProfileNextButton'}>
          <button 
          // onClick={}
          // disabled={!isButtonClickable}
          // style={{
          //   background: isButtonClickable ? '#0866FF' : '#E4E4E8',
          //   color: isButtonClickable ? 'white' : 'black', // You can adjust text color as needed
          // }}
          >
            Next</button>
        </div>
      </div>
  );
};

export { Summary };
