import React, {useState} from 'react';
import '../../../../../App.css';
import {useNavigate} from "react-router-dom";




const PensionPayments = () => {
    const [selectedOption, setSelectedOption] = useState("pp-single-key-in");

    const navigate = useNavigate();

    const handleSubmit = () => {
        if (selectedOption === "pp-single-key-in") {
            navigate("/admin/payment/pension-payments/single");
        } else if (selectedOption === "pp-bulk-upload") {
            navigate("/admin/payment/pension-payments/bulk");
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (
        <>
            <div className={'pensionPayments'}>
                <div className={'pensionPaymentsBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#6366F1"
                        />
                    </svg>
                    <div>Pension Payments</div>
                </div>

                <div className={'pensionPaymentsRow1'}>
                    <div>Pension Payments</div>

                    <div>Select method of upload</div>

                    <div>
                        <div>
                            <label
                                style={{
                                    backgroundColor: selectedOption === "pp-single-key-in" ? '#EDF9F1' : '#FFFFFF',
                                    border: selectedOption === "pp-single-key-in" ? '1px solid #6366F1' : '1px solid #CCCCCC',
                                }}
                                onClick={() => {
                                    if (selectedOption !== "pp-single-key-in") {
                                        setSelectedOption("pp-single-key-in");
                                    }
                                }}
                            >
                                <div className={'inputDiv'}>
                                    <input
                                        style={{
                                            marginRight: '15px',
                                        }}
                                        type="radio"
                                        value="pp-single-key-in"
                                        checked={selectedOption === "pp-single-key-in"}
                                        onChange={handleOptionChange}
                                    />
                                </div>

                                <div>
                                    <div>
                                        {selectedOption === "pp-single-key-in" ? (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.5 22.5L20 32.5L37.5 22.5M2.5 16.25L20 26.25L37.5 16.25L20 6.25L2.5 16.25Z" stroke="#6366F1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        ) : (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.5 22.5L20 32.5L37.5 22.5M2.5 16.25L20 26.25L37.5 16.25L20 6.25L2.5 16.25Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>

                                    <div className={'pensionType'}
                                         style={{
                                             fontWeight: selectedOption === "pp-single-key-in" ? 500 : 400,
                                             color: selectedOption === "pp-single-key-in" ? '#6366F1' : '#666666',
                                         }}
                                    >
                                        Single key-in
                                    </div>
                                </div>
                            </label>
                        </div>

                        <div>
                            <label
                                style={{
                                    backgroundColor: selectedOption === "pp-bulk-upload" ? '#EDF9F1' : '#FFFFFF',
                                    border: selectedOption === "pp-bulk-upload" ? '1px solid #6366F1' : '1px solid #CCCCCC',
                                }}
                                onClick={() => {
                                    if (selectedOption !== "pp-bulk-upload") {
                                        setSelectedOption("pp-bulk-upload");
                                    }
                                }}
                            >
                                <div className={'inputDiv'}>
                                    <input
                                        style={{
                                            marginRight: '15px',
                                        }}
                                        type="radio"
                                        value="pp-bulk-upload"
                                        checked={selectedOption === "pp-bulk-upload"}
                                        onChange={handleOptionChange}
                                    />
                                </div>

                                <div>
                                    <div>
                                        {selectedOption === "pp-bulk-upload" ? (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 27.5L20 36.25L35 27.5M5 20L20 28.75L35 20M5 12.5L20 21.25L35 12.5L20 3.75L5 12.5Z" stroke="#6366F1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        ) : (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 27.5L20 36.25L35 27.5M5 20L20 28.75L35 20M5 12.5L20 21.25L35 12.5L20 3.75L5 12.5Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>

                                    <div className={'pensionType'}
                                         style={{
                                             fontWeight: selectedOption === "pp-bulk-upload" ? 500 : 400,
                                             color: selectedOption === "pp-bulk-upload" ? '#6366F1' : '#666666',
                                         }}
                                    >
                                        Bulk upload
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className={'ppProceedButton'}>
                        <button onClick={handleSubmit}>
                            Proceed
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PensionPayments;
