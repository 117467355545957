import type { FormInstance } from "antd";
import { Button, Form } from "antd";
import { ButtonType } from "antd/es/button";
import { isEmpty } from "core/helpers";
import React from "react";

const KycSubmitButton = ({
  form,
  submitProp,
  className,
  otherDisable,
  onClick,
  type,
}: {
  form: FormInstance;
  submitProp?: any;
  className?: any;
  otherDisable?: boolean;
  onClick?: () => void;
  type?: ButtonType;
}) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  // const [windowSize, setWindowSize] = useState<{
  //   width: number;
  //   height: number;
  // }>({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });

  // const handleResize = () => {
  //   setWindowSize({
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   });
  // };

  // useEffect(() => {
  //   setWindowSize({
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   });
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <Button
      htmlType="submit"
      type={type || "primary"}
      disabled={!submittable || (!isEmpty(otherDisable) && !otherDisable)}
      onClick={() => onClick?.()}
      className={`${className}`}
    >
      {submitProp || <span>Proceed</span>}
    </Button>
  );
};
export { KycSubmitButton };
