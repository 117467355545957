import React, { useEffect, useState } from "react";
import { Select } from "antd";
import SimpliPayLogo from '../../../../core/assets/img/SimpliPayLogo.png'
import { useNavigate } from "react-router-dom";


const { Option } = Select;

const RegisteredBusiness: React.FC = () => {
  
  // Define industry-specific categories
  const industryCategories: { [key: string]: string[] } = {
      Agriculture: ["Crop Farming", "Livestock Farming", "Fishing", "Horticulture"],
      Commerce: ["Retail", "Wholesale", "E-commerce", "Marketplace"],
      Education: ["Schools", "Colleges", "Training Centers", "Online Learning"],
      'Financial services': ["Banking", "Insurance", "Investment", "Financial Planning"],
      Gaming: ["Video Games", "Board Games", "Online Gaming", "Mobile Gaming"],
      Health: ["Hospitals", "Clinics", "Pharmaceuticals", "Telehealth"],
      Hospitality: ["Hotels", "Restaurants", "Event Venues", "Catering"],
      'Leisure & Entertainment': ["Amusement Parks", "Concerts", "Movies", "Theme Parks"],
      Logistics: ["Shipping", "Transportation", "Warehousing", "Supply Chain"],
      'Non-Profits': ["Charities", "Foundations", "NGOs", "Community Organizations"],
      Travel: ["Air Travel", "Hotel Accommodation", "Tourism", "Cruises"],
      Utilities: ["Water Supply", "Energy", "Telecommunications", "Waste Management"],
  };

  const [selectedIndustry, setSelectedIndustry] = useState<string>("");
  const [isButtonClickable, setIsButtonClickable] = useState(false);
  const [selectedRegistrationType, setSelectedRegistrationType] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState('Agriculture');
  const categoryOptions = Object.keys(industryCategories);


  // Handle the industry selection change
  const handleIndustryChange = (value: string) => {
    setSelectedIndustry(value);
  };


  // Handle the registration type selection change
  const handleRegistrationTypeChange = (value: string) => {
    setSelectedRegistrationType(value);
  };


  // Update button clickability when any dropdown changes
  useEffect(() => {
    setIsButtonClickable(!!selectedIndustry && !!selectedCategory && !!selectedRegistrationType);
  }, [selectedIndustry, selectedCategory, selectedRegistrationType]);

  const navigate = useNavigate();

  return (
    <div className={'onboarding'}>
    <div className={'SimpliPayLogo'}>
      <img src={SimpliPayLogo} alt={'SimpliPayLogo'} width={'140'}/>
    </div>

    <div className={'onboardingForm'}>
        <div className={'onboardingFormRow1'}>
          Basic Information
        </div>

        <div className={'onboardingFormRow2'}>

            <div className={'onboardingDetails'}>

              <div className={`mt-1`}>
                <div className="col-form-label py-0 input-height">
                    <label className="required fw-bold fs-9">
                    Industry
                    </label>
                </div>
                <div>
                <Select 
                  size={"large"}  
                  style={{ width: '100%' }}
                  placeholder="- Select an industry -"
                  onChange={handleIndustryChange}
                >
                  {categoryOptions.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Select>
                </div>

                <div className="fv-plugins-message-container input-height">
                 
                </div>
              </div>

              <div className={`mt-1`}>
                <div className="col-form-label py-0 input-height">
                    <label className="required fw-bold fs-9">
                    Category
                    </label>
                </div>
                <div>
                  <Select
                    size={"large"}
                    style={{ width: '100%' }}
                    placeholder="- Select a category -"
                    disabled={!selectedIndustry}
                  >
                    {industryCategories[selectedIndustry]?.map((category) => (
                      <Option key={category} value={category}>
                        {category}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="fv-plugins-message-container input-height">
                 
                </div>
              </div>
            </div>

            <div className={'onboardingDetails'}>
             <div className={`mt-1`}>
                <div className="col-form-label py-0 input-height">
                    <label className="required fw-bold fs-9">
                       Registration type
                    </label>
                </div>
                <div>
                <Select
                  size={"large"}  
                  style={{ width: '100%' }}
                  placeholder="- Select a registration type -"
                  onChange={handleRegistrationTypeChange}
                >
                  <Option value=""  disabled selected>- Select a registration type </Option>
                  <Option value="privateIncorporatedCompany">Private Incorporated Company</Option>
                  <Option value="publicIncorporatedCompany">Public Incorporated Company</Option>
                  <Option value="businessNameRegistration">Business Name Registration</Option>
                  <Option value="freeZoneEntity">Free Zone Entity</Option>
                  <Option value="incorporatedTrustees">Incorporated Trustees</Option>
                  <Option value="governmentEntity">Government Entity</Option>
                  <Option value="privateEntity">Private Entity created by Government Approval</Option>
                  <Option value="cooperativeSociety">Cooperative Society</Option>
                </Select>
                </div>

                <div className="fv-plugins-message-container input-height">
                 
                </div>
              </div>

             <div className={`mt-1`}></div>
            </div>

            <div className={'onboardingNextButton'}>
              <button
                disabled={!isButtonClickable}
                onClick={() => {
                  sessionStorage.setItem('requiredDocuments', selectedRegistrationType)
                  navigate('/admin/profile-setup/registered-business/required-document')
                }}
                style={{
                  background: isButtonClickable ? '#0866FF' : '#E4E4E8',
                  color: isButtonClickable ? 'white' : 'black', // You can adjust text color as needed
                }}
              >
                Next
              </button>
            </div>
        </div>

  </div>



  </div>
  );
};

export { RegisteredBusiness };
