export function convertToFirstLetterCap(word) {
  const formatWord = word
    ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    : "";
  return formatWord;
}

export function formatStatus(word: string) {
  let status: string = "";

  switch (word) {
    case "AWAITING_APPROVAL":
      status = "Awaiting Approval";
      break;

    default:
      status = word ?  convertToFirstLetterCap(word):'';
      break;
  }
  return status;
}
