import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  arrowDown,
  arrowRight,
  arrowUp,
  envelope,
  voxepayGroup,
  voxepayImg,
  voxepayFrame,
  mtn,
    Ninemobile,
  airtel,
  ibedc,
  ikejaElectric,
  bedc,
  jamb,
  wifi,
  eedc,
  aedc,
    glo,
  spectranet,
} from '../../../core/assets/imgs';
import { Hero } from '../../componets/Hero.tsx';
import { AboutPage } from '../../componets/about';
import { Footer } from '../../componets/footer';
import NavBar from '../../componets/nav/NavBar';
import { Service } from '../../componets/service';
import { useRequestSubscribtion } from '../payments/hooks/usePayment';
import { UserHero } from '../../componets/Hero.tsx/userHero';
import { UserAboutPage } from '../../componets/about/UserAbout';
import { chat } from '../../../core/assets/icons';
import { UserHeroPersonal } from 'app/componets/Hero.tsx/userHeroPersonal';

const profileDetailsSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
});

const initialValues = {
  email: '',
};

const PersonalHomePage: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(initialValues);
  const [show, setShow] = useState<any>(false);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const [arrow, setArrow] = useState(true);
  const [FaqNo, setFaqNo] = useState<Number>(0);
  const handleFaq = (index: Number) => {
    if (index == FaqNo && arrow) {
      setArrow(false);
    } else {
      setArrow(true);
      setFaqNo(index);
    }
  };

  const done = () => {
    navigate('/payment');
  };

  const { data: subData, mutate, isLoading } = useRequestSubscribtion();

  // useEffect(() => {
  //   if (subData) {
  //     setShow(true);
  //   }
  // }, [mutate, subData]);

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      console.log(values);

      mutate(values);

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
  });

  const FloatingButton = () => {
    return (
      <div className="floating-button-container ">
        <button className="floating-button">
          <img src={chat} alt="" className="w-25e h-25e" />
        </button>
      </div>
    );
  };

  const PageModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-9 modal-card"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        backdrop={'static'}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="d-flex flex-column align-items-center m-10">
            <article className="about-img d-flex justify-content-center align-items-center">
              <iframe
                className="hero-photo-video"
                width="90%"
                height="304px"
                src="https://www.youtube.com/embed/zH1HtWW_3-Q"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
              ></iframe>
            </article>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const faq = [
    {
      title: 'Why use TMS?',
      desc: 'The Treasury Portal is an application that provides ease of payments for government services through multiple payment channels for your convenience.',
    },
    {
      title: 'How to pay for school fees?',
      desc: 'To make fees payment or any other payment, please go to Revenue Payment, search for the MDA and select service.',
    },
    {
      title: 'Do I have to register to use TMS?',
      desc: 'No. You can go ahead to make payment for a services through the provided options without registering. Once you have made your first payment, you can carry out subsequent payment using same details.',
    },
  ];
  return (
    <div>
      <NavBar />
      <UserHeroPersonal />

      <div className="w-100 px-5 " id="help">
        <div className="help-center-section-3" style={{ width: '96vw' }}>
          <span className="tc-1">
            {' '}
            Start <span>Paying</span>
          </span>
          <span className="tc-2">No signup required</span>
        </div>

        <div className="help-center-section-4" style={{ width: '96vw' }}>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Buy Airtime</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Buy Airtime</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Buy Airtime</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Buy Airtime</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>

        <div className="help-center-section-4" style={{ width: '96vw' }}>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Buy Airtime</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Buy Data </span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Pay TV</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Buy Electricity</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>


        <div className="help-center-section-4" style={{ width: '96vw' }}>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Pay TSA and States</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Pay Salaries and Statutory Payments</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Pay for Jamb and other exams</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Pay for Water</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>


        <div className="help-center-section-4" style={{ width: '96vw' }}>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Bills and Purchases</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Pay Invoice</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Resend Receipt or Invoices</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            <span>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  fill="#FAFAFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="28"
                  height="28"
                  rx="3.5"
                  stroke="#CDE0FF"
                />
                <path
                  d="M16.5863 9.15627C17.4869 9.39848 18.3081 9.87307 18.9675 10.5325C19.6269 11.192 20.1015 12.0131 20.3437 12.9137M16.0362 11.2094C16.5765 11.3547 17.0692 11.6395 17.4649 12.0351C17.8606 12.4308 18.1453 12.9235 18.2906 13.4638M12.141 14.7885C12.6921 15.9151 13.6053 16.8242 14.7344 17.3701C14.817 17.4093 14.9084 17.4262 14.9995 17.4193C15.0907 17.4124 15.1785 17.3819 15.2543 17.3308L16.9168 16.2222C16.9903 16.1732 17.0749 16.1433 17.1629 16.1352C17.2509 16.1271 17.3396 16.1411 17.4208 16.1759L20.5311 17.5089C20.6367 17.5537 20.7249 17.6318 20.7824 17.7311C20.8399 17.8305 20.8635 17.9459 20.8497 18.0598C20.7514 18.8291 20.3761 19.5361 19.794 20.0486C19.2119 20.561 18.463 20.8437 17.6875 20.8438C15.2923 20.8438 12.9951 19.8923 11.3014 18.1986C9.60775 16.5049 8.65625 14.2078 8.65625 11.8125C8.65629 11.037 8.939 10.2881 9.45145 9.70603C9.9639 9.12396 10.6709 8.74863 11.4402 8.65032C11.5542 8.63653 11.6695 8.66015 11.7689 8.71762C11.8683 8.77509 11.9463 8.8633 11.9912 8.96895L13.3253 12.0819C13.3598 12.1625 13.3739 12.2503 13.3662 12.3375C13.3586 12.4248 13.3295 12.5088 13.2815 12.5821L12.1767 14.2702C12.1265 14.3461 12.0967 14.4338 12.0905 14.5246C12.0842 14.6155 12.1016 14.7064 12.141 14.7885Z"
                  stroke="#0866FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Pay Taxes</span>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 8H13.5M13.5 8L9 3.5M13.5 8L9 12.5"
                  stroke="#1F2937"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>


               <div
        className="py-20 w-100 px-5 "
        id="help"
      >
        <div className="help-center-section-patners" style={{ width: '96vw' }}>
          <div className="d-flex flex-column align-items-center">
            <div className="help-center-rw-personal">
              <span className="help-text-rw-1">
          Over 200,000 billers onboarded, Seamlessly pay anyone, anywhere.
              </span>

              <div className='pateners'>
                <div>
                  <div>
                    <img src={mtn} alt="" />
                  </div>
                  <div>  <img src={glo} alt="" /></div>
                  <div>  <img src={Ninemobile} alt="" /></div>
                  <div>  <img src={airtel} alt="" /></div>
                  <div>  <img src={ibedc} alt="" /></div>
                  <div>  <img src={ikejaElectric} alt="" /></div>
                  <div>  <img src={bedc} alt="" /></div>
                    <div>  <img src={jamb} alt="" /></div>
                  <div>  <img src={wifi} alt="" /></div>
                  <div>  <img src={eedc} alt="" /></div>
                  <div>  <img src={aedc} alt="" /></div>
                  <div>  <img src={spectranet} alt="" /></div>
                
                     
 

                </div>

                     {/* <div>
                  <div>
                    <img src={mtn} alt="" />
                  </div>
                  <div>  <img src={mtn} alt="" /></div>
                  <div>  <img src={mtn} alt="" /></div>
                  <div>  <img src={mtn} alt="" /></div>
                  <div>  <img src={mtn} alt="" /></div>
                  <div>  <img src={mtn} alt="" /></div>
                  <div>  <img src={mtn} alt="" /></div>
                 

                </div> */}

                
              </div>
         
              <button>
                <span>Get Started</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="15"
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M1.25 7.5H17.75M17.75 7.5L11 0.75M17.75 7.5L11 14.25"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
         
        </div>
      </div>

      <Footer />
      {PageModal()}
      {FloatingButton()}
    </div>
  );
};

export { PersonalHomePage };
