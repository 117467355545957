export function getCurrentDate(): string {
  const months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentDate: Date = new Date();
  const day: number = currentDate.getDate();
  const month: string = months[currentDate.getMonth()];
  const year: number = currentDate.getFullYear();

  return `${day} ${month} ${year}`;
}

export function getBankName(bankCode: any): any {
    
  const bankNameAndCode = {
    'BERACHAH MICROFINANCE BANK': '090618',
    'Iyin Ekiti MFB': '090620',
    'GIDAUNIYAR ALHERI MICROFINANCE BANK': '090621',
    'Royal Blue MFB': '090622',
    'Mab Allianz MFB': '090623',
    'Babura Microfinance Bank': '090625',
    'OHHA Microfinance Bank': '090626',
    'Nsehe Microfinance Bank': '090628',
    'Mabinas MFB': '090630',
    'Awacash Microfinance Bank': '090633',
    'CASHBRIDGE MICROFINANCE BANK': '090634',
    'Oyan Microfinance Bank': '090635',
    'Briyth Covenant Microfinance Bank': '090636',
    'Unifund Microfinance Bank': '090637',
    'Tangale Microfinance Bank': '090638',
    'GLOBAL INITIATIVE MFB': '090639',
    'ABSU Microfinance Bank': '090640',
    'SOURCE MICROFINANCE BANK': '090641',
    'Prosperity Microfinance Bank': '090642',
    'DIOBU MICROFINANCE BANK': '090643',
    'SUNTOP MICROFINANCE BANK': '090644',
    'Amucha Microfinance Bank': '090645',
    'Okengwe MFB': '090646',
    'Canaan Microfinance Bank': '090647',
    'Cashrite MFB': '090649',
    'Leadcity MFB': '090650',
    'BAM MFB': '090651',
    'UMUCHUKWU MICROFINANCE BANK': '090652',
    'Odoakpu MFB': '090654',
    'Bunkure MFB': '090655',
    'Entity MFB': '090656',
    'Pyramid MFB': '090657',
    'MOUA MFB': '090659',
    'Bundi MFB': '090661',
    'Awesome MFB': '090662',
    'Treasures MFB': '090663',
    'Good Shephard MFB': '090664',
    'REVELATION MICRO FINANCE BANK LIMITED': '090666',
    FETS: '100001',
    PAGA: '100002',
    'PARKWAY-READYCASH': '100003',
    OPAY: '100004',
    CELLULANT: '100005',
    ETRANZACT: '100006',
    'STANBIC IBTC @EASE WALLET': '100007',
    'ECOBANK XPRESS ACCOUNT': '100008',
    GTMOBILE: '100009',
    TEASYMOBILE: '100010',
    MKUDI: '100011',
    VTNetwork: '100012',
    ACCESSMONEY: '100013',
    'FIRSTMONIE WALLET': '100014',
    FORTISMOBILE: '100016',
    HEDONMARK: '100017',
    ZENITHMOBILE: '100018',
    'FIDELITY MOBILE': '100019',
    MONEYBOX: '100020',
    GOMONEY: '100022',
    TAGPAY: '100023',
    'IMPERIAL HOMES MORTGAGE BANK': '100024',
    KONGAPAY: '100025',
    CARBON: '100026',
    INTELLIFIN: '100027',
    'AG MORTGAGE BANK PLC': '100028',
    'INNOVECTIVES KESH': '100029',
    'FCMB EASY ACCOUNT': '100031',
    'CONTEC GLOBAL': '100032',
    PALMPAY: '100033',
    ZWallet: '100034',
    M36: '100035',
    'KEGOW(CHAMSMOBILE)': '100036',
    'PAYSTACK-TITAN': '100039',
    Xpresswallet: '100040',
    'BETA-ACCESS YELLO': '100052',
    'PAYATTITUDE ONLINE': '110001',
    'FLUTTERWAVE TECHNOLOGY SOLUTIONS LIMITED': '110002',
    'INTERSWITCH LIMITED': '110003',
    'First Apple Limited': '110004',
    '3LINE CARD MANAGEMENT LIMITED': '110005',
    'PAYSTACK PAYMENTS LIMITED': '110006',
    'TEAM APT': '110007',
    'KADICK INTEGRATION LIMITED': '110008',
    'Venture Garden Nigeria Limited': '110009',
    'Interswitch Financial Inclusion Services (IFIS)': '110010',
    'Arca Payments': '110011',
    'Cellulant PSSP': '110012',
    'QR Payments': '110013',
    'Cyberspace Limited': '110014',
    'Vas2nets Limited': '110015',
    CROWDFORCE: '110017',
    'Microsystems Investment and Development Limited': '110018',
    'NIBSSUSSD PAYMENTS': '110019',
    'Bud infrastructure Limited': '110021',
    Koraypay: '110022',
    'Capricorn Digital': '110023',
    'Resident Fintech Limited': '110024',
    'NetApps Technology Limited': '110025',
    'SPAY business': '110026',
    'Yello Digital financial services': '110027',
    'Nomba financial services Limited': '110028',
    'Woven Finance': '110029',
    'Leadremit Limited': '110044',
    'IROFIT TECHNOLOGIES LIMITED': '110075',
    '9 payment service Bank': '120001',
    HopePSB: '120002',
    'MoMo PSB': '120003',
    'SmartCash Payment Service bank': '120004',
    'Money Master PSB': '120005',
    FSDH: '400001',
    'NIP VIRTUAL BANK': '999999',
    'TASUED MICROFINANCE BANK LTD': '090593',
    'DAL Microfinance Bank': '090596',
    'KABO MICROFINANCE BANK': '090597',
    'IBA MFB': '090598',
    'Greenacres MFB': '090599',
    'AVE MARIA MICROFINANCE BANK LTD': '090600',
    'KENECHUKWU MICROFINANCE BANK': '090602',
    'Macrod MFB': '090603',
    'MADOBI MFB': '090605',
    'KKU Microfinance Bank': '090606',
    'MINJIBIR MICROFINANCE BANK': '090607',
    'Akpo Microfinance Bank': '090608',
    'Ummah Microfinance Bank': '090609',
    'AMOYE MICROFINANCE BANK': '090610',
    'Creditville MFB': '090611',
    'Medef MFB': '090612',
    'Total Trust Microfinance Bank': '090613',
    'Flourish MFB': '090614',
    'Beststar MFB': '090615',
    'RAYYAN MFB': '090616',
    "Banex Microfinance Bank": "090425",
    "Tangerine Money MFB": "090426",
    "EBSU MICROFINANCE Bank": "090427",
    "Ishie Microfinance Bank": "090428",
    "CrossRiver Microfinance Bank": "090429",
    "Ilora Microfinance Bank": "090430",
    "Bluewhales Microfinance Bank": "090431",
    "Memphis Microfinance Bank": "090432",
    "RIGO Microfinance Bank": "090433",
    "Insight Microfinance Bank": "090434",
    "Links Microfinance Bank": "090435",
    "Spectrum Microfinance Bank": "090436",
    "Oakland Microfinance Bank": "090437",
    "Futminna Microfinance Bank": "090438",
    "IBETO Microfinance Bank": "090439",
    "Cherish Microfinance Bank": "090440",
    "Giwa Microfinance Bank": "090441",
    "RIMA Microfinance Bank": "090443",
    "BOI MF Bank": "090444",
    "Capstone MF Bank": "090445",
    "Support MF Bank": "090446",
    "Moyofade MF Bank": "090448",
    "REX Microfinance Bank": "090449",
    "Kwasu MF Bank": "090450",
    "ATBU Microfinance Bank": "090451",
    "Unilag Microfinance Bank": "090452",
    "Uzondu MF Bank": "090453",
    "Borstal Microfinance Bank": "090454",
    "MKOBO MICROFINANCE BANK LTD": "090455",
    "Ospoly Microfinance Bank": "090456",
    "Nice Microfinance Bank": "090459",
    "OLUYOLE MICROFINANCE BANK": "090460",
    "UNIIBADAN Microfinance Bank": "090461",
    "Monarch Microfinance Bank": "090462",
    "Rehoboth Microfinance Bank": "090463",
    "Unimaid Microfinance Bank": "090464",
    "Maintrust MFB": "090465",
    "YCT Microfinance Bank": "090466",
    "Good Neighbours Microfinance Bank": "090467",
    "OLOFIN OWENA Microfinance Bank": "090468",
    "Aniocha MFB": "090469",
    "DOT MFB": "090470",
    "OLUCHUKWU Microfinance Bank": "090471",
    "CARETAKER Microfinance Bank": "090472",
    "ASSETS Microfinance Bank": "090473",
    "Verdant Microfinance Bank": "090474",
    "Giant Stride MFB": "090475",
    "Anchorage MFB": "090476",
    "Light Microfinance Bank": "090477",
    "Avuenegbe MFB": "090478",
    "First Heritage MFB": "090479",
    "Kolomoni Microfinance Bank Ltd": "090480",
    "Prisco Microfinance Bank": "090481",
    "Fedeth MFB": "090482",
    "Ada MFB": "090483",
    "Garki MFB": "090484",
    "Safegate Microfinance Bank": "090485",
    "Fortress Microfinance Bank": "090486",
    "Kingdom College Microfinance Bank": "090487",
    "Ibu-Aje Microfinance": "090488",
    "Alvana Microfinance Bank": "090489",
    "Chukwunenye Microfinance Bank": "090490",
    "Nsuk Microfinance Bank": "090491",
    "Oraukwu Microfinance Bank": "090492",
    "Iperu Microfinance Bank": "090493",
    "Boji Boji Microfinance Bank": "090494",
    "Goodnews Microfinance Bank": "090495",
    "Radalpha Microfinance bank": "090496",
    "Palmcoast Microfinance bank": "090497",
    "Catland Microfinance Bank": "090498",
    "Pristine Divitis Microfinance Bank": "090499",
    "Gwong Microfinance bank": "090500",
    "Boromu Microfinance bank": "090501",
    "Shalom Microfinance Bank": "090502",
    "Projects Microfinance bank": "090503",
    "Zikora Microfinance bank": "090504",
    "Nigeria PrisonsMicrofinance bank": "090505",
    "Solid Allianze MFB": "090506",
    "FIMS MFB": "090507",
    "Borno Renaissance Microfinance bank": "090508",
    "CAPITALMETRIQ SWIFT MICROFINANCE BANK": "090509",
    "Umunnachi Microfinance Bank": "090510",
    "Cloverleaf MFB": "090511",
    "BUBAYERO Microfinance Bank": "090512",
    "SEAP Microfinance Bank": "090513",
    "Umuchinemere Procredit Microfinance Bank": "090514",
    "Rima Growth pathway Microfinance Bank": "090515",
    "Numo Microfinance bank": "090516",
    "Uhuru Microfinance bank": "090517",
    "Afemai Microfinance Bank": "090518",
    "Ibom fadama Microfinance Bank": "090519",
    "IC GLOBALMicrofinance bank": "090520",
    "Foresight Microfinance bank": "090521",
    "Chase Microfinance bank": "090523",
    "Solidrock Microfinance bank": "090524",
    "Triple A Microfinance bank": "090525",
    "Crescent Microfinance bank": "090526",
    "Ojokoro Mfb": "090527",
    "Mgbidi Microfinance Bank": "090528",
    "Bankly Microfinance bank": "090529",
    "CONFIDENCE MICROFINANCE BANK LTD": "090530",
    "Aku MFB": "090531",
    "IBOLO MICORFINANCE BANK LTD": "090532",
    "Polyibadan Microfinance Bank": "090534",
    "NKPOLU-UST MICROFINANCE": "090535",
    "Ikoyi-Osun Microfinance Bank": "090536",
    "Lobrem Microfinance Bank": "090537",
    "Blue Investments Microfinance Bank": "090538",
  
    "PERSONAL TRUST MICROFINANCE BANK": "090135",
    "MICROCRED MICROFINANCE BANK": "090136",
    "PECAN TRUST MICROFINANCE BANK": "090137",
    "ROYAL EXCHANGE MICROFINANCE BANK": "090138",
    "VISA MICROFINANCE BANK": "090139",
    "SAGAMU MICROFINANCE BANK": "090140",
    "CHIKUM MICROFINANCE BANK": "090141",
    "YES MFB": "090142",
    "APEKS MICROFINANCE BANK": "090143",
    "CIT MICROFINANCE BANK": "090144",
    "FULL RANGE MFB": "090145",
    "TRIDENT MICROFINANCE BANK": "090146",
    "HACKMAN MICROFINANCE BANK": "090147",
    "BOWEN MFB": "090148",
    "IRL MICROFINANCE BANK": "090149",
    "VIRTUE MFB": "090150",
    "MUTUAL TRUST MICROFINANCE BANK": "090151",
    "NARGATA MFB": "090152",
    "FFS MICROFINANCE BANK": "090153",
    "CEMCS MFB": "090154",
    "ADVANS LA FAYETTE MFB": "090155",
    "E-BARCS MFB": "090156",
    "INFINITY MFB": "090157",
    "FUTO MFB": "090158",
    "CREDIT AFRIQUE MFB": "090159",
    "ADDOSSER MFBB": "090160",
    "OKPOGA MFB": "090161",
    "STANFORD MFB": "090162",
    "FIRST MULTIPLE MFB": "090163",
    "FIRST ROYAL MICROFINANCE BANK": "090164",
    "PETRA MICROFINANCE BANK": "090165",
    "ESO-E MICROFINANCE BANK": "090166",
    "DAYLIGHT MICROFINANCE BANK": "090167",
    "GASHUA MICROFINANCE BANK": "090168",
    "ALPHAKAPITAL MFB": "090169",
    "RAHAMA MFB": "090170",
    "MAINSTREET MFB": "090171",
    "ASTRAPOLARIS MFB": "090172",
    "RELIANCE MFB": "090173",
    "MALACHY MFB": "090174",
    "RUBIES MFB": "090175",
    "BOSAK MFB": "090176",
    "LAPO MFB": "090177",
    "GREENBANK MFB": "090178",
    "FAST Microfinance Bank": "090179",
    "AMJU MFB": "090180",
    "Balogun Fulani  Microfinance Bank": "090181",
    "Standard MFB": "090182",
    "Girei MFB": "090186",
    "BAINES CREDIT MFB": "090188",
    "ESAN MFB": "090189",
    "MUTUAL BENEFITS MFB": "090190",
    "KCMB MFB": "090191",
    "Midland MFB": "090192",
    "UNICAL MFB": "090193",
    "NIRSAL NATIONAL MICROFINANCE BANK": "090194",
    "GROOMING MICROFINANCE BANK": "090195",
    "PENNYWISE MICROFINANCE BANK": "090196",
    "ABU MICROFINANCE BANK": "090197",
    "RENMONEY MICROFINANCE BANK": "090198",
    "XPRESS PAYMENTS": "090201",
    "ACCELEREX NETWORK": "090202",
    "NEWDAWN MICROFINANCE BANK": "090205",
    "ITEX INTEGRATED SERVICES LIMITED": "090211",
    "UNN MFB": "090251",
    "Yobe MFB": "090252",
    "Wudil Microfinance Bank": "090253",
    "CoalCamp Microfinance Bank": "090254",
    "IMO MICROFINANCE BANK": "090258",
    "ALEKUN MICROFINANCE BANK": "090259",
    "ABOVE ONLY MICROFINANCE BANK": "090260",
    "QUICKFUND MICROFINANCE BANK": "090261",
    "STELLAS MICROFINANCE BANK": "090262",
    "NAVY MICROFINANCE BANK": "090263",
    "AUCHI MICROFINANCE BANK": "090264",
    "LOVONUS MICROFINANCE BANK": "090265",
    "UNIBEN MICROFINANCE BANK": "090266",
    "KUDA MICROFINANCE BANK": "090267",
    "Enco Finance": "050012",
    "Dignity Finance": "050013",
    "TRINITY FINANCIAL SERVICES LIMITED": "050014",
    "Lukefield Finance Company Limited": "050015",
    "E-Finance": "050016",
    "CARDINALSTONE FINANCE": "050017",
    "Vale Finance Limited": "050020",
    "CORONATION": "060001",
    "FBNQUEST MERCHANT BANK": "060002",
    "NOVA MB": "060003",
    "Greenwich Merchant Bank": "060004",
    "NPF MICROFINANCE BANK": "070001",
    "FORTIS MICROFINANCE BANK": "070002",
    "COVENANT MFB": "070006",
    "LIVINGTRUST MORTGAGE BANK PLC": "070007",
    "PAGE FINANCIALS": "070008",
    "GATEWAY MORTGAGE BANK": "070009",
    "ABBEY MORTGAGE BANK": "070010",
    "REFUGE MORTGAGE BANK": "070011",
    "LBIC MORTGAGE BANK": "070012",
    "PLATINUM MORTGAGE BANK": "070013",
    "FIRST GENERATION MORTGAGE BANK": "070014",
    "BRENT MORTGAGE BANK": "070015",
    "INFINITY TRUST  MORTGAGE BANK": "070016",
    "HAGGAI MORTGAGE BANK": "070017",
    "MAYFRESH MORTGAGE BANK": "070019",
    "COOP Mortgage Bank": "070021",
    "STB Mortgage Bank": "070022",
    "Delta Trust Mortgage bank": "070023",
    "HomeBase Mortgage": "070024",
    "Akwa savings & Loans Limited": "070025",
    "FHA MORTGAGE BANK LTD": "070026",
    "TajWallet": "080002",
    "ASOSAVINGS": "090001",
    "JUBILEELIFE": "090003",
    "PARRALEX": "090004",
    "TRUSTBOND": "090005",
    "SAFETRUST": "090006",
    "EKONDO MFB": "090097",
    "FIRSTTRUST MORGAGES LIMITED": "090107",
    "NEW PRUDENTIAL BANK": "090108",
    "VFD MFB": "090110",
    "FINATRUST MICROFINANCE BANK": "090111",
    "SEED CAPITAL MICROFINANCE BANK": "090112",
    "MICROVIS MICROFINANCE BANK": "090113",
    "EMPIRETRUST MICROFINANCE BANK": "090114",
    "TCF": "090115",
    "AMML MFB": "090116",
    "BOCTRUST MICROFINANCE BANK": "090117",
    "IBILE MICROFINANCE BANK": "090118",
    "OHAFIA MFB": "090119",
    "WETLAND MFB": "090120",
    "HASAL MFB": "090121",
    "GOWANS MFB": "090122",
    "TRUSTBANC J6 MICROFINANCE BANK LIMITED": "090123",
    "XSLNCE MICROFINANCE BANK": "090124",
    "REGENT MFB": "090125",
    "FIDFUND MFB": "090126",
    "BC KASH MFB": "090127",
    "NDIORAH MFB": "090128",
    "MONEYTRUST MFB": "090129",
    "CONSUMER  MFB": "090130",
    "ALLWORKERS MFB": "090131",
    "RICHWAY MFB": "090132",
    "AL-BARKAH MFB": "090133",
    "ACCION MFB": "090134",

    
    "TAJ BANK": "000026",
    "GLOBUS BANK": "000027",
    "Central Bank of Nigeria": "000028",
    "Lotus Bank": "000029",
    "Parallex Bank": "000030",
    "Premium Trust bank": "000031",
    "e-Naira": "000033",
    "Signature Bank": "000034",
    "OPTIMUS BANK": "000036",
    "Alternative Bank Limited": "000037",
    "NEXIM Bank": "030001",
    "COUNTY FINANCE LTD": "050001",
    "FEWCHORE FINANCE COMPANY LIMITED": "050002",
    "SageGrey Finance Limited": "050003",
    "NEWEDGE FINANCE LTD": "050004",
    "AAA FINANCE": "050005",
    "Branch International Financial Services": "050006",
    "Tekla Finance Ltd": "050007",
    "FAST CREDIT": "050009",
    "FUNDQUEST FINANCIAL SERVICES LTD": "050010",
    "STERLING BANK": "000001",
    "KEYSTONE BANK": "000002",
    "FCMB": "000003",
    "UNITED BANK FOR AFRICA": "000004",
    "ACCESS BANK PLC (DIAMOND)": "000005",
    "JAIZ BANK": "000006",
    "FIDELITY BANK": "000007",
    "POLARIS BANK": "000008",
    "CITI BANK": "000009",
    "ECOBANK BANK": "000010",
    "UNITY BANK": "000011",
    "STANBICIBTC BANK": "000012",
    "GTBANK PLC": "000013",
    "ACCESS BANK": "000014",
    "ZENITH BANK PLC": "000015",
    "FIRST BANK OF NIGERIA": "000016",
    "WEMA BANK": "000017",
    "UNION BANK": "000018",
    "ENTERPRISE BANK": "000019",
    "HERITAGE": "000020",
    "STANDARDCHARTERED": "000021",
    "SUNTRUST BANK": "000022",
    "PROVIDUS BANK": "000023",
    "RAND MERCHANT BANK": "000024",
    "TITAN TRUST BANK": "000025",








  };

  const bankKeys = Object.keys(bankNameAndCode);
  for (const key of bankKeys) {
    if (String(bankNameAndCode[key]) === String(bankCode)) {
      return key;
    }
  }

  return "N/A";
}

export function getAmountFilterOptions(
  amounts: any[],
  noOfRecords: number
): any[] {
  const amountFilterOptionArray: any[] = [];
  const minAmount: number = Math.round(Math.min(...amounts));
  const maxAmount: number = Math.round(Math.max(...amounts));
  const numberOfAmountFilterOption: number = noOfRecords;
  const amountRange: number = Math.round(
    (maxAmount - (minAmount - 1)) / numberOfAmountFilterOption
  );
  let currentMinAmount: number = minAmount;
  for (let x: number = 0; x < numberOfAmountFilterOption; x++) {
    const option: any = {
      value: `${currentMinAmount}-${currentMinAmount + amountRange}`,
      label: `₦${currentMinAmount.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} - ₦${(currentMinAmount + amountRange).toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    };
    currentMinAmount = currentMinAmount + amountRange;
    amountFilterOptionArray.push(option);
  }

  return amountFilterOptionArray;
}
