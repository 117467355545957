import axios, { AxiosRequestConfig } from "axios";
// import {
//   BrowserView,
//   MobileView,
//   isMobile,
//   isTablet,
// } from 'react-device-detect';

// import { User } from '../../../shared/types';
import {
  baseUrl,
  baseUrlNameEnquiry,
  baseUrlStepThree,
  baseUrlStepTwo,
  kycBaseURL,
} from './constants';

export const ipApi = axios.create({
  baseURL: "https://api.ipify.org",
});



export function getJWTHeader(user: any): Record<string, string> {
  return { Authorization: `Bearer ${user.token}` };
}

export function getJWTHeaderPreLogin(token: string): Record<string, string> {
  return { Authorization: `Bearer ${token}` };
}
const token: string | null = localStorage.getItem("token");

const config: AxiosRequestConfig = { baseURL: baseUrl };
const axiosInstance = axios.create(config);

axiosInstance.defaults.headers.common["LONG"] = "-73.989308";
axiosInstance.defaults.headers.common["DEVICE"] = "DELL";
axiosInstance.defaults.headers.common["IP"] = "105.113.69.38";
axiosInstance.defaults.headers.common["LAT"] = "40.741895";
// axiosInstance.defaults.headers.common['LOCATION'] = 'Lagos, Nigeria';

if (token) {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axiosInstance.interceptors.response.use(
  (response) => {
    // Do something with the response data
    return response;
  },
  (error) => {
    // Handle response error
    //console.log(error, "updateeeeeeeeeeeeee");
    return Promise.reject(error);
    // console.log(error, "updateeeeeeeeeeeeee");
  }
);

const config2: AxiosRequestConfig = { baseURL: baseUrlStepTwo };
const axiosInstance2 = axios.create(config2);

axiosInstance2.defaults.headers.common["LONG"] = "-73.989308";
axiosInstance2.defaults.headers.common["DEVICE"] = "DELL";
axiosInstance2.defaults.headers.common["IP"] = "105.113.69.38";
axiosInstance2.defaults.headers.common["LAT"] = "40.741895";
// axiosInstance2.defaults.headers.common['LOCATION'] = 'Lagos, Nigeria';

if (token) {
  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
axiosInstance2.interceptors.response.use(
  (response) => {
    // Do something with the response data
    return response;
  },
  (error) => {
    // Handle response error
    console.log(error);
    // const errMessage = error.response.data;
    // const message = errMessage.message
    //   ? errMessage.message
    //   : "Something went wrong.";

    // ErrorToast(message);

    return Promise.reject(error);
    // console.log(error, "updateeeeeeeeeeeeee");
  }
);



const configNameEnquire: AxiosRequestConfig = { baseURL: baseUrlNameEnquiry };
const axiosInstanceNameEnquire = axios.create(configNameEnquire);


axiosInstanceNameEnquire.interceptors.response.use(
  (response) => {
    // Do something with the response data
    return response;
  },
  (error) => {
    // Handle response error
    console.log(error);
    // const errMessage = error.response.data;
    // const message = errMessage.message
    //   ? errMessage.message
    //   : "Something went wrong.";

    // ErrorToast(message);

    return Promise.reject(error);
    // console.log(error, "updateeeeeeeeeeeeee");
  }
);



const configKyc: AxiosRequestConfig = { baseURL: kycBaseURL };
const axiosKyc = axios.create(configKyc);

axiosKyc.interceptors.response.use(
  (response) => {
    // Do something with the response data
    return response;
  },
  (error) => {
    // Handle response error
    console.log(error);
    // const errMessage = error.response.data;
    // const message = errMessage.message
    //   ? errMessage.message
    //   : "Something went wrong.";

    // ErrorToast(message);

    return Promise.reject(error);
    // console.log(error, "updateeeeeeeeeeeeee");
  }
);

export { axiosInstance, axiosInstance2, axiosInstanceNameEnquire, axiosKyc };

const config3: AxiosRequestConfig = { baseURL: baseUrlStepThree };
export const axiosInstance3 = axios.create(config3);
