import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../../../componets/footer";
import NavBar from "../../../componets/nav/NavBar";

type Props = {
  children?: any;
};

const HomeLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className={`alt-bg`}>
      <NavBar />
      <div>
        <Outlet />
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export { HomeLayout };
