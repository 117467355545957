import React from 'react';
import { Footer } from '../../componets/footer';
import NavBar from '../../componets/nav/NavBar';
import './terms.scss';

const TermsCondition: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div>
      <NavBar />

      <div
        className="center-all my-10"
        style={{ marginTop: '6rem !important;' }}
      >
        <span className="text-center text-dark fs-1  fw-bolder">
          Terms & Conditions
        </span>
      </div>

      <div className="body-style mt-5 mb-20" style={{ marginTop: '3rem' }}>

          <div className="frame">
      <p className="div">
        You must read, agree with, and accept the terms and conditions contained in this Agreement, before you may
        become an individual user or corporate entity of VoxePay platform or mobile application. It is your
        responsibility to access this Agreement, as well as any amendments made thereto and to contact VoxePay by
        Simplify International Services Limited in the event of any queries you may have.
      </p>
      <div className="div-2">
        <div className="text-wrapper">1.0 Definitions</div>
        <div className="div-wrapper">
          <p className="in-this-document">
            <span className="span"
              >In this document, &#34;User Agreement&#34;, &#34;Agreement&#34;, &#34;Terms and Conditions of
              Service&#34; are synonymous and used interchangeably.<br /><br />In this Agreement, &#34;you&#34; or
              &#34;your&#34; or &#34;User&#34; refers to any person or entity using the service. Unless otherwise
              stated, &#34;VPSP,&#34; &#34;we&#34; or &#34;our&#34; or &#34;us&#34; will refer collectively to VoxePay
              by Simplify International Services Limited and its subsidiaries, affiliates, directors, officers,
              employees, agents and partners.<br /><br />You and VPSP are jointly referred to as &#39;the Parties&#39;
              in this document, &#34;VoxePay&#34;, &#34;our Services&#34;, &#34;the Services&#34;, &#34;the System&#34;,
              &#39;VoxePay app&#39; &#34;the platform&#34; are synonymous and used interchangeably unless otherwise
              specified.<br /><br />&#34;VoxePay&#34; means the range of products and services with the &#34;VoxePay
              Powered by Simplify&#34; notice offered by VPSP and its partners as an online platform for the Management
              of:<br />INDIVIDUAL AND BUSINESS USERS<br /><br
            /></span>
            <span className="text-wrapper-2">Payment Collection from Service Users:<br /></span>
            <span className="span"
              >VoxePay enables automated collection of fees and other charges from service beneficiaries via voxePay
              checkout.<br /><br
            /></span>
            <span className="text-wrapper-2">Payment of Salaries:<br /></span>
            <span className="span"
              >VoxePay enables direct payment of salaries from any bank account into beneficiaries&#39; accounts in any
              bank or other designated financial schemes.<br /><br
            /></span>
            <span className="text-wrapper-2">Payment of Vendors and Third Parties:<br /></span>
            <span className="span"
              >VoxePay enables customers to make electronic payments from any bank account into the accounts of vendors,
              suppliers and other third parties in any bank or other designated financial schemes.<br /><br
            /></span>
            <span className="text-wrapper-2">Account Balance and Transaction Monitoring:<br /></span>
            <span className="span"
              >VoxePay enables customers at their convenience, to view their account balances across banks, and it
              provides clear and definitive status of transactions at all times.<br /><br
            /></span>
            <span className="text-wrapper-2">Value Added Services:<br /></span>
            <span className="span"
              >VoxePay platform also provides value-added services such as transaction referencing, third-party
              application integration, etc. and also Marketplace services such as; ticketing, airtime and utility
              vending, International transfers, etc.</span
            >
          </p>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">2.0 Eligibility</div>
        <div className="div-wrapper">
          <p className="in-order-to-use-the">
            In order to use the Service, you must apply for a mandate on your bank account for a Personal or Corporate
            profile. Our Services are available to you based on the approval of your VoxePay account.<br /><br />Without
            limiting the foregoing, we reserve the right to deny, suspend or terminate delivery of our Service to
            persons who present an unacceptable level of risk as determined by regulatory, industry and our standards.
          </p>
        </div>
      </div>
      <div className="div-2">
        <p className="text-wrapper">3.0 Contract Between You and VPSP</p>
        <div className="div-3">
          <p className="your-acceptance-of">
            Your acceptance of these Terms and Conditions of Service is established either:<br />by using your personal
            details to log-in to VoxePay or any of the related solutions &#34;Powered by Simplify&#34;, or<br />by
            installing or using any VoxePay service or products or solution &#34;Powered by Simplify&#34;, or<br />by
            integrating VoxePay with your third party application for the purpose of sending payment or collection
            instructions for processing and receiving feedback as to the status of the processed transactions.
          </p>
          <div className="div-4">
            <div className="text-wrapper-3">3.1 Relationship</div>
            <p className="voxepay-facilitates">
              VoxePay facilitates your transfer to and receipt of funds from third parties. Based on your instructions,
              you acknowledge that:<br /><br />Payment instructions on the platform are driven strictly by account
              numbers and not by the beneficiary names supplied.<br /><br />Payments will be applied by Simplify
              International Synergy Partner Banks into the beneficiary account numbers supplied by you at the time of
              upload of payment instructions.<br /><br />Any payment instruction, including but not limited to standing
              instruction related debits, processed through VoxePay that failed processing for any reason whatsoever
              will be automatically retried towards ensuring its successful processing.<br /><br />You are fully
              responsible for the completeness, correctness and validity of data supplied on the platform, including but
              not limited to:<br />Beneficiary account number<br />Beneficiary account name<br />Amount<br />Beneficiary
              phone number<br />Beneficiary e-mail address<br />Neither VPSP nor any of the Partner Banks can be held
              liable for any incorrect beneficiary name, account number, amount, phone number, e-mail address or other
              data that a registered user uploads on the platform.<br /><br />VoxePay is not a bank.<br /><br />We are
              not acting as a trustee, fiduciary or escrow with respect to your funds.<br /><br />When you send a
              payment instruction, until that payment is accepted by the recipient, you remain the owner of those funds,
              but you will not be able to withdraw those funds or send the funds to any other recipient unless the
              initial transaction is cancelled.<br /><br />We act as service providers by creating, hosting, maintaining
              and providing our Service to you through the Internet. We do not have any control over the products or
              services that are paid for through our Service. We cannot ensure that a buyer or a seller you are dealing
              with will actually complete the transaction and we shall not bear any liability for any incomplete
              transaction between you and the other party with whom you may be transacting.<br /><br />When you use
              VoxePay to manage the payroll of your organization, VoxePay shall use best efforts to process the
              information provided by you to ensure that the computed figures are accurate and represent your
              expectations. It however remains your responsibility to confirm your satisfaction with computed figures
              before you approve same for payment.<br /><br />When you use VoxePay for collection of funds by way of an
              automated direct debit from the accounts of third parties, such parties will be entitled to a prompt
              refund of such payments simply by submitting appropriate claims to us in accordance with the relevant
              direct debit guidelines from the Central Bank of Nigeria or other regulatory requirements.<br /><br />When
              you use VoxePay as a registered Biller/Collector/Merchant for collection of funds paid by service
              beneficiaries into your designated collection account(s) through any of the VoxePay payment channels such
              as any bank branch, online banking sites, debit/credit cards, digital wallets, POS or mPOS terminals,
              direct debit, standing order, cash collection points etc., VoxePay shall use best efforts to process
              information provided by the payer in accordance with what has been entered into the system such that funds
              collected are accurate and represent your expectations. It however remains your responsibility to confirm
              your satisfaction with collected funds for which the payer is enjoying your products or services.<br /><br />VoxePay
              would normally settle collected amounts into merchant accounts on the same day, except for funds
              collection via debit/credit cards, digital wallets, POS and mPOS terminals and other card related
              transactions, settlement for which is normally done to merchants accounts on a T+1 basis (the day after
              the transaction), in line with industry standards. International payments will be subject to international
              best practices.<br /><br />When you use VoxePay as a registered Biller for collection of funds paid by
              service beneficiaries into your designated collection account(s) through any of the VoxePay payment
              channels, You hereby grant us the unconditional right (without any liability whatsoever) to publish your
              name as a Biller on any other platform owned by VPSP and/or any other aggregator’s platform with whom we
              have a business relationship, for the purpose of facilitating the collection of payments due to you, and
              expanding your access to payers.<br /><br />In addition to being able to access your VoxePay profile
              through VoxePay web and the VoxePay mobile application, by becoming a registered user on the platform, you
              may be able to make payments directly from the portal/platform of a Biller/Merchant registered on VoxePay.
              In such an instance, you shall be required to authenticate your status as a registered VoxePay user,
              select any of your bank accounts that are profiled on the platform from which the payment is to be made
              and authorize your payment accordingly”<br /><br />As a Biller, You shall inform us of any change in
              control or ownership of your business.<br />You will inform us of any alliance, joint venture, partnership
              or any other business combination arrangement You may go into with any foreign entity.<br />You shall not
              accept payment for or on behalf of any third Party through VoxePay.<br />You shall fully comply with all
              applicable Payment Schemes rules and regulations, Card usage and acceptance requirements and merchant
              monitoring standards.<br /><br />We reserve the right to terminate this Agreement in the event any of Your
              contracts with a payment scheme provider or Bank partner is terminated for any reason whatsoever.<br /><br />We
              shall not oblige Your request to switch off any authenticated procedures insofar as it pertains to the
              processing of a card payment transaction.<br /><br />You must not submit, and we shall not accept, any
              transaction that you know is, or should have known was, illegal or fraudulent.<br /><br />You shall at all
              times, prevent fraud through your action or inaction on VoxePay, and shall provide us with reasonable
              assistance upon request, for the prevention and detection of fraud or other criminal activity in respect
              of transaction.<br /><br />You shall be responsible for the conduct of your employees, agents and
              representatives as it pertains to the use of VoxePay as contemplated in this Agreement, and you shall
              indemnify us for any loss we may suffer as a result of any action or inaction of your employees, agents
              and/or representatives.<br /><br />We shall not accept transactions made using a payment card for any Debt
              repayment (unless authorized by us in writing).
            </p>
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">3.2 Identity Authentication</div>
            <p className="div-5">
              We use many techniques to identify users when they register on the platform. Verification of Users is only
              an indication of increased likelihood that a User&#39;s identity is correct. You authorize us to, directly
              or through third parties, make any inquiries we consider necessary to validate your registration. This may
              include verifying the information you provide against third party databases. In addition, we reserve the
              right to employ other means of verification of authenticity for transactions we deem suspicious or for
              accounts conducting high value or high volume transactions to ensure integrity of the transactions and we
              may thus delay execution of such instructions.
            </p>
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">3.3 No Warranty</div>
            <p className="div-5">
              We provide our services &#34;as is&#34; and without any warranty or condition, express, implied or
              statutory. We, specifically disclaim any implied warranties of title, merchantability, fitness for a
              particular purpose and non-infringement to the full extent permissible by the law.<br /><br />We shall
              provide the Services with due care and skill, in consideration for your payment of the applicable fee or
              charge. Please be informed that our Services may suffer from delays, errors or other unintended
              outcomes.<br /><br />We shall make reasonable efforts to ensure that requests for electronic debits and
              credits and other transactional operations are processed in a timely manner but we make no representations
              or warranties regarding the amount of time needed to complete processing because our Service is also
              dependent upon other factors outside of our control, one of which is the operational efficiency of all
              stakeholders.
            </p>
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">3.4 Limitation of Liability</div>
            <p className="div-5">
              We shall only be liable for your direct loss or damage, proven to be a foreseeable result of our breach of
              this agreement. We will also be liable for any obligation that cannot be limited or excluded as a matter
              of law.<br /><br />We will not be liable for any loss or damage arising as a result of unauthorised access
              to the service if (a) You intentionally or negligently failed to take reasonable precautions to protect
              your security, access codes, login details or any device used to access the service, (b) You failed to
              promptly notify us that the service was being accessed in an unauthorised way after becoming aware of it,
              or (c) You acted fraudulently.<br /><br />In no event shall VPSP be liable for loss of income, profits,
              business, opportunity, contracts or any indirect, special, incidental or consequential damages arising out
              of or in connection with our platform, our service, or this Agreement.<br /><br />Our liability to you or
              any third party in any circumstance of proven liability by us, shall not exceed the fees paid to us in
              respect of the specific transaction that gave rise to the claim or liability.
            </p>
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">3.5 Indemnification</div>
            <p className="div-5">
              You agree that if you are in breach of this Agreement or the documents it incorporates by reference, or if
              you violate any law or the rights of a third party in connection with your use of the service, we shall
              not be responsible for any damage, loss, liability or third party claim you incur wholly or partly by your
              breach or violation.<br /><br />You agree to indemnify and hold VPSP, harmless from any claim or demand
              (including attorneys&#39; fees) made by you or any third party arising wholly or partly from your breach
              of this Agreement or the documents it incorporates by reference, or your violation of any law or the
              rights of a third party relating to your use of the Service.
            </p>
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">3.6 Authorised Signatories</div>
            <div className="div-5">You undertake that:</div>
            <p className="div-5">
              <span className="text-wrapper-2">3.6.1</span>
              <span className="span"> Authorised signatories shall be setup on VoxePay to;</span>
            </p>
            <p className="p">
              3.6.1.1 approve payment instructions and other relevant transactions in accordance with your internal
              approval rules. You understand that these approval rules will be applied to all payment instructions and
              other relevant transactions processed on VoxePay and therefore will not be subjected to telephone, e-mail
              or any other manual confirmation by banks before transactions are completed.<br /><br />3.6.1.2 approve on
              your behalf, subscription for additional services that may be offered by us on the Platform, including but
              not limited to Data Referencing Service.<br /><br />3.6.2 If any Authorized signatory or any of your other
              registered users on the Platform cease to be your employee, you must immediately terminate his/her access
              to VoxePay and any other service which the Authorized signatory or registered user can access on your
              behalf. You will be responsible for any and all actions taken by your Authorized signatory or any of your
              other registered users on the Platform. You must notify us immediately you notice or suspect any
              unauthorized use of your profile on the Platform.<br /><br />3.6.3 You will keep confidential all VoxePay
              security related information such as passwords, Access Codes and Personal Identification Numbers (PIN).
              You understand that VPSP, banks, their affiliates and service providers will never request you to divulge
              any of these information by phone, mail or any other means. You are obliged to report any representation
              to the contrary to VPSP and your bank promptly.<br /><br />3.6.4 Payment instructions made to your banks
              by cheques, or any channel other than VoxePay, will continue to be subjected to all your current mandate
              instructions and confirmation rules.<br /><br />3.6.5 When you make a payment through VoxePay, you are
              requesting an electronic transfer from your account. Upon such request, VoxePay will transmit your
              instructions to your bank to transfer from your account the amount you specify. You agree that such
              requests constitute your authorisation for such transfers.<br /><br />3.6.6 Except when caused by
              VPSP&#39; or the banks&#39; misconduct or negligence, you will protect VPSP, the banks, their affiliates
              and service providers from any/and all claims, liability, damages, expenses and costs caused by or arising
              from your use of the service.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">4.0 Support Services</div>
        <div className="div-3">
          <p className="div">
            <span className="span"
              >VPSP has dedicated and highly experienced support personnel. Customer support issues are managed through
              a Customer Relationship Management (CRM) system. Customer complaints made through our dedicated email and
              telephone lines are logged and monitored until a resolution is achieved. Our support team is always
              available to receive enquiries on weekdays, weekends and public holidays. All support related enquiries
              should be routed via the email support@voxepay.com or telephone number
            </span>
            <span className="text-wrapper-2">**************. </span>
            <span className="span"
              >Contact details are also displayed on the &#39;Contact&#39; section of our website at www.voxepay.com,
              which may be updated from time to time.</span
            >
          </p>
          <div className="div-4">
            <div className="text-wrapper-3">4.1 Standard Support Services</div>
            <p className="div-5">
              Standard Support Services provided by us are as listed below and are covered by your transaction fees and
              do not attract any further charges.
            </p>
            <p className="div-5">
              <span className="text-wrapper-2">4.1.1 </span>
              <span className="span"
                >Processing of your application to use the system including the creation of your login details and
                activation of your VoxePay profile.<br /><br
              /></span>
              <span className="text-wrapper-2">4.1.2 </span>
              <span className="span">Initial Setup of your profile and users on the system.<br /><br /></span>
              <span className="text-wrapper-2">4.1.3</span>
              <span className="span">
                Required support to ensure successful execution of your payment instructions.<br /><br
              /></span>
              <span className="text-wrapper-2">4.1.4</span>
              <span className="span"> Deployment of application updates and patches.<br /><br /></span>
              <span className="text-wrapper-2">4.1.5</span>
              <span className="span"> Telephone and e-mail support services in response to your queries.</span>
            </p>
          </div>
          <div className="div-4">
            <p className="text-wrapper-3">4.2 Extended Support Services for Corporate Users</p>
            <p className="div-5">
              Upon your request, we are also able to provide additional services which are charged separately. Our
              extended support services include:
            </p>
            <p className="p">
              4.2.1 Consultancy Services requiring our physical presence in your office or any designated location
              indicated by you. Please note that, we are able to provide consulting services to you under the following
              terms and conditions.<br /><br />Your nominated representative shall be available for the assignment 100%
              of the time, give highest priority to tasks, activities and issues relating to the assignment and adhere
              to schedules and provisions of this Agreement.<br /><br />You will accomplish all data entry tasks, either
              by being directly involved in entering data required or by mobilizing necessary clerical support.<br /><br />You
              shall provide reasonable and appropriate office space for our consultants, with internet access and assure
              their access to a meeting room, before the assignment will commence and throughout the engagement.<br /><br />The
              consultancy services shall attract a fee payable before resource persons are deployed to your location.<br /><br />4.2.2
              Audit and Investigative support services: Where our services are required to support your internal or
              external auditors, regulatory or law enforcement agencies, we shall work with you to determine the level
              of work required, agree on the scope, work plan and applicable charges for the assignment.<br /><br />4.2.3
              Bespoke Development and Interfaces as may be requested by you: We shall work with you to determine the
              level of work required, agree on the scope, work plan and applicable charges for the assignment.<br /><br />4.2.4
              Training Services on VoxePay will be based on the following conditions which shall govern your request for
              training before, during and/or after your commencement of the use of VoxePay:<br /><br />You shall
              register your nominees online at www.VoxePay.com<br /><br />You shall notify us about cancellations of
              nominations at least five (5) days prior to course start dates.<br /><br />You shall pay us the total
              training fees at least seven (5) working days before commencement of any training course.<br /><br />You
              shall be responsible for the transportation and accommodation of your nominees throughout the period of
              the course.<br /><br />Your nominees shall be available for scheduled training courses between our
              official training hours of 9.00 am and 4.00 pm.<br /><br />You shall be responsible for the absence,
              withdrawal, or non-active participation of your nominees at our training courses.<br /><br />You shall,
              through your nominees, ensure that our copyright in our training materials is protected.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">5.0 Fees</div>
        <div className="div-3">
          <p className="div">
            The processing fees payable for our services are as displayed on your VoxePay profile screen during
            transaction processing or as stated below. The fees are computed per record and charged alongside each batch
            of transactions that you process.<br />The fees payable for our services are:<br /><br />Bills Payments
            (Regular): 1.5% of transaction value subject to a maximum of ₦2,000 per transaction. Fees for payments in
            favour of educational institutions is N300 per transaction.<br /><br />International Transaction: 4.0% of
            transaction value<br />Payment to Vendors, Payroll/Salary Beneficiaries and other 3rd parties with value-add
            services: ₦100 per million, subject to a maximum of ₦5,000 per transaction.<br /><br />Direct Debit/
            Standing Order: 1% Maximum of ₦1,000 per transaction.<br /><br />Convenience Fees: Where applicable and
            subject to our discretion, we may charge a convenience fee in addition to the applicable fee listed above.
            In such an instance, the Payer shall be notified of the amount payable at the point of consummating the
            transaction,<br /><br />Special Prices not included here: The prices stated in this Agreement are for the
            standard service offerings on the platform. Special prices will be agreed for projects with features
            extending beyond our standard services.<br /><br />The above fees are computed and charged alongside each
            batch of transactions that you process. Any disparity between the fees stated herein and the fees stated on
            your VoxePay Profile Screen will be resolved in favour of the fees stated on your VoxePay Profile Screen
            during transaction processing.
          </p>
          <div className="div-4">
            <p className="text-wrapper-3">5.1 Fees for Extended Support Services</p>
            <p className="div-5">
              <span className="text-wrapper-2">5.1.1</span>
              <span className="span">
                Consultancy, audit and investigative support services are charged at ₦100,000 per Consultant per day,
                regardless of time spent, up to a maximum of 8 hours per day. This fee covers our Consultant&#39;s time
                and expertise spent on the assignment. It excludes reimbursable expenses for logistics, travel, hotel
                accommodation and other incidentals outside Abuja; report production, stationeries, and photocopying
                which are charged separately.<br /><br
              /></span>
              <span className="text-wrapper-2">5.1.2</span>
              <span className="span">
                User Administration requests are charged at ₦10,000 per amendment request.<br /><br
              /></span>
              <span className="text-wrapper-2">5.1.3</span>
              <span className="span">
                Customization and/or integration charges are specified per project, and will be determined at the time
                of scoping the work, as the scope of work involved may vary.<br /><br
              /></span>
              <span className="text-wrapper-2">5.1.4</span>
              <span className="span">
                The fees for our Training services are published alongside our Training schedules on our website.
                Payment for all our extended services shall be upfront and paid in full</span
              >
            </p>
          </div>
          <div className="div-4">
            <p className="text-wrapper-3">5.2 Fees Exclusive of Taxes</p>
            <p className="div-5">
              All fees and charges quoted in this Agreement exclude any applicable taxes, which will be chargeable at
              the prevailing rate. You will be responsible for the payment of any taxes imposed by any governmental
              taxing authority on the amounts you are liable to pay to us under this Agreement, including, but not
              limited to, withholding taxes of whatever nature. If any deductions or withholdings are required by law to
              be made from the fees payable to us, you agree to promptly pay such Withholding Taxes and obtain and
              deliver to us proof of payment of such Withholding Taxes together with official evidence thereof issued by
              the governmental authority concerned, sufficient to enable us support a claim for a tax credit in respect
              of any sum so withheld. If we are unable to obtain such tax credit due to your failure to comply with the
              above provision, then you agree to pay to us a sum equal to the amount of the tax credit we are not able
              to claim as a result of your failure.
            </p>
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">5.3 Change of Fees</div>
            <p className="div-5">
              VPSP reserves the right to change the processing fees as well as the fees for extended support services.
              Notices of such changes shall be communicated in line with the provisions of Clause 12.1 of this
              Agreement.
            </p>
          </div>
          <div className="div-4">
            <p className="text-wrapper-3">5.4 Chargebacks, Refunds and Reversals</p>
            <p className="p">
              5.4.1 As a registered biller on VoxePay, we may apply chargebacks against your collection accounts for:<br /><br />5.4.1.1
              disputed payments reported by a Payer to which satisfactory evidence of goods and service delivery could
              not be provided by you,<br />5.4.1.2 transactions that we suspect to be unlawful;<br />5.4.1.3
              transactions that are prohibited under this Agreement;<br />5.4.1.4 transactions that do not comply with
              card network/scheme rules or the terms of this Agreement or<br />5.4.1.5 any reversals for any reason by
              the card network/scheme, our processor, or the participating banks.<br />5.4.2 Where a chargeback occurs,
              you shall become immediately liable for all claims, fines, liabilities and expenses we incur arising out
              of that chargeback.<br />5.4.3 As a beneficiary of any form of payment on VoxePay, if it is established
              that your account was erroneously credited with a value (“Wrongful Payment”), appropriate reversals will
              be triggered against such account into which the Wrongful Payment was credited, till the entire amount is
              fully reversed. Wrongful Payments include but are not limited to (a) unfunded payments, (b) multiple
              payments for the same set of transactions and (c) fraudulently processed payments.<br />5.4.4 We do not
              guarantee or assume any liability for transactions authorised and completed that are later reversed or
              charged back. You are solely responsible for all reversed or charged back transactions, regardless of the
              reason, basis, or duration of the reversal or chargeback.<br />5.4.5 You hereby grant us the unconditional
              right to deduct any amount, fee, fine or penalty due to be paid by you as a result of any reversals,
              chargebacks, or violation of card scheme rules. You agree that we shall debit your collection account or
              any account linked to your BVN to enable us recover any fee, fine or penalty, and/or set-off the
              applicable amounts against future settlements due to you.<br />5.4.6 We shall not be involved in any
              repayment claim from you, after a chargeback claim has been successfully settled in favour of a customer.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">6.0 Use Policy</div>
        <div className="div-3">
          <div className="div-4">
            <div className="text-wrapper-3">6.1 Acceptable Use Policy</div>
            <p className="div-5">
              The following activities constitute a violation of the Acceptable use of VoxePay<br /><br />Using the
              VoxePay service to make or receive payments for any illegal, fraudulent, immoral or otherwise socially
              reprehensible purposes including but not limited to materials that infringe the intellectual property
              rights of third parties.<br />Using the VoxePay service to make or receive payments for any narcotics,
              other controlled substances, steroids or illicit drugs.<br />Using VoxePay to make or accept payments for
              goods or services where payment is not yet due and for which no invoice exists which can be presented to
              VPSP upon request.<br />Using VoxePay to make or accept payment or conduct activities in a manner that
              VPSP or any of its partners reasonably believe to be an abuse or violation of existing rules by
              regulators, Card scheme providers or the CBN.<br />Using VoxePay to make or accept payments for the
              following high risk activities without VPSP’ prior written approval;<br />Sell securities, business
              opportunities, franchise, multi-level marketing, or for the pre-order of goods;<br />payment for any
              sexually oriented or obscene materials or services;<br />Buy or sell cryptographic currencies;<br />Inbound
              and outbound international money transfer services;<br />payments for wagers, gambling debts or gambling
              winnings, regardless of the location or type of gambling activity;<br />Offer, or conduct any form of
              direct marketing activities (such as, but not limited to inbound and/or outbound telemarketing activities)
              and travel related services;<br />payments for any prescription and proprietary drugs and non-prescription
              (over the counter) medicines;<br />payments for the wholesale distribution of prescription drugs,
              proprietary drugs, vitamins, druggists’ sundries and toiletries, antiseptics, bandages, pharmaceuticals,
              and biological or related products;<br />Receive payments in exchange for personal property left with you
              , as a merchant, as security (in other words, any service associated with a pawn shop);<br />Receive
              payments for high-value jewelry, precious stones, gems, gold, platinum, silver or minerals; and/or <br />Receive
              payment for tobacco or liquor.
            </p>
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">6.2 Restricted Activities</div>
            <p className="div-5">
              You agree that your information and your activities (including your payments and receipt of payments)
              through our Service shall not:<br /><br />Be false, inaccurate or misleading;<br />Be fraudulent or
              involve the sale of counterfeit or stolen items;<br />Be related in any way to terrorism and/or criminal
              activities, including but not limited to payment or the acceptance of payments for unauthorised firearms
              or weapons;<br />Infringe on any third party&#39;s copyright, patent, trademark, trade secret or other
              property rights or rights of publicity or privacy;<br />Violate any law, statute, ordinance, contract or
              regulation (including, but not limited to, those governing financial services, consumer protection, unfair
              competition, antidiscrimination, or false advertising);<br />Be defamatory, libelous, unlawfully
              threatening or unlawfully harassing;<br />Be obscene or contain child pornography;<br />Contain any
              viruses, Trojan horses, worms, time bombs, cancel bots, easter eggs or other computer programming routines
              that may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data
              or other personal information; or<br />Create liability for us or cause us to lose (in whole or in part)
              the services of our ISP&#39;s or other suppliers. If you use, or attempt to use the Service for purposes
              other than sending and receiving payments, managing your account and the services listed in clause 1.0
              above, including but not limited to tampering with, hacking, modifying or otherwise corrupting the
              security or functionality of the Service, your account will be terminated and you will be subject to
              claims for damages and other lawful penalties, including criminal prosecution where applicable.
            </p>
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">6.3 Access and Interference</div>
            <p className="div-5">
              You agree that you will not use any robot, spider, other automatic device, or manual process to monitor or
              copy our web pages or the content contained herein without our prior express written permission. You agree
              that you will not use any device, software or routine to attempt to interfere with the proper working of
              the VoxePay site or any activities conducted on our site. You agree that you will not take any action that
              imposes an unreasonable or disproportionately large load on our infrastructure. Most of the information on
              our site is proprietary or is licensed to us. You agree that you will not copy, reproduce, alter, modify,
              create derivative works, publicly display or frame any content from our website without our prior express
              written permission.
            </p>
          </div>
          <div className="div-4">
            <p className="text-wrapper-3">6.4 Our Remedies and Right to terminate or restrict your activities.</p>
            <p className="div-5">
              Without limiting other remedies available to us, we may verify inaccurate or incorrect information you
              provide to us, contact you by means other than by electronic means, immediately warn our community of your
              actions, limit access to an account and any or all of the account&#39;s functions (including but not
              limited to the ability to send money or make payments), limit activities, indefinitely suspend or close
              your account, terminate this Agreement and refuse to provide our Services to you if:<br /><br />You breach
              this Agreement or the documents it incorporates by reference;<br />We are unable to verify or authenticate
              any information you provide to us;<br />We believe that your account or activities pose a significant
              fraud risk to us;<br />We believe that your actions may cause financial loss or legal liability for you,
              our users or us;<br />We believe that any applicable rule stipulated by the Payment schemes may affect
              your use of the platform.<br />Your use of VoxePay is deemed by us to constitute abuse of the electronic
              payment system or electronic payment rules, including (without limitation), using the VoxePay system to
              test VoxePay card behaviours.<br />For the avoidance of doubt, if a fraudulent activity is associated with
              the operation of your VoxePay profile, you agree that we have the right to apply restrictions to the
              profile and report to appropriate law enforcement agencies.
            </p>
          </div>
          <div className="div-4">
            <p className="text-wrapper-3">6.5 Suspension of Activated Mandates.</p>
            <p className="div-5">
              <span className="text-wrapper-2">6.5.1</span>
              <span className="span">
                Without recourse to you, we may suspend any activated mandate, debit or credit instruction initiated by
                you if;<br /><br
              /></span>
              <span className="text-wrapper-2">6.5.1.1</span>
              <span className="span">
                We consider such suspension would be in the interest of any of the stakeholders on the platform; or<br
              /></span>
              <span className="text-wrapper-2">6.5.1.2</span>
              <span className="span">
                There are reasonable grounds to suspect a compromise of security or any unauthorized activity on your
                registered bank account or profile on the Platform.<br
              /></span>
              <span className="text-wrapper-2">6.5.2</span>
              <span className="span">
                Further to clause 6.5.1, you understand that such a suspension may impact your ability to initiate any
                transaction, activate another debit against a suspended mandate or complete any duly authorized
                transaction for the duration of such suspension. In such an instance, we shall not be liable to you for
                any reason whatsoever.</span
              >
            </p>
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">6.6 Privacy and Security</div>
            <p className="div-5">
              1. We view the protection of users&#39; privacy as a very important principle. We understand clearly that
              you and your Information are one of our most important assets. We store and process your Information on
              computers that are protected by physical as well as technological security devices. We do not give your
              personal information to third parties for marketing purposes without your consent. You may object to your
              information being used in this way and thereby opt out from using our Services. Please ensure that you
              read our privacy policy available on www.voxePay.com. By consenting to this Agreement, you also consent to
              our privacy policy.<br /><br />2. You hereby grant us the right to conduct any investigation in any manner
              we deem fit, on your background and operations, including but not limited to credit background checks,
              banking relationships and financial history. In addition, you hereby give us your consent to disclose the
              details of any investigation conducted pursuant to this clause, to Payment scheme, processors, Bank
              partners and/or regulators, without recourse to you.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <p className="text-wrapper">7.0 Warranty and Service Disruption</p>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              We warrant that the service will in all material respects, deliver on the agreed terms herein. Should the
              service be disrupted to such an extent that there is likely to be an adverse effect to the service
              provided, we will endeavor to notify you of such within a reasonable time. In the event of any service
              delay or failure, we shall take necessary steps to ensure speedy service restoration and reduce to the
              barest minimum the extent of such service failures. However we shall not be liable to you for any loss or
              damage.<br />As a Biller, You warrant that you are not a payment service provider and you do not intend to
              provide any form of aggregation services.<br />You warrant that you have never had an agreement with a
              Payment scheme which was terminated upon request and/or demand by the Payment scheme or any regulatory
              authority.<br />You shall, upon written request by us, provide us with any information we request of you,
              including your company registration documents, and you hereby give us your consent to disclose any
              information obtained pursuant to this clause, to any government regulator or payment scheme provider.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">8.0 Confidentiality</div>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              By the nature of this Agreement, the parties may have access to information that is confidential to one
              another, such confidential information shall include the parties&#39; business methods, salary structure,
              marketing strategies, pricing, competitor information, and all other information designated as
              confidential by either party. Each party agrees to maintain the confidentiality of such information and to
              protect the other party&#39;s confidential information by using all reasonable efforts to prevent any
              unauthorized copying, use, distribution, installation or transfer of possession of such information.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <p className="text-wrapper">9.0 Trademarks and other Intellectual Proprietary rights</p>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              VoxePay, VoxePay.net and all logos, products, services or other content on www.VoxePay.net or other mobile
              download platforms are the intellectual property of VoxePay by Simplify International Services Limited, or
              its licensors, and may not be copied, imitated or used, in whole or in part, without the prior written
              permission of VPSP. In addition, all page headers, custom graphics, button icons, and scripts are service
              marks, trademarks, and/or trade dress of VoxePay and may not be copied, imitated, or used, in whole or in
              part, without the prior written permission of VPSP.<br /><br />Neither this Agreement, nor your use of
              VoxePay will convey title or any interest or rights in VPSP&#39; intellectual property rights.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">10.0 Assignability</div>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              You may not transfer any rights or obligations you may have under this Agreement without our prior written
              consent. We reserve the right to transfer this Agreement or any of our rights and/or obligations under
              this Agreement. You may be able to continue to use the platform after such an assignment, if you consent
              to it.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">11.0 Legal Compliance</div>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              You shall comply with all applicable Nigerian laws, and regulations, regarding your use of our
              Services.<br /><br />If any specific term or condition violates the law, that term alone shall stand
              severed or amended as far as is necessary to comply with the law.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">12.0 Notices and Amendment</div>
        <div className="div-3">
          <div className="div-4">
            <div className="text-wrapper-3">12.1 Notices</div>
            <p className="div-5">
              You agree that these Terms and Conditions constitute &#34;an Agreement duly signed or executed by
              &#34;you&#34; under any applicable law or regulation. To the fullest extent permitted by applicable law,
              this Agreement and any other agreements, notices or other documents regarding your account and/or your use
              of the Service, may be provided to you electronically and you agree to receive all Notices from VPSP in
              electronic form.<br /><br />You may print a copy of any Notice and retain it for your records. All Notices
              in either electronic or paper format will be considered to be in &#34;writing and to have been received
              and shall become effective thirty (30) days after being posted or placed on our website.
            </p>
          </div>
        </div>
        <div className="div-3">
          <div className="div-4">
            <div className="text-wrapper-3">12.2 Notice Procedure</div>
            <p className="div-5">
              Notices from you to VPSP shall be by email from you to support@VoxePay.net.<br />Notices by us to you may
              be placed on our website, VoxePay.net or sent to your email address registered with us.<br />A notice by
              email shall be deemed received by the other party, once the email is sent, unless the sending party is
              aware that the email was not received.<br />Notice posted to our website shall be deemed received upon
              your visit to our site or your first log-in subsequent to the posting
            </p>
          </div>
        </div>
        <div className="div-3">
          <div className="div-4">
            <div className="text-wrapper-3">12.3 Amendment</div>
            <p className="div-5">
              We may amend this Agreement at any time by notice to you or posting the amended terms on VoxePay.net. All
              amended terms shall be effective thirty (30) days (or any other date as we may determine) after publishing
              it on www.VoxePay.net.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">13.0 Dispute Resolution</div>
        <div className="div-3">
          <div className="div-4">
            <p className="text-wrapper-3">13.1 Negotiated Settlement or Mediation</p>
            <p className="div-5">
              In the event of a dispute arising between you and VPSP, our goal is to provide you with a neutral and cost
              effective means of resolving the dispute quickly. In the event of any dispute, the Parties shall seek to
              resolve any such dispute amicably between themselves or through a negotiated settlement and in the event
              of their inability to resolve the dispute as aforesaid, the parties shall explore a mediated settlement
              with both Parties appointing one(1) Mediator who shall act as a catalyst for resolution.
            </p>
          </div>
        </div>
        <div className="div-3">
          <div className="div-4">
            <div className="text-wrapper-3">13.2 Arbitration</div>
            <p className="div-5">
              If at any time the Parties are unable to amicably resolve any dispute(s) through negotiated settlement or
              mediation, either party shall refer the matter to be finally settled by arbitration in accordance with the
              Arbitration &amp; Conciliation Act, Cap A18, Laws of the Federation of Nigeria (LFN) 2004. The arbitration
              shall take place in Lagos, Nigeria and be conducted in English Language. . If the parties fail to agree on
              the Arbitrator, the Arbitrator shall be appointed by the President of the Chartered Institute of
              Arbitrators UK (Nigeria Branch). Each Party will bear its costs save for joint costs which will be borne
              jointly.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">14.0 Extended Non-use</div>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              Your non-use of our platform for an extended period does not terminate this contract. Any monies due and
              payable by you to us before, during or after the period of your passivity shall remain payable and become
              due immediately upon your reactivation. Termination or non-use shall not relieve the continuing
              obligations under this Agreement, including but not limited to the requirements in Clauses (3.5)
              Indemnification, (6.3) Access and Interference, and (9) Trademarks and other intellectual Proprietary
              Rights of this Agreement.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">15.0 General</div>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              You represent that you have the capacity to enter into this Agreement.<br />That you are an adult,
              eligible to operate banking services and that you are not under any contractual inhibition known to your
              national or international law.<br />If any provision of this Agreement is held to be invalid or
              unenforceable, such provision shall be struck out and the remaining provisions shall be enforceable.<br />You
              agree that this Agreement and all incorporated agreements may be automatically assigned by VoxePay to a
              third party in the event of a merger or acquisition.<br />Headings are for reference purposes only and in
              no way define, limit, construe or describe the scope or extent of such section(s).<br />Our failure to act
              with respect to a breach by you or others does not amount to a waiver of our right to act with respect to
              subsequent or similar breaches.<br />The non-specification of a particular legal or equitable remedy shall
              not be construed as a waiver, prohibition or limitation of any legal or equitable remedies in the event of
              a breach of any of the clauses.<br />Each of the Parties acknowledge that it is acting as an independent
              contractor, and each Party has the sole right and obligation to supervise, manage, direct, procure,
              perform, or cause to be performed, all work or other obligations to be performed by such Party under this
              agreement.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">16.0 Entire Agreement</div>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              This agreement and any documents referred to herein constitute the entire agreement between the parties
              and supersede any and all prior agreements between the parties, whether oral or written, with respect to
              the subject matter thereof.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">17.0 Applicable Law</div>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              This Agreement shall be governed by and interpreted according to the laws of the Federal Republic of
              Nigeria and shall be subject to the exclusive jurisdiction of Nigerian courts.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">18.0 Improvement and Changes</div>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              These VoxePay functions are subject to continuous technological improvement and consequently may change.
              All changes to VoxePay functions and services shall be published by VPSP from time to time on the website
              www.VoxePay.net All such published changes shall form part of this Agreement.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">19.0 Force Majeure</div>
        <div className="div-3">
          <div className="div-4">
            <p className="div">
              VPSP shall not be in breach of its obligations under this Agreement or be responsible for any delay in
              carrying out its obligations if performance is prevented or delayed wholly or in part as a consequence of
              force majeure. Force majeure means any circumstance beyond the reasonable control of VPSP including but
              not limited to acts of war, state or national emergency, strike, rebellion, insurrection, government
              sanctions, actions of regulatory or supervisory authorities, accident, power failure, internet and
              communication link failure, fire, earthquake, flood, storm, tornadoes, hurricane, epidemic or pandemic,
              collapse of buildings, fire, explosion, events of force majeure declared by VPSP&#39; partners or service
              providers involved in the performance of VPSP&#39; obligations in this Agreement or any other act of God
              or any technical failure caused by devices, matters or materials.<br /><br /><br /><br />Contact Us<br />VoxePay
              by Simplified International Synergy Limited, licensed by the Central Bank of Nigeria.<br />- Head Office:
              18, A-Close, 14 Road, Gwarimpa, FCT, Abuja<br />- Email: info@voxepay.com
            </p>
          </div>
        </div>
      </div>
    </div>
            </div>




      <Footer />
    </div>
  );
};

export { TermsCondition };
