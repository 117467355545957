import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
// import { Button, Form, Input } from "antd";

interface BusinessContactsProps {
  onNext: () => void;
}

const Schema = Yup.object().shape({
  businessEmail: Yup.string().required('Business Email Address is required'),
  supportEmail: Yup.string().required('Support Email Address is required'),
  disputeEmail: Yup.string().required('Dispute Email Address is required'),
  businessPhoneNumber: Yup.string().required('Business Phone Number is required'),
  website: Yup.string().required('Website is required'),
  country: Yup.string().required('State/Region is required'),
  stateRegion: Yup.string().required('State/Region is required'),
  city: Yup.string().required('City is required'),
  streetAddress: Yup.string().required('Business Name is required'),
});

const initialValues = {
  businessEmail: '',
  supportEmail: '',
  disputeEmail: '',
  businessPhoneNumber: '',
  website: '',
  country: '',
  stateRegion: '',
  city: '',
  streetAddress: '',
};

const BusinessContacts: React.FC<BusinessContactsProps> = ({ onNext }) => {

  // const [isButtonClickable, setIsButtonClickable] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      console.log(values);
    
    },
  });

  // const onFinish = (values) => {
  //   console.log('Submitted values:', values);
  // };

  return (
    <div className={'businessSetup'}>
      <div className={'profileInformation'}>
        Profile Information
      </div>

      <div className={'businessDetails'}>
                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Business email
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="email"
                      placeholder="Business email"
                      className={'no-outline text-dark fs-8'}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.businessEmail &&
                        formik.errors.businessEmail &&
                        formik.errors.businessEmail}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Support email
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="email"
                      placeholder="Support email"
                      className={'no-outline text-dark fs-8'}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.supportEmail &&
                        formik.errors.supportEmail &&
                        formik.errors.supportEmail}
                    </div>
                  </div>
                </div>
      </div>

      <div className={'businessDetails'}>
      <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Dispute email
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="email"
                      placeholder="Dispute email"
                      className={'no-outline text-dark fs-8'}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.disputeEmail &&
                        formik.errors.disputeEmail &&
                        formik.errors.disputeEmail}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Business phone number
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Business phone number"
                      className={'no-outline text-dark fs-8'}                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.businessPhoneNumber &&
                        formik.errors.businessPhoneNumber &&
                        formik.errors.businessPhoneNumber}
                    </div>
                  </div>
                </div>
      </div>

      <div className={'businessDetails'}>
      <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Website
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Website"
                      className={'no-outline text-dark fs-8'}                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.website &&
                        formik.errors.website &&
                        formik.errors.website}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className=" fw-bold fs-9">
                      Facebook
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Facebook"
                      className={'no-outline text-dark fs-8'}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    
                  </div>
                </div>
      </div>

      <div className={'businessDetails'}>
                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className=" fw-bold fs-9">
                        X (Twitter)
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="X (Twitter)"
                      className={'no-outline text-dark fs-8'}                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                  
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className=" fw-bold fs-9">
                      Instagram
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Instagram"
                      className={'no-outline text-dark fs-8'}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                  
                  </div>
                </div>
      </div>

    <div className={'profileInformation'}>
    Business address
    </div>

      <div className={'businessDetails'}>
          <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Country
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <select
                      className={`no-outline text-dark fs-8`}
                    >
                      <option value="" disabled selected>- Select country-</option>
                      <option value="registeredBusiness">Nigeria</option>
                      <option value="starterBusiness">Niger</option>
                    </select>
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.country &&
                        formik.errors.country &&
                        formik.errors.country}
                    </div>
                  </div>
          </div>

          <div className="mt-1">
            <div className="col-form-label py-0 input-height">
                <label className="required fw-bold fs-9">
                State/Region
                </label>
            </div>
            <div className={`form-control form-control-solid`}>
              <input
                type="text"
                placeholder="State/Region"
                className={'no-outline text-dark fs-8'}                    />
            </div>
            <div className="fv-plugins-message-container input-height">
              <div className="fv-help-block fs-9 text-danger">
                {formik.touched.stateRegion &&
                  formik.errors.stateRegion &&
                  formik.errors.stateRegion}
              </div>
            </div>
          </div>
      </div>

      <div className={'businessDetails'}>
          <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      City
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="City"
                      className={'no-outline text-dark fs-8'}                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.city &&
                        formik.errors.city &&
                        formik.errors.city}
                    </div>
                  </div>
          </div>

          <div className="mt-1">
            
          </div>
      </div>

      <div className={''}>
                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                        Street address
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Street address"
                      className={'no-outline text-dark fs-8'}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.streetAddress &&
                        formik.errors.streetAddress &&
                        formik.errors.streetAddress}
                    </div>
                  </div>
                </div>

                
    </div>

    <div className={'businessDetails'}>
          <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className=" fw-bold fs-9">
                      Building name
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Building name"
                      className={'no-outline text-dark fs-8'}                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    
                  </div>
          </div>

          <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className=" fw-bold fs-9">
                      Floor/Unit number
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Floor/Unit number"
                      className={'no-outline text-dark fs-8'}                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    
                  </div>
          </div>
      </div>

      <div className={'businessProfileNextButton'}>
          <button 
          onClick={onNext}
          // disabled={!isButtonClickable}
          // style={{
          //   background: isButtonClickable ? '#0866FF' : '#E4E4E8',
          //   color: isButtonClickable ? 'white' : 'black', // You can adjust text color as needed
          // }}
          >
            Next</button>
        </div>
      </div>
  );
};

export { BusinessContacts };
