import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../KycRequirement.scss";
import { RegisteredBusinessKycStepOne } from "./Step1";
import { RegisteredBusinessKycStepTwo } from "./Step2";

export const RegisteredBusinessKyc = () => {
  const [currentStage, setCurrentStage] = useState(1);

  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentStage]);

  return (
    <>
      <div className={"onboarding"}>
        <div className={"kycForm"}>
          <h5 className="fs-5 text-center">Organization Information</h5>
          <p className="fs-base text-center text-gray-500">
            Please provide the following information for us to know more about
            your organization
          </p>

          <div className="d-flex align-items-center justify-content-center my-10">
            <span
              className={`fw-bold fs-9 ${
                currentStage === 1 ? "touched-kyc-step" : "unTouched-kyc-step"
              }`}
            >
              1
            </span>

            {currentStage === 1 && (
              <span className="mx-2 fs-8 text-primary">
                Registration, Tax, and Proof of Address
              </span>
            )}

            <span className={`mx-2 kyc-line`} />

            <span
              className={`fw-bold fs-9 ${
                currentStage === 2 ? "touched-kyc-step" : "unTouched-kyc-step"
              }`}
            >
              2
            </span>

            {currentStage === 2 && (
              <span className="mx-2 fs-8 text-primary">
                Signed Resolution & Signers Information
              </span>
            )}

            <span className={`mx-1 kyc-line`} />

            <span
              className={`fw-bold fs-9 ${
                currentStage === 3 ? "touched-kyc-step" : "unTouched-kyc-step"
              }`}
            >
              3
            </span>
          </div>

          {currentStage === 1 && (
            <RegisteredBusinessKycStepOne
              isFormSubmitted={() => setCurrentStage(currentStage + 1)}
            />
          )}

          {currentStage === 2 && (
            <RegisteredBusinessKycStepTwo
              isFormSubmitted={() => navigate("/admin")}
              setCurrentStage={setCurrentStage}
              currentStage={currentStage}
            />
          )}
        </div>
      </div>
    </>
  );
};
