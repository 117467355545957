export const BackSvg = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5303 3.96967C11.2374 3.67678 10.7626 3.67678 10.4697 3.96967L2.96967 11.4697C2.82902 11.6103 2.75 11.8011 2.75 12C2.75 12.1989 2.82902 12.3897 2.96967 12.5303L10.4697 20.0303C10.7626 20.3232 11.2374 20.3232 11.5303 20.0303C11.8232 19.7374 11.8232 19.2626 11.5303 18.9697L5.31066 12.75H21.5C21.9142 12.75 22.25 12.4142 22.25 12C22.25 11.5858 21.9142 11.25 21.5 11.25H5.31066L11.5303 5.03033C11.8232 4.73744 11.8232 4.26256 11.5303 3.96967Z"
        fill="#0866FF"
      />
    </svg>
  );
};
