import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import voxepay from '../../../core/assets/img/voxepay.png'
import { useQuery, useMutation } from "react-query";
import { useJwt } from "react-jwt";
import {
    OAGFLogoPng,
    back,
    envelope,
    lockSimple,
    loginImg,
    NewOAGFLogoLogin,
    simplipayillustration3,
} from "../../../core/assets/imgs";
import { useRequestSignin, useUser } from "./hooks/useUser";
import { useAuth } from "./useAuth";

const Schema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
});

const initialValues = {
    username: "",
    password: "",
    rememberMe: "",
};

export const hasRoleOAGFAdmin = (roles) => {
    return roles?.some((role) => role?.id === "ROLE_OAGF_ADMIN");
};

const Login: React.FC = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    // const [data, setData] = useState<any>(initialValues);

    const auth = useAuth();
    const { user, updateUser } = useUser();

    const { data, error, mutate, isLoading } = useRequestSignin();

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            mutate(values, {
                onSuccess(res) {
                    // console.log(res.data.organizationUser.authorities);

                    const isAdmin = hasRoleOAGFAdmin(
                        res.data.organizationUser.authorities
                    );

                    updateUser(res.data);



                    localStorage.setItem("token", res.data.idToken);

                    localStorage.setItem("orgId", res.data.id);
                    localStorage.setItem("login", res.data.organizationUser.login);


                    if (res.data.organizationUser.onboardingStage === 0 || res.data.organizationUser.onboardingStage === 2 || res.data.organizationUser.onboardingStage === 3) {
                        
                    if (isAdmin) {
                        navigate("/admin/oagf-overview", { replace: true });
                    } else {
                        navigate("/admin", { replace: true });
                    }
                    } else {
                       navigate("/auth/register", { 
  replace: true, 
  state: { stage:  res.data.organizationUser.onboardingStage } 
});

                    }




                },
            });

            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        },
    });

    const done = () => {
        navigate("/mda-home");
    };


     const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <main className={'loginPage'}>
            <div className={'loginPageLeftDiv'}>
                <div>
                    <img src={voxepay} alt={'voxepay'} width={'140'}/>

                    <div>Unlock Seamless Transactions: Your Gateway to Effortless Payments!.</div>

                    <div className={'loginImgDiv'}>
                        <img src={simplipayillustration3} alt={'loginImg'} width={'600'}/>
                    </div>
                </div>
            </div>

            <div className={'loginPageRightDiv'}>
                <div className={'simplypayHome'}>
                    <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5 12L4.5 12M4.5 12L11.25 18.75M4.5 12L11.25 5.25" stroke="#3BAEFE"/>
                            </svg>
                    </span>

                    <Link to={'/'}>voxepay home</Link>
                </div>

                <div className={'loginForm'}>
                    <form
                        onSubmit={formik.handleSubmit}
                        noValidate
                        className="form align-middle  gx-0 gy-5 mt-5"
                    >
                        {/*<span className="login_text login-text">Login</span>*/}
                        <div className="mt-5">
                            <div className="row mt-1">
                                <div className="col-lg-4 col-form-label py-0 input-height">
                                    {formik.values.username.length !== 0 && (
                                        <label className="required fw-bold fs-9">
                                            Username
                                        </label>
                                    )}
                                </div>

                                <div
                                    className={`form-control form-control-solid !formik.errors.username ? "border-success" : ""`}
                                >
                                    <img
                                        src={envelope}
                                        alt="username"
                                        className="icon-style"
                                    />

                                    <input
                                        type="text"
                                        placeholder="Username*"
                                        className={"no-outline ps-4 text-dark fs-8"}
                                        {...formik.getFieldProps("username")}
                                    />
                                </div>

                                <div className="fv-plugins-message-container input-height">
                                    <div className="fv-help-block fs-9 text-danger">
                                        {formik.touched.username &&
                                            formik.errors.username &&
                                            formik.errors.username}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-4 col-form-label py-0 input-height">
                                    {formik.values.password.length !== 0 && (
                                        <label className="required fw-bold fs-9">
                                            Password
                                        </label>
                                    )}
                                </div>

                                <div
                                    className={`form-control form-control-solid !formik.errors.username ? "border-success" : ""`}
                                >
                                    <img
                                        src={lockSimple}
                                        alt="password"
                                        className="icon-style"
                                    />

                                    <input
                                       type={showPassword ? "text" : "password"}
                                        placeholder="Password * "
                                        className={"no-outline ps-4 text-dark fs-8"}
                                        {...formik.getFieldProps("password")}
                                    />
                                   
              <i
                className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                onClick={togglePasswordVisibility}
                style={{
                  marginLeft: "-30px",
                  cursor: "pointer",
                  color: "GrayText",
                }}
              ></i>

                                </div>

                                <div className="fv-plugins-message-container input-height">
                                    <div className="fv-help-block fs-9 text-danger">
                                        {formik.touched.password &&
                                            formik.errors.password &&
                                            formik.errors.password}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="login-rem-grid">
                                    <div>
                                        <input
                                            type="checkbox"
                                            placeholder="rememberMe*"
                                            className=""
                                            {...formik.getFieldProps("rememberMe")}
                                        />

                                        <span
                                            className="login-remeber-text"
                                            style={{ marginLeft: "5px" }}
                                        >
                            Remember Me
                          </span>
                                    </div>

                                    <Link to="/auth/forgot-password">
                                        {" "}
                                        <span className="login-forgot-password-text">
                            Forgot Password?
                          </span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="login-text mt-5">
                            <div className="">
                                <button
                                    id="kt_password_cancel "
                                    type="submit"
                                    className={`btn w-100 ${
                                        !(formik.isValid && !isLoading && formik.dirty)
                                            ? "btn-secondary"
                                            : "btn-success"
                                    } text-white px-7 py-2`}
                                    disabled={
                                        !(formik.isValid && !isLoading && formik.dirty)
                                    }
                                >
                                    {!isLoading && (
                                        <span className="indicator-label">Login</span>
                                    )}
                                    {isLoading && (
                                        <span
                                            className="indicator-progress"
                                            style={{ display: "block" }}
                                        >
                            Please wait...{" "}
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                                    )}
                                </button>
                            </div>
                        </div>

                  {/*      <span className="login-text mt-5  login-remeber-text">*/}
                  {/*  Don’t have an account?{" "}*/}
                  {/*          <Link to="/auth/register">*/}
                  {/*    <span style={{ color: "#2075FF" }}>Register</span>*/}
                  {/*  </Link>*/}
                  {/*</span>*/}
                    </form>

                    <div className={'createAcctDiv'}>
                        Don’t have an account? <Link to={'/auth/register'}>Create Account</Link>
                    </div>

                </div>
            </div>
        </main>
    );
};

export { Login };
