import { Button, Form, Select } from "antd";
import { useOrganizationKYC } from "app/pages/auth/hooks/NewSimplyPay/onboarding";
import React, { useEffect } from "react";
import { FileUpload } from "../component/FileUpload";
import { ForwardSvg } from "../component/ForwardSvg";
import { InfoTooltip } from "../component/InfoTooltip";
import { LoaderContainer } from "core/helpers/Loading";
import { KycSubmitButton } from "../component/CustomButton";

interface LLCKycStepOneProps {
  isFormSubmitted: () => void;
  setCurrentStage: (e: number) => void;
  setStepOneData: (e: any) => void;
  currentStage: number;
  stepOneData: any
}
export const LLCKycStepOne: React.FC<LLCKycStepOneProps> = ({
  isFormSubmitted,
  setCurrentStage,
  currentStage,
  setStepOneData,
  stepOneData
}) => {
  const { mutate, isLoading } = useOrganizationKYC();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Success:", values);

    const orgId = localStorage.getItem("orgId");

    const payload: any = {
      serialVersionUID: 0,
      id: "",
      code: "",
      description: "",
      fileName: "",
      organizationId: orgId,
      businessRepresentatives: [
        {
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: values.dateOfBirth,
          country: values.nationality,
          businessRole: "OWNER",
          percentageShare: 0,
          idDocument: values.identificationDocument,
          idDocumentNumber: values.documentNumber,
          idDocumentPath: "",
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values.city,
          state: values.stateRegion,
          documentType: values.documentType,
          documentPath: "",
          documents: [""],
        },
      ],
      documents: [""],
    };

    mutate(payload, {
      onSuccess: (res: any) => {
        isFormSubmitted();
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }

    console.log(e);

    return e?.fileList;
  };

  return (
    <>
      {isLoading && <LoaderContainer />}
      <div className={"kycFormRow2"}>
        <Form
          name="organizationInformation"
          initialValues={{ ...stepOneData}}
          layout="vertical"
          onFinish={onFinish}
          //onFinish={() => isFormSubmitted()}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
          defaultValue={stepOneData}
          
        >
          <div
            style={{ fontWeight: 500, fontSize: "16px", marginBlock: "10px" }}
          >
            Organization Registration Documents
            <p className="fs-8 text-gray-500 my-2">
              <InfoTooltip
                text={"Accepted document formats are jpg, png, or pdf"}
              />
              Accepted document formats are jpg, png, or pdf
            </p>
          </div>

          <div className={"form-group"}>
            <div>
              <label className="required fw-bold fs-base text-dark required my-1">
                Certificate of Incorporation
              </label>
              <Form.Item
                name="certificate_Of_Incorporation"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Certificate of Incorporation is required",
                  },
                ]}
              >
                <FileUpload
                  setFileLink={(e: string) =>
                    form.setFieldValue("certificate_Of_Incorporation", e)
                  }
                  accepted=".pdf,.jpg,.png"
                />
              </Form.Item>
            </div>

            <div>
              <label className="required fw-bold fs-base text-dark required my-1">
                Memorandum & Articles of Association
              </label>
              <Form.Item
                name="memorandum"
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Memorandum & Articles of Association is required",
                  },
                ]}
              >
                <FileUpload
                  setFileLink={(e: string) =>
                    form.setFieldValue("memorandum", e)
                  }
                  accepted=".pdf,.jpg,.png"
                />
              </Form.Item>
            </div>

            <div>
              <label className="fw-bold fs-9 my-1">
                Operating License (if applicable)
              </label>
              <Form.Item
                name="operatingLicense"
                valuePropName="fileList"
                //getValueFromEvent={normFile}
              >
                <FileUpload
                  setFileLink={(e: string) =>
                    form.setFieldValue("operatingLicense", e)
                  }
                  accepted=".pdf,.jpg,.png"
                />
              </Form.Item>
            </div>

            <div>
              <label className="fw-bold fs-9 my-1">Year of Incorporation</label>
              <Form.Item
                name="year_Of_Incorporation"
                rules={[
                  {
                    required: true,
                    message: "Year of Incorporation is required",
                  },
                ]}
              >
                <Select
                  placeholder=""
                  size="large"
                  options={[
                    { label: "Pre 2017", value: "pre" },
                    { label: "Post 2017", value: "post_2017" },
                  ]}
                />
              </Form.Item>
            </div>
          </div>

          <div className="my-5">
            <div className="fw-bold">
              Tax Document
              <p className="fs-8 text-gray-500 my-2">
                <InfoTooltip
                  text={"Accepted document formats are jpg, png, or pdf"}
                />
                Accepted document formats are jpg, png, or pdf
              </p>
            </div>

            <div className={"form-group"}>
              <div>
                <label className="required fw-bold fs-base text-dark required my-1">
                  Certificate of Taxpayer Identification Number
                </label>
                <Form.Item
                  name="tin"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message:
                        "Certificate of Taxpayer Identification Number is required",
                    },
                  ]}
                >
                  <FileUpload
                    setFileLink={(e: string) => form.setFieldValue("tin", e)}
                    accepted=".pdf,.jpg,.png"
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div>
            <div className="fw-bold">Proof of Address</div>
            <div className="d-flex  align-items-center">
              <InfoTooltip text="Proof of address " />

              <span className="fs-8 text-gray-500">
                Proof of address document can be bank statement, tax bill valid
                for the current year, lease or tenancy agreement, utility bill
                (electricity bill, telephone bill, water bill, waste bill etc.)
                bearing company operating address. Document provided should not
                be older than 3 months and accepted formats are jpg, png, or pdf
              </span>
            </div>

            <div className={"form-group"}>
              <div>
                <label className="required fw-bold fs-base text-dark required my-1">
                  Document Type
                </label>
                <Form.Item
                  name="identificationDocument"
                  rules={[
                    {
                      required: true,
                      message: "Identification document is required",
                    },
                  ]}
                >
                  <Select
                    placeholder=""
                    size="large"
                    options={[
                      { label: "Bank Statement", value: "bankStatement" },
                      { label: "Tax Bill", value: "taxBill" },
                      {
                        label: "Lease or Tenancy Agreement",
                        value: "leaseOrTenancyAgreement",
                      },
                      { label: "Utility Bill", value: "utilityBill" },
                    ]}
                  />
                </Form.Item>
              </div>

              <div>
                <label className="required fw-bold fs-base text-dark required my-1">
                  Proof of Address Document
                </label>
                <Form.Item
                  name="proof_address"
                  valuePropName="fileList"
                  rules={[
                    {
                      required: true,
                      message: "Proof of Address Document is required",
                    },
                  ]}
                >
                  <FileUpload
                    setFileLink={(e: string) =>
                      form.setFieldValue("proof_address", e)
                    }
                    accepted=".pdf,.jpg,.png"
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className={"onboardingNextButton"}>
            <Form.Item>
              <KycSubmitButton
                form={form}
                onClick={() => {
                  setStepOneData(form.getFieldsValue());
                  setCurrentStage(currentStage + 1);
                }}
                submitProp={
                  <div className="d-flex gap-2 justify-content-center align-items-center">
                    Next
                    <ForwardSvg />
                  </div>
                }
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};
