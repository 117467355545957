import { Table, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FlapperSpinner } from "react-spinners-kit";
import "../../../../../App.css";
import { plusWhite, searchCollection } from "../../../../../core/assets/icons";
import { notePencil } from "../../../../../core/assets/imgs";
import { queryClient } from "../../../../react-query/queryClient";
import {
  toggleService,
  useRequestgetServices,
} from "../../../auth/hooks/useUser";
import { Layout } from "../../layout";
import { ErrorToast, SuccessToast } from "../../../../componets/toast";

const CollectionRules = () => {
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);

  const {
    data: createServiceResponse,
    mutate: serviceListMutate,
    isLoading,
  } = useRequestgetServices();
  const queryData = queryClient.getQueriesData("serviceList");

  const edit = (record: any) => {
    if (record.editable) {
      navigate(`/admin/setup-administration/edit-collection/${record.id}`);
    }
  };

  const [isCopied, setIsCopied] = useState("false");
  
  

  const handleCopy = async (record: any) => {

    
    
        const host = window.location.hostname;
    var text = ''
    
    console.log(host);
    
    
if (host==='localhost') {
  text = `http://localhost:3000/revenue/${record.id}/${createServiceResponse['0']?.orgId}`
  console.log("djkefjke", text);
  
}
    
    if (host === 'dev.voxepay.com') {
             text = `https://dev.voxepay.com/revenue/${record.id}/${createServiceResponse['0']?.orgId}`

    }


    
    if (host === 'voxepay.com') {
             text = `https://voxepay.com/revenue/${record.id}/${createServiceResponse['0']?.orgId}`
    }


    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(record.id);
      setTimeout(() => setIsCopied(""), 1500); // Reset copied state after 1.5 seconds
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const onChangeVisiblity = (record: any) => {
    setToggleLoading(true);
    toggleService(record?.id)
      .then(() => {
        serviceListMutate();
        SuccessToast(
          `Service visibility has been toggled ${
            !record?.mdaVisibe ? "on" : "off"
          }.`
        );
      })
      .catch((err: any) => {
        ErrorToast("");
      })
      .finally(() => {
        setToggleLoading(false);
      });
  };

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Active Status",
      dataIndex: "status",
      key: "status",
    },


    {
      title: "Action",
      dataIndex: "action",
      render: (_, record: any) => (
        <div className="service-share">
           <div
          className={`${record.editable ? "cursor-pointer" : ""}`}
             onClick={() => handleCopy(record)}
        >
         <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.188 11.4376V1.3125H3.06248M0.8125 3.56258H10.9379V13.6876H0.8125V3.56258Z" stroke="#01903C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

          <span
            className={`ms-2 fw-bold ${
              record.editable ? "text-dark" : "text-muted"
            }`}
          >
              {isCopied === record.id ? 'Copied!' : ' Copy Payment Link'}
            </span>
                      </div>

          
    
          
             <div
          className={`${record.editable ? "cursor-pointer" : ""}`}
          onClick={() => edit(record)}
        >
          <img src={notePencil} alt="Description" className="w-1r1 h-1r1" />
          <span
            className={`ms-2 fw-bold ${
              record.editable ? "text-dark" : "text-muted"
            }`}
          >
            Edit Service
          </span>
        </div>
        </div>
  

        
      ),
    },
  ];

  const filteredData = createServiceResponse?.filter(
    (item: any) =>
      item?.id?.toLowerCase().includes(search?.toLowerCase()) ||
      item?.serviceType?.description
        ?.toLowerCase()
        .includes(search?.toLowerCase())
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    serviceListMutate();
  }, [location]);

  const dataSource: any[] = [];
  for (let i = 0; i <= filteredData?.length; i++) {
    // console.log("ggg\n", filteredData[i]);

    var msgText = "";
    var editable = false;
    switch (filteredData[i]?.active) {
      case true:
        msgText = "Active";
        editable = true;
        break;
      case false:
        if (filteredData[i]?.status === "AWAITINGAPPROVAL") {
          msgText = "Awaiting Approval";
          editable = false;
        } else if (filteredData[i]?.status === "REJECTED") {
          msgText = "Rejected";
          editable = true;
        } else {
          msgText = "Inactive";
          editable = false;
        }
        break;
      default:
        msgText = "";
        editable = false;
        break;
    }

    if (filteredData[i]) {
      const datad = {
        key: i.toString(),
        sn: i + 1,
        id: filteredData[i].id,
        name: filteredData[i]?.description,
        active: filteredData[i]?.active,
        mdaVisibe: filteredData[i]?.visible,
        status: msgText,
        editable,
      };
      dataSource.push(datad);
    }
  }
  return (
    <>
      <>
        {isLoading || toggleLoading ? (
          <div className="loading-container">
            <FlapperSpinner /> {/* Replace with your loading indicator */}
          </div>
        ) : (
          <div className={"businessPreference bg-admin-body"}>
            <div className={"breadcrumb"}>
              <div>Setup & Administration</div>
              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                  fill="#6366F1"
                />
              </svg>
              <div>Collection Setup</div>
            </div>

            <div className="bg-white px-5 py-2">
              <div className="d-flex flex-column mt-5">
                <span className="fs-base fw-bolder text-dark lh-xl">
                  Collection Rules
                </span>
                <span className="fs-8 fw-bold text-muted lh-xxl">
                  Create a list of the Service and Rules
                </span>
                <hr className="my-1 text-muted" />
              </div>

              <div>
                <div className="d-flex flex-column align-items-end  mb-0">
                  <button
                    className="btn btn-success  mt-10 d-flex align-items-center justify-content-center  w-262e h-41 px-2 rounded-1 px-5"
                    onClick={() =>
                      navigate("/admin/setup-administration/create-collection")
                    }
                  >
                    <span className="fs-8 fw-bold">Create Service</span>
                    <img src={plusWhite} className="icon-style ms-5" />
                  </button>

                  {createServiceResponse?.length > 0 && (
                    <div className="form-control form-control-solid pe-1 collection-serach">
                      <input
                        type="text"
                        value={search}
                        placeholder="Search by ID, Name"
                        className={"no-outline text-dark fs-8 w-90"}
                        onChange={handleSearchChange}
                      />

                      <img
                        src={searchCollection}
                        alt="search"
                        className="icon-style"
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column mt-5 ">
                  <span className="fs-base fw-bolder text-dark lh-xxl">
                    List of Services
                  </span>
                  <hr className="my-1 text-muted" />
                </div>

                {createServiceResponse?.length === 0 ? (
                  <div className="d-flex justify-content-center py-15">
                    <span className="text-center">No Results</span>
                  </div>
                ) : (
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                    sortDirections={["descend", "ascend"]}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default CollectionRules;
