import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
// import { Button, Form, Input } from "antd";

interface BusinessOwnerInformationProps {
  onNext: () => void;
}

const Schema = Yup.object().shape({
  firstName: Yup.string().required('firstName Address is required'),
  lastName: Yup.string().required('LastName Address is required'),
  dateOfBirth: Yup.string().required('Date Of Birth is required'),
  nationality: Yup.string().required('Nationality is required'),
  identificationDocument: Yup.string().required('Identification Document is required'),
  documentNumber: Yup.string().required('Document Number is required'),
  documentType: Yup.string().required('Document Type is required'),
  uploadDocument: Yup.string().required('Document is required'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  nationality: '',
  identificationDocument: '',
  documentNumber: '',
  documentType: '',
  uploadDocument: '',
};

const BusinessOwnerInformation: React.FC<BusinessOwnerInformationProps> = ({ onNext }) => {

  // const [isButtonClickable, setIsButtonClickable] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      console.log(values);
    
    },
  });

  // const onFinish = (values) => {
  //   console.log('Submitted values:', values);
  // };

  return (
    <div className={'businessSetup'}>
      <div className={'profileInformation'}>
        Profile Information
      </div>

      <div className={'businessDetails'}>
                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      First name
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="First name"
                      className={'no-outline text-dark fs-8'}
                      {...formik.getFieldProps('firstName')}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.firstName &&
                        formik.errors.firstName &&
                        formik.errors.firstName}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Last name
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Last name"
                      className={'no-outline text-dark fs-8'}
                      {...formik.getFieldProps('lastName')}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.lastName &&
                        formik.errors.lastName &&
                        formik.errors.lastName}
                    </div>
                  </div>
                </div>
      </div>

      <div className={'businessDetails'}>
      <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Date of birth
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Date of birth"
                      className={'no-outline text-dark fs-8'}
                    />
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.dateOfBirth &&
                        formik.errors.dateOfBirth &&
                        formik.errors.dateOfBirth}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Nationality
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <select
                      className={`no-outline text-dark fs-8`}
                    >
                      <option value="" disabled selected>- Nationality-</option>
                      <option value="nigeria">Nigeria</option>
                      <option value="ghana">Ghana</option>
                    </select>
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.nationality &&
                        formik.errors.nationality &&
                        formik.errors.nationality}
                    </div>
                  </div>
                </div>
      </div>

    <div className={'profileInformation'}>
    Identity Verification
    </div>

      <div className={'businessDetails'}>
          <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Identification document
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <select
                      className={`no-outline text-dark fs-8`}
                    >
                      <option value="" disabled selected>- Identification document-</option>
                      <option value="nin">NIN</option>
                      <option value="votersCard">Voter's Card</option>
                    </select>
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.identificationDocument &&
                        formik.errors.identificationDocument &&
                        formik.errors.identificationDocument}
                    </div>
                  </div>
          </div>

          <div className="mt-1">
            <div className="col-form-label py-0 input-height">
                <label className="required fw-bold fs-9">
                Document number
                </label>
            </div>
            <div className={`form-control form-control-solid`}>
              <input
                type="text"
                placeholder="Document number"
                className={'no-outline text-dark fs-8'}                    />
            </div>
            <div className="fv-plugins-message-container input-height">
              <div className="fv-help-block fs-9 text-danger">
                {formik.touched.documentNumber &&
                  formik.errors.documentNumber &&
                  formik.errors.documentNumber}
              </div>
            </div>
          </div>
      </div>

      <div className={'profileInformation'}>
      Proof of address
    </div>

      <div className={'businessDetails'}>
          <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Document type
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <select
                      className={`no-outline text-dark fs-8`}
                    >
                      <option value="" disabled selected>- Document type-</option>
                      <option value="utilityBill">Utility bill</option>
                      <option value="votersCard">Utility bill</option>
                    </select>
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.identificationDocument &&
                        formik.errors.identificationDocument &&
                        formik.errors.identificationDocument}
                    </div>
                  </div>
          </div>

          <div className="mt-1">
            <div className="col-form-label py-0 input-height">
                <label className="required fw-bold fs-9">
                Upload document
                </label>
            </div>
            <div className={`form-control form-control-solid`}>
              <input
                type="text"
                placeholder="Click here to upload or drag and drop here"
                className={'no-outline text-dark fs-8'}                    />
            </div>
            <div className="fv-plugins-message-container input-height">
              <div className="fv-help-block fs-9 text-danger">
                {formik.touched.uploadDocument &&
                  formik.errors.uploadDocument &&
                  formik.errors.uploadDocument}
              </div>
            </div>
          </div>
      </div>

    

      <div className={'businessProfileNextButton'}>
          <button 
          onClick={onNext}
          // disabled={!isButtonClickable}
          // style={{
          //   background: isButtonClickable ? '#0866FF' : '#E4E4E8',
          //   color: isButtonClickable ? 'white' : 'black', // You can adjust text color as needed
          // }}
          >
            Next</button>
        </div>
      </div>
  );
};

export { BusinessOwnerInformation };
