import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { phoneRegExp } from '../../../../../../core/helpers';
import {
  useEditUser,
  useGetProcessTypes,
  useRequestActivateUser,
  useRequestOnboardingStepUserCreationDetails,
  useRequestOnboardingStepUserWorkFlowProcess,
} from '../../../../auth/hooks/useUser';
import { Modal } from "react-bootstrap";
import { FlapperSpinner } from 'react-spinners-kit';

// Define validation schema as a constant
const validationSchema = Yup.object().shape({
  login: Yup.string()
    .required('Login Name is required')
    .matches(/^\S*$/, 'Username should not contain spaces')
    .max(50, 'Text field must be at most 50 characters long'),
  // Add other validation rules here
});

const StepTwo: React.FC<{ handleNextStep: () => void; userLogin: any; stepOneData: any, setStepOneData: any, handlePreviousStep: any}> = ({
  handleNextStep,
  handlePreviousStep,
  userLogin,
  stepOneData,
  setStepOneData
}) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    login: '',
  };

  console.log();
  

  

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [dropdowns, setDropdowns] = useState<{ [key: string]: boolean }>({});
  const [businessGroups, setSelectedUserArray] = useState<any[]>([]);
  const { data: ProcessTypes, mutate: mutateProcessTypes } =
    useGetProcessTypes();
  const { mutate } = useRequestOnboardingStepUserWorkFlowProcess();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission if needed
    },
  });


   const { data, mutate: OnboardingMutate, isLoading } =
      useRequestOnboardingStepUserCreationDetails();

      useRequestActivateUser();
  
  
  useEffect(() => {
    mutateProcessTypes();
  }, []);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSelectedOption(value);

    // Show the dropdown corresponding to the selected radio button
    setDropdowns((prevDropdowns) => ({
      ...prevDropdowns,
      [value]: true,
    }));
  };

  const processData = (value: string) => {
    // setSelectedUserArray((prevSelectedUserArray) => [
    //   ...prevSelectedUserArray,
    //   {
    //     cat: selectedOption,
    //     key: selectedOption,
    //     roles: [
    //       {
    //         name: value,
    //         users: [userLogin],
    //       },
    //     ],
    //   },
    // ]);

    setSelectedUserArray((prevSelectedUserArray) => [
      ...prevSelectedUserArray,
      {
        id: null,
        code: `${selectedOption}#${value}`,
        description: value,
      },
    ]);
  };

  const submit = () => {
    console.log(businessGroups);


    // stepOneData(...values,businessGroups)
    
setStepOneData({
  ...stepOneData, // Spread the existing object properties
  businessGroups  // Add or update the businessGroups property
});
    handleNextStep();
    


    // mutate(selectedUserArray, {
    //   onSuccess: (res: any) => {
    //     // Handle success
    //   },
    // });

    // var payload = {
    //  value: {businessGroups},
    //  login: userLogin.toLowerCase()
    // }


      //     OnboardingMutate(
      //   {
      //     firstName: stepOneData.firstName,
      //     lastName: stepOneData.lastName,
      //     email: stepOneData.email,
      //     password: '12345',
      //     phoneNumber: stepOneData.phoneNumber,
      //     login: stepOneData.login,
      //     businessGroups
      //   },

      //   {
      //     onSuccess: (res: any) => {
       
      //     setShow(true)
      //     },
      //   }
      // );
    

//        mutateEditUser(
//        payload,

//         {
//           onSuccess: (res: any) => {
//           //  window.location.reload();
// setShow(true)
//           },
//         }
//       );
  };

  const refresh = () => {
     window.location.reload();
  }

const [show, setShow] = useState(false)

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Successful!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  You have successfully added a user.
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
           

              <button className="otp-button " onClick={() => refresh()}>
                <span className="otp-button-text">Done</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      
      { isLoading ? (
        <div className="loading-container-2">
          <FlapperSpinner />
        </div>
      ) : (
          <div className="workflowAssignment">
            
        <div className="workflowAssignmentTitle">Workflow Assignment</div>

        <div className="workflowAssignmentDesc">
          This section is to assign workflows to users based on process types
        </div>

        <div className="workflowAssignmentCheckbox">
          {ProcessTypes?.map((role, i) => (
            <div key={role.code}>
              <label style={{ paddingBottom: '13px' }}>
                <input
                  style={{ marginRight: '15px' }}
                  type="checkbox"
                  value={role.code}
                  checked={selectedOption === role.code}
                  onChange={handleOptionChange}
                />
                {role.description}
              </label>
              {dropdowns[role.code] && (
                <div className="checkbox-dropdown">
                  <label>
                    <input
                      type="checkbox"
                      value="initiator"
                      onChange={() => processData('initiator')}
                    />
                    Initiator
                  </label>

            

                  <label>
                    <input
                      type="checkbox"
                      value="approver"
                      onChange={() => processData('approver')}
                    />
                    Approver
                  </label>
                </div>
              )}
            </div>
          ))}
        </div>

            <div className="workflowAssignmentNextButton">
                      {/* <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                  className="nav-btn-back nav-back-text"
                  id="nav-btn"
                  onClick={handlePreviousStep}
                >
                  <i className="fa fa-solid fa-arrow-left"></i>{' '}
                  <span style={{ marginLeft: '0.2rem' }}>back</span>
                    </span>{' '} */}
          <button className="" onClick={submit}>
            Proceed
          </button>
        </div>
          </div>
            )
      }
      {SuccessModal()}
    </>
  );
};

export { StepTwo };
