import { FlapperSpinner } from 'react-spinners-kit';
import { useMdaActivate, useMdaDetails } from '../../auth/hooks/useUser';
import { DatePicker, Select, Table, Tabs } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Download } from '../../../componets/download';
import { filter } from '../../../../core/assets/icons';
import { money } from 'core/assets/imgs';
import { useLocation, useNavigate } from 'react-router-dom';

const dateFormat = 'YYYY/MM/DD';

const Details = () => {
  const { isLoading } = useMdaDetails();
  const navigate = useNavigate();
  
  const location = useLocation();
  const { transactionData } = location.state || {};

  console.log(transactionData);
  

  const {
    data: activateData,
    mutate: activateMutate,
    isLoading: activateLoading,
  } = useMdaActivate();

  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [[]];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '30',
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      width: '30',
    },
    {
      title: 'Payment Date and Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      width: '30',
    },
    {
      title: 'VRR/VPR',
      dataIndex: 'vrr',
      key: 'vrr',
      width: '30',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30',
    },
    {
      title: 'Payment/Service Type',
      dataIndex: 'serviceType',
      key: 'serviceType',
      width: '30',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      width: '30',
    },
  ];

  for (let i = 1; i <= 100; i++) {
    const row = {
      id: i,
      transactionType: `Inflow`,
      dateTime: `20 Aug 2024, 11:45am`,
      vrr: `00182883939`,
      name: `Tolulope Oluwadamilare`,
      serviceType: `Airtime purchase`,
      paymentStatus: `Successful`,
    };
    dataSource.push(row);
  }

  const goBack = () => {
     return  navigate("/admin/transactions");

  };

  return (
    <>
      {isLoading || activateLoading ? (
        <div className="loading-container">
          <FlapperSpinner /> {/* Replace with your loading indicator */}
        </div>
      ) : (
        <div className={'outstandingPayment'}>
          <div className={'outstandingPaymentRow1'}>
            <div className={'outstandingPaymentBreadcrumb'}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3935_65299)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                    fill="#0866FF"
                  />
                </g>
                <rect
                  x="0.5"
                  y="1"
                  width="23"
                  height="23"
                  rx="11.5"
                  stroke="#0866FF"
                />
                <defs>
                  <clipPath id="clip0_3935_65299">
                    <rect y="0.5" width="24" height="24" rx="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <div onClick={goBack} style={{cursor: "pointer"}}>Transactions</div>

              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                  fill="#6366F1"
                />
              </svg>

              <div>Transaction details</div>
            </div>
          </div>

          <div className={'outstandingPaymentRow-details'}>
            <div className="details-header">
              <div className="deatils-header-txt">Transaction Details</div>

              <Download
                dataSourceForExcel={dataSourceForExcel}
                dataSource={dataSource}
                excelFileTitle={'Collection Inflow for MDA'}
                pdfFileTitle={'Collection Inflow for MDA'}
                excelPageTitle={'Collection Inflow for MDA'}
                grandTotalFor={{
                  transactionAmount: 'Transaction Amount',
                  processingFee: 'Processing Fee',
                  vatProcessingFee: 'VAT Processing Fee',
                  totalProcessingFee: 'Total Processing Fee',
                  netAmount: 'Net Amount',
                  cumulativeNetAmount: 'Cumulative Net Amount',
                }}
              />
            </div>

            <div className="details-body">
              <div className="details-body-lhs">
                <div className=" m-auto responsive-card">
                  <div className="invoice-card-no-top">
                    <div className="d-flex justify-content-between py-3">
                      <span className="deatils-header-txt">
                        {transactionData?.transactionType} Payment
                      </span>
                        <span className="header-lhs">

                           {
                            transactionData?.paymentStatus === "Failed" &&   <span className="lhs-failed">{transactionData?.paymentStatus}</span>
                        }
                          

                             {
                            transactionData?.paymentStatus === "Pending" &&   <span className="lhs-pending">{transactionData?.paymentStatus}</span>
                        }
                          
                            {
                            transactionData?.paymentStatus === "Successful" &&   <span className="lhs-Successful">{transactionData?.paymentStatus}</span>
                        }
                         
                          
                          {
                            transactionData?.paymentStatus === "Failed" && transactionData?.transactionType === 'Outflow'  &&  <span className="lhs-pending-2">Reprocess</span>
                          }

                          {
                             transactionData?.paymentStatus === "Rejected" &&  transactionData?.transactionType === 'Outflow' &&  <span className="lhs-pending-2">Reprocess</span>
                          }
                          
                             {
                            transactionData?.paymentStatus === "Pending" &&  transactionData?.transactionType === 'Outflow'&&  <span className="lhs-pending-2">Refund</span>
                        }

                       
                      </span>
                    </div>

                    <hr className="text-muted h-1px" />
                    <div>
                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Date
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          02 Mar 2023, 11:45am
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Payment Ref
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          99999999900001
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M26.9989 22.9982V4.99805H8.99801M4.99805 8.99819H22.9988V26.9982H4.99805V8.99819Z"
                              stroke="#0866FF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Amount
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          7,000.00
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Fees
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          10.55
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Net Payment
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          6989.45
                        </span>
                      </div>

                      <hr className="text-muted h-1px" />

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Customer’s Name
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          Tolulope Oluwadamilare
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Payment Channel
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          Bank
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Payment Method
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          02300110110
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Bank
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          United Bank for Africa
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Currency
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          Naira (₦)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div className="details-body-rhs">
                  <div className='text-muted fs-9 fw-bold text-start min-w-25'>Pending Stage</div>
                  <div className="text-dark fs-9 fw-bold  overflow-hidden">Approval</div>

                   <div className="deatils-header-txt-rhs">
                       Transaction Timeline
                  </div>
                  

                  <div className='ddff'>
                       <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          26 Aug 2024<br />
                      02:04pm
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                         Payment Pending
                        </span>
                  </div>
                  
                       <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                      26 Aug 2024 <br />
                      02:04pm
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                         02:00pm
                        </span>
                  </div>
                  
                       <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          26 Aug 2024<br />
                      02:04pm
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                        Payment initiated
                        </span>
                    </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Details;
