import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// import { Button, Form, Input } from "antd";


const Schema = Yup.object().shape({
  bankName: Yup.string().required('Bank Name is required'),
  accountNumber: Yup.string().required('Account Number is required'),
});

const initialValues = {
  bankName: '',
  accountNumber: '',
};

const BusninessBankDetails: React.FC = () => {

  const navigate = useNavigate();

  // const [isButtonClickable, setIsButtonClickable] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      console.log(values);
    
    },
  });

  // const onFinish = (values) => {
  //   console.log('Submitted values:', values);
  // };

  return (
    <div className={'businessSetup'}>
      <div className={'businessDetails'}>
          <div className="mt-1">
                  <div className="col-form-label py-0 input-height">
                      <label className="required fw-bold fs-9">
                      Bank name
                      </label>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <select
                      className={`no-outline text-dark fs-8`}
                    >
                      <option value="" disabled selected>- Bank name-</option>
                      <option value="utilityBill">Guaranty Trust Bank</option>
                      <option value="votersCard">UBA</option>
                    </select>
                  </div>
                  <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9 text-danger">
                      {formik.touched.bankName &&
                        formik.errors.bankName &&
                        formik.errors.bankName}
                    </div>
                  </div>
          </div>

          <div className="mt-1">
            <div className="col-form-label py-0 input-height">
                <label className="required fw-bold fs-9">
                Account number
                </label>
            </div>
            <div className={`form-control form-control-solid`}>
              <input
                type="text"
                placeholder="Account number"
                className={'no-outline text-dark fs-8'}                    />
            </div>
            <div className="fv-plugins-message-container input-height">
              <div className="fv-help-block fs-9 text-danger">
                {formik.touched.accountNumber &&
                  formik.errors.accountNumber &&
                  formik.errors.accountNumber}
              </div>
            </div>
          </div>
      </div>

      <div className={'proofOfAddress'}>
        <div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.38 7.38H8V11.76H8.62M8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5ZM8 5.01C8.25 5.01 8.45 4.81 8.45 4.56C8.45 4.31 8.25 4.11 8 4.11C7.75 4.11 7.55 4.31 7.55 4.56C7.55 4.81 7.75 5.01 8 5.01Z" stroke="#DC2626" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>

        <div>
        Name on account must be the same as business owner’s name.
        </div>
      </div>


      <div className={'businessProfileNextButton'}>
          <button 
          onClick={() => navigate('/simplypay-onboarding/starter-business/summary')}
          // disabled={!isButtonClickable}
          // style={{
          //   background: isButtonClickable ? '#0866FF' : '#E4E4E8',
          //   color: isButtonClickable ? 'white' : 'black', // You can adjust text color as needed
          // }}
          >
            Next</button>
        </div>
      </div>
  );
};

export { BusninessBankDetails };
