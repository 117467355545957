import { Button, Form, Input, Radio, Select, Table } from "antd";
//import { useOrganizationKYC } from "app/pages/auth/hooks/NewSimplyPay/onboarding";
import React, { useState } from "react";
import { InfoSvg } from "../component/InfoSvg";
import { BackSvg } from "../component/BackSvg";
import { ForwardSvg } from "../component/ForwardSvg";
import { PlusSvg } from "../component/PlusSvg";
import { Modal } from "react-bootstrap";

interface RegisteredBusinessKycStepTwoProps {
  isFormSubmitted: () => void;
  setCurrentStage: (e: number) => void;
  currentStage: number;
}

interface DataSourceInterface {
  id: string
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  role: string
  idType: string
  idDocument: string
  status: string
}
export const RegisteredBusinessKycStepTwo: React.FC<
  RegisteredBusinessKycStepTwoProps
> = ({ isFormSubmitted, setCurrentStage, currentStage }) => {
  //const { mutate } = useOrganizationKYC();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [dataSource,setDataSource]= useState<any>([]);

  const onFinish = (values: any) => {
    console.log("Success:", values);

    const data = {
      id: dataSource.length + 1,
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      email: values?.email,
      role: values?.businessRole,
      idType: values?.idType,
      idDocument: values?.identificationDocument,
      status: values?.status,
    };

    const OldDataWithNew = [...dataSource,data]

    setDataSource(OldDataWithNew);
      
    

    setShowModal(false);
    //const orgId = localStorage.getItem("orgId");

    // const payload: any = {
    //   serialVersionUID: 0,
    //   id: "",
    //   code: "",
    //   description: "",
    //   fileName: "",
    //   organizationId: orgId,
    //   businessRepresentatives: [
    //     {
    //       firstName: values.firstName,
    //       lastName: values.lastName,
    //       dateOfBirth: values.dateOfBirth,
    //       country: values.nationality,
    //       businessRole: "OWNER",
    //       percentageShare: 0,
    //       idDocument: values.identificationDocument,
    //       idDocumentNumber: values.documentNumber,
    //       idDocumentPath: "",
    //       addressLine1: values.addressLine1,
    //       addressLine2: values.addressLine2,
    //       city: values.city,
    //       state: values.stateRegion,
    //       documentType: values.documentType,
    //       documentPath: "",
    //       documents: [""],
    //     },
    //   ],
    //   documents: [""],
    // };

    // mutate(payload, {
    //   onSuccess: (res: any) => {
    //     isFormSubmitted();
    //   },
    // });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Official Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "ID Type",
      dataIndex: "idType",
      key: "idType",
    },
    {
      title: "ID Document",
      dataIndex: "idDocument",
      key: "idDocument",
    },

    {
      title: "Actions ",
      dataIndex: "actions",
    },
  ];


  const AddResolutionSigners = () => {
    return (
      <Modal
        dialogClassName="m-auto px-5 modal-card-service"
        aria-hidden="false"
        show={showModal}
        onHide={() => setShowModal(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        style={{zIndex:10000}}
      >
        <Modal.Body className="rounded">
          <div className="m-5">
            <div className="fw-bold">Resolution Signers Information</div>
            <hr />

            <Form
              name="organizationInformation"
              initialValues={{ remember: true }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className={"form-group"}>
                <Form.Item
                  label="First name"
                  name="firstName"
                  rules={[
                    { required: true, message: "First name is required" },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Last name"
                  name="lastName"
                  rules={[{ required: true, message: "Last name is required" }]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    { required: true, message: "Phone Number is required" },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Official Email"
                  name="email"
                  rules={[
                    { required: true, message: "Official Email is required" },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </div>

              <div>
                <span>Role</span>

                <Form.Item
                  label="What is the role of the resolution signer?"
                  name="businessRole"
                  rules={[
                    { required: true, message: "Role of resolution signer" },
                  ]}
                >
                  <Radio.Group
                    options={[
                      "Registered Trustee",
                      "Nominated Signatory/Authorizer",
                    ]}
                  />
                </Form.Item>
              </div>

              <div className="my-10">
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBlock: "10px",
                  }}
                >
                  Identity Verification
                  <div className="d-flex justify-items-center  align-items-center">
                    <InfoSvg />

                    <p className="fs-8 text-gray-500 my-2">
                      Identification document can be driver’s license,
                      international passport, permanent voter’s card, national
                      identification card or slip. Accepted document formats are
                      jpg, png, or pdf
                    </p>
                  </div>
                </div>

                <div className={"form-group"}>
                  <Form.Item
                    label="ID Type"
                    name="identificationDocument"
                    rules={[
                      {
                        //required: true,
                        message: "Identification document is required",
                      },
                    ]}
                  >
                    <Select
                      placeholder=""
                      //size="large"
                      options={[
                        { label: "Driver’s License", value: "driversLicense"},
                        {
                          label: "International Passport",
                          value: "internationalPassport",
                        },
                        {
                          label: "Permanent Voter’s Card",
                          value: "voterCard",
                        },
                        {
                          label: "National Identification Card or Slip ",
                          value: "ninSlip",
                        },
                      ]}
                    />

                    <select>
                      <option value=''>International Passport</option>
                    </select>
                  </Form.Item>

                  {/* <Form.Item
                label="Document number"
                name="documentNumber"
                rules={[
                  { required: true, message: "Document number is required" },
                ]}
              >
                <Input size="large" />
              </Form.Item> */}

                  <div>
                    <label className="required fw-bold fs-9 required my-1">
                      Certificate of Registration/Incorporation
                    </label>
                    <div className={`form-control fileUpload`}>
                      <label>
                        <div>Click here to upload document</div>
                        <input
                          hidden
                          id="input"
                          type="file"
                          // accept="text/csv"
                          // onChange={extractData}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center gap-15 ">
                <Form.Item>
                  <Button
                    htmlType="button"
                    className="d-flex justify-content-center align-items-center w-200px border-primary text-primary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="d-flex justify-content-center align-items-center w-200px"
                  >
                    Save
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <div className={"kycFormRow2"}>
        <div style={{ fontWeight: 500, fontSize: "16px", marginBlock: "10px" }}>
          Signed Resolution
          <p className="fs-8 text-gray-500 my-2">
            <InfoSvg />
            Accepted document formats are jpg, png, or pdf
          </p>
        </div>

        <div className="my-5">
          <div className={"form-group"}>
            <div>
              <label className="required fw-bold required my-1">
                Resolution/Minutes of Last Meeting
              </label>{" "}
              <InfoSvg />
              <div className={`form-control fileUpload`}>
                <label>
                  <div>Click here to upload document</div>
                  <input
                    hidden
                    id="input"
                    type="file"
                    // accept="text/csv"
                    // onChange={extractData}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div style={{ fontWeight: 500, fontSize: "16px", marginBlock: "10px" }}>
          Resolution Signers Information
        </div>
        <hr />

        <div className="my-10" style={{ overflowX: "auto" }}>
          <Table
            dataSource={dataSource}
            bordered
            columns={columns}
            scroll={{ x: "1950px" }}
          />
        </div>

        <div className="mx-15">
          <div className="d-flex justify-content-center my-5">
            <Button
              type="link"
              htmlType="submit"
              className="d-flex flex-column justify-content-center align-items-center w-300px"
              onClick={() => setShowModal(true)}
            >
              <div className="d-flex">
                <PlusSvg /> Add More Resolution Signers
              </div>

              <hr className="text-primary w-220px m-1 opacity-100" />
            </Button>
          </div>

          <div className="step-btn-group">
            <Button
              type="default"
              htmlType="button"
              onClick={() => setCurrentStage(currentStage - 1)}
              className="border-primary"
            >
              <div className="d-flex gap-2 justify-content-center align-items-center">
                <BackSvg />
                <span className="fs-8 text-primary">Previous</span>
              </div>
            </Button>

            <Button
              type="primary"
              htmlType="button"
              //onClick={() => setCurrentStage(currentStage + 1)}
            >
              <div className="d-flex gap-2 justify-content-center align-items-center fs-8">
                Next
                <ForwardSvg />
              </div>
            </Button>

            {AddResolutionSigners()}
          </div>
        </div>
      </div>
    </>
  );
};
