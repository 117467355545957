import React from "react";
import { useNavigate } from 'react-router-dom';




const PrivateEntity: React.FC = () => {


  const navigate = useNavigate();

  return (
        <div className={''}>
          <div className={'documentTitle'}>Private Entity created by Government Approval</div>

          <div>
            <ul className="bullet-list">
              <li className={'listStyle'}>Government approval document</li>
              <li className={'listStyle'}>Document showing business owners</li>
              <li className={'listStyle'}>Proof of business address</li>
              <li className={'listStyle'}>Proof of identity and address for directors of business</li>
              <li className={'listStyle'}>Proof of identity and address for shareholders that own up to 51% of business</li>
            </ul>
          </div>

          <div className={'requiredDocumentButton'}>
          <button
              onClick={() => navigate('/admin/profile-setup/registered-business/business-setup')}
            >
              Proceed
            </button>
          </div>
        </div>
          
  );
};

export { PrivateEntity };
