export const ForwardSvg = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9697 0.96967C11.2626 0.676777 11.7374 0.676777 12.0303 0.96967L19.5303 8.46967C19.671 8.61032 19.75 8.80109 19.75 9C19.75 9.19891 19.671 9.38968 19.5303 9.53033L12.0303 17.0303C11.7374 17.3232 11.2626 17.3232 10.9697 17.0303C10.6768 16.7374 10.6768 16.2626 10.9697 15.9697L17.1893 9.75H1C0.585786 9.75 0.25 9.41421 0.25 9C0.25 8.58579 0.585786 8.25 1 8.25H17.1893L10.9697 2.03033C10.6768 1.73744 10.6768 1.26256 10.9697 0.96967Z"
        fill="#E6EFFF"
      />
    </svg>
  );
};
