import {Select, Table} from "antd";
import React, { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import {useMdaActivate, useMdaDetails} from "../../../auth/hooks/useUser";
import {filter} from "../../../../../core/assets/icons";
import { Download } from "../../../../componets/download";


const PayablesDue = () => {

  const {
    isLoading,
  } = useMdaDetails();

  const {
    data: activateData,
    mutate: activateMutate,
    isLoading: activateLoading,
  } = useMdaActivate();


  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [
    [

    ],
  ];


  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "30",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      width: "180",
    },
    {
      title: "Payment Option",
      dataIndex: "paymentOption",
      key: "paymentOption",
      width: "60",
    },
    {
      title: "VoxePay Payment Reference  (GPR)",
      dataIndex: "gpr",
      key: "gpr",
      width: "60",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "80",
    },
    {
      title: "Initiation Date",
      dataIndex: "initiationDate",
      key: "initiationDate",
      width: "80",
    },
    {
      title: "Level Pending",
      dataIndex: "levelPending",
      key: "levelPending",
      width: "42",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: "42",
    },
  ];

  for (let i = 1; i <= 100; i++) {
    const row = {
      id: i,
      paymentType: `Pension`,
      paymentOption: `Bulk`,
      gpr: `GPR56577889990`,
      amount: `₦1,000,000`,
      initiationDate: `22-Mar-2023`,
      levelPending: `Reviewer`,
      time: `8:00 AM`,
    };
    dataSource.push(row);
  }


  return (
        <div className={"mdaPayableRow4"}>
          <div className="d-flex justify-content-end  mb-9 me-4">
            <Download
                dataSourceForExcel={dataSourceForExcel}
                dataSource={dataSource}
                excelFileTitle={"Collection Inflow for MDA"}
                pdfFileTitle={"Collection Inflow for MDA"}
                excelPageTitle={"Collection Inflow for MDA"}
                grandTotalFor={{
                  transactionAmount: "Transaction Amount",
                  processingFee: "Processing Fee",
                  vatProcessingFee: "VAT Processing Fee",
                  totalProcessingFee: "Total Processing Fee",
                  netAmount: "Net Amount",
                  cumulativeNetAmount: "Cumulative Net Amount",
                }}
            />
          </div>

          <div>Filter by:</div>

          <div className={"mdaPayableSelectOptions"}>
            <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Payment Type"
                style={{ width: 140 }}
                options={[{ value: "pension", label: "Pension" }]}
            />

            <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Payment Option"
                style={{ width: 152 }}
                options={[
                  { value: "bulk", label: "Bulk" },
                  { value: "single", label: "Single" },
                ]}
            />

            <button>
              <span>Apply Filter</span>
              <img src={filter} alt="" />
            </button>
          </div>

          <Table
              dataSource={dataSource}
              bordered
              columns={columns}
              scroll={{ x: "1000px" }}
          />
        </div>
        );
};

export default PayablesDue;
