import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MDAdirectory,
  arrowRight,
  arrowUpCircle,
  einvoice,
  homepageBubles,
  playCircle,
  receiptgeneration,
  revenuepayment,
  userHeroImg,
  welcomeImg,
  heroImg
} from "../../../core/assets/imgs";

import { Modal } from "react-bootstrap";
import Typewriter from "typewriter-effect";

const UserHeroPersonal: React.FC = () => {
  const [show, setShow] = useState<any>(false);
  const navigate = useNavigate();

  const done = () => {
    navigate("/payment");
  };
  const PageModal = () => {
    return (
      <Modal
        dialogClassName="home-modal"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
      >
        <div className=" d-flex flex-cloumn justify-content-center align-items-center w-536e h-326e bg-black rounded-1">
          <article className="w-100 h-100">
            <iframe
              className="rounded-1"
              width="100%"
              height="326"
              src="https://www.youtube-nocookie.com/embed/zH1HtWW_3-Q?rel=0&showinfo=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
              allowFullScreen
            ></iframe>
          </article>
        </div>
      </Modal>
    );
  };
  return (
    <div>
      <header className="hero-2 mt-3b">
        <div className="section-center hero-center">


          <div className="hero-div">
            <span className="hero-header-2">Make and Receive Payments</span>
            <span className="hero-text-2">Simple and Straightforward.<br />
              Easily and Instantly.</span>
            

          </div>


            <div className="hero-input">
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M14.9431 14.4438L19.4995 19.0001M17.25 8.875C17.25 13.2242 13.7242 16.75 9.375 16.75C5.02576 16.75 1.5 13.2242 1.5 8.875C1.5 4.52576 5.02576 1 9.375 1C13.7242 1 17.25 4.52576 17.25 8.875Z" stroke="#6AA3FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            <input className="custom-input" type="text" placeholder="Search for billers" />
            

            <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" width="40" height="40" rx="4" fill="#E6EFFF"/>
<path d="M12.25 20H28.75M28.75 20L22 13.25M28.75 20L22 26.75" stroke="#6AA3FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


            </div>
              


      


    
   

    

     

        
        </div>
      </header>

      {PageModal()}
    </div>
  );
};

export { UserHeroPersonal };
