import React, { useState } from "react";
import SimpliPayLogo from '../../../../../core/assets/img/SimpliPayLogo.png'
import { useNavigate } from "react-router-dom";
import { Steps } from "antd";
import { BusinessDetails } from "./BusinessDetails";
import { BusinessRegistrationInformation } from "./BusinessRegistrationInformation";
import { BusinessRepresentativeDetails } from "./BusinessRepresentatives/BusinessRepresentativeDetails";
import { BusinessBankAccountDetails } from "./BusinessBankAccountDetails";


const { Step } = Steps;

const BusinessSetup: React.FC = () => {

  const navigate = useNavigate();

  const [current, setCurrent] = useState(0);

  const steps = [
    'Business Details',
    'Business Registration Information',
    'Business Representative Details',
    'Business Bank Account Details',
  ];


  return (
    <div className={'onboarding'}>
    <div className={'SimpliPayLogo'}>
      <img src={SimpliPayLogo} alt={'SimpliPayLogo'} width={'140'}/>
    </div>

    <div className={'businessSetupSteps'}>
      <div className={'businessSetupStepsRow1'}>
          <div onClick={() => 
          current === 0 ? 
          navigate('/admin/profile-setup/registered-business') : setCurrent(current - 1)
        
        }

          
          >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9016 15.7123C11.1945 16.0052 11.6694 16.0052 11.9623 15.7123C12.2552 15.4194 12.2552 14.9445 11.9623 14.6516L10.9016 15.7123ZM8.25 12L7.71967 11.4697C7.42678 11.7626 7.42678 12.2374 7.71967 12.5303L8.25 12ZM11.9623 9.34836C12.2552 9.05547 12.2552 8.58059 11.9623 8.2877C11.6694 7.99481 11.1945 7.99481 10.9016 8.2877L11.9623 9.34836ZM15.75 12.75C16.1642 12.75 16.5 12.4142 16.5 12C16.5 11.5858 16.1642 11.25 15.75 11.25V12.75ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75ZM11.9623 14.6516L8.78033 11.4697L7.71967 12.5303L10.9016 15.7123L11.9623 14.6516ZM8.78033 12.5303L11.9623 9.34836L10.9016 8.2877L7.71967 11.4697L8.78033 12.5303ZM8.25 12.75H15.75V11.25H8.25V12.75Z" fill="#2A7CB4"/>
              </svg>
          </div>

          <div>Business Setup</div>
      </div>

      <div className="businessSetupStepsRow2">
        <Steps current={current} size="small" className="stepTitle">
          {steps.map((title, index) => (
            <Step key={title} title={current === index ? title : null} />
          ))}
        </Steps>

          {current === 0 && <BusinessDetails onNext={() => setCurrent(current + 1)} />}
          {current === 1 && <BusinessRegistrationInformation onNext={() => setCurrent(current + 1)} />}
          {current === 2 && <BusinessRepresentativeDetails onNext={() => setCurrent(current + 1)} />}
          {current === 3 && <BusinessBankAccountDetails />}

      </div>

  </div>

  </div>
  );
};

export { BusinessSetup };
