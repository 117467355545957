import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routing/Routes";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "../app/react-query/queryClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes />
        <ToastContainer />
      </BrowserRouter>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
};

export { App };
