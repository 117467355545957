export const FormatMoney = (value: number): string => {
  const suffixes = ["", "K", "M", "B", "T", "Q"];
  const suffixNum = Math.floor(("" + value).length / 3); 

  let formattedValue = parseFloat(
    (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toFixed(2)
  );
  if (formattedValue % 1 !== 0) {
    formattedValue = parseFloat(formattedValue.toFixed(1));
  }

  return `₦${formattedValue.toLocaleString()}${suffixes[suffixNum]}`;
};

export const NoCurrencyFormatMoney = (value: number): string => {
  const suffixes = ["", "K", "M", "B", "T", "Q"];
  const suffixNum = Math.floor(("" + value).length / 3); 

  let formattedValue = parseFloat(
    (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toFixed(2)
  );
  if (formattedValue % 1 !== 0) {
    formattedValue = parseFloat(formattedValue.toFixed(1));
  }

  return `${formattedValue.toLocaleString()}${suffixes[suffixNum]}`;
};


export const formatMoneyLocal = (valueNumber: number) => {
  const FormatData = Number(valueNumber).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  
  return FormatData;
};

