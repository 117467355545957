import { DatePicker, Form, Radio, Select } from "antd";
import { FormSubmitButton } from "app/componets/Button/FormSubmitBtn";
import { SuccessModalComponent } from "app/componets/modal/SuccessModalComponent";
import { useRequestGetServices } from "app/pages/auth/hooks/useUser";
import {
  useDownloadInflowReport,
  useGetAllMda,
} from "app/pages/payments/hooks/usePayment";
import { isOagfAdmin, LoaderContainer, SelectSuffixIcon } from "core/helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import "./PaymentReports.scss";

const { Option } = Select;

const InflowReportController = () => {
  const [form] = Form.useForm();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const {
    data: allService,
    mutate: mutateService,
    isLoading: loadingService,
  } = useRequestGetServices();

  const { mutate: mutateDownloadMutate, isLoading: loadingDownload } =
    useDownloadInflowReport();

  const onFinishFailed = (errorInfo: any) => {
  };

  const {
    data: mdaNameList,
    mutate: mdaMutate,
    isLoading: allMdaLoading,
  } = useGetAllMda();

  const onFinish = (values: any) => {
    const { mda, startDate, endDate, dir, paymentType } = values;

    const formattedStartDate = moment(startDate.toISOString()).format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = moment(endDate.toISOString()).format("YYYY-MM-DD");

    let searchFilter = "";
    if (startDate) searchFilter += `start-date=${formattedStartDate}&`;
    if (endDate) searchFilter += `end-date=${formattedEndDate}&`;
    if (mda && mda !== "all") searchFilter += `mda=${mda}&`;
    if (paymentType && paymentType !== "all")
      searchFilter += `type=${paymentType}&`;
    // if (dir) searchFilter += `dir=${dir}`;
    if (dir) searchFilter += `sort=paymentDate,asc`;

    // Remove the trailing '&' if the searchFilter is not empty
    if (searchFilter.endsWith("&")) {
      searchFilter = searchFilter.slice(0, -1);
    }
    
    mutateDownloadMutate(searchFilter, {
      onSuccess: (res) => {
        setShowSuccessModal(true);
      },
    });
  };

  useEffect(() => {
    mutateService();

    if (isOagfAdmin()) {
      mdaMutate();
    }
  }, []);

  const [transMax, setTransMax] = useState<number>(0);
  const [transMin, setTransMin] = useState<number>(0);

  const onAmountInput = (e: any) => {
    const { floatValue } = e;
    form.setFieldValue("maxTransAmount", floatValue);
    setTransMax(floatValue ? floatValue : 0);
  };

  const onMinAmountInput = (e: any) => {
    const { floatValue } = e;
    form.setFieldValue("minTransAmount", floatValue);
    setTransMin(floatValue ? floatValue : 0);
  };
  return (
    <>
      {allMdaLoading || loadingService || loadingDownload ? (
        <LoaderContainer />
      ) : (
        <div className={"reviewerDashboard"}>
          <div className={"ciBreadcrumb pb-0"}>
            <div>Reports</div>
            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                fill="#6366F1"
              />
            </svg>
            <div>Inflow Reports</div>
          </div>
          <hr className="text-gray-800" />

          <div className="PaymentReportContainer">
            <div className="text-center fs-base text-dark2 fw-bold">
              <span>Report Controller</span>
            </div>

            <div className="mt-5">
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
                form={form}
              >
                <div className="input-grid mb-3">
                  {isOagfAdmin() && (
                    <Form.Item
                      name={"mda"}
                      label={
                        <label className="text-federal-blue ">Select MDA</label>
                      }
                      rules={[{ required: true, message: "MDA is required" }]}
                    >
                      <Select placeholder="" suffixIcon={<SelectSuffixIcon />}>
                        <Option value={null}> </Option>
                        <Option value="all">All</Option>
                        {mdaNameList?.map(
                          (
                            mda: { id: string; registeredName: string },
                            index: React.Key | null | undefined
                          ) => {
                            return (
                              <Option value={mda.id} key={index}>
                                {mda?.registeredName}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item
                    name={"service"}
                    label={
                      <label className="text-federal-blue required">
                        Select Service
                      </label>
                    }
                    required={false}
                    rules={[{ required: true, message: "MDA is required" }]}
                  >
                    <Select placeholder="" suffixIcon={<SelectSuffixIcon />}>
                      <Option value={null}> </Option>
                      <Option value="all">All</Option>
                      {allService?.map(
                        (
                          mda: { id: string; description: string },
                          index: React.Key | null | undefined
                        ) => {
                          return (
                            <Option value={mda.id} key={index}>
                              {mda?.description}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={"groupBy"}
                    label={
                      <label className="text-federal-blue">Group By</label>
                    }
                  >
                    <Select placeholder="" suffixIcon={<SelectSuffixIcon />}>
                      <Option value={null}> </Option>
                      <Option value="currency">Currency</Option>
                      <Option value="paymentGateway">Payment Gateway</Option>
                      <Option value="paymentStatus">Payment Status</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={
                      <label className="text-federal-blue required">
                        Start Date
                      </label>
                    }
                    name="startDate"
                    rules={[
                      {
                        type: "object" as const,
                        required: true,
                        message: "Select Start Date",
                      },
                    ]}
                    required={false}
                  >
                    <DatePicker placeholder="Start Date" className="w-100" />
                  </Form.Item>

                  <Form.Item
                    label={
                      <label className="text-federal-blue required">
                        End Date
                      </label>
                    }
                    name="endDate"
                    dependencies={["startDate"]}
                    rules={[
                      {
                        type: "object" as const,
                        required: true,
                        message: "Select End Date",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const endDate: any = new Date(value);
                          const startDate: any = new Date(
                            getFieldValue("startDate")
                          );

                          if (startDate < endDate || startDate == endDate) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("End Date can't be less than start date!")
                          );
                        },
                      }),
                    ]}
                    required={false}
                  >
                    <DatePicker placeholder="End Date" className="w-100" />
                  </Form.Item>

                  <Form.Item
                    label="Minimum Transaction Amount"
                    name={"minTransAmount"}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (
                            value &&
                            (transMax === 0 || transMax >= transMin)
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "Minimum Transaction Amount cannot be greater than Maximum Transaction Amount!"
                            )
                          );
                        },
                      }),
                    ]}
                    className="mb-0"
                  >
                    <NumericFormat
                      type="text"
                      prefix="₦"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      className={`no-outline ant-input  text-green-dark-blue-500  ant-col ant-form-item-control css-dev-only-do-not-override-2q8sxy ${
                        transMax < form.getFieldValue("maxTransAmount")
                          ? "ant-input-status-error"
                          : ""
                      }  `}
                      placeholder=""
                      allowNegative={false}
                      onValueChange={onMinAmountInput}
                      allowLeadingZeros={false}
                      value={form.getFieldValue("minTransAmount")}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Maximum Transaction Amount"
                    name={"maxTransAmount"}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (
                            value &&
                            (transMin === 0 || transMax >= transMin)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Maximum Transaction Amount cannot be less than Minimum Transaction Amount!"
                            )
                          );
                        },
                      }),
                    ]}
                    className="mb-0"
                  >
                    <NumericFormat
                      type="text"
                      prefix="₦"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      className={`no-outline ant-input text-green-dark-blue-500  ant-col ant-form-item-control css-dev-only-do-not-override-2q8sxy${
                        form.getFieldValue("minTransAmount") >
                        form.getFieldValue("maxTransAmount")
                          ? "ant-input-status-error"
                          : ""
                      }  `}
                      placeholder=""
                      allowNegative={false}
                      onValueChange={onAmountInput}
                      allowLeadingZeros={false}
                      value={form.getFieldValue("maxTransAmount")}
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  label={
                    <label className="text-federal-blue fw-bold">Sort By</label>
                  }
                  name="dir"
                >
                  <Radio.Group className="input-grid">
                    <Radio value="asc">Ascending Order (FIFO)</Radio>
                    <Radio value="desc">Descending Order (LIFO)</Radio>
                  </Radio.Group>
                </Form.Item>

                <div className="d-flex justify-content-center gap-3">
                  <button
                    className="resetBtn"
                    type="button"
                    onClick={() => form.resetFields()}
                  >
                    <span>Reset</span>
                  </button>

                  <Form.Item className="d-flex justify-content-center">
                    <FormSubmitButton
                      form={form}
                      submitProp={"Download Report"}
                      className={"submitBtn"}
                    />
                  </Form.Item>
                </div>
              </Form>
            </div>

            <SuccessModalComponent
              show={showSuccessModal}
              setShow={(e: boolean) => setShowSuccessModal(e)}
              proceed={() => {
                form.resetFields();
                setShowSuccessModal(false);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export { InflowReportController };
