import React, { useEffect, useState } from "react";
import "../../../App.css";
import dayjs from "dayjs";
import type { MenuProps } from "antd";
import {Menu, Select, Space, DatePicker, Alert, Button} from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { useNavigate } from "react-router-dom";
import {
  useGetUserBankDetails,
  useGetTotalOutFlow,
  useGetTotalInFlow,
  useGetAvailableBalance,
  useGetBalanceByAccountNumber,
  useGetGraphInflowOutflow,
  useGetGraphInflowOutflowAccountNumber,
  useGetCheckKycStatus,
} from "./Payments/hooks/usePayment";
import { FlapperSpinner } from "react-spinners-kit";
import ReviewerDashboard from "../Reviewer/ReviewerDashboard";
import moment from "moment";
import { eyeDisable, eyeVisible } from "../../../core/assets/icons";
import { useGetAwaitingRequest } from "../payments/hooks/usePayment";
import { isOagfAdmin } from "../../../core/helpers";
import { Modal as MOD } from "react-bootstrap";
import { getStoredUser } from "../auth/user-storage";



ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

const { RangePicker } = DatePicker;

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

export const optionsss = {
  responsive: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const labels = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// console.log(labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })));

// console.log(data);

const dateFormat = "YYYY/MM/DD";

const Dashboard: React.FC = () => {
  const [inflowG, setInflowG] = useState([]);

     const authed = getStoredUser();

  const data: any = {
    labels,
    datasets: [
      {
        label: "Inflow",
        data: labels.map(() =>
          faker.datatype.number({ min: -1000, max: 1000 })
        ),
        borderColor: "rgb(31,133,5)",
        pointRadius: 0,
        backgroundColor: "rgba(0,0,0,0)", // set the backgroundColor property to transparent to remove the background lines
        borderWidth: 1,
      },
      {
        label: "Outflow",
        data: labels.map(() =>
          faker.datatype.number({ min: -1000, max: 1000 })
        ),
        borderColor: "rgb(238,21,29)",
        pointRadius: 0,
        backgroundColor: "rgba(0,0,0,0)", // set the backgroundColor property to transparent to remove the background lines
        borderWidth: 1,
      },
    ],
  };

  const {
    data: bankData,
    mutate: mutateBankData,
    isLoading: isLoadingBankData,
  } = useGetUserBankDetails();
  const {
    data: availableData,
    mutate: mutateAvailableData,
    isLoading: isLoadingAvailableData,
  } = useGetAvailableBalance();
  const {
    data: availableDataByAccount,
    mutate: mutateAvailableDataByAccount,
    isLoading: isLoadingAvailableDataByAccount,
  } = useGetBalanceByAccountNumber();

  const {
    data: awaitingRequest,
    mutate: mutateRequests,
    isLoading: requestLoading,
  } = useGetAwaitingRequest();

  const {
    data: dataGraphy,
    mutate: mutateGraphy,
    isLoading: requestGraphy,
  } = useGetGraphInflowOutflow();

  console.log(dataGraphy);
  

  const {
    data: dataGraphyAccount,
    mutate: mutateGraphyAccount,
    isLoading: requestGraphyAccount,
  } = useGetGraphInflowOutflowAccountNumber();



  const {
    data: dataEntity,
    mutate: mutateEntity,
    isLoading: isLoadingEntity,
  } = useGetCheckKycStatus();

  const [ischeckedAccount, setIscheckedAccount] = useState<Boolean>(false);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    mutateBankData();
    mutateAvailableData();
    mutateRequests();
    mutateEntity(authed?.mdaCode)
    mutateGraphy();
  }, []);

   console.log(dataEntity?.data?.onboardingStatus);

  const getInflowData = () => {
    let inflow = [] as any;
    dataGraphyAccount?.map((d, i) => {
      inflow.push(d.inflow);
    });

    setInflowG(inflow);
  };

  const navigate = useNavigate();

  const handleMenuClick: MenuProps["onClick"] = (e: any) => {
    //message.info("Click on menu item.", e.key);
    console.log("clicggdgdk", e.key);

    //mutateAvailableDataByAccount(e.key);
  };

  const outFlow = () => {
    navigate("/admin/reports/payment-report", { replace: true });
  };

  const inFlow = () => {
    navigate("/admin/reports/payment-report", { replace: true });
  };

  const menuProps2 = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Last 30 Days">Last 30 Days</Menu.Item>
      <Menu.Item key="Last 60 Days">Last 60 Days</Menu.Item>
      <Menu.Item key="Last 90 Days">Last 90 Days</Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (isOagfAdmin()) {
      navigate("/admin/oagf-overview", { replace: true });
    }
  }, []);

  const getAccountB = () => {
    //  mutateAvailableDataByAccount(bank.accountNumber)
  };

  useEffect(() => {});

  // const menuProps = (
  //   <Menu onClick={handleMenuClick}>
  //     {bankData?.map((bank, i) => {
  //       return (
  //         <Menu.Item key={bank.accountNumber}>
  //           {bank.bankName}-{bank.accountNumber}{" "}
  //         </Menu.Item>
  //       );
  //     })}
  //   </Menu>
  // );

  const menuProps = Array.from(new Set(bankData?.map((item: any) => item))).map(
    (item: any) => ({
      value: item.accountNumber,
      label: `${item.accountName}-${item.accountNumber}`,
    })
  );

  const [selectedAcctNum, setSelectedAcctNum] = useState<any>("");

  const getAccountNumberDetails = (e: string) => {
    setSelectedAcctNum(e);
    if (e.length === 0) {
      mutateAvailableData();
      mutateGraphy();
    } else {
      mutateAvailableDataByAccount(e);
      mutateGraphyAccount(e);
      getInflowData();
    }
  };

  const [dateRange, setDateRange] = useState<any>([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      const startDate = moment(new Date(dates[0]).toISOString()).format(
        "YYYY-MM-DD"
      );
      const endDate = moment(new Date(dates[1]).toISOString()).format(
        "YYYY-MM-DD"
      );
      //mutateLog(`start-date=${startDate}&end-date=${endDate}`);
    } else {
      setDateRange([]);
    }
  };

  const [showBalance, SetShowBalance] = useState<boolean>(true);

const [show, setShow] = useState(true);

  const refresh = () => {
    navigate('/admin/profile-setup')
  };
  const closeModal = () => setShow(false);

  const SuccessModal = () => {
    return (
 <MOD
      dialogClassName="m-auto px-7 modal-card-success custom-modal-width"
      aria-hidden="true"
      show={dataEntity?.data?.onboardingStatus === 'NOTSTARTED'}
      onHide={closeModal}
      animation
      centered
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MOD.Body className="rounded">
        <div className="">
          <div className="d-flex flex-column align-items-center">
            <div className="mt-5 mb-3"></div>

            <div className="email-verification-container mb-3">
              <span className="kyc-header">Welcome, SIMPLIFY SYNERGY</span>
            </div>

            <div className="email-verification-container mb-8">
              <span className="kyc-body">
                To activate your business for payment transactions, you will need to undergo the KYC process. The KYC requirements based on your Business Type/Registration Type are:
              </span>
            </div>
          </div>

          <div className="email-verification-container mb-2">
            <span className="kyc-body-header">
              Starter Business (Unregistered Business)
            </span>
          </div>

          <div className="kyc-ul mb-8">
            <ul>
              <li className="mb-2">Basic information and BVN of business owner</li>
              <li className="mb-2">Business owner’s identity verification document</li>
              <li className="mb-2">Business owner’s proof of address document</li>
            </ul>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-5 pe-9 ps-9">
            <button
              id="kt_password_cancel"
              type="submit"
              className="btn nav-btn-Proceed btn-primary text-white px-7 py-2"
               onClick={() => refresh()}
            >
              <span className="indicator-label">Get Started</span>
            </button>
          </div>
        </div>
      </MOD.Body>
    </MOD>

    );
  };




  return (
    <>
      {isLoadingAvailableData ||
      requestLoading ||
        isLoadingBankData ||
        isLoadingEntity ||
      isLoadingAvailableDataByAccount ? (
        <div className="loading-container">
          <FlapperSpinner />
        </div>
      ) : (
        <div className="dashboard-root-2">
          {
            dataEntity?.data?.onboardingStatus === 'UNDER_REVIEW' ? <Alert
                  message="Setup your profile"
                  description="Your KYC document is:  Review"
                  type="info"
                  action={
                  <Space direction="vertical">
                  <Button size="small" type="primary" onClick={() => navigate('/admin/profile-setup')}>
                Pending Review
              </Button>
              </Space>
            }
              
              /> : null
          }
          <div className="dashboard-overview">
            <div className="dashboard-overview-root-1">
              <span className="overView-text">Overview</span>
            </div>
            <div className="dashboard-overview-root-2">
              <div className="mx-1">
                {/* <Dropdown overlay={menuProps}>
                  <Button
                    type="ghost"
                    className="dashboard-over-btn"
                    size="large"
                  >
                    <div className="btn-content-overview">
                      <div className="btn-text-overview">Account</div>
                      <div>
                        {" "}
                        <DownOutlined />
                      </div>
                    </div>
                  </Button>
                </Dropdown> */}

                <Select
                  style={{ minWidth: 180 }}
                  options={[
                    { value: "", label: "Select Account" },
                    ...menuProps,
                  ]}
                  onChange={(e) => getAccountNumberDetails(e)}
                  showSearch
                  value={selectedAcctNum}
                  dropdownMatchSelectWidth={false}
                  size="middle"
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </div>

              <div className="mx-1">
                {/* <Dropdown overlay={menuProps2}>
                  <Button
                    type="ghost"
                    className="dashboard-over-btn"
                    size="large"
                  >
                    <div className="btn-content-overview">
                      <div className="btn-text-overview"> Last 30 Days</div>
                      <div>
                        {" "}
                        <DownOutlined />
                      </div>
                    </div>
                  </Button>
                </Dropdown> */}

                <Select
                  style={{ width: 130 }}
                  options={[
                    { value: "Last 30 Days", label: "Last 30 Days" },
                    { value: "Last 60 Days", label: "Last 60 Days" },
                    { value: "Last 90 Days", label: "Last 90 Days" },
                  ]}
                  // onChange={(e) => getAccountNumberDetails(e)}
                  defaultValue={"Last 30 Days"}
                  // value={selectedAcctNum}
                  dropdownMatchSelectWidth={false}
                  size="middle"
                />
              </div>

              <div>
                <span className="data-range-text-overview">Date Range:</span>
                {/* <span className="data-from-text-overview">From</span> */}
                <Space direction="vertical" size={12}>
                  <RangePicker
                    className="py-2"
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    allowClear
                  />
                </Space>
                {/* <DatePicker
                  defaultValue={dayjs("2015/01/01", dateFormat)}
                  format={dateFormat}
                  className="datepicker"
                />
                <span className="data-from-text-overview">To</span>
                <DatePicker
                  defaultValue={dayjs("2015/01/01", dateFormat)}
                  format={dateFormat}
                  className="datepicker"
                />{" "} */}
              </div>
            </div>
          </div>

          <main>
            <div className="overview-main-root">
              <div
                className="overview-main-root-1 cursor-pointer "
                onClick={() => SetShowBalance(!showBalance)}
              >
                {/* <div className="center-all me-2">
                  <img src={showBalance ? eyeDisable : eyeVisible} />
                </div>
                <div className="text-overview-hide">
                  {" "}
                  {showBalance ? "Hide Balance" : "Show Balance"}
                </div> */}
              </div>
              <div className="overview-main-root-1 center-all">
                <div className="overview-mute-text about-info">
                  Net Cash Flow:
                </div>
                {showBalance ? (
                  selectedAcctNum.length > 0 ? (
                    <div className="overview-number">
                      {" "}
                      {availableDataByAccount?.balance
                        ? availableDataByAccount?.balance?.toLocaleString(
                            "en-NG",
                            {
                              style: "currency",
                              currency: "NGN",
                            }
                          )
                        : "₦0"}{" "}
                    </div>
                  ) : (
                    <div className="overview-number">
                      {" "}
                      {availableData?.inflow
                        ? (
                            availableData?.inflow - availableData?.outflow
                          )?.toLocaleString("en-NG", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : "₦0"}{" "}
                    </div>
                  )
                ) : (
                  <span className="overview-number">₦******</span>
                )}
              </div>
            </div>

            <div className="overview-chat-root">
              <div className="overview-chat-card">
                <div className="overview-card cursor-pointer">
                  <div className="overview-card-root-content" onClick={outFlow}>
                    <div className="overview-card-header">
                      <div className="overview-card-header-item-1">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22.25C6.34614 22.25 1.75 17.6539 1.75 12C1.75 6.34614 6.34614 1.75 12 1.75C17.6539 1.75 22.25 6.34614 22.25 12C22.25 17.6539 17.6539 22.25 12 22.25ZM12 2.25C6.62386 2.25 2.25 6.62386 2.25 12C2.25 17.3761 6.62386 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62386 17.3761 2.25 12 2.25Z"
                            fill="#A50E15"
                            stroke="#A50E15"
                          />
                          <path
                            d="M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V9.5C11.25 9.09 11.59 8.75 12 8.75C12.41 8.75 12.75 9.09 12.75 9.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z"
                            fill="#A50E15"
                          />
                          <path
                            d="M14.9975 12.2499C14.8075 12.2499 14.6175 12.1799 14.4675 12.0299L11.9975 9.55994L9.5275 12.0299C9.2375 12.3199 8.7575 12.3199 8.4675 12.0299C8.1775 11.7399 8.1775 11.2599 8.4675 10.9699L11.4675 7.96994C11.7575 7.67994 12.2375 7.67994 12.5275 7.96994L15.5275 10.9699C15.8175 11.2599 15.8175 11.7399 15.5275 12.0299C15.3775 12.1799 15.1875 12.2499 14.9975 12.2499Z"
                            fill="#A50E15"
                          />
                        </svg>
                      </div>
                      <div className="overview-card-header-item-2 ">
                        <span className="overview-card-header-item-text-mute">
                          Yesterday
                        </span>
                        <span>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.25 2.625L9.625 7L5.25 11.375"
                              stroke="#666666"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    {selectedAcctNum.length > 0 ? (
                      <div className="text-number-blod-overflow">
                        {availableDataByAccount?.totalOutflow
                          ? availableDataByAccount?.totalOutflow?.toLocaleString(
                              "en-NG",
                              {
                                style: "currency",
                                currency: "NGN",
                              }
                            )
                          : "₦0"}
                      </div>
                    ) : (
                      <div className="text-number-blod-overflow">
                        {availableData?.outflow
                          ? availableData?.outflow?.toLocaleString("en-NG", {
                              style: "currency",
                              currency: "NGN",
                            })
                          : "₦0"}
                      </div>
                    )}

                    <div className="text-bottom-overview">Total Outflow</div>
                  </div>
                </div>
                <div
                  className="overview-card-2 cursor-pointer"
                  onClick={inFlow}
                >
                  {" "}
                  <div className="overview-card-root-content">
                    <div className="overview-card-header">
                      <div className="overview-card-header-item-1">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22.25C6.34614 22.25 1.75 17.6539 1.75 12C1.75 6.34614 6.34614 1.75 12 1.75C17.6539 1.75 22.25 6.34614 22.25 12C22.25 17.6539 17.6539 22.25 12 22.25ZM12 2.25C6.62386 2.25 2.25 6.62386 2.25 12C2.25 17.3761 6.62386 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62386 17.3761 2.25 12 2.25Z"
                            fill="#2D864A"
                            stroke="#2D864A"
                          />
                          <path
                            d="M12 15.25C11.59 15.25 11.25 14.91 11.25 14.5V8.5C11.25 8.09 11.59 7.75 12 7.75C12.41 7.75 12.75 8.09 12.75 8.5V14.5C12.75 14.91 12.41 15.25 12 15.25Z"
                            fill="#2D864A"
                          />
                          <path
                            d="M11.9975 16.2499C11.8075 16.2499 11.6175 16.1799 11.4675 16.0299L8.4675 13.0299C8.1775 12.7399 8.1775 12.2599 8.4675 11.9699C8.7575 11.6799 9.2375 11.6799 9.5275 11.9699L11.9975 14.4399L14.4675 11.9699C14.7575 11.6799 15.2375 11.6799 15.5275 11.9699C15.8175 12.2599 15.8175 12.7399 15.5275 13.0299L12.5275 16.0299C12.3775 16.1799 12.1875 16.2499 11.9975 16.2499Z"
                            fill="#2D864A"
                          />
                        </svg>
                      </div>
                      <div className="overview-card-header-item-2 ">
                        <span className="overview-card-header-item-text-mute">
                          Yesterday
                        </span>
                        <span>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.25 2.625L9.625 7L5.25 11.375"
                              stroke="#666666"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    {selectedAcctNum.length > 0 ? (
                      <div className="text-number-blod-overflow">
                        {availableDataByAccount?.totalInflow
                          ? availableDataByAccount?.totalInflow?.toLocaleString(
                              "en-NG",
                              {
                                style: "currency",
                                currency: "NGN",
                              }
                            )
                          : "₦0"}
                      </div>
                    ) : (
                      <div className="text-number-blod-overflow">
                        {availableData?.inflow
                          ? availableData?.inflow?.toLocaleString("en-NG", {
                              style: "currency",
                              currency: "NGN",
                            })
                          : "₦0"}
                      </div>
                    )}

                    <div className="text-bottom-overview">Total Inflow</div>
                  </div>
                </div>
                <div className="overview-card-3 cursor-pointer">
                  {" "}
                  <div className="overview-card-root-content">
                    <div className="overview-card-header">
                      <div className="overview-card-header-item-1">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22 12C22 17.52 17.52 22 12 22C6.48 22 3.11 16.44 3.11 16.44M3.11 16.44H7.63M3.11 16.44V21.44M2 12C2 6.48 6.44 2 12 2C18.67 2 22 7.56 22 7.56M22 7.56V2.56M22 7.56H17.56"
                            stroke="#AE8704"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="overview-card-header-item-2 ">
                        <span className="overview-card-header-item-text-mute">
                          Yesterday
                        </span>
                        <span>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.25 2.625L9.625 7L5.25 11.375"
                              stroke="#666666"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    {selectedAcctNum.length > 0 ? (
                      <div className="text-number-blod-overflow">
                        {availableDataByAccount?.balance
                          ? availableDataByAccount?.balance?.toLocaleString(
                              "en-NG",
                              {
                                style: "currency",
                                currency: "NGN",
                              }
                            )
                          : "₦0"}{" "}
                      </div>
                    ) : (
                      <div className="text-number-blod-overflow">
                        {availableData?.inflow
                          ? (
                              availableData?.inflow - availableData?.outflow
                            )?.toLocaleString("en-NG", {
                              style: "currency",
                              currency: "NGN",
                            })
                          : "₦0"}{" "}
                      </div>
                    )}

                    <div className="text-bottom-overview">Net Cash Flow</div>
                  </div>
                </div>
              </div>
              <div className="overview-chat">
                <Line options={options} data={data} />
              </div>
            </div>
          </main>

          <ReviewerDashboard
            requestList={awaitingRequest}
            mutate={mutateRequests}
          />
        </div>
      )}

      {SuccessModal()}
    </>
  );
};

export { Dashboard };
