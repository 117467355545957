import React, {useState} from 'react';
import '../../../../../App.css';
import {Layout} from '../../layout';
import {useNavigate} from "react-router-dom";
// @ts-ignore
// import SalaryPayment from '../../../../../core/assets/files/SalaryPayment.csv';
import SalaryPayment from '../../../../../core/assets/files/SalaryPayment.temp.csv';
import InstitutionCode from '../../../../../core/assets/files/institutionCode.csv';




const PayrollPayments = () => {
    const [selectedOption, setSelectedOption] = useState("");

    const navigate = useNavigate();

    const handleSubmit = () => {
        if (selectedOption === "salary-only") {
            navigate("/admin/payment/salary-only");
        } else if (selectedOption === "salary-all") {
            navigate("/admin/payment/salary-only");
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <>
            <div className={'payrollPayments'}>
                <div className={'payrollPaymentsBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#6366F1"
                        />
                    </svg>
                    <div>Salary Payments</div>
                </div>


                <div className={'payrollPaymentsRadioButtons'}>
                    <div className={'ppText'}>Salary Payments</div>
                    <div style={{textAlign: "center"}}>
                        <a className={'downloadTemplate'} href={SalaryPayment}>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.5 7.5H8V11H8.5M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                                    stroke="#48B460" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M7.875 6C8.28921 6 8.625 5.66421 8.625 5.25C8.625 4.83579 8.28921 4.5 7.875 4.5C7.46079 4.5 7.125 4.83579 7.125 5.25C7.125 5.66421 7.46079 6 7.875 6Z"
                                    fill="#48B460"/>
                            </svg>
                        </span>

                            Download a Salary Only
                        </a>
                            {/* <a className={'downloadTemplate'} href={SalaryPaymentAll}>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.5 7.5H8V11H8.5M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                                    stroke="#48B460" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M7.875 6C8.28921 6 8.625 5.66421 8.625 5.25C8.625 4.83579 8.28921 4.5 7.875 4.5C7.46079 4.5 7.125 4.83579 7.125 5.25C7.125 5.66421 7.46079 6 7.875 6Z"
                                    fill="#48B460"/>
                            </svg>
                        </span>

                            Download a Salary All
                        </a><br /> */}
                        <a className={'downloadTemplate'} href={InstitutionCode}>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.5 7.5H8V11H8.5M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                                    stroke="#48B460" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M7.875 6C8.28921 6 8.625 5.66421 8.625 5.25C8.625 4.83579 8.28921 4.5 7.875 4.5C7.46079 4.5 7.125 4.83579 7.125 5.25C7.125 5.66421 7.46079 6 7.875 6Z"
                                    fill="#48B460"/>
                            </svg>
                        </span>

                            Download a Institution Code
                        </a>

                    </div>


                    <label>
                        <input
                            style={{marginRight: '15px'}}
                            type="radio"
                            value="salary-only"
                            checked={selectedOption === "salary-only"}
                            onChange={handleOptionChange}
                        />
                        Salary only
                    </label>

                    {/* <label>
                        <input
                            style={{marginRight: '15px'}}
                            type="radio"
                            value="salary-all"
                            checked={selectedOption === "salary-all"}
                            onChange={handleOptionChange}
                        />
                        Salary all
                    </label> */}

                    <div className={'ppProceedButton'}>
                        <button
                            style={{background: selectedOption ? '#6366F1' : ' #666666'}}
                            onClick={handleSubmit}>Proceed</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PayrollPayments;
