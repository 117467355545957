import {FlapperSpinner} from "react-spinners-kit";
import {useMdaActivate, useMdaDetails} from "../../../auth/hooks/useUser";
import {DatePicker, Select, Tabs} from "antd";
import dayjs from "dayjs";
import React from "react";
import {Doughnut, Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement, PointElement,
} from "chart.js";
import {faker} from "@faker-js/faker";
import OagfReceipts from "./OagfReceipts";
import OagfExpenses from "./OagfExpenses";


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

export const lineOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Chart.js Line Chart",
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    },
};

const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export const lineData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',],
    datasets: [
        {
            label: 'Expense',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 2000000000 })),
            borderColor: "#979797",
            pointRadius: 0,
            backgroundColor: "#979797", // set the backgroundColor property to transparent to remove the background lines
            borderWidth: 1,
        },
        {
            label: 'Receipt',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 2000000000 })),
            borderColor: "#666666",
            pointRadius: 0,
            backgroundColor: "#666666", // set the backgroundColor property to transparent to remove the background lines
            borderWidth: 1,
        },
    ],
};


const dateFormat = "YYYY/MM/DD";
const OagfExpenseReceiptView = () => {
    // const navigate = useNavigate()

    const chartWidth = 100; // Set the desired width
    const chartHeight = 100;

    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const chartData = {
        labels: ['Expense', 'Receipt'],
        datasets: [{
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000000 })),
            backgroundColor: [
                '#979797',
                '#666666',
                // Add more colors if you have more data points
            ],
        }],
    };



    const {
        isLoading,
    } = useMdaDetails();

    const {
        data: activateData,
        mutate: activateMutate,
        isLoading: activateLoading,
    } = useMdaActivate();


    return (
       <>
                {isLoading || activateLoading ? (
                    <div className="loading-container">
                        <FlapperSpinner/> {/* Replace with your loading indicator */}
                    </div>
                ) : (
                    <div className={"expReceiptView"}>
                       <div className={"expReceiptViewRow1"}>
                           <div className={"expReceiptViewBreadcrumb"}>
                               <div>Analytics</div>

                               <svg width="10" height="13" viewBox="0 0 10 13" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                   <path
                                       d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                       fill="#6366F1"/>
                               </svg>

                               <div>Expense vs Receipt View</div>
                           </div>

                           <div>
                               <div className="mx-1">
                                   <Select
                                       style={{ width: 150 }}
                                       options={[
                                           { value: "Last 30 Days", label: "Last 30 Days" },
                                           { value: "Last 60 Days", label: "Last 60 Days" },
                                           { value: "Last 90 Days", label: "Last 90 Days" },
                                       ]}
                                       defaultValue={"Last 30 Days"}
                                       dropdownMatchSelectWidth={false}
                                       size="large"
                                   />
                               </div>

                               <div>
                                   <span className="data-range-text-overview">Date Range:</span>
                                   <span className="data-from-text-overview">From</span>
                                   <DatePicker
                                       defaultValue={dayjs("2015/01/01", dateFormat)}
                                       format={dateFormat}
                                       className="datepicker"
                                   />
                                   <span className="data-from-text-overview">To</span>
                                   <DatePicker
                                       defaultValue={dayjs("2015/01/01", dateFormat)}
                                       format={dateFormat}
                                       className="datepicker"
                                   />{" "}
                               </div>
                           </div>
                       </div>

                        <div className={"expReceiptViewRow2"}>
                            <div>
                                <div>Expense vs Receipts View</div>

                                <div>
                                    This represents all expense and receipts for the selected MDA
                                </div>
                            </div>

                            <div>
                                <Line options={lineOptions} data={lineData} />
                            </div>
                        </div>

                        <div className={"expReceiptViewRow3"}>
                           <div>
                               <div style={{ width: '200px', height: '200px' }}>
                                   <Doughnut  options={chartOptions}   width={chartWidth} height={chartHeight}  data={chartData} />
                               </div>
                           </div>

                            <div>
                                <div>
                                    <div>Total Expenses</div>
                                    <div>N998M</div>
                                    <div>View Report</div>
                                </div>

                                <div>
                                    <div>Total Receipts</div>
                                    <div>N308M</div>
                                    <div>View Report</div>
                                </div>
                            </div>
                        </div>

                        <div className={'transHistory'}>Transaction History</div>

                        <div className={'expReceiptViewTab'}>
                            <Tabs>
                                <Tabs.TabPane tab="Expenses" key="expenses">
                                    <OagfExpenses/>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Receipts" key="receipts">
                                    <OagfReceipts/>
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                    </div>
                )}
            </>
    );
};

export default OagfExpenseReceiptView;
