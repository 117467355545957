export const EditPencilSvg = ({ onClick }) => {
  return (
    <div className="d-flex justify-content-center align-items-center gap-3 cursor-pointer" onClick={onClick}>
      <span>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.125 4.375L15.625 6.875M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z"
            stroke="#0866FF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>

      <span className="fs-8 text-primary">Edit</span>
    </div>
  );
};
