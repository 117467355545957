import React from "react";
import {Button, Checkbox, Form, Input, InputNumber, Radio, Select} from "antd";
import {useOrganizationKYC} from "../../../../auth/hooks/NewSimplyPay/onboarding";


interface AddBusinessRepresentativeProps {
    onNext: (payload: any) => void;
}

const AddBusinessRepresentative: React.FC<AddBusinessRepresentativeProps> = ({ onNext }) => {
    const {data, mutate, isLoading} = useOrganizationKYC();


    const countries = [
        {
            label: 'Asia',
            options: [
                { label: 'Afghanistan', value: 'afghanistan' },
                { label: 'China', value: 'china' },
            ],
        },
        {
            label: 'Africa',
            options: [
                { label: 'Algeria', value: 'algeria' },
                { label: 'Nigeria', value: 'nigeria' },
            ],
        },
        {
            label: 'Europe',
            options: [
                { label: 'France', value: 'france' },
                { label: 'Germany', value: 'germany' },
            ],
        },
        {
            label: 'North America',
            options: [
                { label: 'Canada', value: 'canada' },
                { label: 'United States', value: 'us' },
            ],
        },
        {
            label: 'South America',
            options: [
                { label: 'Argentina', value: 'argentina' },
                { label: 'Brazil', value: 'brazil' },
            ],
        },
        {
            label: 'Oceania',
            options: [
                { label: 'Australia', value: 'australia' },
                { label: 'New Zealand', value: 'new-zealand' },
            ],
        },
    ];

    const onFinish = (values: any) => {
        console.log('Success:', values);

        const orgId = localStorage.getItem('orgId')

        const payload: any = {
            "serialVersionUID": 0,
            "id": "",
            "code": "",
            "description": "",
            "fileName": "",
            "organizationId": orgId,
            "businessRepresentatives": [{
                "firstName": values.firstName,
                "lastName": values.lastName,
                "dateOfBirth": values.dateOfBirth,
                "country": values.nationality,
                "businessRole": "OWNER",
                "percentageShare": 0,
                "idDocument": values.identificationDocument,
                "idDocumentNumber": values.documentNumber,
                "idDocumentPath": "",
                "addressLine1": values.addressLine1,
                "addressLine2": values.addressLine2,
                "city": values.city,
                "state": values.stateRegion,
                "documentType": values.documentType,
                "documentPath": "",
                "documents": [""]
            }],
            "documents": [""]
        }

        mutate(payload, {
            onSuccess: (res: any) => {
                onNext(payload)
            },
        })

    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    return (
    <div className={'businessSetup'}>
        <Form
            name="basicInformation"
            initialValues={{remember: true}}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div className={'profileInformation'}>
                Profile Information
            </div>

            <div className={ 'businessDetails'}>
                <Form.Item
                    label="First name"
                    name="firstName"
                    rules={[{required: true, message: 'First name is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>

                <Form.Item
                    label="Last name"
                    name="lastName"
                    rules={[{required: true, message: 'Last name is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>
            </div>

            <div className={ 'businessDetails'}>
                <Form.Item
                    label="Date of birth"
                    name="dateOfBirth"
                    rules={[{required: true, message: 'Date of birth is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>

                <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[{required: true, message: 'Nationality is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>
            </div>

            <div className={ 'businessDetails'}>
                <Form.Item
                    label="Role at the business"
                    name="businesRole"
                    rules={[{required: true, message: 'Date of birth is required'}]}
                >
                    <Radio.Group options={['Owner', 'Director', 'Shareholder']} />
                </Form.Item>

                <Form.Item
                    label="Percentage owned"
                    name="percentageOwned"
                    rules={[{required: true, message: 'Percentage owned is required'}]}
                >
                    <InputNumber min={1} max={100} defaultValue={0} addonAfter="%" />
                </Form.Item>
            </div>

            <div className={'profileInformation'}>
                Identity Verification
            </div>

            <div className={ 'businessDetails'}>
                <Form.Item
                    label="Identification document"
                    name="identificationDocument"
                    rules={[{required: true, message: 'Identification document is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>

                <Form.Item
                    label="Document number"
                    name="documentNumber"
                    rules={[{required: true, message: 'Document number is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>
            </div>

            <div className={ 'businessDetails'}>
                <div>
                    <label className="required fw-bold fs-9">
                        Identification document
                    </label>
                    <div className={`form-control form-control-solid fileUpload`}>
                        <label>
                            <div>Click here to upload document</div>
                            <input
                                hidden
                                id="input"
                                type="file"
                                // accept="text/csv"
                                // onChange={extractData}
                            />
                        </label>
                    </div>

                </div>
            </div>

            <div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.38 9.38H10V13.76H10.62M10 17.5C14.14 17.5 17.5 14.14 17.5 10C17.5 5.86 14.14 2.5 10 2.5C5.86 2.5 2.5 5.86 2.5 10C2.5 14.14 5.86 17.5 10 17.5ZM10 7.01C10.25 7.01 10.45 6.81 10.45 6.56C10.45 6.31 10.25 6.11 10 6.11C9.75 6.11 9.55 6.31 9.55 6.56C9.55 6.81 9.75 7.01 10 7.01Z" stroke="#3DADFE" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <span>Accepted document formats are .jpg, .png or .pdf.</span>
            </div>

            <div className={'profileInformation'}>
                Home Address
            </div>

            <div className={ 'businessDetails'}>
                <Form.Item
                    label="Country"
                    name="businessCountry"
                    rules={[{required: true, message: 'Country is required'}]}
                >
                    <Select
                        size="large"
                        placeholder="Select a country"
                        options={countries}
                    >
                    </Select>
                </Form.Item>

                <Form.Item
                    label="State/Region"
                    name="stateRegion"
                    rules={[{required: true, message: 'State/Region is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>
            </div>

            <div className={ 'businessDetails'}>
                <Form.Item
                    label="City"
                    name="city"
                    rules={[{required: true, message: 'City is required'}]}
                >
                    <Input size="large"/>
                </Form.Item>

                <div>
                    <label className="required fw-bold fs-9">
                        Proof of address document
                    </label>
                    <div className={`form-control form-control-solid fileUpload`}>
                        <label>
                            <div>Click here to upload document</div>
                            <input
                                hidden
                                id="input"
                                type="file"
                                // accept="text/csv"
                                // onChange={extractData}
                            />
                        </label>
                    </div>

                </div>
            </div>


            <Form.Item
                label="Address line 1"
                name="addressLine1"
                rules={[{required: true, message: 'Address line 1 is required'}]}
            >
                <Input size="large"/>
            </Form.Item>

            <Form.Item
                label="Address line 2"
                name="addressLine2"
            >
                <Input size="large"/>
            </Form.Item>

            <div className={'proofOfAddress'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.38 9.38H10V13.76H10.62M10 17.5C14.14 17.5 17.5 14.14 17.5 10C17.5 5.86 14.14 2.5 10 2.5C5.86 2.5 2.5 5.86 2.5 10C2.5 14.14 5.86 17.5 10 17.5ZM10 7.01C10.25 7.01 10.45 6.81 10.45 6.56C10.45 6.31 10.25 6.11 10 6.11C9.75 6.11 9.55 6.31 9.55 6.56C9.55 6.81 9.75 7.01 10 7.01Z" stroke="#3DADFE" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div>
                    Proof of address document can be bank statements, utility bills (electricity, water, DSTV, etc),
                    tax assessment, or a letter from a government authority
                </div>
            </div>

            {/*<div className={'addBusinessRep'}>*/}
            {/*    <button onClick={onNext}>Add another business representative</button>*/}
            {/*</div>*/}


            <div className={'businessRegNextButton'}>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </div>
        </Form>




      </div>
  );
};

export { AddBusinessRepresentative };
