import { Table } from "antd";
import { formatMoneyLocal } from "core/helpers";
import React from "react";
import { EditPencilSvg } from "../component/EditPencilSvg";



interface PreviewProps {
  goBack: () => void;
  step: any;
  setStep: (e: number) => void;
  setSelectedRecord: (e: any) => void;
  setShowDirectorModal: (e: boolean) => void;
  setShowDirectorDeleteModal: (e: boolean) => void;
  directorTable: any[];
  shareholderTable: any[];
  setShowShareholderModal: (e: boolean) => void;
  setShowShareholderDeleteModal: (e: boolean) => void;
  stepOneData:any
}

const Preview: React.FC<PreviewProps> = ({
  goBack,
  step,
  setStep,
  setSelectedRecord,
  setShowDirectorModal,
  setShowDirectorDeleteModal,
  directorTable,
  stepOneData,
  shareholderTable,
  setShowShareholderModal,
  setShowShareholderDeleteModal,
}) => {

  const directorColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Official Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Bvn",
      dataIndex: "bvn",
      key: "bvn",
    },

    {
      title: "Actions ",
      dataIndex: "actions",

      render: (_: any, record: any) => (
        <div className={`d-flex gap-2`}>
          <div
            className="text-primary fs-8 d-flex align-items-center gap-1 cursor-pointer"
            onClick={() => editRecord(record, setShowDirectorModal)}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.125 4.875L15.625 7.375M16.875 9.875V16.75C16.875 16.9158 16.8092 17.0747 16.6919 17.1919C16.5747 17.3092 16.4158 17.375 16.25 17.375H3.75C3.58424 17.375 3.42527 17.3092 3.30806 17.1919C3.19085 17.0747 3.125 16.9158 3.125 16.75V4.25C3.125 4.08424 3.19085 3.92527 3.30806 3.80806C3.42527 3.69085 3.58424 3.625 3.75 3.625H10.625M10 13H7.5V10.5L15 3L17.5 5.5L10 13Z"
                stroke="#0866FF"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Edit</span>
          </div>

          <div
            className="text-danger fs-8 d-flex align-items-center gap-1 cursor-pointer"
            onClick={() => removeRecord(record,setShowDirectorDeleteModal)}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8753 8.625V13.625M8.12531 8.625V13.625M16.875 4.875L3.125 4.87501M15.6253 4.875V16.75C15.6253 16.9158 15.5595 17.0747 15.4422 17.1919C15.325 17.3092 15.1661 17.375 15.0003 17.375H5.00031C4.83455 17.375 4.67557 17.3092 4.55836 17.1919C4.44115 17.0747 4.37531 16.9158 4.37531 16.75V4.875M13.1253 4.875V3.625C13.1253 3.29348 12.9936 2.97554 12.7592 2.74112C12.5248 2.5067 12.2068 2.375 11.8753 2.375H8.12531C7.79378 2.375 7.47584 2.5067 7.24142 2.74112C7.007 2.97554 6.87531 3.29348 6.87531 3.625V4.875"
                stroke="#EC2106"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Delete</span>
          </div>
        </div>
      ),
    },
  ];

  const shareholderColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Official Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Shared Owned (%)",
      dataIndex: "sharedOwned",
      key: "sharedOwned",
    },

    {
      title: "ID Type",
      dataIndex: "idType",
      key: "idType",
    },

    {
      title: "ID Document",
      dataIndex: "idDocument",
      key: "idDocument",
    },

    {
      title: "Actions ",
      dataIndex: "actions",

      render: (_: any, record: any) => (
        <div className={`d-flex gap-2`}>
          <div
            className="text-primary fs-8 d-flex align-items-center gap-1 cursor-pointer"
            onClick={() => editRecord(record, setShowShareholderModal)}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.125 4.875L15.625 7.375M16.875 9.875V16.75C16.875 16.9158 16.8092 17.0747 16.6919 17.1919C16.5747 17.3092 16.4158 17.375 16.25 17.375H3.75C3.58424 17.375 3.42527 17.3092 3.30806 17.1919C3.19085 17.0747 3.125 16.9158 3.125 16.75V4.25C3.125 4.08424 3.19085 3.92527 3.30806 3.80806C3.42527 3.69085 3.58424 3.625 3.75 3.625H10.625M10 13H7.5V10.5L15 3L17.5 5.5L10 13Z"
                stroke="#0866FF"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Edit</span>
          </div>

          <div
            className="text-danger fs-8 d-flex align-items-center gap-1 cursor-pointer"
            onClick={() => removeRecord(record,setShowShareholderModal)}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8753 8.625V13.625M8.12531 8.625V13.625M16.875 4.875L3.125 4.87501M15.6253 4.875V16.75C15.6253 16.9158 15.5595 17.0747 15.4422 17.1919C15.325 17.3092 15.1661 17.375 15.0003 17.375H5.00031C4.83455 17.375 4.67557 17.3092 4.55836 17.1919C4.44115 17.0747 4.37531 16.9158 4.37531 16.75V4.875M13.1253 4.875V3.625C13.1253 3.29348 12.9936 2.97554 12.7592 2.74112C12.5248 2.5067 12.2068 2.375 11.8753 2.375H8.12531C7.79378 2.375 7.47584 2.5067 7.24142 2.74112C7.007 2.97554 6.87531 3.29348 6.87531 3.625V4.875"
                stroke="#EC2106"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Delete</span>
          </div>
        </div>
      ),
    },
  ];

  const DirectorTable: React.FC<any> = () => {
    return (
      <Table
        dataSource={directorTable}
        bordered
        columns={directorColumns}
        scroll={{ x: "1000px" }}
      />
    );
  };

  const ShareHolderTable: React.FC<any> = () => {
    return (
      <Table
        dataSource={shareholderTable}
        bordered
        columns={shareholderColumns}
        scroll={{ x: "1000px" }}
      />
    );
  };


  console.log('shareholderTable==>', shareholderTable ,"\n directorTable", directorTable);
  
  const editRecord = (e: any,showModalFn: (e: boolean) => void) => {
    setSelectedRecord(e);
    showModalFn(true);
  };

  const removeRecord = (e: any,showModalFn: (e: boolean) => void) => {
    setSelectedRecord(e);
    showModalFn(true);
  };

  return (
    <div
      className="form align-middle  gx-0 gy-5 mt-3"
    >
      <div className="preview-container">
        <div className="card m-auto project-preview-card pt-0">
          <div className="text-center preview-header">
            <p className="text-success fs-5 fw-bold lh-lg mb-1">PREVIEW</p>
            <p>
              Please ensure all information entered is correct before you
              proceed to submit
            </p>
          </div>
          <div>
            <div className="d-flex justify-content-between px-5">
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-dark fw-bold lh-lg">
                  Registration, Tax and Proof of Address
                </span>
              </div>

              <EditPencilSvg
                onClick={() => {
                  setStep(1);
                }}
              />
            </div>

            <div className="px-5">
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bold text-start min-w-25">
                  Company Registration Documents
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 text-start min-w-25">
                  Certificate of Incorporation
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {stepOneData?.certificate_Of_Incorporation}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 text-start min-w-25">
                  Memorandum & Articles of Association
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {stepOneData?.memorandum}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 text-start min-w-25">
                  Operating License
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {stepOneData?.operatingLicense}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 text-start min-w-25">
                  Year of Incorporation
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {stepOneData?.year_Of_Incorporation}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 text-start min-w-25">
                  Form CAC 1.1
                </span>
                <span className="text-dark fs-8 fw-bold text-end">{stepOneData?.tin}</span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bold text-start min-w-25">
                  Tax Document
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 text-start min-w-25">
                  Certificate of Taxpayer Identification Number
                </span>
                <span className="text-dark fs-8 fw-bold text-end">{stepOneData?.tin}</span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bold text-start min-w-25">
                  Proof of Address
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 text-start min-w-25">
                  Document Type
                </span>
                <span className="text-dark fs-8 fw-bold text-end">{stepOneData?.identificationDocument}</span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 text-start min-w-25">
                  Proof of Address Document
                </span>
                <span className="text-dark fs-8 fw-bold text-end">{stepOneData?.proof_address}</span>
              </div>

              <hr className="text-muted h-1px" />
            </div>
          </div>
        </div>

        <div className="card m-auto project-preview-card">
          <div className="d-flex justify-content-between px-5">
            <div className="d-flex justify-content-center align-items-center">
              <span className="text-dark fs-base fw-bold lh-lg">
                Registered Directors Information
              </span>
            </div>

            <div
              className="d-flex justify-content-center align-items-center cursor-pointer gap-2"
              onClick={() => {
                setShowDirectorModal(true)
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9H17M9 1V17"
                  stroke="#0866FF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="text-primary fs-8 ">Add more director</span>
            </div>
          </div>

          <div className="px-5 mt-5">
            <DirectorTable />

            <hr className="text-muted h-1px mt-10" />
          </div>
        </div>

        <div className="card m-auto p-5 project-preview-card">
          <div className="d-flex justify-content-between ">
            <div className="d-flex justify-content-center align-items-center">
              <span className="text-dark fw-bold lh-lg">
                Shareholders Information
              </span>
            </div>

            <div
              className="d-flex justify-content-center align-items-center cursor-pointer gap-2"
              onClick={() => {
                setShowShareholderModal(true)
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9H17M9 1V17"
                  stroke="#0866FF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="text-primary fs-8 ">Add more shareholder</span>
            </div>
          </div>

          <div className=" mt-5">
            <ShareHolderTable />

            <hr className="text-muted h-1px mt-10" />
          </div>
        </div>

        <div className="d-flex  justify-content-center mt-5">
          <div className="">
            <button
              id="kt_password_cancel "
              type="submit"
              className={` btn ${false ? "btn-secondary" : "btn-success"}
                 text-white px-15 py-2 h-44 w-min-110 rounded-1`}
              // disabled={!(formik.isValid && formik.dirty) || isEmpty(workflow)}
            >
              <span className="indicator-label fs-base">Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
