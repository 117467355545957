import React, {useState} from "react";
import {Button, Form, Input, Select, Steps} from "antd";
import {useNavigate} from "react-router-dom";
import {useStarterBusiness} from "../../auth/hooks/NewSimplyPay/onboarding";

export const GovernmentEntitySetup = () => {
    const { data, mutate, isLoading } = useStarterBusiness();
    const [currentStage, setCurrentStage] = useState(1);


    const countries = [
        {
            label: 'Asia',
            options: [
                { label: 'Afghanistan', value: 'afghanistan' },
                { label: 'China', value: 'china' },
            ],
        },
        {
            label: 'Africa',
            options: [
                { label: 'Algeria', value: 'algeria' },
                { label: 'Nigeria', value: 'nigeria' },
            ],
        },
        {
            label: 'Europe',
            options: [
                { label: 'France', value: 'france' },
                { label: 'Germany', value: 'germany' },
            ],
        },
        {
            label: 'North America',
            options: [
                { label: 'Canada', value: 'canada' },
                { label: 'United States', value: 'us' },
            ],
        },
        {
            label: 'South America',
            options: [
                { label: 'Argentina', value: 'argentina' },
                { label: 'Brazil', value: 'brazil' },
            ],
        },
        {
            label: 'Oceania',
            options: [
                { label: 'Australia', value: 'australia' },
                { label: 'New Zealand', value: 'new-zealand' },
            ],
        },
    ];

    const mdaSector = [
        { label: "Information Technology (IT)", value: "it" },
        { label: "Healthcare", value: "healthcare" },
        { label: "Finance and Banking", value: "finance" },
        { label: "Manufacturing", value: "manufacturing" },
        { label: "Retail", value: "retail" },
        { label: "Education", value: "education" },
        { label: "Construction", value: "construction" },
        { label: "Hospitality and Tourism", value: "hospitality" },
        { label: "Telecommunications", value: "telecom" },
        { label: "Transportation and Logistics", value: "logistics" },
        { label: "Energy and Utilities", value: "energy" },
        { label: "Agriculture", value: "agriculture" },
        { label: "Media and Entertainment", value: "media" },
        { label: "Real Estate", value: "real-estate" },
        { label: "Automotive", value: "automotive" },
        { label: "Pharmaceuticals", value: "pharmaceuticals" },
        { label: "Aerospace and Defense", value: "aerospace-defense" },
        { label: "Government and Public Administration", value: "government" },
        { label: "Non-profit and Social Services", value: "non-profit" },
        { label: "Legal Services", value: "legal" },
        { label: "Consulting", value: "consulting" },
        { label: "Marketing and Advertising", value: "marketing" },
        { label: "Environmental Services", value: "environmental" },
        { label: "Research and Development", value: "research" },
        { label: "Insurance", value: "insurance" },
        { label: "Human Resources", value: "hr" },
        { label: "Food and Beverage", value: "food-beverage" },
        { label: "Fitness and Wellness", value: "fitness-wellness" },
        { label: "Fashion and Apparel", value: "fashion-apparel" },
        { label: "Art and Design", value: "art-design" }
    ];

    const onFinish = (values: any) => {
        console.log('Success:', values);

        // setFormData(values)
        const payload: any = {
            "registeredName": values.businessName,
            "tradingName": values.tradingName,
            "mdaAdminCode": "RC123459",
            "description": values.businessDescription,
            "contact": {
                "physicalAddress": values.streetAddress,
                "phoneNumber": values.businessPhoneNumber,
                "websiteUrl": values.website,
                "email": values.streetAddress,
                "businessEmail": values.businessEmail,
                "supportEmail": values.supportEmail,
                "disputeEmail": values.disputeEmail,
                "twitterHandle": values.twitter,
                "facebookHandle": values.facebook,
                "instagramHandle": values.instagram,
                "country": values.country,
                "state": values.stateRegion,
                "city": values.city,
                "buildingName": values.buildingName,
                "floor": values.floorUnitNumber
            },
            "bankDetails": [
                {
                    "bankName": values.bankName,
                    "accountNumber": values.accountNumber,
                    "accountName": values.bankName,
                    "dailyLimit": 0.0,
                    "accountType": {
                        "code": "",
                        "description": ""
                    },
                    "orgId": "",
                    "balance": 0.0,
                    "institutionCode": "123456"
                }
            ],
            "registrationType": "PRIVATE_INCORPORATED",
            "organizationType": {
                "code": "STARTER",
                "description": "",
                "orgType": "CORPORATE",
                "address": "",
                "documents": []
            },
            "kycDocuments": {},
            "sector": {
                "code": "RC654323",
                "description": "Affairs"
            },
            "staffSize": "",
            "industryType": "COMMERCE",
            "category": "REAL_ESTATE"
        }

        mutate(payload, {
            onSuccess: (res: any) => {
                navigate('/admin/profile-setup/government-business/account-setup')
            },
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const navigate = useNavigate();


    return (
        <>
            <div className={'onboarding'}>
                <div className={'onboardingForm'}>
                    <div className={'onboardingFormRow1'}>
                        Government entity setup
                    </div>

                    <div className={'onboardingFormRow2'}>
                    <div style={{fontWeight: 500, fontSize: '16px', marginBlock: '10px'}}>Entity Details</div>

                    <Form
                        name="governmentEntitySetup"
                        initialValues={{remember: true}}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="Registered entity name"
                                name="registeredEntityName"
                                rules={[{required: true, message: 'Registered entity name is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="Administrative code"
                                name="administrativeCode"
                                rules={[{required: true, message: 'Administrative code  is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="Trading name"
                                name="tradingName"
                                rules={[{required: true, message: 'Trading name is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="MDA sector"
                                name="mdaSector"
                                rules={[{required: true, message: 'MDA sector is required'}]}
                            >
                                <Select
                                    size="large"
                                    placeholder="Select a sector"
                                    options={mdaSector}
                                />
                            </Form.Item>
                        </div>

                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="MDA Parent Organization"
                                name="mdaParentOrganization"
                                rules={[{required: true, message: 'MDA Parent Organization is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="Brief description of entity"
                                name="briefDescriptionOfEntity"
                                rules={[{required: true, message: 'Brief description of entity is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div style={{fontWeight: 500, fontSize: '16px', marginBlock: '10px'}}>Contact Details</div>

                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="Entity country"
                                name="entityCountry"
                                rules={[{required: true, message: 'Entity country is required'}]}
                            >
                                <Select
                                    size="large"
                                    placeholder="Select a country"
                                    options={countries}
                                >
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Entity State/Region"
                                name="stateRegion"
                                rules={[{required: true, message: 'Entity State/Region is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>



                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="Entity city"
                                name="entityCity"
                                rules={[{required: true, message: 'Entity city is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div>
                            <Form.Item
                                label="Street address"
                                name="streetAddress"
                                rules={[{required: true, message: 'Street address is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="Entity phone number"
                                name="entityPhoneNumber"
                                rules={[{required: true, message: 'Entity phone number is invalid', pattern: /^\+(?:[0-9] ?|[0-9]\([0-9]+\)[\s-]?){6,14}[0-9]$/}]}
                            >
                                <Input size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="Entity email address"
                                name="entityEmailAddress"
                                rules={[{required: true, message: 'Entity email address is invalid', type:'email'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="Website URL"
                                name="website"
                                rules={[{ message: 'Website is invalid', pattern: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/}]}
                            >
                                <Input size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="X (Twitter) handle"
                                name="twitter"
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="Facebook handle"
                                name="facebook"
                            >
                                <Input size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="Instagram handle"
                                name="instagram"
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="LinkedIn handle"
                                name="linkedIn"
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div className={'onboardingNextButton'}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Next
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
               </div>

                {/*<div className={'onboardingForm'}>*/}
                {/*    <div className={'onboardingFormRow'}>*/}
                {/*        <div onClick={() => navigate('/admin/profile-setup/government-business/entity-setup')}>*/}
                {/*            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*                <path d="M10.9016 15.7123C11.1945 16.0052 11.6694 16.0052 11.9623 15.7123C12.2552 15.4194 12.2552 14.9445 11.9623 14.6516L10.9016 15.7123ZM8.25 12L7.71967 11.4697C7.42678 11.7626 7.42678 12.2374 7.71967 12.5303L8.25 12ZM11.9623 9.34836C12.2552 9.05547 12.2552 8.58059 11.9623 8.2877C11.6694 7.99481 11.1945 7.99481 10.9016 8.2877L11.9623 9.34836ZM15.75 12.75C16.1642 12.75 16.5 12.4142 16.5 12C16.5 11.5858 16.1642 11.25 15.75 11.25V12.75ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75ZM11.9623 14.6516L8.78033 11.4697L7.71967 12.5303L10.9016 15.7123L11.9623 14.6516ZM8.78033 12.5303L11.9623 9.34836L10.9016 8.2877L7.71967 11.4697L8.78033 12.5303ZM8.25 12.75H15.75V11.25H8.25V12.75Z" fill="#2A7CB4"/>*/}
                {/*            </svg>*/}
                {/*        </div>*/}

                {/*        <div>Government entity setup</div>*/}
                {/*    </div>*/}

                {/*    <div className={'onboardingFormRow2'}>*/}
                {/*        <div style={{fontWeight: 500, fontSize: '16px', marginBlock: '10px'}}>Bank Account Details</div>*/}

                {/*        <Form*/}
                {/*            name="governmentEntitySetup"*/}
                {/*            initialValues={{remember: true}}*/}
                {/*            layout="vertical"*/}
                {/*            onFinish={onFinish}*/}
                {/*            onFinishFailed={onFinishFailed}*/}
                {/*            autoComplete="off"*/}
                {/*        >*/}
                {/*            <div className={'onboardingDetails'}>*/}
                {/*                <Form.Item*/}
                {/*                    label="Bank name"*/}
                {/*                    name="bankName"*/}
                {/*                    rules={[{required: true, message: 'Bank name is required'}]}*/}
                {/*                >*/}
                {/*                    <Input size="large"/>*/}
                {/*                </Form.Item>*/}

                {/*                <Form.Item*/}
                {/*                    label="Bank Account number"*/}
                {/*                    name="accountNumber"*/}
                {/*                    rules={[{required: true, message: 'Account number is required'}]}*/}
                {/*                >*/}
                {/*                    <Input size="large"/>*/}
                {/*                </Form.Item>*/}
                {/*            </div>*/}

                {/*            <div className={'onboardingDetails'}>*/}
                {/*                <Form.Item*/}
                {/*                    label="Daily transaction limit"*/}
                {/*                    name="dailyTransactionLimit"*/}
                {/*                    rules={[{required: true, message: 'Daily transaction limit is required'}]}*/}
                {/*                >*/}
                {/*                    <Input size="large"/>*/}
                {/*                </Form.Item>*/}
                {/*            </div>*/}

                {/*            <div>*/}
                {/*                Add another bank account*/}
                {/*            </div>*/}

                {/*            <div className={'onboardingNextButton'}>*/}
                {/*                <Form.Item>*/}
                {/*                    <Button type="primary" htmlType="submit">*/}
                {/*                        Next*/}
                {/*                    </Button>*/}
                {/*                </Form.Item>*/}
                {/*            </div>*/}
                {/*        </Form>*/}


                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    );
};
