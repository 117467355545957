import {AxiosResponse} from "axios";
import {axiosInstance2} from "../../../../../axiosInstance";
import {useMutation} from "react-query";
import {SuccessToast} from "../../../../componets/toast";
import {ErrorHandler} from "../../../../../core/helpers";

export function useOnboardingPersonalDetails(): any {
    return useMutation(async (values) => {
            return await axiosInstance2.post(
                `/api/onboarding-step-one/personal-details`, values
            );
        },
        {
            onSuccess: (res) => {
                SuccessToast("Your request was successful");
            },
            onError: (err: any) => {
                ErrorHandler(err);
            },
        }
    );
}

export function useStarterBusiness(): any {
    return useMutation(async (values) => {
            return await axiosInstance2.post(
                `/services/organization/api/onboarding-step-two/organizations`, values
            );
        },
        {
            onSuccess: (res) => {
                SuccessToast("Your request was successful");
            },
            onError: (err: any) => {
                ErrorHandler(err);
            },
        }
    );
}

export function useOrganizationKYC(): any {
    return useMutation(async (values) => {
            return await axiosInstance2.post(
                `/services/organization/api/onboarding-step-two/organizations/kyc`, values
            );
        },
        {
            onSuccess: (res) => {
                SuccessToast("Your request was successful");
            },
            onError: (err: any) => {
                ErrorHandler(err);
            },
        }
    );
}

export function useSendBankDetails(): any {
    return useMutation(async (values) => {
            const orgId: string = localStorage.getItem("orgId") as string;

            return await axiosInstance2.post(
                `/services/organization/api/onboarding-step-two/organizations/${orgId}/banks`, values
            );
        },
        {
            onSuccess: (res) => {
                SuccessToast("Your request was successful");
            },
            onError: (err: any) => {
                ErrorHandler(err);
            },
        }
    );
}
