import React from "react";
import { Footer } from "../../componets/footer";
import NavBar from "../../componets/nav/NavBar";
import "./policy.scss";

const PrivacyPolicy: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <NavBar />

      <div className="center-all my-5">
        <span className="text-center text-dark fs-1 fw-bolder">
          Privacy Policy
        </span>
      </div>

      <div className="body-style px-10 my-5 mb-20">

         <div className="frame">
      <p className="div">
        Please read our Privacy Policy before you may become a business or personal user of the VoxePay platform. It is
        an important responsible for you to access our Terms &amp; Agreements, as well as any amendments made thereto
        and to contact VoxePay by Simplify International Synergy Limited (&#34;VoxePay, VPSP, We, Us, Our&#34;) in the
        event of any queries you may have. By using any part of the VoxePay service, you acknowledge and agree to the
        processing of your personal data under the terms and conditions set out in this privacy policy.<br /><br />Before
        you start using the VoxePay platform as either a business or personal user, please take a moment to read through
        our Privacy Policy. It&#39;s important for you to understand our Terms &amp; Agreements, and to reach out to us
        at VoxePay, managed by Simplify International Synergy Limited (&#34;VoxePay,&#34; &#34;VPSP,&#34; &#34;We,&#34;
        &#34;Us,&#34; &#34;Our&#34;), if you have any questions. By using VoxePay services, you agree to the processing
        of your personal data as outlined in this policy.
      </p>
      <div className="div-2">
        <div className="text-wrapper">1.0 Your Privacy Rights</div>
        <div className="div-3">
          <p className="div">
            This Privacy Policy explains your rights concerning our collection, use, storage, sharing, and protection of
            your personal information. It applies to the VoxePay platform, including the website and mobile
            applications, and covers all related services, tools, and content, regardless of how you access them.
            Click HERE to request specific information on your personal data.
          </p>
 
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">2.0 Consent</div>
        <div className="div-wrapper-2">
          <p className="p">
            By signing up for, accessing, or using our products, services, and features on the VoxePay platform, you
            accept this Privacy Policy. This policy applies to all users, both registered and unregistered, unless a
            separate contract overrides it. We may update this policy at any time by posting a revised version on our
            platform or notifying you via email. Changes become effective five (5) days after posting or on a date
            specified in the notification.
          </p>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">3.0 Your Personal Information</div>
        <div className="div-4">
          <div className="div-5">
            <p className="div">
              <span className="span">3.1 Automatic Collection: </span>
              <span className="text-wrapper-3"
                >When you use VoxePay, we automatically receive information from your device, such as IP addresses,
                device IDs, and geolocation data. This includes details on your interactions with our platform and
                traffic data.</span
              >
            </p>
          </div>
          <div className="div-5">
            <p className="div">
              <span className="span">3.2 Directly Provided Information: </span>
              <span className="text-wrapper-3"
                >We collect information that you provide directly, such as transaction details and contact information
                such as; name, address, phone number, email, etc. This may include sensitive data like your Bank
                Verification Number (BVN), National Identification Number (NIN), bank account name and numbers and these
                may be linked to your VoxePay profile, where applicable. Information obtained through survey responses,
                telephone conversations, user enquiries and other means may also be collected and used by us.</span
              >
            </p>
          </div>
          <div className="div-5">
            <p className="div">
              <span className="span">3.3 Third-Party Information: </span>
              <span className="text-wrapper-3"
                >We may use information from third parties like social media sites and verification agencies and we are
                not responsible for how these external sources use your data.</span
              >
            </p>
          </div>
          <div className="div-5">
            <p className="div">
              <span className="span">3.4 Correction and Deletion: </span>
              <span className="text-wrapper-3"
                >You can request to update or delete your information unless we need to keep it for legitimate business
                or legal reasons. We may ask you to verify your identity before we make changes to your personal
                information. We may reject requests that are unreasonably repetitive, require disproportionate technical
                effort (for example, developing a new system or fundamentally changing an existing practice), risk the
                privacy of others, or would be extremely impractical (for instance, requests concerning information
                residing on backup systems). Where we can provide information access and correction, we will do so for
                free, except where it would require a disproportionate effort. We aim to maintain our services in a
                manner that protects information from accidental or malicious destruction. Because of this, after you
                delete information from our services, we may not immediately delete residual copies from our active
                servers and may not remove information from our backup systems.</span
              >
            </p>
          </div>
          <div className="div-5">
            <p className="element-you-can-also-let">
              <span className="span">3.5</span>
              <span className="text-wrapper-4">
                You can also let us know if you feel that your personal information is being processed in any way other
                than how you have consented to it being used. We will take reasonable steps to ensure that your personal
                information is processed in the manner in which you have consented to it, or we may delete it based on
                your instruction. However, we may not accede to your request if it is unrealistic, impractical or
                inimical to our compliance with applicable regulation or laws.</span
              >
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <p className="text-wrapper">4.0 What we do with your personal information</p>
        <div className="div-4">
          <p className="div">
            We collect personal information to give you secure and reliable customer experience. We may use your
            information in the following ways:
          </p>
          <div className="div-5">
            <p className="text-wrapper-5">
              4.1 To provide VoxePay Payment Services and Support; <br /><br />4.2 To verify your identity;<br /><br />4.3
              To process transactions, and send notices about your transactions to requisite parties;<br /><br />4.4 To
              resolve disputes, collect fees, and troubleshoot problems;<br /><br />4.5 To manage risk, or detect,
              prevent, and/or remediate fraud or other potentially prohibited or illegal activities;<br /><br />4.6 To
              detect, prevent or remediate violations of policies or applicable user agreements;<br /><br />4.7 To
              improve VoxePay Services by implementing aggregate user preferences;<br /><br />4.8 To measure performance
              of VoxePay Services and improve content, technology and layout;<br /><br />4.9 To manage and protect our
              information technology infrastructure;<br /><br />4.10 To contact you at any time through your provided
              telephone number, email address or other contact details;<br /><br />4.11 To notify you about your
              account, troubleshoot problems with your account, resolve a dispute, collect fees or monies owed, poll
              your opinions through surveys or questionnaires, enforce our policy, or as otherwise necessary to service
              your account, we may contact you through information provided. VPSP will not request for your Personal
              Identification Number (PIN), Account Identification Number (AIN), Dynamic Personal Identification Number
              (Dynamic PIN) or Bank Verification Number (BVN) from you either through email, telephone call, SMS or
              written documents. You are solely responsible for safeguarding any Personal Identification Number (PIN),
              Account Identification Number (AIN) or Dynamic Authentication Number (Dynamic PIN) you generate in the
              course of using VoxePay.<br /><br />4.12 To notify you of new products, services and/or offerings,
              newsletters and similar messages (“promotional messages”); or any enhancements/amendments to existing
              products services and/or offerings that we think may be of interest to you. If you do not want to receive
              such promotional materials from us, you may opt out at any time by sending an email to dpo@VoxePay.com<br /><br />4.13
              To create stores of value and/or bank accounts for you and in your name with CBN licensed financial
              institution(s) to enable you transact seamlessly on the platform<br /><br />4.14 Carry out other
              activities permitted by law including; the fulfillment of our contract with you, compliance with legal
              and/or regulatory requirements, to protect your vital interest or that of any other natural person, and so
              on.<br /><br />4.15 If we intend to process your personal information for any purpose other than those
              listed in this policy, we shall provide you with relevant information on the further processing and seek
              your consent before the processing commences.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">5.0 Cookies</div>
        <div className="div-4">
          <p className="div">
            <span className="span">5.1 </span>
            <span className="text-wrapper-3"
              >Cookies are small blocks of data placed on your device to enable easy identification of your device as
              you navigate applications and websites in other to store your preferences and deliver content, options or
              functions that are specific to you. Like most interactive websites, and online platforms, VoxePay uses
              cookies to enhance your experience and identify your device during sessions. Our session cookies are
              secure and do not collect information from your device. VoxePay uses only encrypted session cookies which
              are erased either after a predefined timeout period or once the user logs out of VoxePay and closes the
              browser. Session cookies do not collect information from the user&#39;s computer. They will typically
              store information in the form of a session identification that does not personally identify the user.<br /><br
            /></span>
            <span className="span">5.2</span>
            <span className="text-wrapper-3">
              We do not have access or control over the cookies of our partners, affiliates, and service providers. We
              are therefore not responsible for their use of their cookies.<br
            /></span>
          </p>
        </div>
      </div>
      <div className="div-2">
        <p className="text-wrapper">6.0 How we protect your personal information</p>
        <div className="div-4">
          <div className="div-5">
            <p className="div">
              <span className="span">6.1 </span>
              <span className="text-wrapper-3"
                >We store and process your personal information on our computers in Nigeria, Africa and elsewhere in the
                world where our facilities are located. Data protection laws vary among countries, with some providing
                more protection than others. Regardless of where your information is processed, we apply the same
                protections described in this policy. You have a right (upon your request) to be informed of the
                appropriate safeguards for data protection in the foreign country.<br
              /></span>
              <span className="span">6.2</span>
              <span className="text-wrapper-3">
                We protect your information using physical, technical, and administrative security measures to reduce
                the risks of loss, misuse, unauthorized access, disclosure and alteration. Some of the safeguards we use
                are firewalls and data encryption, physical access controls to our data centers, and information access
                authorization controls.<br
              /></span>
              <span className="span">6.3</span>
              <span className="text-wrapper-3">
                Furthermore, in the event of an actual or suspected breach of your personal information, we will:<br
              /></span>
              <span className="span">6.3.1</span>
              <span className="text-wrapper-3">
                Furthermore, in the event of an actual or suspected breach of your personal information, we will:<br
              /></span>
              <span className="span">6.3.2</span>
              <span className="text-wrapper-3">
                Use best effort to remedy the breach within one (1) month from the date we report the breach to
                you.</span
              >
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <p className="text-wrapper">7.0 Access to Your Data</p>
        <div className="div-4">
          <div className="div-5">
            <p className="div">
              <span className="span">7.1</span>
              <span className="text-wrapper-3">
                If you are a registered user on VoxePay, you may access your personal information using your secure
                login credentials to access the relevant system features for such purpose. If you are a non-registered
                user, you may request for your personal information by sending an email to support@VoxePay.com<br
              /></span>
              <span className="span">7.2</span>
              <span className="text-wrapper-3">
                This information should only be used for your own purposes and not to circumvent the platform, or to be
                distributed to other parties who may use such information to the disadvantage of VPSP.<br
              /></span>
              <span className="span">7.3</span>
              <span className="text-wrapper-3">
                You will be able to access reports of your previous transactions up to seven years after the transaction
                date, subject to a continuous period of use. The applicability of the seven year period will re-commence
                if there has been a break in use for a period of more than three months. VPSP will advise you on any
                applicable fees to request access to data in such events.<br
              /></span>
              <span className="span">7.4</span>
              <span className="text-wrapper-3">
                You have the right to request that we stop using your data or return your data to you, or transmit the
                data directly to another data controller. The withdrawal of your consent at any time shall not affect
                the lawfulness of the processing based on your consent before its withdrawal. In the event you intend to
                exercise your right in this regard, kindly notify us through support@VoxePay.com and your request shall
                be treated promptly.</span
              >
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <p className="text-wrapper">8.0 How We Share your information with other Relevant Parties</p>
        <div className="div-4">
          <div className="div-5">
            <p className="text-wrapper-5">
              8.1 During your transaction, we may provide the corresponding party with information such as your name,
              account identification details, contact details, shipping and billing address, or other information needed
              to promote the integrity and security of the transaction. We may also provide details of unsuccessful
              transactions.<br /><br />8.2 We work with third parties, including but not limited to merchants, to enable
              them to accept or send payments from or to you, using VoxePay. In doing so, a third party may share
              information about you with us, such as your email address or mobile phone number, to inform you that a
              payment has been sent to you or when you attempt to pay a merchant or third party. We use this information
              to confirm that you are a VoxePay customer and that VoxePay as a payment and collection platform can be
              enabled. We also validate your status as a VoxePay customer to third parties on your instruction.<br /><br />8.3
              We enter into contracts with third parties to perform certain business-related functions necessary to
              ensure the optimal provision of the service to you, including but not limited to payment processing, data
              storage, security, identity verification, fraud prevention, etc. We disclose relevant portions of your
              information to these third parties where reasonably necessary. With respect to the confidentiality of your
              personal information, these third parties are bound by extant laws and regulations on data protection,
              including the provisions of NDPA 2023 (including amendments or supplemental provisions).<br /><br />8.4
              Except in instances relating to clause 8.3 above, upon receiving your clear and unequivocal instruction,
              we may also share your personal information with third parties in a manner deemed reasonable and
              convenient for us, either physically or through any digital channel available at any point in time.<br /><br />8.5
              You accept that your pictures and testimonials on all social media platforms about VoxePay can be used for
              limited promotional purposes by VPSP or its agents. This does not include your trademark or copyright
              protected materials.<br /><br />8.6 We may also divulge data about you if, in our opinion, the disclosure
              is reasonable and legally permissible for the purpose of ensuring the integrity of our business
              operations. Additionally, in the event of a merger or corporate restructuring, we may also divulge your
              personal information to applicable parties.<br /><br />8.7 Where your employee, as part of the process of
              application for a service from VPSP or a third party, requires his/her data to be provided in support of
              such an application to VPSP or a third party, VPSP is obligated to avail such request, upon the employee
              giving the requisite authorisation as the Data Owner/Data Subject, in the form prescribed by VPSP or the
              third party.<br /><br />8.8 From time to time we may send you relevant information such as product
              updates, new functionalities, tips on how to use VoxePay and new service offerings.<br /><br />8.9 We may
              also share your personal information in compliance with local or international regulatory requirements,
              with fraud prevention and risk management agencies, with our service providers or in compliance with a
              court order.<br /><br />8.10 We may also share your personal information with our affiliate entities,
              particularly members of the SystemSpecs Group (i.e., SystemSpecs Holdings Limited and other entities in
              which SystemSpecs Holdings Limited holds considerable influence and control), to enable us to provide you
              with information and services which we believe may be beneficial or may be of interest to you and/or
              members within your organisation. The affiliate entities may use the personal information for marketing
              and non-marketing activities. You may opt out at any time by sending an email to dpo@VoxePay.com<br /><br />8.11
              We recognise the privacy of minors, and we do not knowingly collect personal information belonging to
              minors.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">9.0 Security</div>
        <div className="div-4">
          <div className="div-5">
            <p className="div">
              We will always hold your information securely. To prevent unauthorized access to your information, we have
              implemented controls and security safeguards at technical and operational levels. This site uses Secure
              Sockets Layer/Transport Layer Security (SSL/TLS) to ensure secure transmission of your personal data. You
              should see the padlock symbol in your URL address bar when accessing the site, www.VoxePay.com. The URL
              address will also start with “https://” depicting a secure webpage. SSL applies encryption between two
              points such as your personal computer (PC) or any other electronic device used by you to access VoxePay,
              and the connecting server. Any data transmitted during the session will be encrypted before transmission
              and decrypted at the receiving end. This is to ensure that data cannot be read during transmission.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">10.0 Data Confidentiality Rights</div>
        <div className="div-4">
          <div className="div-5">
            <p className="div">
              Your information is regarded as confidential and will not be divulged to any third party except under
              legal and/or regulatory conditions. You have the right to request for copies of all information we keep on
              you, provided that you give us reasonable notice of such a request in writing. While VPSP is responsible
              for safeguarding the information entrusted to us, your role in fulfilling confidentiality duties includes
              but is not limited to adopting and enforcing appropriate security measures such as non-sharing of
              passwords and other platform login or transaction authentication details. You are ultimately responsible
              for the security of your platform login and transaction authentication details.
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <p className="text-wrapper">11.0 Links to Other Websites</p>
        <div className="div-4">
          <div className="div-5">
            <p className="div">
              <span className="span">11.1</span>
              <span className="text-wrapper-3">
                Certain transaction processing channels on VoxePay may require links to other websites. Please note that
                VPSP is not responsible for, and has no control over third party websites. We do not monitor or review
                the content of other parties’ websites which are linked from this website. Opinions expressed or
                materials appearing on such websites are not necessarily shared or endorsed by us, and VPSP should not
                be regarded as the publisher of such opinions or materials, and you agree that we shall not be liable
                for any liability arising from your interpretation of any information on any third party website linked
                to VoxePay. Please be aware that we are not responsible for the privacy practices, or content of any
                third party sites and we encourage our users to read the privacy statements of these third party sites.
                You should evaluate the security and trustworthiness of any other site connected to this site or
                accessed through this site yourself, before disclosing any personal information to it. VPSP will not
                accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from
                your disclosure to third parties of your personal information.<br /><br
              /></span>
              <span className="span">11.2</span>
              <span className="text-wrapper-3">
                We reserve the right to modify our Policy at any time and without any prior notice to you. Ensure you
                check this Policy regularly to stay updated with any amendment and understand how we use your
                information.</span
              >
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">12.0 Limitation of Liability</div>
        <div className="div-4">
          <div className="div-5">
            <p className="div">
              <span className="span">12.1</span>
              <span className="text-wrapper-3">
                VPSP will not be liable for loss of income, profits, business, opportunity, goodwill, contracts or any
                indirect, special, incidental or consequential damages arising out of or in connection with the Service,
                any computation or failed transaction on VoxePay, arising out of any breach of this Policy.<br /><br
              /></span>
              <span className="span">12.2</span>
              <span className="text-wrapper-3">
                We will not be liable for any loss or damage arising as a result of unauthorized access to the Service
                if (a) you intentionally or negligently fail to take reasonable precautions to protect your security,
                access codes, login details or any device used to access the Service, (b) you failed to promptly notify
                us that the Service was being accessed in an unauthorised way after becoming aware of it, or (c) you
                acted fraudulently.<br /><br
              /></span>
              <span className="span">12.3</span>
              <span className="text-wrapper-3">
                Our liability to you or any third party in any circumstance of proven liability by us, shall not exceed
                the fees paid to us in respect of the specific transaction that gave rise to the claim or liability,
                unless otherwise specified by a court of competent jurisdiction.<br /><br
              /></span>
              <span className="span">12.4</span>
              <span className="text-wrapper-3">
                Notwithstanding any provision in clauses 12.1, 12.2 and 12.3, limitation of liability shall not apply if
                we breach any provision of the Nigeria Data Protection Regulation 2019 (“NDPR”).</span
              >
            </p>
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper">13.0 QUERIES AND QUESTIONS</div>
        <div className="div-4">
          <div className="div-5">
            <p className="div">
              <span className="span">13.1</span>
              <span className="text-wrapper-3">
                You have the right to request that we stop using your data or return your data to you. In the event you
                intend to exercise your right in this regard, kindly notify us via support@VoxePay.com and your request
                shall be treated promptly.<br /><br
              /></span>
              <span className="span">13.2</span>
              <span className="text-wrapper-3">
                Any other complaints, questions, comments and requests regarding this privacy policy are welcomed and
                should be addressed to our Data Protection Officer through support@VoxePay.com, and where your
                complaints are not sufficiently addressed, you may lodge a complaint with a relevant data protection
                authority.<br /><br />Contact Us<br />VoxePay by Simplified International Synergy Limited, licensed by
                the Central Bank of Nigeria.<br />- Head Office: 18, A-Close, 14 Road, Gwarimpa, FCT, Abuja<br />-
                Email: info@voxepay.com<br /><br />For further information, please visit our website and review our
                [Privacy Policy]() and [Terms &amp; Conditions]().<br /><br />Connect with Us<br
              /></span>
            </p>
          </div>
        </div>
      </div>
    </div>
             </div>

      <Footer />
    </div>
  );
};

export { PrivacyPolicy };
