import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {Button, Form, Input, Select} from "antd";
import {useDoAccountNameVerification, useGetBankInstitutions} from "../../../auth/hooks/useUser";
import {useSendBankDetails} from "../../../auth/hooks/NewSimplyPay/onboarding";


const BusinessBankAccountDetails: React.FC = () => {
    const {
        data: bankInstitutions,
        mutate: mutateBankInstitutions,
        isLoading: mutateBankInstitutionsLoading,
    } = useGetBankInstitutions();

    const {
        data: bankDetails,
        mutate: mutateBankDetails,
        isLoading: mutateBankDetailsLoading,
    } = useSendBankDetails();


    const {
        data: getAccountNameVerification,
        mutate: mutateAccountNameVerification,
        isLoading: mutateAccountNameVerificationLoading,
    } = useDoAccountNameVerification();

  const navigate = useNavigate();


    useEffect(() => {
        mutateBankInstitutions()
    }, []);

    const onFinish = (values: any) => {
        console.log('Success:', values);

        const orgId = localStorage.getItem('orgId')

        values.bankName = JSON.parse(values.bankName)
        const payload: any = {
            "bankName": values.bankName.institutionName,
            "accountNumber": values.accountNumber,
            "accountName": values.accountName,
            "dailyLimit": Number(values.dailyLimit),
            "accountType": {
                "code": values.bankName.institutionCode,
                "description": ""
            },
            "balance": 0.0,
            "institutionCode": values.bankName.institutionCode
        }

        mutateBankDetails(payload, {
            onSuccess: {
                onSuccess: (res: any) => {
                    console.log('WWWWWW: ', res)
                },
            }
        })

        navigate('/admin')
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    const handleNameVerification = async () => {
        let formData = form.getFieldsValue()
        formData = JSON.parse(formData.bankName)
        console.log(formData)
        await mutateAccountNameVerification({
            accountNumber: formData.accountNumber,
            destinationInstitutionCode: formData.institutionCode
        });
    };


    const [form] = Form.useForm();

    return (
    <div className={'businessSetup'}>
        <Form
            form={form}
            name="basicInformation"
            initialValues={{remember: true}}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div className={'profileInformation'}>
                Account Information
            </div>

            <div className={ 'businessDetails'}>
                <Form.Item
                    label="Bank name"
                    name="bankName"
                    rules={[{ required: true, message: "Bank name is required" }]}
                >
                    <Select
                        showSearch
                        placeholder="Select bank"
                        size="large"
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {bankInstitutions &&
                            bankInstitutions.map((institution) => (
                                <Select.Option key={institution.id} value={JSON.stringify(institution)}>
                                    {institution.institutionName}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Account Number"
                    name="accountNumber"
                    rules={[
                        {required: true, message: 'Account Number is required'},
                        { pattern: /^\d{10}$/, message: 'Account number must be a 10-digit number' },
                    ]}
                >
                    <Input onBlur={() => handleNameVerification()} size="large" minLength={10} maxLength={10}/>
                </Form.Item>
            </div>

            <div className={ 'businessDetails'}>

                <Form.Item
                    label="Account Name"
                    name="accountName"
                    rules={[
                        {required: true, message: 'Account Number is required'},
                    ]}
                >
                    <Input size="large"/>
                </Form.Item>

                <Form.Item
                    label="Daily Limit"
                    name="dailyLimit"
                    rules={[
                        {required: true, message: 'Daily limit is required'},
                        { pattern: /^\d+$/, message: 'Daily limit must be a  number' },
                    ]}
                >
                    <Input size="large"/>
                </Form.Item>
            </div>

            <div className={'proofOfAddress'}>
                <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.38 7.38H8V11.76H8.62M8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5ZM8 5.01C8.25 5.01 8.45 4.81 8.45 4.56C8.45 4.31 8.25 4.11 8 4.11C7.75 4.11 7.55 4.31 7.55 4.56C7.55 4.81 7.75 5.01 8 5.01Z" stroke="#DC2626" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>

                <div>
                    Name on account must be the same as business owner’s name.
                </div>
            </div>

            <div className={'businessRegNextButton'}>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </div>
        </Form>

  </div>
  );
};

export { BusinessBankAccountDetails };
