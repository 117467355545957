import { Download } from '../../../../componets/download';
import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  useGetProcessTypes,
  useGetResources,
  useCreateRole,
  useAddResources,
} from '../../../auth/hooks/useUser';
import { FlapperSpinner } from 'react-spinners-kit';
import { infoBlue } from '../../../../../core/assets/icons';

const initialValues = {
  enterRoleName: '',
};

interface MenuItem {
  id: number;
  display: string;
  link: string;
  location: string;
  parent?: ParentMenuItem;
}
interface ParentMenuItem extends MenuItem {
  children?: MenuItem[];
}

export const Create = () => {
  const [selectedOption, setSelectedOption] = useState(0);
  // const [showPreview, setShowPreview] = useState('false');

  const [dropdowns, setDropdowns] = useState({
    // "mda-top-official": false,
    // "mda-admin": false,
    // "mda-collection-officer": false,
    // "mda-payment-officer": false
  });
  const navigate = useNavigate();

  const { data, mutate, isLoading } = useGetResources();

  const {
    data: createRole,
    mutate: mutateCreateRole,
    isLoading: isLoadingCreateROle,
  } = useCreateRole();
  const {
    data: addResources,
    mutate: mutateAddResources,
    isLoading: isLoadingAddResources,
  } = useAddResources();

  const [userRoleArry, setSelectedUserArray] = useState<any[]>([]);

  //   const {
  //     data: ProcessTypes,
  //     mutate: mutateProcessTypes,
  //     isLoading: isLoadingProcessType,
  //   } = useGetProcessTypes();

  useEffect(() => {
    mutate();
    // mutateProcessTypes();
  }, []);

  useEffect(() => {
    groupMenuItems();
  }, [data]);

  const groupMenuItems = (): ParentMenuItem[] => {
    const groupedItems: { [parentId: number]: ParentMenuItem } = {};

    data?.forEach((item) => {
      if (item.parent) {
        const parentId = item.parent.id;
        if (!groupedItems[parentId]) {
          groupedItems[parentId] = { ...item.parent, children: [] };
        }

        groupedItems[parentId].children?.push(item);
      }
    });

    return Object.values(groupedItems);
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: Schema,
    onSubmit: async (values) => {
      console.log(values);

      setConfrim(false);

      mutateCreateRole(
        values.enterRoleName,

        {
          onSuccess: (res: any) => {
            //    window.location.reload();

            mutateAddResources(
              {
                authority: values.enterRoleName,
                resourceIds: userRoleArry,
              },

              {
                onSuccess: (res: any) => {
                  setConfrim(false);

                  setShow(true);
                },
              }
            );
          },
        }
      );
    },
  });

  const [show, setShow] = useState(false);

  const refresh = () => {
    window.location.reload();
  };

  const [showConfirm, setConfrim] = useState<any>(false);

  const confrimModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm}
        onHide={() => setConfrim(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <>
            <div className="m-3 mx-15">
              <div className=" d-flex flex-column align-items-center ">
                <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                <span className="text-center fs-base text-dark">
                  Are you sure you want to submit for review
                </span>
              </div>
              <div className="d-flex justify-content-between mt-10">
                <button
                  id="kt_password_cancel "
                  type="button"
                  className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                  onClick={() => setConfrim(false)}
                >
                  <span className="indicator-label fs-base ">Cancel</span>
                </button>
                <button
                  className=" btn btn-success h-44 w-110 center-all"
                  type="button"
                  onClick={() => formik.handleSubmit()}
                >
                  <span className="indicator-label fs-base ">Submit</span>
                </button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    );
  };

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName="m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Successful!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  You have successfully created a role with resources.
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button className="otp-button " onClick={() => refresh()}>
                <span className="otp-button-text">Done</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    console.log(value);

    // Show the dropdown corresponding to the selected radio button
    // setDropdowns(prevDropdowns => ({
    //     ...prevDropdowns,
    //     [value]: true
    // }));

    // Show the dropdown corresponding to the selected radio button
    setDropdowns((prevDropdowns) => ({
      ...prevDropdowns,
      [value]: true,
    }));
  };

  const processData = (value: any) => {
    // setSelectedUserArray((prevSelectedUserArray) => [
    //   ...prevSelectedUserArray,
    //   {
    //     cat: selectedOption,
    //     key: selectedOption,
    //     roles: [
    //       {
    //         name: value,
    //         users: [userLogin],
    //       },
    //     ],
    //   },
    // ]);

    setSelectedUserArray((prevSelectedUserArray) => [
      ...prevSelectedUserArray,

      Number(selectedOption),
      Number(value),
    ]);

    console.log(userRoleArry);
  };

  console.log(userRoleArry);

  return (
    <>
      {isLoading || isLoadingCreateROle || isLoadingAddResources ? (
        <div className="loading-container">
          <FlapperSpinner /> {/* Replace with your loading indicator */}
        </div>
      ) : (
        <div className={'editRoleView'}>
          <div className={'editRoleViewBreadcrumb'}>
            <div>Setup & Administration</div>

            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                fill="#1F8505"
              />
            </svg>

            <div>Role/Resource Management</div>

            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                fill="#1F8505"
              />
            </svg>

            <div>Create New Role</div>
          </div>
          <div className={'editRoleForm'}>
            <div className={'editRoleTextDiv'}>
              <div
                onClick={() =>
                  navigate(
                    '/admin/setup-administration/role-resource-management'
                  )
                }
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7037_76293)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                      fill="#1F8505"
                    />
                  </g>
                  <rect
                    x="0.75"
                    y="1.25"
                    width="22.5"
                    height="22.5"
                    rx="11.25"
                    stroke="#1F8505"
                    stroke-width="1.5"
                  />
                  <defs>
                    <clipPath id="clip0_7037_76293">
                      <rect
                        y="0.5"
                        width="24"
                        height="24"
                        rx="12"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <div className={'editRoleText'}>ROLE CREATION</div>
            </div>

            <div>
              <div className="mt-1">
                <div className="col-form-label py-0 input-height">
                  {formik.values.enterRoleName.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Enter Role Name
                    </label>
                  )}
                </div>
                <div className={`form-control form-control-solid`}>
                  <input
                    type="text"
                    placeholder="Enter Role Name * "
                    className={'no-outline text-dark fs-8'}
                    {...formik.getFieldProps('enterRoleName')}
                  />
                </div>
                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.touched.enterRoleName &&
                      formik.errors.enterRoleName &&
                      formik.errors.enterRoleName}
                  </div>
                </div>
              </div>
            </div>

            <div className={'resourceText'}>Resources </div>

            <div className={'selectResourceText'}>
              Select resources to assign to the role
            </div>

            <div className={'allResourceDiv'}>
              <div className={'allResourcesText'}>ALL RESOURCES</div>

              <div className={'editUserDetailsCheckbox mt-5'}>
                {/* {groupMenuItems()?.map((role, i) => (
                     
                                
         
          ))} */}
                {groupMenuItems()?.map((role, i) => {
                  if (role?.id && role?.id !== 32) {
                    return (
                      <div key={role.id}>
                        <label style={{ paddingBottom: '13px' }}>
                          <input
                            style={{ marginRight: '15px' }}
                            type="checkbox"
                            value={role.id}
                            checked={selectedOption === role.id}
                            onChange={handleOptionChange}
                          />
                          {role.display}
                        </label>
                        {dropdowns[role.id] && (
                          <div className="checkbox-dropdown">
                            {role?.children?.map((child, i) => {
                              return (
                                <label key={i}>
                                  <input
                                    type="checkbox"
                                    value={child.id}
                                    onChange={() => processData(child.id)}
                                  />
                                  {child.display}
                                </label>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </div>

            <div className={'editRolePreviewButton'}>
              <button type="submit" onClick={() => setConfrim(true)}>
                Preview
              </button>
            </div>
          </div>
        </div>
      )}
      {SuccessModal()}
      {confrimModal()}
    </>
  );
};
