import { FlapperSpinner } from 'react-spinners-kit';
import { useGetTransactionInflow, useMdaActivate, useMdaDetails } from '../../auth/hooks/useUser';
import { DatePicker, Select, Table, Tabs } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Download } from '../../../componets/download';
import { filter } from '../../../../core/assets/icons';
import { useNavigate } from 'react-router-dom';

const dateFormat = 'YYYY/MM/DD';
const Transactions = () => {

    const navigate = useNavigate();


  const {
    data,
    mutate,
    isLoading,
  } = useGetTransactionInflow();

  useEffect(() => {
mutate()
  }, [])

  const dataSource: any[] = [];
  const dataSourceInflow: any[] = [];
  const dataSourceOutflow: any[] = [];

  const dataSourceForExcel: any[] = [[]];

  const statusColors = {
  Paid: '#D2E7CD',
  Rejected: '#FDE7E8',
  Pending: '#FEF5D7',
  Failed: '#FDE7E8',
};

  
  const paymentStatuses = ['Paid', 'Rejected', 'Pending', 'Failed', 'NEW'];


    const getTextColor = {
  Paid: '#1F8505',
  Rejected: '#EE151D',
  Pending: '#E3B118',
  NEW: '#E3B118',
  Failed: '#EE151D',
};

//   function getTextColor(bgColor) {
//   // Convert hex color to RGB
//   const hex = bgColor.replace('#', '');
//   const r = parseInt(hex.substring(0, 2), 16);
//   const g = parseInt(hex.substring(2, 4), 16);
//   const b = parseInt(hex.substring(4, 6), 16);

//   // Calculate the brightness of the color
//   const brightness = (r * 299 + g * 587 + b * 114) / 1000;

//   // Return 'white' for dark colors, 'black' for light colors
//   return brightness > 155 ? 'black' : 'white';
// }


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '30',
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      width: '30',
    },
    {
      title: 'Payment Date and Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      width: '30',
    },
    {
      title: 'VRR/VPR',
      dataIndex: 'vrr',
      key: 'vrr',
      width: '30',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30',
    },
    {
      title: 'Payment/Service Type',
      dataIndex: 'serviceType',
      key: 'serviceType',
      width: '30',
    },
{
  title: 'Payment Status',
  dataIndex: 'paymentStatus',
  key: 'paymentStatus',
  width: '30',
  render: (_, record) => {
    const bgColor = statusColors[record.paymentStatus];
    const textColor = getTextColor[record.paymentStatus];

    return (
      <button
        style={{
          backgroundColor: bgColor,
          color: textColor,
          border: 'none',
          padding: '4px, 0px, 4px, 0px',
          gap: '12px',
          borderRadius: '50px',
          width: '120px',
          cursor: 'pointer',
          height: '24px'
        }}
      >
        {record.paymentStatus}
      </button>
    );
  },
}

  ];

  for (let i = 0; i < data?.length; i++) {
   const currentData = data[i];
  const isOutflow = i % 2 === 0; // Alternates between Inflow and Outflow
  const row = {
    id: i,
    transactionType: `Inflow`,
    dateTime: currentData.issueDate,
    vrr: currentData?.invoiceNumber ,// Unique VRR for each entry
    name: currentData?.payer.fullName,
    serviceType: currentData?.serviceName, // Different service types
    paymentStatus: currentData?.status
  };
  dataSource.push(row);
}
  
  for (let i = 1; i < data?.length; i++) {
   const currentData = data[i];
  const isOutflow = i % 2 === 0; // Alternates between Inflow and Outflow
  const row = {
    id: i,
    transactionType: `Inflow`,
    dateTime: currentData.issueDate,
    vrr: currentData?.invoiceNumber ,// Unique VRR for each entry
    name: currentData?.payer.fullName,
    serviceType: currentData?.serviceName, // Different service types
    paymentStatus: currentData?.status
  };
  dataSourceInflow.push(row);
  }
  
    for (let i = 1; i <= 3; i++) {
  const isOutflow = i % 2 === 0; // Alternates between Inflow and Outflow
  const row = {
    id: i,
    transactionType: `Outflow`,
    dateTime: `20 Aug 2024, 11:45am`,
    vrr: `001828839${String(i).padStart(3, '0')}`, // Unique VRR for each entry
    name: `Tolulope Oluwadamilare`,
    serviceType: isOutflow ? `Bill payment` : `Airtime purchase`, // Different service types
    paymentStatus: isOutflow 
      ? paymentStatuses[Math.floor(Math.random() * paymentStatuses.length)] // Random status for Outflow
      : 'Successful', // Only 'Successful' for Inflow
    };
  dataSourceOutflow.push(row);
}

    const handleRowClick = (record) => {
return navigate("/admin/transactions/details", { state: { transactionData: record } });
    // You can handle the data here, e.g., pass it to another function or set state
  };

  return (
    <>
      {isLoading  ? (
        <div className="loading-container">
          <FlapperSpinner /> {/* Replace with your loading indicator */}
        </div>
      ) : (
        <div className={'outstandingPayment'}>
          <div className={'outstandingPaymentRow1'}>
            <div className={'outstandingPaymentBreadcrumb'}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3935_65299)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                    fill="#0866FF"
                  />
                </g>
                <rect
                  x="0.5"
                  y="1"
                  width="23"
                  height="23"
                  rx="11.5"
                  stroke="#0866FF"
                />
                <defs>
                  <clipPath id="clip0_3935_65299">
                    <rect y="0.5" width="24" height="24" rx="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <div>Transactions</div>
            </div>

            <div>
              <span className="data-range-text-overview">Date Range:</span>
              <span className="data-from-text-overview">From</span>
              <DatePicker
                defaultValue={dayjs('2015/01/01', dateFormat)}
                format={dateFormat}
                className="datepicker"
              />
              <span className="data-from-text-overview">To</span>
              <DatePicker
                defaultValue={dayjs('2015/01/01', dateFormat)}
                format={dateFormat}
                className="datepicker"
              />{' '}
            </div>
          </div>

          <div className={'outstandingPaymentRow2'}>
            <div>Transactions</div>

            <div className="d-flex justify-content-end  mb-9 me-4">
              <Download
                dataSourceForExcel={dataSourceForExcel}
                dataSource={dataSource}
                excelFileTitle={'Collection Inflow for MDA'}
                pdfFileTitle={'Collection Inflow for MDA'}
                excelPageTitle={'Collection Inflow for MDA'}
                grandTotalFor={{
                  transactionAmount: 'Transaction Amount',
                  processingFee: 'Processing Fee',
                  vatProcessingFee: 'VAT Processing Fee',
                  totalProcessingFee: 'Total Processing Fee',
                  netAmount: 'Net Amount',
                  cumulativeNetAmount: 'Cumulative Net Amount',
                }}
              />
            </div>

            <div>
              <div>Total Transactions:</div>
              <div>{data?.length}</div>
            </div>

            <div>
              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Payment Status"
                style={{ width: 184 }}
                options={[
                  { value: 'all', label: 'All' },
                  { value: 'Successful', label: 'Successful' },
                  { value: 'Rejected', label: 'Rejected' },
                  { value: 'Pending', label: 'Pending' },
                  { value: 'Failed', label: 'Failed' },
                ]}
              />

              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Service Type"
                style={{ width: 184 }}
                options={[
                  { value: 'all', label: 'All' },
                  { value: 'single', label: 'Airtime purchase' },
                  { value: 'bulk', label: 'Bulk' },
                ]}
              />

              <div className={'filterButton'}>
                <button style={{ height: '50px', marginTop: '1rem' }}>
                  <img src={filter} alt="" />
                  <span>Apply Filter</span>
                </button>
              </div>

              <div>
                <button style={{ height: '50px', marginTop: '1rem' }}>
                  Reset All
                </button>
              </div>
            </div>

            <div className={''}>
              <Tabs>
                <Tabs.TabPane tab="All" key="all">
                  <Table
                    dataSource={dataSource}
                    bordered
                    columns={columns}
                    scroll={{ x: '900px' }}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          handleRowClick(record);
                        },
                        style: {
        cursor: 'pointer', // Add cursor pointer to the row
      },
                      };
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Inflow" key="Inflow">
                  <Table
                    dataSource={dataSourceInflow}
                    bordered
                    columns={columns}
                      scroll={{ x: '900px' }}
                          onRow={(record) => {
                      return {
                        onClick: () => {
                          handleRowClick(record);
                        },
                        style: {
        cursor: 'pointer', // Add cursor pointer to the row
      },
                      };
                    }}
                  />
                </Tabs.TabPane>

                <Tabs.TabPane tab="OutFlow" key="Outflow">
                  <Table
                    dataSource={dataSourceOutflow}
                    bordered
                    columns={columns}
                      scroll={{ x: '900px' }}
                          onRow={(record) => {
                      return {
                        onClick: () => {
                          handleRowClick(record);
                        },
                        style: {
        cursor: 'pointer', // Add cursor pointer to the row
      },
                      };
                    }}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Transactions;
