import React from "react";
import {Form, Input} from "antd";




const FreeZoneEntity: React.FC<any> = ({ onNext }) => {
    const onFinish = (values: any) => {
        console.log('Success:', values);

        // setFormData(values)
        const payload: any = {
            "registeredName": values.businessName,
            "tradingName": values.tradingName,
            "mdaAdminCode": "RC123459",
            "description": values.businessDescription,
        }

        // mutate(payload, {
        //     onSuccess: (res: any) => {
        //         // setEmailVerification(true)
        //         setCurrentStage(currentStage + 1)
        //     },
        // })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <div className={'businessSetup'}>
                <Form
                    name="basicInformation"
                    initialValues={{remember: true}}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className={'profileInformation'}>
                        Free Zone Entity
                    </div>

                    <div className={ 'businessDetails'}>
                        <Form.Item
                            label="NEPZA number"
                            name="NEPZANumber"
                            rules={[{required: true, message: 'NEPZA number is required'}]}
                        >
                            <Input size="large"/>
                        </Form.Item>
                    </div>

                    <div className={'profileInformation'}>
                        Documents
                    </div>

                    <div className={ 'businessDetails'}>
                        <div>
                            <label className="required fw-bold fs-9">
                                Operating license
                            </label>
                            <div className={`form-control form-control-solid fileUpload`}>
                                <label>
                                    <div>Click here to upload document</div>
                                    <input
                                        hidden
                                        id="input"
                                        type="file"
                                        // accept="text/csv"
                                        // onChange={extractData}
                                    />
                                </label>
                            </div>

                        </div>

                        <div className="mt-1">
                            <label className="required fw-bold fs-9">
                                CTC of Notice of Situation or Change of Registered Address
                            </label>
                            <div className={`form-control form-control-solid fileUpload`}>
                                <label>
                                    <div>Click here to upload document</div>
                                    <input
                                        hidden
                                        id="input"
                                        type="file"
                                        // accept="text/csv"
                                        // onChange={extractData}
                                    />
                                </label>
                            </div>

                        </div>
                    </div>

                    <div className={ 'businessDetails'}>
                        <div>
                            <label className="required fw-bold fs-9">
                                Particulars of Directors or any claim therein
                            </label>
                            <div className={`form-control form-control-solid fileUpload`}>
                                <label>
                                    <div>Click here to upload document</div>
                                    <input
                                        hidden
                                        id="input"
                                        type="file"
                                        // accept="text/csv"
                                        // onChange={extractData}
                                    />
                                </label>
                            </div>

                        </div>

                        <div className="mt-1">
                            <label className="required fw-bold fs-9">
                                Memorandum and Articles of Association
                            </label>
                            <div className={`form-control form-control-solid fileUpload`}>
                                <label>
                                    <div>Click here to upload document</div>
                                    <input
                                        hidden
                                        id="input"
                                        type="file"
                                        // accept="text/csv"
                                        // onChange={extractData}
                                    />
                                </label>
                            </div>

                        </div>
                    </div>

                    <div className={ 'businessDetails'}>
                        <div>
                            <label className="required fw-bold fs-9">
                                Certificate of Incorporation/Registration
                            </label>
                            <div className={`form-control form-control-solid fileUpload`}>
                                <label>
                                    <div>Click here to upload document</div>
                                    <input
                                        hidden
                                        id="input"
                                        type="file"
                                        // accept="text/csv"
                                        // onChange={extractData}
                                    />
                                </label>
                            </div>

                        </div>

                        <div className="mt-1">
                            <label className="required fw-bold fs-9">
                                Statement of Share Capital and Return of Allotment
                            </label>
                            <div className={`form-control form-control-solid fileUpload`}>
                                <label>
                                    <div>Click here to upload document</div>
                                    <input
                                        hidden
                                        id="input"
                                        type="file"
                                        // accept="text/csv"
                                        // onChange={extractData}
                                    />
                                </label>
                            </div>

                        </div>
                    </div>


                    <div className={'businessRegNextButton'}>
                        <button onClick={onNext}>Next</button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export { FreeZoneEntity };
