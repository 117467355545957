import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../KycRequirement.scss";
import { LLCKycStepOne } from "./Step1";
import { LLCKycStepTwo } from "./Step2";
import { LLCKycStepThree } from "./Step3";
import "./LLB.scss";
import PreviewLLCKyc from "./PreviewLLCKyc";
import { Button, Form, Input, Select } from "antd";
import { Modal } from "react-bootstrap";
import { isEmpty } from "core/helpers";
import { FileUpload } from "../component/FileUpload";
import { InfoTooltip } from "../component/InfoTooltip";

export const LimitedLiabilityBusiness = () => {
  const [currentStage, setCurrentStage] = useState(3);
  const [showDirectorModal, setShowDirectorModal] = useState<boolean>(false);
  const [showDirectorDeleteModal, setShowDirectorDeleteModal] =
    useState<boolean>(false);
  const [directorTable, setDirectorTable] = useState<any>([]);

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const [showShareholderModal, setShowShareholderModal] =
    useState<boolean>(false);
  const [showShareholderDeleteModal, setShowShareholderDeleteModal] =
    useState<boolean>(false);
  const [shareholderTable, setShareholderTable] = useState<any>([]);

  const [stepOneData, setStepOneData] = useState<any>({
    certificate_Of_Incorporation: "",
    identificationDocument: "",
    memorandum: "",
    operatingLicense: "",
    proof_address: "",
    tin: "",
    year_Of_Incorporation: "",
  });

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentStage]);

  const deleteRecord = (arrayData, updateArray, closeModalFn) => {
    if (!isEmpty(selectedRecord)) {
      const updatedArray = arrayData
        .map((obj: { id: number }, index: number) =>
          obj.id === selectedRecord.id ? null : { ...obj, id: index + 1 }
        )
        .filter((data: null) => data !== null);

      const newData = updatedArray.map(
        (obj: { id: number }, index: number) => ({
          ...obj,
          id: index + 1,
        })
      );
      updateArray(newData);
    }

    setSelectedRecord({});
    closeModalFn(false);
  };

  const onFinishDirectorRecord = (values: any) => {
    if (!isEmpty(selectedRecord)) {
      const newRecord = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNumber: values?.phoneNumber,
        email: values?.email,
        bvn: values?.bvn,
      };
      const updatedArray = directorTable.map((obj: { id: number }) =>
        obj.id === selectedRecord.id ? { ...obj, ...newRecord } : obj
      );

      setDirectorTable(updatedArray);
      setSelectedRecord({});
      setShowDirectorModal(false);
    } else {
      const data = {
        id: directorTable.length + 1,
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNumber: values?.phoneNumber,
        email: values?.email,
        bvn: values?.bvn,
      };

      const OldDataWithNew = [...directorTable, data];

      setDirectorTable(OldDataWithNew);
      setSelectedRecord({});
      setShowDirectorModal(false);
    }
  };

  const onFinishShareholderRecord = (values: any) => {
    if (!isEmpty(selectedRecord)) {
      const newRecord = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNumber: values?.phoneNumber,
        email: values?.email,
        sharedOwned: values?.sharedOwned,
        idType : values?.idType
      };
      const updatedArray = shareholderTable.map((obj: { id: number }) =>
        obj.id === selectedRecord.id ? { ...obj, ...newRecord } : obj
      );

      setShareholderTable(updatedArray);
      setSelectedRecord({});
      setShowShareholderModal(false);
    } else {
      const data = {
        id: shareholderTable.length + 1,
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNumber: values?.phoneNumber,
        email: values?.email,
        sharedOwned: values?.sharedOwned,
        idType : values?.idType
      };

      const OldDataWithNew = [...shareholderTable, data];

      setShareholderTable(OldDataWithNew);
      setSelectedRecord({});
      setShowShareholderModal(false);
    }
  };

  const AddDirector = () => {
    return (
      <Modal
        dialogClassName="m-auto px-5 modal-card-service"
        aria-hidden="false"
        show={showDirectorModal}
        onHide={() => setShowDirectorModal(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        style={{ zIndex: 10000 }}
      >
        <Modal.Body className="rounded">
          <div className="m-5">
            <div className="fw-bold">Director's Information</div>
            <hr />

            <Form
              name="organizationInformation"
              initialValues={{ remember: true, ...selectedRecord }}
              layout="vertical"
              onFinish={onFinishDirectorRecord}
              autoComplete="off"
            >
              <div className={"form-group"}>
                <div>
                  <label className="required fw-bold fs-base text-dark required my-1">
                    First name
                  </label>

                  <Form.Item
                    name="firstName"
                    rules={[
                      { required: true, message: "First name is required" },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>

                <div>
                  <label className="required fw-bold fs-base text-dark required my-1">
                    Last name
                  </label>
                  <Form.Item
                    name="lastName"
                    rules={[
                      { required: true, message: "Last name is required" },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>

                <div>
                  <label className="required fw-bold fs-base text-dark required my-1">
                    Phone Number
                  </label>

                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      { required: true, message: "Phone Number is required" },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>

                <div>
                  <label className="required fw-bold fs-base text-dark required my-1">
                    Official Email
                  </label>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Official Email is required" },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>

                <div>
                  <label className="required fw-bold fs-base text-dark required my-1">
                    BVN
                  </label>
                  <Form.Item
                    name="bvn"
                    rules={[{ required: true, message: "BVN is required" }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </div>

              <div className="d-flex justify-content-center gap-15 ">
                <Form.Item>
                  <Button
                    htmlType="button"
                    className="d-flex justify-content-center align-items-center w-200px border-primary text-primary"
                    onClick={() => setShowDirectorModal(false)}
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="d-flex justify-content-center align-items-center w-200px"
                  >
                    {!isEmpty(selectedRecord) ? "Update" : "Save"}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const DeleteDirector = () => {
    return (
      <Modal
        dialogClassName="m-auto px-5 modal-card"
        aria-hidden="false"
        show={showDirectorDeleteModal}
        onHide={() => setShowDirectorModal(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        style={{ zIndex: 10000 }}
      >
        <Modal.Body className="rounded">
          <div className="m-5 d-flex flex-column justify-content-center align-items-center">
            <div>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.7506 16.25V26.25M16.2506 16.25V26.25M33.75 8.75L6.25 8.75001M31.2506 8.75V32.5C31.2506 32.8315 31.1189 33.1495 30.8845 33.3839C30.6501 33.6183 30.3321 33.75 30.0006 33.75H10.0006C9.66909 33.75 9.35115 33.6183 9.11673 33.3839C8.88231 33.1495 8.75061 32.8315 8.75061 32.5V8.75M26.2506 8.75V6.25C26.2506 5.58696 25.9872 4.95107 25.5184 4.48223C25.0495 4.01339 24.4137 3.75 23.7506 3.75H16.2506C15.5876 3.75 14.9517 4.01339 14.4828 4.48223C14.014 4.95107 13.7506 5.58696 13.7506 6.25V8.75"
                  stroke="#EC2106"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <div className="my-3 text-center fw-bold">
              <p className="p-0 text-dark">
                Delete {selectedRecord?.firstName} {selectedRecord?.lastName}’s
                Information
              </p>

              <p className="p-0 text-gray-500">
                Please confirm that you want to delete this director’s
                information
              </p>
            </div>

            <div className=" d-flex flex-column gap-5">
              <Button
                htmlType="button"
                className="d-flex justify-content-center align-items-center w-300px border-primary text-primary"
                onClick={() => setShowDirectorDeleteModal(false)}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="button"
                className="d-flex justify-content-center align-items-center w-300px"
                onClick={() =>
                  deleteRecord(
                    directorTable,
                    setDirectorTable,
                    setShowDirectorDeleteModal
                  )
                }
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const AddShareholder = () => {
    return (
      <Modal
        dialogClassName="m-auto px-5 modal-card-service"
        aria-hidden="false"
        show={showShareholderModal}
        onHide={() => setShowShareholderModal(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        style={{ zIndex: 10000 }}
      >
        <Modal.Body className="rounded">
          <div className="m-5">
            <div className="fw-bold">Shareholder's Information</div>
            <hr />

            <Form
              name="organizationInformation"
              initialValues={{ remember: true, ...selectedRecord }}
              layout="vertical"
              onFinish={onFinishShareholderRecord}
              autoComplete="off"
              form={form}
            >
              <div className={"form-group"}>
                <div>
                  <label className="required fw-bold fs-base text-dark required my-1">
                    First name
                  </label>

                  <Form.Item
                    name="firstName"
                    rules={[
                      { required: true, message: "First name is required" },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>

                <div>
                  <label className="required fw-bold fs-base text-dark required my-1">
                    Last name
                  </label>
                  <Form.Item
                    name="lastName"
                    rules={[
                      { required: true, message: "Last name is required" },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>

                <div>
                  <label className="required fw-bold fs-base text-dark required my-1">
                    Phone Number
                  </label>

                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      { required: true, message: "Phone Number is required" },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>

                <div>
                  <label className="required fw-bold fs-base text-dark required my-1">
                    Official Email
                  </label>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Official Email is required" },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>

                <div>
                  <label className="required fw-bold fs-base text-dark required my-1">
                    Percentage of Share Owned (5%-100%)
                  </label>
                  <Form.Item
                    name="sharedOwned"
                    rules={[
                      {
                        required: true,
                        message: " Percentage of Share Owned is required",
                      },
                    ]}
                  >
                    <Input size="large" type="number" />
                  </Form.Item>
                </div>
              </div>

              <div>
                <div className="my-5">
                  <div className="fw-bold">
                    Tax Document
                    <p className="fs-8 text-gray-500 my-2">
                      <InfoTooltip
                        text={"Accepted document formats are jpg, png, or pdf"}
                      />
                      Accepted document formats are jpg, png, or pdf
                    </p>
                  </div>

                  <div className={"form-group"}>
                    <div>
                      <label className="required fw-bold fs-base text-dark required my-1">
                        ID Type
                      </label>
                      <Form.Item
                        name="idType"
                        rules={[
                          {
                            required: true,
                            message: "ID Type is required",
                          },
                        ]}
                      >
                        <Select
                          placeholder=""
                          //size="large"
                          // style={{ width: 200, zIndex:100000 }}
                          dropdownStyle={{zIndex:100000}}
                          options={[
                            {
                              label: "Driver’s License",
                              value: "driversLicense",
                            },
                            {
                              label: "International Passport",
                              value: "internationalPassport",
                            },
                            {
                              label: "Permanent Voter’s Card",
                              value: "voterCard",
                            },
                            {
                              label: "National Identification Card or Slip ",
                              value: "ninSlip",
                            },
                          ]}
                        />
                      </Form.Item>
                    </div>

                    <div>
                      <label className="required fw-bold fs-base text-dark required my-1">
                        Identification Document
                      </label>
                      <Form.Item
                        name="idDocument"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[
                          {
                            required: true,
                            message: "Identification Document is required",
                          },
                        ]}
                      >
                        <FileUpload
                          setFileLink={(e: string) =>
                            form.setFieldValue("idDocument", e)
                          }
                          accepted=".pdf,.jpg,.png"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center gap-15 ">
                <Form.Item>
                  <Button
                    htmlType="button"
                    className="d-flex justify-content-center align-items-center w-200px border-primary text-primary"
                    onClick={() => setShowShareholderModal(false)}
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="d-flex justify-content-center align-items-center w-200px"
                  >
                    {!isEmpty(selectedRecord) ? "Update" : "Save"}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const DeleteShareholder = () => {
    return (
      <Modal
        dialogClassName="m-auto px-5 modal-card"
        aria-hidden="false"
        show={showShareholderDeleteModal}
        onHide={() => setShowShareholderDeleteModal(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        style={{ zIndex: 10000 }}
      >
        <Modal.Body className="rounded">
          <div className="m-5 d-flex flex-column justify-content-center align-items-center">
            <div>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.7506 16.25V26.25M16.2506 16.25V26.25M33.75 8.75L6.25 8.75001M31.2506 8.75V32.5C31.2506 32.8315 31.1189 33.1495 30.8845 33.3839C30.6501 33.6183 30.3321 33.75 30.0006 33.75H10.0006C9.66909 33.75 9.35115 33.6183 9.11673 33.3839C8.88231 33.1495 8.75061 32.8315 8.75061 32.5V8.75M26.2506 8.75V6.25C26.2506 5.58696 25.9872 4.95107 25.5184 4.48223C25.0495 4.01339 24.4137 3.75 23.7506 3.75H16.2506C15.5876 3.75 14.9517 4.01339 14.4828 4.48223C14.014 4.95107 13.7506 5.58696 13.7506 6.25V8.75"
                  stroke="#EC2106"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <div className="my-3 text-center fw-bold">
              <p className="p-0 text-dark">
                Delete {selectedRecord?.firstName} {selectedRecord?.lastName}’s
                Information
              </p>

              <p className="p-0 text-gray-500">
                Please confirm that you want to delete this director’s
                information
              </p>
            </div>

            <div className=" d-flex flex-column gap-5">
              <Button
                htmlType="button"
                className="d-flex justify-content-center align-items-center w-300px border-primary text-primary"
                onClick={() => setShowShareholderDeleteModal(false)}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="button"
                className="d-flex justify-content-center align-items-center w-300px"
                onClick={() =>
                  deleteRecord(
                    shareholderTable,
                    setShareholderTable,
                    setShowShareholderDeleteModal
                  )
                }
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <div className={"onboarding"}>
        <div className={"kycForm"}>
          {currentStage !== 4 && (
            <>
              <h5 className="fs-5 text-center">Company Information</h5>
              <p className="fs-base text-center text-gray-500">
                Please provide the following information for us to know more
                about your company
              </p>

              <div className="d-flex align-items-center justify-content-center my-10">
                <span
                  className={`fw-bold fs-9 ${
                    currentStage === 1
                      ? "touched-kyc-step"
                      : "unTouched-kyc-step"
                  }`}
                >
                  1
                </span>

                {currentStage === 1 && (
                  <span className="mx-2 fs-8 text-primary">
                    Registration, Tax, and Proof of Address
                  </span>
                )}

                <span className={`mx-2 kyc-line`} />

                <span
                  className={`fw-bold fs-9 ${
                    currentStage === 2
                      ? "touched-kyc-step"
                      : "unTouched-kyc-step"
                  }`}
                >
                  2
                </span>

                {currentStage === 2 && (
                  <span className="mx-2 fs-8 text-primary">
                    Registered Directors Information
                  </span>
                )}

                <span className={`mx-1 kyc-line`} />

                <span
                  className={`fw-bold fs-9 ${
                    currentStage === 3
                      ? "touched-kyc-step"
                      : "unTouched-kyc-step"
                  }`}
                >
                  3
                </span>
                {currentStage === 3 && (
                  <span className="mx-2 fs-8 text-primary">
                    Shareholders Information
                  </span>
                )}
              </div>
            </>
          )}

          {currentStage === 1 && (
            <LLCKycStepOne
              isFormSubmitted={() => setCurrentStage(currentStage + 1)}
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
              stepOneData={stepOneData}
              setStepOneData={setStepOneData}
            />
          )}

          {currentStage === 2 && (
            <LLCKycStepTwo
              isFormSubmitted={() => navigate("/admin")}
              setCurrentStage={setCurrentStage}
              currentStage={currentStage}
              directorTable={directorTable}
              setSelectedRecord={setSelectedRecord}
              setShowDirectorModal={setShowDirectorModal}
              setShowDirectorDeleteModal={setShowDirectorDeleteModal}
            />
          )}

          {currentStage === 3 && (
            <LLCKycStepThree
              isFormSubmitted={() => navigate("/admin")}
              setCurrentStage={setCurrentStage}
              currentStage={currentStage}
              shareholderTable={shareholderTable}
              setSelectedRecord={setSelectedRecord}
              setShowShareholderModal={setShowShareholderModal}
              setShowShareholderDeleteModal={setShowShareholderDeleteModal}
            />
          )}

          {currentStage === 4 && (
            <PreviewLLCKyc
              goBack={() => setCurrentStage(currentStage - 1)}
              step={currentStage}
              setStep={setCurrentStage}
              stepOneData={stepOneData}
              directorTable={directorTable}
              setSelectedRecord={setSelectedRecord}
              setShowDirectorModal={setShowDirectorModal}
              setShowDirectorDeleteModal={setShowDirectorDeleteModal}
              shareholderTable={shareholderTable}
              setShowShareholderModal={setShowShareholderModal}
              setShowShareholderDeleteModal={setShowShareholderDeleteModal}
            />
          )}

          {AddDirector()}
          {DeleteDirector()}
          {AddShareholder()}
          {DeleteShareholder()}
        </div>
      </div>
    </>
  );
};
