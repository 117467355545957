import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useResetForgotPassword,
  useRequestOnboardingSendVerificatoinCode,
  useVerificationSinglecode,
  useVerificationcode,
} from "./hooks/useUser";
import {
  OAGFLogoPng,
  back,
  lockSimple,
  loginImg,
  NewOAGFLogoLogin,
  voxepay,
  simplipayillustration3
} from "../../../core/assets/imgs";
import { Modal } from "react-bootstrap";
import { ErrorToast } from "../../componets/toast";
import OTPInput from "react-otp-input";

const Schema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  confirmpassword: Yup.string().required("Password is required"),
});

const initialValues = {
  password: "",
  confirmpassword: "",
};

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();

  const { search } = useLocation();
  const [show, setShow] = useState<boolean>(false);

  const params = new URLSearchParams(search);
  const keyParam = params.get("key");
  const type = params.get("type");
  const email = params.get("email");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(initialValues);

  const { mutate, isLoading } = useResetForgotPassword();

  const {
    data: dataActivate,
    mutate: mutateActivate,
    isLoading: isLoadingActivate,
  } = useRequestOnboardingSendVerificatoinCode();

  const [otp, setOtp] = useState("");

  // const [formData, setFormData] = useState({
  //   input1: "",
  //   input2: "",
  //   input3: "",
  //   input4: "",
  //   input5: "",
  //   input6: "",
  // });

  // const handleChange = (event: any) => {
  //   event.preventDefault();
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const {
    data: sendVerifyData,
    mutate: sendVerifyMuute,
    isLoading: isVerfyLoading,
  } = useRequestOnboardingSendVerificatoinCode();

  const {
    data: verifyCode,
    mutate: mutateVerify,
    isLoading: loadingVerify,
  } = useVerificationcode();

  const sendVerification = () => {
    sendVerifyMuute(email, {
      onSuccess: (res: any) => {
        // setShow(false);
      },
    });
  };

  const submitVerification = () => {
    // const combinedData = Object.values(formData).join("");
    // combinedData.toString();
    // console.log(combinedData);

    const payload = {
      password: formik.values?.password,
      confirmPassword: formik.values?.confirmpassword,
      code: otp,
      email: email,
    };

    //console.log(payload);

    mutateVerify(payload, {
      onSuccess: (res: any) => {
        setShow(false);
        goBack();
      },
    });
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      //console.log(type);

      if (type === "reset") {
        if (values.confirmpassword !== values.password) {
          ErrorToast("You password dont match");
        } else {
          mutate(
            {
              key: keyParam,
              newPassword: values.password,
            },
            {
              onSuccess: (res: any) => {
                navigate("/auth/login");
              },
            }
          );
        }
      }

      if (type === "activate") {
        mutateActivate(email, {
          onSuccess: (res: any) => {
            setShow(true);
          },
        });
      }

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
  });

  const done = () => {
    navigate("/mda-home");
  };

  const goBack = () => {
    navigate("/auth/login");
  };
   const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
  
  const [showPassword2, setShowPassword2] = useState(false);

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };
  return (
    <div>
      {/* <NavBar /> */}

      <div className="login-root">
        <div className="login-aside">
          <div className="login-aside-img">
            <div
              className="  m-auto mb-3 responsive-card  cursor-pointer"
              onClick={goBack}
            >
              <img src={back} alt="invoice" className="icon-style" />
            </div>
            <div className="img-login">
              <img src={simplipayillustration3} alt="LoginImg" />
            </div>
          </div>
          <div className="login-aside-footer">
            <div>
              <span>
                Let’s make payments, collections and reconciliation
                <br /> an easy task for you with VOXEPAY.
              </span>
            </div>
          </div>
        </div>
        <div className="login-main ">
          <div className="login-center">
            <div className="py-15 pt-3">
              {/* <div className="  m-auto mb-3 responsive-card " onClick={goBack}>
                <img src={back} alt="invoice" className="icon-style" />
              </div>  new*/}

              <div
                className="  m-auto mb-3 responsive-card img-center mt-8 cursor-pointer"
                onClick={done}
              >
                <img src={voxepay} alt="Logo" className="nav-logo" width={140} />
              </div>

              <div className="card m-auto p-11 responsive-card">
                <form
                  onSubmit={formik.handleSubmit}
                  noValidate
                  className="form align-middle  gx-0 gy-5 mt-5"
                >
                  <span className="login_text login-text">
                    Set Password
                  </span>

                  <div className="">
                    <div className="row ">
                      <div className="col-lg-12 col-form-label py-0 input-height">
                        {formik.values.password.length !== 0 && (
                          <label className="required fw-bold fs-9">
                            Password
                          </label>
                        )}
                      </div>

                      <div className="form-control form-control-solid  ">
                        <img
                          src={lockSimple}
                          alt="password"
                          className="icon-style"
                        />

                        <input
                           type={showPassword ? "text" : "password"}
                          placeholder="Enter Password * "
                          className={"no-outline ps-4 text-dark fs-8"}
                          {...formik.getFieldProps("password")}
                        />

                        <i
                className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                onClick={togglePasswordVisibility}
                style={{
                  marginLeft: "-30px",
                  cursor: "pointer",
                  color: "GrayText",
                }}
              ></i>
                      </div>

                      <div className="fv-plugins-message-container input-height">
                        <div className="fv-help-block fs-9">
                          {formik.touched.password &&
                            formik.errors.password &&
                            formik.errors.password}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-lg-12 col-form-label py-0 input-height">
                        {formik.values.confirmpassword.length !== 0 && (
                          <label className="required fw-bold fs-9">
                            Confirm Password
                          </label>
                        )}
                      </div>

                      <div className="form-control form-control-solid  ">
                        <img
                          src={lockSimple}
                          alt="Confirm Password"
                          className="icon-style"
                        />

                        <input
                          type={showPassword2 ? "text" : "password"}
                          placeholder="Confirm Password *"
                          className={"no-outline ps-4 text-dark fs-8"}
                          {...formik.getFieldProps("confirmpassword")}
                        />

                           <i
                className={`fas ${showPassword2 ? "fa-eye-slash" : "fa-eye"}`}
                onClick={togglePasswordVisibility2}
                style={{
                  marginLeft: "-30px",
                  cursor: "pointer",
                  color: "GrayText",
                }}
              ></i>
                      </div>
                    </div>
                  </div>

                  <div className="login-text mt-5">
                    <div className="">
                      <button
                        id="kt_password_cancel "
                        type="submit"
                        className={`btn ${
                          !(formik.isValid && formik.dirty && !isLoading)
                            ? "btn-secondary"
                            : "btn-success"
                        } text-white px-7 py-2`}
                        disabled={
                          !(formik.isValid && formik.dirty && !isLoading)
                        }
                      >
                        {!isLoading && (
                          <span className="indicator-label">
                            Change Password
                          </span>
                        )}
                        {isLoading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName=" m-auto px-7 modal-card-signup"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5">
                <svg
                  width="21"
                  height="19"
                  viewBox="0 0 21 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 7V16.75C1.5 16.9489 1.57902 17.1397 1.71967 17.2803C1.86032 17.421 2.05109 17.5 2.25 17.5H18.75C18.9489 17.5 19.1397 17.421 19.2803 17.2803C19.421 17.1397 19.5 16.9489 19.5 16.75V7M1.5 7L10.5 1L19.5 7M1.5 7L8.86363 12.25M19.5 7L12.1363 12.25M8.86363 12.25L1.73126 17.2881M8.86363 12.25L12.1363 12.25M19.2688 17.2881L12.1363 12.25"
                    stroke="#3BAEFE"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container">
                <span className="email-verification-text">
                  Email Verification
                </span>
              </div>

              <div className="email-verification-container">
                <div className="email-verification-body">
                  <div>Please enter the 6-digit code sent to</div>
                  <div>{email}</div>
                </div>
              </div>

              {/* <div className="otp-box mt-5">
                <input
                  type="text"
                  maxLength={1}
                  name="input1"
                  onChange={handleChange}
                  value={formData.input1}
                />
                <input
                  type="text"
                  maxLength={1}
                  name="input2"
                  onChange={handleChange}
                  value={formData.input2}
                />
                <input
                  type="text"
                  maxLength={1}
                  name="input3"
                  onChange={handleChange}
                  value={formData.input3}
                  className="space"
                />
                <input
                  type="text"
                  maxLength={1}
                  name="input4"
                  onChange={handleChange}
                  value={formData.input4}
                />

                <input
                  type="text"
                  maxLength={1}
                  name="input5"
                  onChange={handleChange}
                  value={formData.input5}
                />

                <input
                  type="text"
                  maxLength={1}
                  name="input6"
                  onChange={handleChange}
                  value={formData.input6}
                />
              </div> */}

              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                containerStyle={"otp-box mt-5"}
                inputStyle={"w-100"}
                placeholder="------"
              />

              <div className="email-verification-container mt-2">
                <span className="resend-code-text" onClick={sendVerification}>
                  Resend Code
                </span>
              </div>
            </div>

            <div className=" d-flex flex-column align-items-center mt-5 ">
              <button
                className={`otp-button center-all ${
                  otp.length !== 6 ? "btn bg-gray-500" : ""
                } `}
                onClick={() => submitVerification()}
                disabled={
                  otp.length !== 6
                    ? true
                    : isVerfyLoading || loadingVerify
                    ? true
                    : false
                }
              >
                {isVerfyLoading || loadingVerify ? (
                  <span className="otp-button-text">Loading..</span>
                ) : (
                  <span className="otp-button-text">Verify</span>
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Footer /> */}
    </div>
  );
};

export { ResetPassword };
