import React, { useState } from "react";
import voxepay from '../../../core/assets/img/voxepay.png'
import appPreview from '../../../core/assets/img/app-preview.png'
import mtn from '../../../core/assets/img/mtn.png'
import konga from '../../../core/assets/img/konga.png'
import teamApt from '../../../core/assets/img/teamApt.png'
import enyata from '../../../core/assets/img/enyata.png'
import acumen from '../../../core/assets/img/acumen.png'
import mono from '../../../core/assets/img/mono.png'
import tecmic from '../../../core/assets/img/tecmic.png'
import thirdRowImg from '../../../core/assets/img/thirdRowImg.png'
import fourthRowImage from '../../../core/assets/img/fourthRowImage.png'

import yellowMTN from '../../../core/assets/img/yellowMTN.png'
import phElectric from '../../../core/assets/img/phElectric.png'
import airtel from '../../../core/assets/img/airtel.png'
import glo from '../../../core/assets/img/glo.png'
import phElectric2 from '../../../core/assets/img/phElectric2.png'
import nineMobile from '../../../core/assets/img/9mobile.png'
import dstv from '../../../core/assets/img/dstv.png'
import gotv from '../../../core/assets/img/gotv.png'
import ekedc from '../../../core/assets/img/ekedc.png'
import tsvt from '../../../core/assets/img/tsvt.png'
import ibedc from '../../../core/assets/img/ibedc.png'
import starTimes from '../../../core/assets/img/starTimes.png'

import revenueImage from '../../../core/assets/img/revenueImage.png'
import qrCode from '../../../core/assets/img/qrCode.png'
import code from '../../../core/assets/img/code.png'
import { Link } from "react-router-dom";


const HomePage: React.FC = () => {

  return (
    <main className={'homePage'}>
        <nav>
            <div>
                <img src={voxepay} alt={'voxepay'} width={'140'}/>

                {/* <div className={'navColumn2'}>
                    <div className={'navColumn2Value'}>
                        <span>Corporate</span>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.73017 7.20938C6.02875 6.92228 6.50353 6.93159 6.79063 7.23017L10.5 11.1679L14.2094 7.23017C14.4965 6.93159 14.9713 6.92228 15.2698 7.20938C15.5684 7.49647 15.5777 7.97125 15.2906 8.26983L11.0406 12.7698C10.8992 12.9169 10.704 13 10.5 13C10.296 13 10.1008 12.9169 9.95938 12.7698L5.70938 8.26983C5.42228 7.97125 5.43159 7.49647 5.73017 7.20938Z" fill="#0F172A"/>
                        </svg>
                    </div>
                    <div className={'navColumn2Value'}>
                        <span>Government</span>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.73017 7.20938C6.02875 6.92228 6.50353 6.93159 6.79063 7.23017L10.5 11.1679L14.2094 7.23017C14.4965 6.93159 14.9713 6.92228 15.2698 7.20938C15.5684 7.49647 15.5777 7.97125 15.2906 8.26983L11.0406 12.7698C10.8992 12.9169 10.704 13 10.5 13C10.296 13 10.1008 12.9169 9.95938 12.7698L5.70938 8.26983C5.42228 7.97125 5.43159 7.49647 5.73017 7.20938Z" fill="#0F172A"/>
                        </svg>
                    </div>
                    <div className={'navColumn2Value'}>
                        <span>Resources</span>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.73017 7.20938C6.02875 6.92228 6.50353 6.93159 6.79063 7.23017L10.5 11.1679L14.2094 7.23017C14.4965 6.93159 14.9713 6.92228 15.2698 7.20938C15.5684 7.49647 15.5777 7.97125 15.2906 8.26983L11.0406 12.7698C10.8992 12.9169 10.704 13 10.5 13C10.296 13 10.1008 12.9169 9.95938 12.7698L5.70938 8.26983C5.42228 7.97125 5.43159 7.49647 5.73017 7.20938Z" fill="#0F172A"/>
                        </svg>
                    </div>
                    <div className={'navColumn2Value'}>
                        <span>For Developers</span>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.73017 7.20938C6.02875 6.92228 6.50353 6.93159 6.79063 7.23017L10.5 11.1679L14.2094 7.23017C14.4965 6.93159 14.9713 6.92228 15.2698 7.20938C15.5684 7.49647 15.5777 7.97125 15.2906 8.26983L11.0406 12.7698C10.8992 12.9169 10.704 13 10.5 13C10.296 13 10.1008 12.9169 9.95938 12.7698L5.70938 8.26983C5.42228 7.97125 5.43159 7.49647 5.73017 7.20938Z" fill="#0F172A"/>
                        </svg>
                    </div>
                    <div className={'navColumn2Value'}>
                        <span>Company</span>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.73017 7.20938C6.02875 6.92228 6.50353 6.93159 6.79063 7.23017L10.5 11.1679L14.2094 7.23017C14.4965 6.93159 14.9713 6.92228 15.2698 7.20938C15.5684 7.49647 15.5777 7.97125 15.2906 8.26983L11.0406 12.7698C10.8992 12.9169 10.704 13 10.5 13C10.296 13 10.1008 12.9169 9.95938 12.7698L5.70938 8.26983C5.42228 7.97125 5.43159 7.49647 5.73017 7.20938Z" fill="#0F172A"/>
                        </svg>
                    </div>
                </div> */}

                <div className={'authDiv'}>
                    <button className={'signInButton'}>
                        <span>Sign in</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="white"/>
                        </svg>
                    </button>

                    <button className={'createButton'}>
                        <span>Create account</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="#0866FF"/>
                        </svg>
                    </button>
                </div>
            </div>
        </nav>

        <div className={'homePageRow1'}>
            <div>
                <div className={'homePageRow1Text'}>
                    <span>Automate treasury </span>processes and consolidate cashflow data
                </div>

                <div className={'getStartedButton'}>
                    <button>Get Started</button>
                </div>
            </div>
        </div>

        <div className={'homePageRow2'}>
          <img src={appPreview} alt={''} width={'720'}/>

          <div className={'weAreText'}>We are trusted by 1 million + businesses around the world</div>

          <div className={'logosdiv'}>
              <img src={mtn} alt={''} width={'60'}/>
              <img src={konga} alt={''} width={'110'}/>
              <img src={teamApt} alt={''} width={'140'}/>
              <img src={enyata} alt={''} width={'131'}/>
              <img src={acumen} alt={''} width={'123'}/>
              <img src={mono} alt={''} width={'134'}/>
              <img src={tecmic} alt={''} width={'157'}/>
          </div>
        </div>

        <div className={'homePageRow3'}>
            <div>
                <div className={'leftText'}>
                    <h3>Lorem ipsum dolor sit amet consectetur. Purus quis tincidunt urna nunc dapibus massa. </h3>
                    <span>Neque lacinia auctor leo nisl id a lacus ut consectetur. Dictum ut in ipsum quis magna dictum lectus. Pulvinar ultrices elementum blandit a volutpat sed quam.</span>
                    <button>Get Started</button>
                </div>
                <div>
                    <img src={thirdRowImg} alt={''} width={'642'}/>
                </div>
            </div>
        </div>



        <div className={'homePageRow4'}>
            <div>
                <div>
                    <img src={fourthRowImage} alt={''} width={'542'}/>
                </div>
                <div className={'rightText'}>
                    <h3>Lorem ipsum dolor sit amet consectetur. Purus quis tincidunt urna nunc dapibus massa. </h3>
                    <span>Neque lacinia auctor leo nisl id a lacus ut consectetur. Dictum ut in ipsum quis magna dictum lectus. Pulvinar ultrices elementum blandit a volutpat sed quam.</span>
                    <button>Get Started</button>
                </div>
            </div>
        </div>



        <div className={'homePageRow5'}>
            <div>
                <h3>Lorem ipsum dolor sit amet consectetur. Purus quis tincidunt urna nunc dapibus massa. </h3>
                <div>Neque lacinia auctor leo nisl id a lacus ut consectetur. Dictum ut in ipsum quis magna dictum lectus. Pulvinar ultrices elementum blandit a volutpat sed quam.</div>
                <div className={'companyLogos'}>
                    <img src={yellowMTN} alt={''} />
                    <img src={phElectric} alt={''} />
                    <img src={airtel} alt={''} />
                    <img src={glo} alt={''} />
                    <img src={phElectric2} alt={''} />
                    <img src={nineMobile} alt={''} />
                    <img src={dstv} alt={''} />
                    <img src={gotv} alt={''} />
                    <img src={ekedc} alt={''} />
                    <img src={tsvt} alt={''} />
                    <img src={ibedc} alt={''} />
                    <img src={starTimes} alt={''} />
                </div>

                <div className={'ctaButton'}>
                    <button className={'registerAsBiller'}>
                        <span>Register as a biller</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="white"/>
                        </svg>
                    </button>

                    <button className={'payRegisteredBiller'}>
                        <span>Pay a registered biller</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="#0866FF"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>



        <div className={'homePageRow6'}>
            <div>
                <div>
                    <h3>Trusted by 200,000+ businesses</h3>
                    <span>Thousands of organizations trust voxepay to grow their business.</span>
                </div>

                <div className={'cardRow'}>
                    <div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.25 7.5C20.25 9.98528 16.5563 12 12 12C7.44365 12 3.75 9.98528 3.75 7.5M20.25 7.5C20.25 5.01472 16.5563 3 12 3C7.44365 3 3.75 5.01472 3.75 7.5M20.25 7.5V12M3.75 7.5V12M3.75 12C3.75 14.4853 7.44365 16.5 12 16.5C16.5563 16.5 20.25 14.4853 20.25 12M3.75 12V16.5C3.75 18.9853 7.44365 21 12 21C16.5563 21 20.25 18.9853 20.25 16.5V12" stroke="#369EE7" />
                        </svg>

                        <div>
                            <strong>Central Repository</strong>
                            <span>The FGN Treasury Portal serves as a central repository of disaggregated banking data for all TSA collections and payments transactions, thereby ensuring a complete view of the nation’s financial position.</span>
                        </div>

                        <Link to={'/'}>
                            <span>Learn more</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="#369EE7"/>
                            </svg>
                        </Link>
                    </div>

                    <div>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="44" height="44" rx="5" fill="#EBF7FF"/>
                            <path d="M31.375 29.5H12.625M14.125 29.5V22.75H19.375M19.375 29.5V18.25H24.625M24.625 13.75H29.875V29.5H24.625V13.75Z" stroke="#369EE7" />
                        </svg>

                        <div>
                            <strong>Comprehensive Reporting</strong>
                            <span>Most processes, such as data collection, process reconciliation, cash flow forecasting, liquidity planning, reporting e.t.c. are automated with a TMS.</span>
                        </div>

                        <Link to={'/'}>
                            <span>Learn more</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="#369EE7"/>
                            </svg>
                        </Link>
                    </div>

                    <div>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="44" height="44" rx="5" fill="#EBF7FF"/>
                            <path d="M31 29.5H13V14.5M29.5002 16L22.0002 23.5L19.0002 20.5L13.0002 26.5M29.5002 16V19.75M29.5002 16H25.7502" stroke="#369EE7" />
                        </svg>

                        <div>
                            <strong>Fiscal Insights</strong>
                            <span>Since all data is automatically pulled from different systems once the TMS is connected to them, the reporting becomes more accurate. Reports are generated based on the exact data from each source system.</span>
                        </div>

                        <Link to={'/'}>
                            <span>Learn more</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="#369EE7"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>



        <div className={'homePageRow7'}>
            <div>
                <div>
                    <img className={'phone'} src={revenueImage} alt={''} width={'700'} />
                </div>

                <div className={'leftSide'}>
                    <div>
                        <strong>Download the app now to start making e-invoice and revenue payments</strong>
                        <span>Get insight into bank balances, transactions, and cash flow predictions.</span>
                    </div>

                    <div className={'downloadOn'}>Download on</div>

                    <div className={'buttonRow'}>
                        <button>
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.08505 19.5414C5.45372 19.5072 4.85962 19.2319 4.42541 18.7723C3.90595 18.2725 3.4424 17.7177 3.04288 17.1176C2.4189 16.2241 1.92565 15.246 1.57806 14.2131C1.18469 13.1017 0.977758 11.933 0.965589 10.7542C0.935047 9.59753 1.22149 8.45457 1.7939 7.44897C2.21318 6.72421 2.81119 6.11891 3.53083 5.69096C4.24258 5.26482 5.05475 5.03542 5.88426 5.02632C6.50956 5.06331 7.12408 5.2062 7.70155 5.44897C8.1624 5.6518 8.64631 5.79439 9.1433 5.87271C9.69149 5.75783 10.2272 5.58969 10.7427 5.37066C11.3528 5.13247 11.9988 4.99903 12.6534 4.97608C12.7407 4.97608 12.827 4.97608 12.9103 4.98614C14.3461 5.02729 15.6814 5.7321 16.5248 6.89379C15.8666 7.24584 15.319 7.77349 14.9426 8.41813C14.5664 9.06277 14.3762 9.79915 14.3933 10.5454C14.3867 11.116 14.5012 11.6815 14.7291 12.2047C14.957 12.7278 15.2932 13.1967 15.7156 13.5805C16.1001 13.9467 16.5478 14.2403 17.0369 14.447C16.9365 14.7482 16.821 15.0393 16.6985 15.3325C16.4204 15.9804 16.0773 16.5983 15.6744 17.1769C15.2935 17.7565 14.8501 18.2925 14.3522 18.7753C13.8979 19.226 13.2925 19.4919 12.6534 19.5213C12.1116 19.4973 11.5793 19.3702 11.085 19.1468C10.5522 18.9146 9.97944 18.7875 9.3983 18.7723C8.80083 18.7841 8.21119 18.9105 7.66137 19.1448C7.18655 19.359 6.6792 19.4924 6.16035 19.5393L6.08505 19.5414ZM9.2477 4.97608C9.1724 4.97608 9.09709 4.97608 9.02179 4.96704C9.00601 4.84759 8.99758 4.72716 8.99673 4.60662C9.02956 3.60373 9.42149 2.64602 10.1011 1.90783C10.4813 1.48361 10.942 1.13921 11.4566 0.894755C11.9361 0.647948 12.46 0.499454 12.9977 0.458008C13.0128 0.589574 13.0128 0.718068 13.0128 0.840538C12.9987 1.82771 12.6278 2.77632 11.9686 3.51126C11.6494 3.93915 11.2405 4.29204 10.7705 4.54512C10.3004 4.79819 9.78065 4.9453 9.2477 4.97608Z" fill="white"/>
                            </svg>
                            <span>App Store</span>
                        </button>

                        <button>
                            <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.63296 19.0699C1.46146 19.0729 1.29003 19.0437 1.12863 18.9832L9.25172 10.8571L11.9406 13.549L2.60622 18.7935C2.3106 18.967 1.97559 19.062 1.63296 19.0699ZM0.458984 18.3992C0.29384 18.1002 0.21166 17.7624 0.220919 17.4209V2.58984C0.219588 2.36467 0.253598 2.14076 0.321798 1.92618L8.6254 10.2318L0.455957 18.4012L0.458984 18.3992ZM12.7424 13.0961L9.87702 10.2328L13.0369 7.07395L15.8437 8.65035C16.3823 8.89241 16.7434 9.41181 16.7797 10.0008C16.7618 10.2902 16.665 10.5691 16.4998 10.8073C16.3347 11.0456 16.1074 11.2341 15.8427 11.3523L12.7424 13.0961ZM9.25172 9.60345L0.840224 1.19293C1.07115 1.02149 1.35054 0.930716 1.63799 0.929688C1.97687 0.94179 2.3077 1.03765 2.60119 1.20908L12.2341 6.62312L9.25172 9.60345Z" fill="white"/>
                            </svg>

                            <span>Google Play</span>
                        </button>
                    </div>

                    <div>Or scan to download</div>

                    <img src={qrCode} alt={''} />
                </div>
            </div>
        </div>



        <div className={'homePageRow8'}>
            <div>
                <h3>Get paid from your website or mobile app</h3>
                <div className={'integrateTo'}>Integrate to voxepay with our plug-and-play APIs, SDKs, and plugins to start receiving payments seamlessly.</div>
                <div className={'homePageRow8Button'}>
                    <button className={'simplyPayButton'}>
                        <span>voxepay API Quickstart</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="white"/>
                        </svg>
                    </button>

                    <button className={'readDocumentButton'}>
                        <span>Read Documentation</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="#0866FF"/>
                        </svg>
                    </button>
                </div>

                <img src={code} alt={''} width={'642'} />
            </div>
        </div>



        <div className={'homePageRow9'}>
            <div>
            <div className={'startAutomating'}>Start automating your financial processes today</div>
            <div className={'getInsight'}>Get insight into bank balances, transactions, and cash flow predictions.</div>

            <div className={'homePageRow9Button'}>
                <button className={'createAcctButton'}>
                    <span>Create an account</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="#0866FF"/>
                    </svg>
                </button>

                <button className={'requestDemoButton'}>
                    <span>Request demo</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="#E6E6ED"/>
                    </svg>
                </button>
            </div>
            </div>
        </div>



        {/* <div className={'homePageFooter'}>
          <div>
                <div className={'footerRow'}>
                        <div>
                        <img src={voxepay} alt={'voxepay'} width={'140'}/>
                        </div>

                       <div>
                       <div className={'footerRowValue'}>
                            <strong>voxepay</strong>
                            <span>For Entrepreneurs</span>
                            <span>For Corporates</span>
                            <span>For Governments</span>
                            <span>For Startups</span>
                            <span>For International Companies</span>
                            <span>For Fintechs</span>
                        </div>

                        <div className={'footerRowValue'}>
                            <strong>Resources</strong>
                            <span>Blog</span>
                            <span>Guides</span>
                            <span>Video Tutorials</span>
                            <span>Commerce</span>
                            <span>Terminal</span>
                        </div>

                        <div className={'footerRowValue'}>
                            <strong>Developers</strong>
                            <span>Overview</span>
                            <span>Documentation</span>
                            <span>Integration</span>
                            <span>Status Page</span>
                        </div>

                        <div className={'footerRowValue'}>
                            <strong>Company</strong>
                            <span>About</span>
                            <span>Careers</span>
                            <span>Subscribe</span>
                            <span>Privacy & Terms</span>
                        </div>

                        <div className={'footerRowValue'}>
                            <strong>Contact</strong>
                            <span>Support</span>
                            <span>Help Desk</span>
                            <span>Instagram</span>
                            <span>Twitter</span>
                            <span>Facebook</span>
                        </div>
                       </div>
                </div>

                <div className={'footerRow'}>
                        <div>
                            <div style={{width: '140px'}}></div>
                        </div>

                        <div>

                        <div className={'footerRowValue'}>
                            <strong>United Kingdom</strong>
                            <span>Leicester Square,</span>
                            <span>London,</span>
                            <span>England</span>
                            <span>+44 20 3802 3899</span>
                            <span>Lewisham,</span>
                            <span>London,</span>
                            <span>England</span>
                            <span>+44 73 4139 5882</span>
                        </div>

                        <div className={'footerRowValue'}> 
                            <strong>South Africa</strong>
                            <span>Pretoria,</span>
                            <span>South Africa</span>
                            <span>+27 78 1668 616</span>
                        </div>

                        <div className={'footerRowValue'}>
                            <strong>Nigeria</strong>
                            <span>Gwarimpa,</span>
                            <span>Abuja, FCT,</span>
                            <span>Nigeria</span>
                            <span>+234 703 163 5772</span>
                        </div>

                        <div>
                        </div>

                        <div>
                        </div>
                        </div>
                </div>

                <div className={'lineBreak'}>
                    <hr/>
                </div>

                <div className={'copyright'}>
                    <span style={{width: '140px'}}></span>
                    
                    <span>© 2023 voxepay Limited</span>
                </div>
          </div>
        </div> */}
    </main>
  );
};

export { HomePage };
