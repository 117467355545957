import React, {useState} from "react";
import {Button, Checkbox, Form, Input, Modal} from "antd";
import SimpliPayLogo from '../../../../core/assets/img/SimpliPayLogo.png'
import {useNavigate} from "react-router-dom";
import {useOnboardingPersonalDetails} from "../hooks/NewSimplyPay/onboarding";
import {useRequestOnboardingSendVerificatoinCode, useVerificationcode} from "../hooks/useUser";
import successModal from "../../payments/components/SuccessModal";
import OTPInput from "react-otp-input";


const Onboarding: React.FC = () => {
    const [emailVerification, setEmailVerification] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [otp, setOtp] = useState("");
    const [formData, setFormData] = useState<any>();
    const { data, mutate, isLoading } = useOnboardingPersonalDetails();
    const navigate = useNavigate();
    const [confirmationModal, setConfirmationModal] = useState(false);
    const {
        data: sendVerifyData,
        mutate: sendVerifyMuute,
        isLoading: isVerfyLoading,
    } = useRequestOnboardingSendVerificatoinCode();
    const {
        data: verifyCode,
        mutate: mutateVerify,
        isLoading: loadingVerify,
    } = useVerificationcode();


    const onFinish = (values: any) => {
        console.log('Success:', values);

        setFormData(values)
        const payload: any = {
            "firstName": values.firstName,
            "lastName": values.lastName,
            "phoneNumber": values.phoneNumber,
            "email": values.email,
            "login": values.username,
            "password": values.password,
            "confirmPassword": values.confirmPassword
        }

        mutate(payload, {
            onSuccess: (res: any) => {
                setEmailVerification(true)
            },
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    const sendVerification = () => {
        sendVerifyMuute(formData?.email, {
            onSuccess: (res: any) => {
                // setShow(false);
            },
        });
    };


    const submitVerification = () => {
        const payload = {
            code: otp,
            email: formData?.email,
            password: formData?.password,
            confirmPassword: formData?.confirmPassword,
        };

        console.log(payload);

        mutateVerify(payload, {
            onSuccess: (res: any) => {
                setEmailVerification(false);
                setSuccessModal(true)
            },
        });
    };

    return (
    <div className={'onboarding'}>
            <div className={'SimpliPayLogo'}>
                <img src={SimpliPayLogo} alt={'SimpliPayLogo'} width={'140'}/>
            </div>

            <div className={'onboardingForm'}>
                <div className={'onboardingFormRow1'}>
                    Basic Information
                </div>

                <div className={'onboardingFormRow2'}>
                    <Form
                        name="basicInformation"
                        initialValues={{remember: true}}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        {/*<div className={'onboardingDetails'}>*/}
                        {/*    <Form.Item*/}
                        {/*        label="Business country"*/}
                        {/*        name="businessCountry"*/}
                        {/*        rules={[{required: true, message: 'Business country is invalid'}]}*/}
                        {/*    >*/}
                        {/*        <Input size="large"/>*/}
                        {/*    </Form.Item>*/}

                        {/*    <Form.Item*/}
                        {/*        label="Business name"*/}
                        {/*        name="businessName"*/}
                        {/*        rules={[{required: true, message: 'Business name is invalid'}]}*/}
                        {/*    >*/}
                        {/*        <Input size="large"/>*/}
                        {/*    </Form.Item>*/}
                        {/*</div>*/}

                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="First name"
                                name="firstName"
                                rules={[{required: true, message: 'First name is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="Last name"
                                name="lastName"
                                rules={[{required: true, message: 'Last name is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="Work email address"
                                name="email"
                                rules={[{required: true, type: 'email', message: 'Work email address is invalid'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[{required: true, message: 'Username is required'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>
                        <div className={'onboardingDetails'}>
                            <Form.Item
                                label="Phone number"
                                name="phoneNumber"
                                rules={[{required: true, pattern: /^\+(?:[0-9] ?){6,14}[0-9]$/, message: 'Phone number is invalid. Phone number should include country code'}]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </div>

                        <div className={'onboardingDetails'}>
                            <Form.Item label="Password" name="password" rules={[{required: true, message: 'Password is required'}]}>
                                <Input.Password size="large"/>
                            </Form.Item>

                            <Form.Item
                                label="Confirm Password"
                                name="confirmPassword"
                                dependencies={['password']}
                                rules={[{
                                    required: true, message: 'Password confirmation is required'
                                }, ({getFieldValue}): any => ({
                                    validator(_: any, value: any): any {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),]}
                            >
                                <Input.Password size="large"/>
                            </Form.Item>
                        </div>


                        <div className={'onboardingNextButton'}>

                            <Form.Item
                                name="remember"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error('Should accept Terms of Use and Privacy Policy')),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    <div className={'onboardingTerms'}>
                                        I have read and agree to the <span> Terms of Use </span> and <span> Privacy Policy </span> of SimpliPay
                                    </div>
                                </Checkbox>
                            </Form.Item>


                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Next
                                </Button>
                            </Form.Item>

                            <div className={'alreadyHave'}>
                                Already have an account? <span>Sign in</span>
                            </div>
                        </div>
                    </Form>

                </div>


            </div>


        <Modal centered open={emailVerification} closable={false} footer={null} width={300}>
            <div className=" d-flex flex-column align-items-center ">
                <div className="mt-5">
                    <svg
                        width="21"
                        height="19"
                        viewBox="0 0 21 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.5 7V16.75C1.5 16.9489 1.57902 17.1397 1.71967 17.2803C1.86032 17.421 2.05109 17.5 2.25 17.5H18.75C18.9489 17.5 19.1397 17.421 19.2803 17.2803C19.421 17.1397 19.5 16.9489 19.5 16.75V7M1.5 7L10.5 1L19.5 7M1.5 7L8.86363 12.25M19.5 7L12.1363 12.25M8.86363 12.25L1.73126 17.2881M8.86363 12.25L12.1363 12.25M19.2688 17.2881L12.1363 12.25"
                            stroke="#3BAEFE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>

                <div className="email-verification-container">
                <span className="email-verification-text">
                  Email Verification
                </span>
                </div>

                <div className="email-verification-container">
                    <div className="email-verification-body">
                        <div>An email containing a 6-digit code was sent to {formData?.email}. Enter the code below to verify your email address.</div>
                    </div>
                </div>

                <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    containerStyle={"otp-box mt-5"}
                    inputStyle={"w-100"}
                    placeholder="------"
                />

                <div className="email-verification-container mt-2">
                    <span className="resend-code-text" onClick={sendVerification}>
                      Resend Code
                    </span>
                </div>
            </div>

            <div className=" d-flex flex-column align-items-center mt-5 ">
                <Button type="primary" loading={loadingVerify} onClick={() => submitVerification()}>
                    Verify
                </Button>
            </div>
        </Modal>


        <Modal centered open={successModal} closable={false} footer={null} width={300}>
            <div className=" d-flex flex-column align-items-center ">
                <div style={{fontWeight: 500, fontSize: '18px'}}>Email verification successful!</div>

                <div style={{textAlign: 'center'}}>You may now proceed to set up your account.</div>
            </div>

            <div className=" d-flex flex-column align-items-center mt-5 ">
                <Button type="primary" loading={loadingVerify} onClick={() => navigate('/auth/login')}>
                    Proceed
                </Button>
            </div>
        </Modal>

    </div>);
};

export {Onboarding};
