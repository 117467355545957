import { Table } from 'antd';
import Moment from 'moment';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import OTPInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { MetroSpinner } from 'react-spinners-kit';
import '../../../App.css';
import { close_red, infoBlue } from '../../../core/assets/icons';
import { getBankName } from '../../services/utilityService';
import {
  useRequestOnboardingSendVerificatoinCode,
  useVerificationSinglecode,
} from '../auth/hooks/useUser';
import { useRequestReviewed } from '../payments/hooks/usePayment';

const ReviewerDashboard = (Data: any) => {
  // const { data, mutate, isLoading } = useGetAwaitingRequest();

  const {
    data: reviewData,
    mutate: mutateReview,
    isLoading: isLoadingReview,
  } = useRequestReviewed();

  const orgUser: any | null = localStorage.getItem('lazyday_user');
  const [reasonForReject, SetReasonForReject] = useState<string>('');

  const orgUserParser = JSON.parse(orgUser);

  const [modalData, setModalData] = useState<any>(null);

  const [serviceModalData, setServiceModalData] = useState<any>(null);
  const [userModalData, setUserModalData] = useState<any>(null);

  const viewReport = (data) => {
    navigate(`/admin/reviewer/request-awaiting-review/1`, { state: data });
  };

  const viewPensionReport = (data) => {
    navigate(`/admin/reviewer/pension/1`, { state: data });
  };


    const viewVATReport = (data) => {
    navigate(`/admin/reviewer/vat/1`, { state: data });
  };




  const setModal = (data: any) => {

  
    
    let dataLen = data?.data?.content?.data?.paymentInstructions?.length;

    console.log(dataLen);
    

    if (dataLen === 1) {
      setModalData(data);
      setShow(true);
    }

    if (
      dataLen > 1 &&
      data?.data?.content?.data.paymentInstructions['0'].pfaCode === null &&
      data?.data?.content?.data.paymentInstructions['0'].vat === null
    ) {
      console.log("pfacode == null");
      
      viewReport(data);
    }

    if (
      dataLen > 1 &&
      data?.data?.content?.data.paymentInstructions['0'].pfaCode !=null
    ) {
            console.log("pfacode == !null");

      console.log(dataLen);

      viewPensionReport(data);
    }


        if (
      dataLen > 1 &&
      data?.data?.content?.data.paymentInstructions['0'].vat != null
    ) {
      console.log(dataLen, "ddddfrf");

       viewVATReport(data);
    }


    // } else {
    //
    // }

    if (data?.processType === 'Collection Rules') {
      setShowService(true);
      setServiceModalData(data);
      if (data.data.content.data?.revCode) {
        setRevenueCode(data.data.content.data.revCode);
      } else {
        setRevenueCode('');
      }
    }



    if (data?.processType === 'User Management') {

      console.log(data);
      
      

       setUserModalData(data)
         setShowUsersCreation(true)

    }
  };

  const getCollectionRules = (rule: any) => {
    const hashIndex: number = rule.indexOf('#');
    const processedRule: string =
      hashIndex !== -1 ? rule.substring(0, hashIndex) : rule;

    if (
      processedRule.startsWith('collection_rule') ||
      processedRule.startsWith('payroll_payments') ||
      processedRule.startsWith('user_management')
    ) {
      const formattedRule: string = processedRule
        .replace(/_/g, ' ')
        .replace(/(?:^|\s)\S/g, (match: string) => match.toUpperCase());

      return formattedRule;
    }

    return '';
  };

  const dataSource: any[] = [];
  let cumulativeNetAmount = 0;
  for (let i = 0; i < Data.requestList?.length; i++) {
    var payI = Data.requestList[i]?.content?.data?.paymentInstructions;
    var typeOfRequest = Data.requestList[i].type.code;

    console.log(Data.requestList[i].awaitingGroup);

    // var processType = '';
    // switch (typeOfRequest) {
    //   case 'collection_rules':
    //     processType = getCollectionRules(Data.requestList[i]?.awaitingGroup);
    //     break;
    //   case 'user_management':
    //     processType = getCollectionRules(Data.requestList[i]?.awaitingGroup);
    //     break;
    //   case 'payroll_payments':
    //   case 'vendor_payments':
    //   case 'retail_payments':
    //   case 'retail_vendor_payments':
    //   case 'pensionPayments':
    //     processType =
    //       Data?.requestList[i]?.content?.data?.paymentInstructions[0]
    //         ?.paymentType?.description;
    //
    //     break;
    //   default:
    //     processType = '';
    //     break;
    // }

    let amount = 0;

    for (let j = 0; j < payI?.length; j++) {
      amount += payI[j]?.amount;
    }

    if (Data.requestList[i]) {
      const datad = {
        key: i.toString(),
        id: i + 1,
        processType: Data.requestList[i].content.data.paymentType.description,
        paymentOption: `${
          Data.requestList[i]?.type.code === 'collection_rules'
            ? Data.requestList[i].content.data?.revCode
              ? 'Service Update'
              : 'Service Creation'
            : Data.requestList[i]?.content?.data?.paymentInstructions
                ?.length === 1
            ? 'Single Payment'
            : Data.requestList[i]?.content?.data?.paymentInstructions?.length >
              1
            ? 'Bulk Payment'
            : Data.requestList[i]?.type.code === 'user_management'
            ? 'User Creation'
            : ''
        }  `,

        date: Moment(Data.requestList[i]?.creationDate).format('MMMM Do YYYY'),
        time: Moment(Data.requestList[i]?.creationDate).format('h:mm a'),
        status: Data.requestList[i]?.status,
        data: Data.requestList[i],
      };

      dataSource.push(datad);
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Process Type',
      dataIndex: 'processType',
      key: 'processType',
    },
    {
      title: 'Request Type',
      dataIndex: 'paymentOption',
      key: 'paymentOption',
    },

    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <button
          className={'rdActionButton'}
          onClick={() => {
            setModal(record);
          }}
        >
          View Details
        </button>
      ),
    },
  ];
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  const [show2, setShow2] = useState<boolean>(false);

  const [showServices, setShowService] = useState<boolean>(false);

    const [showUsersCreation, setShowUsersCreation] = useState<boolean>(false);


  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);

  const [otp, setOtp] = useState('');
  const [sId, setSid] = useState('');

  const {
    data: sendVerifyData,
    mutate: sendVerifyMuute,
    isLoading: isVerfyLoading,
  } = useRequestOnboardingSendVerificatoinCode();

  const sendVerification = () => {
    setLoader(true);
    sendVerifyMuute(orgUserParser?.organizationUser.email, {
      onSuccess: (res: any) => {
        setShow2(true);
        setConfrim(false);
        setShowService(false);
        setShow(false);
      },
      onSettled: () => {
        setLoader(false);
      },
    });
  };

  const {
    data: verifyCode,
    mutate: mutateVerify,
    isLoading: loadingVerify,
  } = useVerificationSinglecode();

  const submitVerification = (id) => {
    const payload = {
      otp: otp,
    };
    setLoader(true);
    mutateVerify(payload, {
      onSuccess: (res: any) => {
        setOtp('');
        mutateReview(
          { id, type: 'APPROVED', revenueCode },
          {
            onSuccess: (res: any) => {
              setShow2(false);
              setLoader(false);
              setShowSProveduccess(true);
              setShowService(false);
            },
            onSettled: () => {
              setLoader(false);
            },
          }
        );
      },
      onError: () => {
        setLoader(false);
      },
    });
  };

  // const review = (id: any) => {
  //     mutateReview({id, type: "APPROVED"}, {

  //         onSuccess: (res: any) => {
  //             setShow(false)

  //             setShowSuccess(true)
  //             mutate()

  //             },

  //     })
  // }

  const hasRoleOAGFAdmin = (roles) => {
    return roles?.some((role) => role?.id === 'ROLE_OAGF_ADMIN');
  };

  const review = (id: any, toApproval) => {
    setSid(id);
    // console.log(toApproval);

    if (toApproval.toApproval) {
      var x: any = localStorage.getItem('lazyday_user');

      var authorities = x !== null ? JSON.parse(x) : null;

      //console.log("This is the new oagf flag hi\n", authorities.oagf);

      //console.log(authorities?.organizationUser?.authorities);

      // const isAdmin = hasRoleOAGFAdmin(
      //   authorities?.organizationUser?.authorities
      // );

      //console.log(isAdmin);

      if (
        toApproval.awaitingGroup === 'collection_rules#approver' &&
        authorities.oagf
      ) {
        setWModal(1);
        setConfrim(true);
        setShowService(false);
        setApproval(false);
      } else {
        setApproval(false);
        sendVerification();
      }
    } else {
      setLoader(true);
      mutateReview(
        { id, type: 'APPROVED' },
        {
          onSuccess: (res: any) => {
            setLoader(false);
            setApproval(false);
            setShow(false);
            setShowService(false);
            setShowSuccess(true);
          },
        }
      );
    }
  };

  const [rejectid, setRejectid] = useState<any>('');
  const cancelRejectModal = () => {
    setRejection(false);
    SetReasonForReject('');
    setRejectid('');
    setWModal(1);
  };
  const ShowRejectModal = (id: any) => {
    setWModal(1);
    setRejection(true);
    setShowService(false);
    setShowUsersCreation(false)
    setShow(false);
    setRejectid(id);
  };

  const [loader, setLoader] = useState<boolean>(false);

  const RejectionModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showRejection}
        onHide={() => cancelRejectModal()}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-6 mx-5">
            {wModal === 1 ? (
              <>
                <div
                  className="d-flex justify-content-end cursor-pointer"
                  onClick={() => cancelRejectModal()}
                >
                  <img src={close_red} alt="" className="w-24px h-24px" />
                </div>
                <div className="mt-1">
                  <div className="col-form-label input-height d-flex justify-content-start">
                    <span className=" fw-bolder fs-8e text-dark">
                      Reason(s) for rejection
                    </span>
                  </div>
                  <div className={``}>
                    <span className="required fs-9 text-gray-500">Message</span>
                    <textarea
                      placeholder=""
                      className={
                        'text-gray-500 fs-8 form-control form-control-solid'
                      }
                      value={reasonForReject}
                      rows={5}
                      onChange={(e) => SetReasonForReject(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end my-5">
                  <button
                    type="button"
                    className={` btn ${
                      reasonForReject.trim().length < 5
                        ? 'btn-secondary'
                        : 'btn-success'
                    } h-35px w-100px rounded-1  center-all  `}
                    onClick={() => setWModal(2)}
                    disabled={reasonForReject.trim().length < 5}
                  >
                    <span className="indicator-label fs-10 text-white ">
                      Submit
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <>
                {loader ? (
                  <div className="d-flex justify-content-center align-items-center my-10">
                    <MetroSpinner color="#6366F1" />
                  </div>
                ) : (
                  <div className="">
                    <div className=" d-flex flex-column align-items-center ">
                      <img
                        src={infoBlue}
                        alt=""
                        className="w-50px h-50px my-5"
                      />

                      <span className="text-center fs-base text-dark">
                        Are you sure you want to reject this request
                      </span>
                    </div>
                    <div className="d-flex justify-content-around mt-10">
                      <button
                        id="kt_password_cancel "
                        type="button"
                        className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                        onClick={() => cancelRejectModal()}
                      >
                        <span className="indicator-label fs-base ">Cancel</span>
                      </button>
                      <button
                        className=" btn btn-success h-44 w-110 center-all"
                        type="button"
                        onClick={() => {
                          reject(rejectid);
                        }}
                      >
                        <span className="indicator-label fs-base ">Submit</span>
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const reject = (id: any) => {
    setLoader(true);
    mutateReview(
      { id, type: 'REJECTED', reason: reasonForReject },
      {
        onSuccess: (res: any) => {
          setLoader(false);
          setRejection(false);
          setShowReject(true);
        },
      }
    );
  };

  const onNext = () => {
    setShowSuccess(false);
    setShowReject(false);
    setShowSProveduccess(false);
    Data.mutate();
  };

  const RejectModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={showReject}
        onHide={() => setShowReject(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Rejected!!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  The requests have been successfully rejected
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button className="otp-button " onClick={() => onNext()}>
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const [showProvedSuccess, setShowSProveduccess] = useState(false);
  const [showApproval, setApproval] = useState<boolean>(false);
  const [showConfirm, setConfrim] = useState<boolean>(false);
  const [showRejection, setRejection] = useState<boolean>(false);
  const [revenueCode, setRevenueCode] = useState<string>('');

  const cancelModal = () => {
    setConfrim(false);
    setRevenueCode('');
  };
  const [wModal, setWModal] = useState<number>(1);

  const ConfrimModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm}
        onHide={() => setConfrim(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-6 mx-11">
            {loader ? (
              <div className="d-flex justify-content-center align-items-center my-10">
                <MetroSpinner color="#6366F1" />
              </div>
            ) : wModal === 1 ? (
              <>
                <div className="mt-1">
                  <div className="col-form-label input-height d-flex justify-content-center">
                    <span className=" fw-bolder fs-8e text-dark">
                      Revenue Code
                    </span>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Please enter revenue code"
                      className={'no-outline ps-0 text-dark fs-8'}
                      value={revenueCode}
                      onChange={(e) => setRevenueCode(e.target.value)}
                      maxLength={50}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-around my-5">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className=" btn btn-white px-7 py-2 h-44 w-110 rounded-1 border-success "
                    onClick={() => cancelModal()}
                  >
                    <span className="indicator-label fs-base text-success ">
                      Back
                    </span>
                  </button>

                  <button
                    type="button"
                    className={` btn ${
                      revenueCode.length < 16 ? 'btn-secondary' : 'btn-success'
                    } h-44 w-110 rounded-1  center-all  `}
                    onClick={() => setWModal(2)}
                    disabled={revenueCode.length < 16}
                  >
                    <span className="indicator-label fs-base text-white ">
                      Proceed
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className=" d-flex flex-column align-items-center ">
                  <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                  <span className="text-center fs-base text-dark">
                    Are you sure you want to activate this service
                  </span>
                </div>
                <div className="d-flex justify-content-around mt-10">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                    onClick={() => cancelModal()}
                  >
                    <span className="indicator-label fs-base ">Cancel</span>
                  </button>
                  <button
                    className=" btn btn-success h-44 w-110 center-all"
                    type="button"
                    onClick={() => {
                      sendVerification();
                    }}
                  >
                    <span className="indicator-label fs-base ">Submit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const SuccessProvedModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={showProvedSuccess}
        onHide={() => setShowSProveduccess(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Approved!</span>
              </div>

              <div className="email-verification-container mb-5">
                {modalData?.data?.content?.data?.batchId ? (
                  <span className="email-verification-body">
                    {modalData?.data?.currentLevel === 1
                      ? `Your payment request with Reference ID ${modalData?.data?.content?.data?.batchId}  has been sent to the Approver Group for approval`
                      : ` Your payment request with Reference ID ${modalData?.data?.content?.data?.batchId} has been sent to the payment gateway for proccessing `}
                  </span>
                ) : (
                  <span className="email-verification-body">
                    {/* Your request has been sent to the OAGF for approval */}
                    Your request has been approved
                  </span>
                )}
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button className="otp-button " onClick={() => onNext()}>
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Reviewed!</span>
              </div>

              <div className="email-verification-container mb-5">
                {serviceModalData?.data ? (
                  <span className="email-verification-body">
                    The requests has been sent to the Approver Group for
                    approval
                  </span>
                ) : (
                  <span className="email-verification-body">
                    The requests with Reference Id{' '}
                    <strong>{modalData?.data.content?.data.batchId}</strong> has
                    been sent to the Approver Group for approval
                  </span>
                )}
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button className="otp-button " onClick={() => onNext()}>
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const ReviewModal = () => {
    return (
      <Modal
        dialogClassName="m-auto px-5 modal-card"
        aria-hidden="false"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        // backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >ƒ
        <Modal.Body className="rounded">
          <div className="m-10">
            <div>
              <div className="d-flex justify-content-between py-3 mb-9">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  PAYMENT DETAILS
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                   Reference ID
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {modalData?.data.content?.data.batchId}
                </span>
              </div>
{/* 
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Reference ID
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {modalData?.data.id}
                </span>
              </div> */}

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Payment Type
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {
                    modalData?.data?.content?.data?.paymentInstructions['0']
                      ?.paymentType?.description
                  }
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Total Payable
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {
                    modalData?.data?.content?.data?.paymentInstructions['0']
                      ?.amount
                  }
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Funding Account
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {
                    modalData?.data?.content?.data?.paymentInstructions['0']
                      ?.sourceAccount
                  }
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Beneficiary Name
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {
                    modalData?.data?.content?.data?.paymentInstructions['0']
                      ?.destAccountName
                  }
                </span>
              </div>

              {modalData?.data?.content?.data?.paymentInstructions['0']
                ?.destinationAccount && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                    Beneficiary Account
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    {
                      modalData?.data?.content?.data?.paymentInstructions['0']
                        ?.destinationAccount
                    }
                  </span>
                </div>
              )}

              {modalData?.data?.content?.data?.paymentInstructions['0']
                ?.destinationBankAccountCode && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                    Beneficiary Bank
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    {getBankName(
                      modalData?.data?.content?.data?.paymentInstructions['0']
                        ?.destinationBankAccountCode
                    ) ||
                      modalData?.data?.content?.data?.paymentInstructions['0']
                        ?.destinationName}
                  </span>
                </div>
              )}

              {modalData?.data?.content?.data?.paymentInstructions['0']
                ?.staffCustomerId && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                    Staff Customer Id
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    {
                      modalData?.data?.content?.data?.paymentInstructions['0']
                        ?.staffCustomerId
                    }
                  </span>
                </div>
              )}

              {modalData?.data?.content?.data?.paymentInstructions['0']
                ?.pfaCode && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                    PFA Code
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    {
                      modalData?.data?.content?.data?.paymentInstructions['0']
                        ?.pfaCode
                    }
                  </span>
                </div>
              )}

              {modalData?.data?.content?.data?.paymentInstructions['0']
                ?.pensionPin && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                    Pension Pin
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    {
                      modalData?.data?.content?.data?.paymentInstructions['0']
                        ?.pensionPin
                    }
                  </span>
                </div>
                )}
              

               {modalData?.data?.content?.data?.paymentInstructions['0']
                ?.contractAmount && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                   Contract Amount
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    {
                      modalData?.data?.content?.data?.paymentInstructions['0']
                        ?.contractAmount
                    }
                  </span>
                </div>
                )}
              

               {modalData?.data?.content?.data?.paymentInstructions['0']
                ?.vat && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  VAT(%)
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    {
                      modalData?.data?.content?.data?.paymentInstructions['0']
                        ?.vat
                    }
                  </span>
                </div>
                )}
              

                   {modalData?.data?.content?.data?.paymentInstructions['0']
                ?.from && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  FROM(Date)
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    {
                      modalData?.data?.content?.data?.paymentInstructions['0']
                        ?.from
                    }
                  </span>
                </div>
                )}
              

               {modalData?.data?.content?.data?.paymentInstructions['0']
                ?.to && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  TO(Date)
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    {
                      modalData?.data?.content?.data?.paymentInstructions['0']
                        ?.to
                    }
                  </span>
                </div>
                )}
              

              
              

            </div>
            <hr className="text-muted h-1px" />

            <div className="d-flex justify-content-between py-3">
              <button
                className={`btn btn-success px-7 py-2 h-44 w-110 rounded-1 ${
                  isLoadingReview ? 'btn bg-gray-500 cursor-disabled' : ''
                } `}
                onClick={() => {
                  ShowApproval(modalData);
                }}
                disabled={isLoadingReview}
              >
                <span className="indicator-label fs-base text-White">
                  {/* {modalData?.data.currentLevel === 2 ? 'Approve' : 'Review'} */}
                  Approve
                </span>
              </button>

              <button
                className={`btnpx-7 py-2 h-44 w-110 rounded-1 ${
                  isLoadingReview ? 'cursor-disabled' : ''
                } `}
                onClick={() => {
                  ShowRejectModal(modalData?.data?.id);
                }}
                disabled={isLoadingReview}
                style={{ background: 'red', border: 0, color: 'whitesmoke' }}
              >
                <span className="indicator-label fs-base text-White">
                  Reject
                </span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const ReviewServiceModal = () => {
    return (
      <Modal
        dialogClassName="m-auto px-5 modal-card-service"
        aria-hidden="false"
        show={showServices}
        onHide={() => setShowService(false)}
        animation
        centered
        size="lg"
        // backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-10">
            <div>
              <div className="d-flex justify-content-center py-3 mb-9">
                <span className="sev-cen">Service Details</span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="sev-pr">Service Profile</span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Service ID
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {serviceModalData?.data?.id}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Service Name / Label
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {serviceModalData?.data?.content?.data?.description}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Service List Type
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {serviceModalData?.data?.content?.data.multiSelect
                    ? 'Multiple'
                    : 'Single'}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Payment Notification Email Address
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {serviceModalData?.data?.content?.data?.notificationEmail}
                </span>
              </div>
            </div>
            <hr className="text-muted h-1px" />

            <div className="d-flex justify-content-between py-3">
              <span className="sev-pr">Prices & Charges</span>
            </div>
            <div className="table-responsive mb-1">
              <table className="table align-middle  gx-0 gy-1 mt-1">
                <thead className="bg-white">
                  <tr>
                    <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold ps-0">
                      Item Name
                    </th>
                    <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold">
                      Amount
                    </th>
                    <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold">
                      Collection Account
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {serviceModalData?.data?.content?.data?.paymentComponents?.map(
                    (array: any, index: React.Key | null | undefined) => {
                      return (
                        <tr key={index}>
                          <td className="text-dark fs-9  text-start border-0 capitaliseText ps-0 fw-bold ">
                            {array.name}
                          </td>
                          <td className="text-dark fs-9  text-start capitaliseText border-0 fw-bold">
                            {array.amount === 0
                              ? 'Not Fixed'
                              : `₦${Number(array.amount).toLocaleString('en', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}
                          </td>
                          <td className="text-dark fs-9 text-start border-0 fw-bold">
                            {array?.destAccount?.accountNumber} -{' '}
                            {array?.destAccount?.accountName}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Value Added Tax (VAT)
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {serviceModalData?.data?.content?.data?.vatRequired
                  ? 'Included'
                  : '  Not included'}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Processing fee
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                Included
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Beneficiary pays processing fee
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {' '}
                {serviceModalData?.data?.content?.data?.payerToPay
                  ? 'Yes'
                  : 'No'}{' '}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Instalmental payment
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {serviceModalData?.data?.content?.data?.installPayment
                  ? 'Allowed'
                  : '  Not allowed'}
              </span>
            </div>

            {serviceModalData?.data?.content?.data?.customFields.length !==
              0 && (
              <>
                <hr className="text-muted h-1px" />

                <div className="d-flex justify-content-between py-3">
                  <span className="sev-pr">More Information</span>
                </div>
                <div className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                    Field name
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    Data type
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    Character count
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    Optional
                  </span>
                </div>

                {serviceModalData?.data?.content?.data?.customFields.map(
                  (data, i) => {
                    return (
                      <div
                        className="d-flex justify-content-between py-3"
                        key={i}
                      >
                        <span className="text-muted fs-9 fw-bold text-start min-w-25 capitaliseText">
                          {data?.label}
                        </span>
                        <span className="text-dark fs-9 fw-bold text-end capitaliseText">
                          {data?.customFieldType?.type}
                        </span>
                        <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                          {data?.customFieldType?.limit
                            ? data?.customFieldType?.limit
                            : '-'}
                        </span>
                      </div>
                    );
                  }
                )}
              </>
            )}

            {serviceModalData?.data?.content?.data?.websiteUrl.length !== 0 &&
              serviceModalData?.data?.content?.data?.notificationUrl.length !==
                0 && (
                <>
                  <hr className="text-muted h-1px" />
                  <div className="d-flex justify-content-between py-3">
                    <span className="sev-pr">Integration</span>
                  </div>
                </>
              )}
            {serviceModalData?.data?.content?.data?.websiteUrl.length !== 0 && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Website URL
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {serviceModalData?.data?.content?.data?.websiteUrl}
                </span>
              </div>
            )}
            {serviceModalData?.data?.content?.data?.notificationUrl.length !==
              0 && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Notification URL
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {serviceModalData?.data?.content?.data?.notificationUrl}
                </span>
              </div>
            )}

            <div
              className="d-flex justify-content-center py-3"
              style={{ gap: '2rem' }}
            >
              <button
                className="btn btn-success px-7 py-2 h-44 w-110 rounded-1 "
                onClick={() => {
                  ShowApproval(serviceModalData);
                }}
              >
                <span className="indicator-label fs-base text-White">
                  {/* {modalData?.data.currentLevel === 2 ? 'Approve' : 'Review'} */}
                  Approve
                </span>
              </button>

              <button
                className="btnpx-7 py-2 h-44 w-110 rounded-1 "
                onClick={() => {
                  ShowRejectModal(serviceModalData?.data.id);
                }}
                style={{ background: 'red', border: 0, color: 'whitesmoke' }}
              >
                <span className="indicator-label fs-base text-White">
                  Reject
                </span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };



  const ReviewUserCreationModal = () => {
    return (
      <Modal
        dialogClassName="m-auto px-5 modal-card-service"
        aria-hidden="false"
        show={showUsersCreation}
        onHide={() => setShowUsersCreation(false)}
        animation
        centered
        size="lg"
        // backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-10">
            <div>
              <div className="d-flex justify-content-center py-3 mb-9">
                <span className="sev-cen">User Creation</span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="sev-pr">User Details</span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                First Name
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
               {userModalData?.data?.content?.data?.firstName}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Last Name
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                   {userModalData?.data?.content?.data?.lastName}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Phone Number
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                                {userModalData?.data?.content?.data?.phoneNumber} 

                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
             Email
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {userModalData?.data?.content?.data?.email} 
                </span>
              </div>

               <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
             Organization Name
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {userModalData?.data?.content?.data?.organizationName} 
                </span>
              </div>



              
            </div>
            <hr className="text-muted h-1px" />

                 <div>
          

              <div className="d-flex justify-content-between py-3">
                <span className="sev-pr">User Roles</span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                {/* First Name */}
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {/* dfvfvfvf */}
                </span>
              </div>
            </div>

    <hr className="text-muted h-1px" />
            

 


            <div
              className="d-flex justify-content-center py-3"
              style={{ gap: '2rem' }}
            >
              <button
                className="btn btn-success px-7 py-2 h-44 w-110 rounded-1 "
                onClick={() => {
                  ShowApproval(userModalData);
                }}
              >
                <span className="indicator-label fs-base text-White">
                  {/* {modalData?.data.currentLevel === 2 ? 'Approve' : 'Review'} */}
                  Approve
                </span>
              </button>

              <button
                className="btnpx-7 py-2 h-44 w-110 rounded-1 "
                onClick={() => {
                  ShowRejectModal(userModalData?.data.id);
                }}
                style={{ background: 'red', border: 0, color: 'whitesmoke' }}
              >
                <span className="indicator-label fs-base text-White">
                  Reject
                </span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const [approvalData, setApprovalData] = useState<any>({});

  const ShowApproval = (data: any) => {
    setApprovalData(data);
    setShowService(false);
    setShow(false);
    setShowUsersCreation(false)

    var x: any = localStorage.getItem('lazyday_user');
    var authorities = x !== null ? JSON.parse(x) : null;
    if (
      data?.data?.awaitingGroup === 'collection_rules#approver' &&
      authorities.oagf
    ) {
      review(data?.data?.id, data?.data);
    } else {
      setApproval(true);
    }
  };

  const cancelShowApproval = () => {
    setApprovalData({});
    setApproval(false);
    setLoader(false);
  };
  const ApprovalModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showApproval}
        onHide={() => setApproval(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-6 mx-5">
            {loader ? (
              <div className="d-flex justify-content-center align-items-center my-10">
                <MetroSpinner color="#6366F1" />
              </div>
            ) : (
              <div className="">
                <div className=" d-flex flex-column align-items-center ">
                  <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                  <span className="text-center fs-base text-dark">
                    Are you sure you want to approve this request
                  </span>
                </div>
                <div className="d-flex justify-content-around mt-10">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                    onClick={() => cancelShowApproval()}
                  >
                    <span className="indicator-label fs-base ">Cancel</span>
                  </button>
                  <button
                    className=" btn btn-success h-44 w-110 center-all"
                    type="button"
                    onClick={() => {
                      review(approvalData?.data?.id, approvalData?.data);
                    }}
                  >
                    <span className="indicator-label fs-base ">Proceed</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    // <>
    <div className={'reviewerDashboard'}>
      <div className={'reviewerTableContainer'}>
        <div className={'reviewerTableActionButton mb-9'}>
          <div>Requests Awaiting Action</div>
          {/* <div>
                          <Select
                              defaultValue=""
                              onChange={() => {}}
                              options={[
                                  {
                                      value: '',
                                      label: 'Payment Option',
                                  },
                                  {
                                      value: 'Bulk',
                                      label: 'Bulk',
                                  },
                                  {
                                      value: 'Single',
                                      label: 'Single',
                                  }
                              ]}
                          />

                        
                      </div> */}
        </div>
        <div>
          <Table dataSource={dataSource} columns={columns} />
        </div>
      </div>
      {SuccessModal()}
      {ReviewModal()}
      {ReviewServiceModal()}
      {SuccessProvedModal()}
      {RejectModal()}
      {ConfrimModal()}
      {RejectionModal()}
      {ApprovalModal()}
      {ReviewUserCreationModal()}

      <Modal
        dialogClassName=" m-auto px-7 modal-card-signup"
        aria-hidden="true"
        show={show2}
        onHide={() => setShow2(false)}
        animation
        centered
        size="lg"
        // backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          {loader ? (
            <div className="d-flex justify-content-center align-items-center my-10">
              <MetroSpinner color="#6366F1" />
            </div>
          ) : (
            <div className="">
              <div className=" d-flex flex-column align-items-center ">
                <div className="mt-5">
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 7V16.75C1.5 16.9489 1.57902 17.1397 1.71967 17.2803C1.86032 17.421 2.05109 17.5 2.25 17.5H18.75C18.9489 17.5 19.1397 17.421 19.2803 17.2803C19.421 17.1397 19.5 16.9489 19.5 16.75V7M1.5 7L10.5 1L19.5 7M1.5 7L8.86363 12.25M19.5 7L12.1363 12.25M8.86363 12.25L1.73126 17.2881M8.86363 12.25L12.1363 12.25M19.2688 17.2881L12.1363 12.25"
                      stroke="#3BAEFE"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className="email-verification-container">
                  <span className="email-verification-text">
                    Email Verification
                  </span>
                </div>

                <div className="email-verification-container">
                  <div className="email-verification-body">
                    <div>Please enter the 6-digit code sent to</div>
                    <div> {orgUserParser?.organizationUser.email} </div>
                  </div>
                </div>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={'otp-box mt-5'}
                  inputStyle={'w-100'}
                  placeholder="------"
                />

                <div className="email-verification-container mt-2">
                  <span className="resend-code-text" onClick={sendVerification}>
                    Resend Code
                  </span>
                </div>
              </div>

              <div className=" d-flex flex-column align-items-center mt-5 ">
                <button
                  className={`otp-button center-all ${
                    otp.length !== 6 ? 'btn bg-gray-500' : ''
                  } `}
                  onClick={() =>
                    submitVerification(
                      modalData?.data.id
                        ? modalData?.data.id
                        : serviceModalData?.data.id
                    )
                  }
                  disabled={
                    otp.length !== 6
                      ? true
                      : isVerfyLoading || loadingVerify
                      ? true
                      : false
                  }
                >
                  {isVerfyLoading || loadingVerify ? (
                    <span className="otp-button-text">Loading..</span>
                  ) : (
                    <span className="otp-button-text">Verify</span>
                  )}
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
    // </>
  );
};

export default ReviewerDashboard;
