import moment from "moment";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { money } from "../../../../core/assets/imgs";
import { ErrorToast } from "../../../componets/toast";
import { isAllowedNumeric } from "../revenuePayments/components/UsefulFn";
// import {Button} from '@simplipayng/checkout';
import {Button} from '@simplipayng/checkout';
import { Modal } from "react-bootstrap";
import { useUpdateInvoice } from "../hooks/usePayment";
import SuccessModal from "./SuccessModal";
    // eslint-disable-next-line react-hooks/rules-of-hooks


const VoxePayment: React.FC<any> = () => {
  const navigate = useNavigate();

    const { data: dataInvoice, mutate, isLoading } = useUpdateInvoice();


  // const data = {
  //   email: "Ayodimeji",
  //   amount: 3000,
  //   customerId: 30007,
  //   transactionRef: "jhdjhjhjh",
  //   currency: "NGN"
  // }

   const location = useLocation();
  const { data } = location?.state;
  

  console.log(data);


  if (!data) {
     navigate("/");
  }

  const close = () => {

  }
  
   
     const handleError = (error: any) => {
      console.log(error.response.data.errorResponse.errors[0]);
      //  alert(error.response.data.errorResponse.errors[0].message)
    ErrorToast(error.response.data.errorResponse.errors[0].message || "Something went wrong please try again.");

  }
  const [show, setShow] = useState(false)
  

  const handleSuccess = (res: any) => { 
     
    console.log(res);


    mutate(
      data?.customerId,
      {
        onSuccess: (res: any) => {
       setShow(true)
        },
      }
    );
     

  }



   const refresh = () => {
         navigate("/", { replace: true });
   }


   const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Successful!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  Your transaction was Successful.
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
           

              <button className="otp-button " onClick={() => refresh()}>
                <span className="otp-button-text">Done</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (

    <>
          <Button
        data={data}
        forceshow={"true"}
        appId='99c6d623-f83f-3f7f-80c0-7aca1ff13856'
        stage='live' 
        onCancel={() => close()}
        onError={handleError}
      

        onSuccess={handleSuccess}
        
     
     >Pay</Button>
      
      { SuccessModal()}
      {/* {handleSuccess("p")} */}
    </>
 
  );
};
export default VoxePayment;
