import { Tooltip } from "antd";
import React from "react";
import { InfoSvg } from "./InfoSvg";

interface InfoTooltipProps {
  text: string;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({ text }) => {
  return (
    <Tooltip placement="topLeft" title={text}>
      <>
        <InfoSvg />
      </>
    </Tooltip>
  );
};
