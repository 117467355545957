import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Preview } from '../../../auth/step/Preview';
import { StepOne } from './steps/stepOne';
import { StepTwo } from './steps/stepTwo';
import {  useEditRole2, useRequestOnboardingStepUserCreationDetails } from 'app/pages/auth/hooks/useUser';
import { Modal } from "react-bootstrap";
import { FlapperSpinner } from 'react-spinners-kit';


const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  officialEmail: '',
};

const finalStep = 3;

// const StepOne: React.FC<Step1Props> = ({ handleNextStep }) => {}
const CreateUsers = () => {
  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState('');
  const [dropdowns, setDropdowns] = useState({
    // "mda-to
  });


   const { data, mutate: OnboardingMutate, isLoading } =
      useRequestOnboardingStepUserCreationDetails();

  const [userLogin, setUserLogin] = useState('');

  const [stepOneData, setStepOneData] = useState<any>([]);

  const handleNextStep = () => {
    if (step === finalStep) {
      console.log(step);
    } else {
      setStep(step + 1);
      console.log(step);
    }
  };
  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: Schema,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    // Show the dropdown corresponding to the selected radio button
    setDropdowns((prevDropdowns) => ({
      ...prevDropdowns,
      [value]: true,
    }));
  };

  const handleCheckboxChange = (event) => {
    // Handle checkbox changes
  };

  

const groupedData = stepOneData?.businessGroups?.reduce((acc, item) => {
  const [cat, role] = item.code.split('#');
  if (!acc[cat]) {
    acc[cat] = [];
  }
  acc[cat].push({ role, description: item.description });
  return acc;
}, {});

    
      const TableUserWork: React.FC<any> = () => {
    return (
<table>
  <thead>
    <tr>
      <th>Category</th>
      <th>Roles</th>
    </tr>
  </thead>
  <tbody>
    {groupedData && Object.keys(groupedData).map((cat, i) => (
      <tr key={i}>
        <td>{cat
  .split('_')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ')}</td>
        <td>
          {groupedData[cat].map((role, j) => (
            <div key={j}>
              <strong>{role.role}</strong>
            </div>
          ))}
        </td>
      </tr>
    ))}
  </tbody>
</table>

    );
      };
  
  const { data: dateEditRoleUser, mutate: mutateEditRoleUser, isLoading: isLoadingEditRoleUser } = useEditRole2();
  


  
  const submit = () => {
    console.log("here 1");

    console.log(stepOneData?.businessGroups, "pdopdpodo");
    

    // Initialize an empty payload
let payload: any = [];

// Check for 'payroll_payments#initiator'
    const hasPayrollPaymentsInitiator = stepOneData?.businessGroups.some(p => p.code === "payroll_payments#initiator");
    
    const hasPayrollPaymentsApprover = stepOneData?.businessGroups.some(
  (p) => p.code === 'payroll_payments#approver'
);


// Check for 'collection_rule#initiator'
    const hasCollectionRuleInitiator = stepOneData?.businessGroups.some(p => p.code === "collection_rule#initiator");
    
    const hasCollectionPaymentsApprover = stepOneData?.businessGroups.some(
  (p) => p.code === 'collection_rule#approver'
);

const hasUserManagementRuleInitiator = stepOneData?.businessGroups.some(
  (p) => p.code === 'user_management#initiator'
);
const hasUserManagementRuleApprover = stepOneData?.businessGroups.some(
  (p) => p.code === 'user_management#approver'
);

const hasSalaryRuleInitiator = stepOneData?.businessGroups.some(
  (p) => p.code === 'salary_payments#initiator'
);



if (hasPayrollPaymentsInitiator) {
    payload.push({ "name": "Merchant Payments Initiator" });
}

if (hasCollectionRuleInitiator) {
  payload.push({ name: 'Collections Initiator' });
}

if (hasUserManagementRuleInitiator) {
  payload.push({ name: 'Merchant User Admin' });
}

if (hasSalaryRuleInitiator) {
  payload.push({ name: 'Merchant Payments Initiator' });
}


if (hasPayrollPaymentsApprover) {
  payload.push({ name: 'Merchant Payments Approver' });
}

    
    if (hasCollectionPaymentsApprover) {
  payload.push({ name: 'Collections Approver' });
}

    
    
if (hasUserManagementRuleApprover) {
  payload.push({ name: 'Merchant User Approver' });
}


console.log(payload);

    
    OnboardingMutate(
        {
          firstName: stepOneData.firstName,
          lastName: stepOneData.lastName,
          email: stepOneData.email,
          password: '12345',
          phoneNumber: stepOneData.phoneNumber,
          login: stepOneData.login,
          businessGroups: stepOneData?.businessGroups
        },

        {
          onSuccess: (res: any) => {
 
                var payload2 = {
      value: payload,
      username: stepOneData.login.toLowerCase()
    }

        mutateEditRoleUser(
       payload2,

        {
          onSuccess: (res: any) => {
            //  window.location.reload();

            console.log(res);
            
           
          },
        }
      );
            



       
            setShow(true)




            
            
            
          },
        }
      );
  }

    const refresh = () => {
     window.location.reload();
  }
  
const [show, setShow] = useState(false)

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Successful!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  You have successfully added a user.
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
           

              <button className="otp-button " onClick={() => refresh()}>
                <span className="otp-button-text">Done</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  

  return (
    <div>
      {/* {
                step !== 3 ? ( */}
      <div className={'createUser'}>
        <div className="  m-auto my-11 responsive-card overflow-none d-flex flex-column">
          <div className="d-flex align-items-center">
            <span
              className={`fw-bold fs-9 ${
                step >= 1 ? 'touched-step' : 'unTouched-step'
              }`}
            >
              1
            </span>

            <span
              className={`mx-1 ${
                step >= 1 ? 'touched-line' : 'unTouched-line'
              }`}
            />

            <span
              className={`fw-bold fs-9 ${
                step >= 2 ? 'touched-step' : 'unTouched-step'
              }`}
            >
              2
            </span>

            <span
              className={`mx-1  ${
                step === 3 ? 'touched-line' : 'unTouched-line'
              }`}
            />

            <span
              className={`fw-bold fs-9 ${
                step === 3 ? 'touched-step' : 'unTouched-step'
              }`}
            >
              3
            </span>
          </div>

          <div className="d-flex justify-content-between my-4">
            <span className="fs-9 ">Step 1 of 3</span>

            <span className="fs-9 ">Step 2 of 3</span>

            <span className="fs-9 ">Step 3 of 3</span>
          </div>
        </div>

        {step === 1 && (
          <StepOne
            handleNextStep={handleNextStep}
            setUserLogin={setUserLogin}
            setStepOneData={setStepOneData}
            stepOneData={stepOneData}
          />
        )}

        {step === 2 && (
          <StepTwo
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            userLogin={userLogin}
            stepOneData={stepOneData}
             setStepOneData={setStepOneData}
          />
        )}

        {step === 3 && (
        <>
                { isLoading ? (
        <div className="loading-container-2">
          <FlapperSpinner />
        </div>
      ) : (
          <div className={'userRoles'}>
            <div className="d-flex justify-content-between ">
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-dark fs-5 fw-bolder lh-lg">
                  Personal Details
                </span>
              </div>
            </div>
            <hr className="text-muted h-1px" />
            <div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  First Name
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {stepOneData?.firstName}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Last Name
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {stepOneData?.lastName}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Phone Number
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {stepOneData?.phoneNumber}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Email
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {stepOneData?.email}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Username
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {stepOneData?.login}
                </span>
              </div>
            </div>
                      <hr className="text-muted h-1px" />
                      
             <TableUserWork />

                  <div className={'userRolesNextButtonG'}>
                    
                    {/* <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                  className="nav-btn-back nav-back-text"
                  id="nav-btn"
                  onClick={handlePreviousStep}
                >
                  <i className="fa fa-solid fa-arrow-left"></i>{' '}
                  <span style={{ marginLeft: '0.2rem' }}>back</span>
                    </span>{' '} */}
                    
              <button
                className=""
                onClick={submit}
              >
                Create
                    </button>
                    
                    
            </div>
      </div>
         )
            }
            </>
        )}
      </div>
          {SuccessModal()}

    </div>

  );
};

export default CreateUsers;
