import {FlapperSpinner} from "react-spinners-kit";
import {Layout} from "../../layout";
import {useMdaActivate, useMdaDetails} from "../../../auth/hooks/useUser";
import {DatePicker, Select} from "antd";
import dayjs from "dayjs";
import React from "react";
import {Bar, Doughnut, Pie} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
} from "chart.js";
import {faker} from "@faker-js/faker";
import {useNavigate} from "react-router-dom";
import { Progress, Space } from 'antd';


ChartJS.register(CategoryScale, LinearScale, BarElement);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Chart.js Line Chart",
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    },
};

const labels = [
    "Retail or Vendor",
    "Pension",
    "Other Deductions",
    "Salary",
    "Tax",
    "Bill",
    "Reprocess failed Payment",
];
export const doughnutOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: "left" as const,
        },
        title: {
            display: true,
            text: "Chart.js Line Chart",
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    },
};

const doughnutLabels = [
    "Reprocess failed Payment",
    "Other Deductions",
    "Pension Payment",
    "Retail or Vendor",
    "Bill Payment",
    "Salary Payment",
    "Tax Payment",
];
export const data = {
    labels,
    datasets: [
        {
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000000 })),
            borderColor: "#A5CE9B",
            pointRadius: 0,
            backgroundColor: "#A5CE9B", // set the backgroundColor property to transparent to remove the background lines
            borderWidth: 1,
        },
    ],
};
export const doughnutData = {
    labels,
    datasets: [
        {
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000000 })),
            borderColor: "#A5CE9B",
            pointRadius: 0,
            backgroundColor: [
                "#3BAEFE",
                "#006CEC",
                "#3EC5E3",
                "#6366F1",
                "#EE151D",
                "#FBD0D1",
                "#A5CE9B",
            ], // set the backgroundColor property to transparent to remove the background lines
            borderWidth: 1,
        },
    ],
};


const dateFormat = "YYYY/MM/DD";
const PaymentView = () => {
    const navigate = useNavigate()

    // const chartWidth = 800;

    const {
        isLoading,
    } = useMdaDetails();

    const {
        data: activateData,
        mutate: activateMutate,
        isLoading: activateLoading,
    } = useMdaActivate();


    return (
       <>
                {isLoading || activateLoading ? (
                    <div className="loading-container">
                        <FlapperSpinner/> {/* Replace with your loading indicator */}
                    </div>
                ) : (
                    <div className={"collectionView"}>
                       <div className={"collectionViewRow1"}>
                           <div className={"collectionViewBreadcrumb"}>
                               <div>Analytics</div>

                               <svg width="10" height="13" viewBox="0 0 10 13" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                   <path
                                       d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                       fill="#6366F1"/>
                               </svg>

                               <div>Payment View</div>
                           </div>

                           <div>
                               <div className="mx-1">
                                   <Select
                                       style={{ width: 150 }}
                                       options={[
                                           { value: "Last 30 Days", label: "Last 30 Days" },
                                           { value: "Last 60 Days", label: "Last 60 Days" },
                                           { value: "Last 90 Days", label: "Last 90 Days" },
                                       ]}
                                       defaultValue={"Last 30 Days"}
                                       dropdownMatchSelectWidth={false}
                                       size="large"
                                   />
                               </div>

                               <div>
                                   <span className="data-range-text-overview">Date Range:</span>
                                   <span className="data-from-text-overview">From</span>
                                   <DatePicker
                                       defaultValue={dayjs("2015/01/01", dateFormat)}
                                       format={dateFormat}
                                       className="datepicker"
                                   />
                                   <span className="data-from-text-overview">To</span>
                                   <DatePicker
                                       defaultValue={dayjs("2015/01/01", dateFormat)}
                                       format={dateFormat}
                                       className="datepicker"
                                   />{" "}
                               </div>
                           </div>
                       </div>

                        <div className={"collectionViewRow2"}>
                            <div style={{background: '#E7F7FB', border: '1px solid #3EC5E3'}}>
                                <div onClick={() => navigate('total-payables')}>View Report</div>
                                <div>
                                    <Progress type="circle" percent={50} size={60} strokeColor={'#3EC5E3'} showInfo={false}/>
                                </div>
                                <div>N6,345,678.67</div>
                                <div>
                                    <div>400</div>
                                    <div>transactions</div>
                                </div>
                                <div>Total Payables</div>
                                <div>
                                    This represents all payments that are
                                    pending reviewal and pending
                                    approval
                                </div>
                            </div>

                            <div style={{background: '#EDF9F1', border: '1px solid #A5CE9B'}}>
                                <div onClick={() => navigate('payments-approved')}>View Report</div>
                                <div>
                                    <Progress type="circle" percent={50} size={60} strokeColor={'#6366F1'} showInfo={false}/>
                                </div>
                                <div>N6,345,678.67</div>
                                <div>
                                    <div>300</div>
                                    <div>transactions</div>
                                </div>
                                <div>Payments Approved</div>
                                <div>
                                    This represents all payments that
                                    have gone through final approval
                                    and ready for processing
                                </div>
                            </div>

                            <div style={{background: '#FDE7E8', border: '1px solid #EE151D'}}>
                                <div onClick={() => navigate('payments-rejected')}>View Report</div>
                                <div>
                                    <Progress type="circle" percent={50} size={60} strokeColor={'#EE151D'} showInfo={false}/>
                                </div>
                                <div>N6,345,678.67</div>
                                <div>
                                    <div>400</div>
                                    <div>transactions</div>
                                </div>
                                <div>Payments Rejected</div>
                                <div>
                                    This represents all payments rejected either at the point of
                                    reviewal or approval.
                                </div>
                            </div>
                        </div>

                        <div className={"collectionViewRow3"}>
                            <div>Pie Chart Representation for Total Payables</div>

                            <div>
                                <div>
                                    <div className="mx-1">
                                        <Select
                                            style={{ width: 150 }}
                                            options={[
                                                { value: "Last 30 Days", label: "Last 30 Days" },
                                                { value: "Last 60 Days", label: "Last 60 Days" },
                                                { value: "Last 90 Days", label: "Last 90 Days" },
                                            ]}
                                            defaultValue={"Last 30 Days"}
                                            dropdownMatchSelectWidth={false}
                                            size="large"
                                        />
                                    </div>

                                    <div>
                                        <span className="data-range-text-overview">Date Range:</span>
                                        <span className="data-from-text-overview">From</span>
                                        <DatePicker
                                            defaultValue={dayjs("2015/01/01", dateFormat)}
                                            format={dateFormat}
                                            className="datepicker"
                                        />
                                        <span className="data-from-text-overview">To</span>
                                        <DatePicker
                                            defaultValue={dayjs("2015/01/01", dateFormat)}
                                            format={dateFormat}
                                            className="datepicker"
                                        />{" "}
                                    </div>
                                </div>

                                <div>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" transform="translate(0.5 0.5)" fill="#f6eeff"/>
                                        <path d="M20.75 14.75V20C20.75 20.1989 20.671 20.3897 20.5303 20.5303C20.3897 20.671 20.1989 20.75 20 20.75H5C4.80109 20.75 4.61032 20.671 4.46967 20.5303C4.32902 20.3897 4.25 20.1989 4.25 20V14.75M8.5625 10.8135L12.5 14.75L16.4375 10.8135M12.5 4.25V14.7472" stroke="#6366F1" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>

                            <div style={{width: '204px', height: '204px'}}>
                                <Doughnut options={doughnutOptions} data={doughnutData}  />
                            </div>
                        </div>

                        <div className={"collectionViewRow3"}>
                            <div>Bar Chart Representation for Payments Approved</div>

                            <div>
                                <div>
                                    <div className="mx-1">
                                        <Select
                                            style={{ width: 150 }}
                                            options={[
                                                { value: "Last 30 Days", label: "Last 30 Days" },
                                                { value: "Last 60 Days", label: "Last 60 Days" },
                                                { value: "Last 90 Days", label: "Last 90 Days" },
                                            ]}
                                            defaultValue={"Last 30 Days"}
                                            dropdownMatchSelectWidth={false}
                                            size="large"
                                        />
                                    </div>

                                    <div>
                                        <span className="data-range-text-overview">Date Range:</span>
                                        <span className="data-from-text-overview">From</span>
                                        <DatePicker
                                            defaultValue={dayjs("2015/01/01", dateFormat)}
                                            format={dateFormat}
                                            className="datepicker"
                                        />
                                        <span className="data-from-text-overview">To</span>
                                        <DatePicker
                                            defaultValue={dayjs("2015/01/01", dateFormat)}
                                            format={dateFormat}
                                            className="datepicker"
                                        />{" "}
                                    </div>
                                </div>

                                <div>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" transform="translate(0.5 0.5)" fill="#f6eeff"/>
                                        <path d="M20.75 14.75V20C20.75 20.1989 20.671 20.3897 20.5303 20.5303C20.3897 20.671 20.1989 20.75 20 20.75H5C4.80109 20.75 4.61032 20.671 4.46967 20.5303C4.32902 20.3897 4.25 20.1989 4.25 20V14.75M8.5625 10.8135L12.5 14.75L16.4375 10.8135M12.5 4.25V14.7472" stroke="#6366F1" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>

                            <div>
                                <Bar options={options} data={data} />
                            </div>
                        </div>
                    </div>
                )}
            </>
    );
};

export default PaymentView;
